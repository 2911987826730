import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import GetOwnSalesResponse from '../../common/types/messages/GetOwnSalesResponse';
import PostSearchResourceMarketResponse from '../../common/types/messages/PostSearchResourceMarketResponse';
import {
  GetOwnMarketSalesActionType,
  getOwnMarketSalesCompleted,
  GetOwnMarketSalesCompletedActionType,
  getOwnMarketSalesFailed,
  GetOwnMarketSalesFailedActionType,
  MarketActionTypes, PostMarketBuyRequestActionType, postMarketBuyRequestCompleted, PostMarketBuyRequestCompletedActionType, postMarketBuyRequestFailed, PostMarketBuyRequestFailedActionType, PostMarketCancelRequestActionType, postMarketCancelRequestCompleted, PostMarketCancelRequestCompletedActionType, postMarketCancelRequestFailed, PostMarketCancelRequestFailedActionType, PostMarketSearchRequestActionType, postMarketSearchRequestCompleted, PostMarketSearchRequestCompletedActionType, postMarketSearchRequestFailed, PostMarketSearchRequestFailedActionType, PostMarketSellRequestActionType, postMarketSellRequestCompleted, PostMarketSellRequestCompletedActionType, postMarketSellRequestFailed, PostMarketSellRequestFailedActionType,
} from '../actions/marketActions';
import { cityUserEntityExpired, CityUserEntityExpiredActionType } from '../actions/userKingdomActions';

export const postMarketSellEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(MarketActionTypes.PostMarketSellRequest),
    mergeMap((action) => onPostMarketSell(action, dependencies))
  );
}

export const onPostMarketSell: (action: PostMarketSellRequestActionType, dependencies: AppDependencies) 
  => Observable<PostMarketSellRequestCompletedActionType | PostMarketSellRequestFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postMarketSellRequest$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return postMarketSellRequestCompleted(response as GetOwnSalesResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(postMarketSellRequestFailed(error.response.value));
    })
  );
}

export const postMarketCancelEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(MarketActionTypes.PostMarketCancelRequest),
    mergeMap((action) => onPostMarketCancel(action, dependencies))
  );
}

export const onPostMarketCancel: (action: PostMarketCancelRequestActionType, dependencies: AppDependencies) 
  => Observable<PostMarketCancelRequestCompletedActionType | PostMarketCancelRequestFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postMarketCancelRequest$(action.payload!.saleId);

  return callResponse$.pipe(
    map(response => {
      return postMarketCancelRequestCompleted(action.payload!.saleId);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(postMarketCancelRequestFailed(error.response.value));
    })
  );
}

export const postMarketBuyEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(MarketActionTypes.PostMarketBuyRequest),
    mergeMap((action) => onPostMarketBuy(action, dependencies))
  );
}

export const onPostMarketBuy: (action: PostMarketBuyRequestActionType, dependencies: AppDependencies) 
  => Observable<PostMarketBuyRequestCompletedActionType | PostMarketBuyRequestFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postMarketBuyRequest$(action.payload!.saleId);

  return callResponse$.pipe(
    map(response => {
      return postMarketBuyRequestCompleted(action.payload!.saleId);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(postMarketBuyRequestFailed(error.response.value));
    })
  );
}

export const postMarketSearchEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(MarketActionTypes.PostMarketSearchRequest),
    mergeMap((action) => onPostMarketSearch(action, dependencies))
  );
}

export const onPostMarketSearch: (action: PostMarketSearchRequestActionType, dependencies: AppDependencies) 
  => Observable<PostMarketSearchRequestCompletedActionType | PostMarketSearchRequestFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postMarketSearchRequest$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return postMarketSearchRequestCompleted(response as PostSearchResourceMarketResponse);
    }),
    catchError(error => {
      return of(postMarketSearchRequestFailed(error.response.value));
    })
  );
}

export const getOwnMarketSalesEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(MarketActionTypes.GetOwnMarketSales),
    mergeMap((action) => onGetOwnMarketSales(action, dependencies))
  );
}

export const onGetOwnMarketSales: (action: GetOwnMarketSalesActionType, dependencies: AppDependencies) 
  => Observable<GetOwnMarketSalesCompletedActionType | GetOwnMarketSalesFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getOwnMarketSales$(action.payload!.kingdomId);

  return callResponse$.pipe(
    map(response => {
      return getOwnMarketSalesCompleted(response as GetOwnSalesResponse);
    }),
    catchError(error => {
      return of(getOwnMarketSalesFailed(error.response.value));
    })
  );
}


