import Action from './baseAction';
import PostPurchaseRequest from '../../common/types/messages/PostPurchaseRequest';

export enum PurchaseActionTypes {
  PostPurchase = "POST_PURCHASE",
  PostPurchaseCompleted = "POST_PURCHASE_COMPLETED",
  PostPurchaseFailed = "POST_PURCHASE_FAILED",
  UpdatePurchaseForm = "UPDATE_PURCHASE_FORM",
  OpenPurchaseForm = "OPEN_PURCHASE_FROM",
  ClosePurchaseForm = "CLOSE_PURCHASE_FORM"
}

export type PostPurchasePayloadType = { request: PostPurchaseRequest };
export type PostPurchaseActionType = Action<PurchaseActionTypes.PostPurchase, PostPurchasePayloadType>;
export const postPurchase = (request: PostPurchaseRequest):PostPurchaseActionType => ({type: PurchaseActionTypes.PostPurchase, payload: { request } });

export type PostPurchaseCompletedPayloadType = { };
export type PostPurchaseCompletedActionType = Action<PurchaseActionTypes.PostPurchaseCompleted, PostPurchaseCompletedPayloadType>;
export const postPurchaseCompleted = (): PostPurchaseCompletedActionType => ({type: PurchaseActionTypes.PostPurchaseCompleted, payload: { } });

export type PostPurchaseFailedPayloadType = { error: Error; };
export type PostPurchaseFailedActionType = Action<PurchaseActionTypes.PostPurchaseFailed, PostPurchaseFailedPayloadType>;
export const postPurchaseFailed = (error: Error): PostPurchaseFailedActionType => ({type: PurchaseActionTypes.PostPurchaseFailed, payload: { error } });

export type UpdatePurchaseFormPayloadType = { request: PostPurchaseRequest };
export type UpdatePurchaseFormActionType = Action<PurchaseActionTypes.UpdatePurchaseForm, UpdatePurchaseFormPayloadType>;
export const updatePurchaseForm = (request: PostPurchaseRequest):UpdatePurchaseFormActionType => ({type: PurchaseActionTypes.UpdatePurchaseForm, payload: { request } });

export type OpenPurchaseFormPayloadType = { };
export type OpenPurchaseFormActionType = Action<PurchaseActionTypes.OpenPurchaseForm, OpenPurchaseFormPayloadType>;
export const openPurchaseForm = (): OpenPurchaseFormActionType => ({type: PurchaseActionTypes.OpenPurchaseForm, payload: { } });

export type ClosePurchaseFormPayloadType = { };
export type ClosePurchaseFormActionType = Action<PurchaseActionTypes.ClosePurchaseForm, ClosePurchaseFormPayloadType>;
export const closePurchaseForm = (): ClosePurchaseFormActionType => ({type: PurchaseActionTypes.ClosePurchaseForm, payload: { } });