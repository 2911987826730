import Action from './baseAction';
import GetNewsResponse from '../../common/types/messages/GetNewsResponse';

export enum NewsActionTypes {
  GetNews = "GET_NEWS",
  GetNewsCompleted = "GET_NEWS_COMPLETED",
  GetNewsFailed = "GET_NEWS_FAILED",
}

export type GetNewsPayloadType = { page: number };
export type GetNewsActionType = Action<NewsActionTypes.GetNews, GetNewsPayloadType>;
export const getNews = (page: number):GetNewsActionType => ({type: NewsActionTypes.GetNews, payload: { page } });

export type GetNewsCompletedPayloadType = { response: GetNewsResponse; };
export type GetNewsCompletedActionType = Action<NewsActionTypes.GetNewsCompleted, GetNewsCompletedPayloadType>;
export const getNewsCompleted = (response: GetNewsResponse): GetNewsCompletedActionType => ({type: NewsActionTypes.GetNewsCompleted, payload: { response } });

export type GetNewsFailedPayloadType = { error: Error; };
export type GetNewsFailedActionType = Action<NewsActionTypes.GetNewsFailed, GetNewsFailedPayloadType>;
export const getNewsFailed = (error: Error): GetNewsFailedActionType => ({type: NewsActionTypes.GetNewsFailed, payload: { error } });