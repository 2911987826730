import { Row, Col, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import "../../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import LandsDisplay from './landDisplay';
import Plot from '../../../common/types/userKingdom/plot';
import { Pager } from '../../pagerComponent/pager';
import { updateLandsOrderBy } from '../../../store/actions/userKingdomActions';
import Improvement from '../../../common/types/userKingdom/improvement';
import Job from '../../../common/types/userKingdom/job';

export interface StateProps {
  plots: Plot[];
  plotImprovements: Improvement[];
  plotJobs: Job[];
  orderBy: string;
  orderByDirection: number;
}

export interface DispatchProps {
  updateOrderBy: (orderBy: string) => void;
}

export type LandDisplayTableProps = StateProps & DispatchProps;

export const LandDisplayTable = (props: LandDisplayTableProps) => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [currentPage, setCurrentPage] = useState(1);
  const [addressFilter, setAddressFilter] = useState("");
  const pageSize = 20;

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const [expandedPlots, setExpandedPlots] = useState(true);

  const renderExpandToggle = (expanded: boolean) => {
    if (expanded) {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleDown}/></Col>
      )
    } else {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleRight}/></Col>
      );
    }
  }

  const updateAddressFilter = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setAddressFilter(changeEvent.currentTarget.value);
  }

  const sortLands = (a: Plot, b: Plot) => {
    let aValue = 0;
    let bValue = 0;

    if (props.orderBy === "Address") {
      if (props.orderByDirection === 1) {
        return a.address > b.address ? 1 : -1;
      } else if (props.orderByDirection === 2) {
        return b.address > a.address ? 1 : -1;
      }
    } else if (props.orderBy === "Size") {
      aValue = a.size - props.plotImprovements.filter((x: Improvement) => x.plotId === a.id).reduce((c,d) => c + d.size, 0);
      bValue = b.size - props.plotImprovements.filter((x: Improvement) => x.plotId === b.id).reduce((c,d) => c + d.size, 0);
    } else if (props.orderBy === "Improvements") {
      aValue = props.plotImprovements.filter((x: Improvement) => x.plotId === a.id).length;
      bValue = props.plotImprovements.filter((x: Improvement) => x.plotId === b.id).length;
    } else if (props.orderBy === "Jobs") {
      aValue = props.plotJobs.filter((x: Job) => x.plotId === a.id).length;
      bValue = props.plotJobs.filter((x: Job) => x.plotId === b.id).length;
    } else if (props.orderBy === "LandType") {
      if (props.orderByDirection === 1) {
        return a.landTypeString > b.landTypeString ? 1 : -1;
      } else if (props.orderByDirection === 2) {
        return b.landTypeString > a.landTypeString ? 1 : -1;
      }
    }

    if (props.orderByDirection === 1) {
      return aValue > bValue ? 1 : -1;
    } else if (props.orderByDirection === 2) {
      return bValue > aValue ? 1 : -1;
    }
    
    return 0;
  }

  const renderSortIcon = (name: string) => {
    if (name === props.orderBy && props.orderByDirection !== 0) {
      if (props.orderByDirection === 1) {
        return <FontAwesomeIcon icon={faSortDown} />
      } else {
        return <FontAwesomeIcon icon={faSortUp} />
      }
    }
  }

  const renderOwnedLands = () => {
    if (!expandedPlots || !props.plots || props.plots.length === 0) {
      return <></>;
    }

    return (
      <>
        <Row className="resource-table-sub-entry dark-wheat-background">
          <Col className="morris-lg">
            {t("CommonWords.Address")}
          </Col>
          <Col className="login-form">
            <Form.Control className="form-text-box" type="text"
                value={addressFilter} onChange={updateAddressFilter}/>
          </Col>
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class" onClick={() => props.updateOrderBy("LandType")}>{t("CommonWords.Type")}{renderSortIcon("LandType")}</Col>
          {!isMobile && <Col className="sub-entry-margin-class" onClick={() => props.updateOrderBy("Address")}>{t("CommonWords.Address")}{renderSortIcon("Address")}</Col>}
          <Col className="sub-entry-margin-class" onClick={() => props.updateOrderBy("Size")}>{t("CommonWords.Size")}{renderSortIcon("Size")}</Col>
          <Col className="sub-entry-margin-class" onClick={() => props.updateOrderBy("Improvements")}>{t("CommonWords.Improvements")}{renderSortIcon("Improvements")}</Col>
          <Col className="sub-entry-margin-class" onClick={() => props.updateOrderBy("Jobs")}>{t("UserKingdomPage.LandsTab.Jobs")}{renderSortIcon("Jobs")}</Col>
          {!isMobile && <Col className="sub-entry-margin-class"></Col>}
        </Row>
        {props.plots
          .sort(sortLands)
          .filter(x => x.address.toLocaleLowerCase().includes(addressFilter.toLocaleLowerCase()))
          .slice((currentPage - 1) * pageSize, (currentPage * pageSize)).map((x) => <LandsDisplay
          key={x.id}
          plot={x} />)}
      </>);
  }

  const renderPager = () => {
    if (isMobile) {
      return (
        <div className="pt-2">
          <Pager
            page={currentPage}
            pageSize={pageSize}
            totalEntries={props.plots ? props.plots.length : 0}
            pageUp={() => setCurrentPage(currentPage + 1)}
            pageDown={() => setCurrentPage(currentPage - 1)}
            useLightBackgroud={true}
          />
        </div>
      );
    } else {
      return (
        <Row className="pt-2">
          <Col></Col>
          <Col></Col>
          <Col>
            <Pager
              page={currentPage}
              pageSize={pageSize}
              totalEntries={props.plots ? props.plots.length : 0}
              pageUp={() => setCurrentPage(currentPage + 1)}
              pageDown={() => setCurrentPage(currentPage - 1)}
              useLightBackgroud={true}
            />
          </Col>
        </Row>
      );
    }
  }

  return (
    <>
      <Row onClick={() => setExpandedPlots(!expandedPlots)} className="resource-table standard-text-md">
        <Col>
          {t(`UserKingdomPage.LandsTab.OwnedLands`)}
        </Col>
        <Col>
          {props.plots && props.plots.length}
        </Col>
        {renderExpandToggle(expandedPlots)}
      </Row>
      {renderOwnedLands()}
      {renderPager()}
    </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    plots: state.UserKingdomState.userKingdom.plots,
    plotImprovements: state.UserKingdomState.userKingdom.improvements,
    plotJobs: state.UserKingdomState.userKingdom.jobs,
    orderBy: state.UserKingdomState.orderBy,
    orderByDirection: state.UserKingdomState.orderByDirection
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    updateOrderBy: (orderBy: string) => dispatch(updateLandsOrderBy(orderBy))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(LandDisplayTable);