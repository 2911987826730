import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, ModalTitle, ModalBody, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { getUserKingdom } from '../../store/actions/userKingdomActions';

interface StateProps {
  isLoadingUserKingdom: boolean,
  showExpiredUserKingdomModal: boolean,
  kingdomId?: number
};

interface DispatchProps {
  getUserKingdom: (kingdomId: number) => void;
}

export type ReloadCityUserEntityComponentProps = DispatchProps & StateProps;

const ReloadCityUserEntityComponent = (props: ReloadCityUserEntityComponentProps) => {
  const { t } = useTranslation();

  const renderReloadModal = () => {
    return (
      <Modal show={props.showExpiredUserKingdomModal} onHide={() => props.getUserKingdom(props.kingdomId!)} animation={false} centered>
        <ModalTitle className="primary-header">{t("ErrorModals.Reload.Title")}</ModalTitle>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <ModalBody className="standard-text">
          <Row className="mb-2">
            <Col>{t("ErrorModals.Reload.Body")}</Col>
          </Row>
          <Row className={`resource-table-sub-entry top-border pt-2`}>
            <Col></Col>
            <Col>
              <div className="darkStone-button morris-md my-1" onClick={() => props.getUserKingdom(props.kingdomId!)} >
                {t("Buttons.Ok")}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showExpiredUserKingdomModal && props.kingdomId && renderReloadModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    getUserKingdom: (kingdomId: number) => dispatch(getUserKingdom(kingdomId))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    isLoadingUserKingdom: state.UserKingdomState.isLoadingUserKingdom,
    showExpiredUserKingdomModal: state.UserKingdomState.showExpiredUserKingdomModal,
    kingdomId: state.UserKingdomState.userKingdom?.kingdomId
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(ReloadCityUserEntityComponent);