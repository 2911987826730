import JoinBattleRequest from '../../common/types/messages/JoinBattleRequest';
import PostRespondToCombatResponse from '../../common/types/messages/PostRespondToCombatResponse';
import Battle from '../../common/types/userKingdom/battle';
import Action from './baseAction';

export enum CombatActionTypes {
  RequestJoinBattle = "REQUEST_JOIN_BATTLE",
  RequestJoinBattleCompleted = "REQUEST_JOIN_BATTLE_COMPLETED",
  RequestJoinBattleFailed = "REQUEST_JOIN_BATTLE_FAILED",
  OpenBattleModal = "OPEN_BATTLE_MODAL",
  CloseBattleModal = "CLOSE_BATTLE_MODAL",
  UpdateBattleGoal = "UPDATE_BATTLE_GOAL"
}

export type RequestJoinBattlePayloadType = { request: JoinBattleRequest };
export type RequestJoinBattleActionType = Action<CombatActionTypes.RequestJoinBattle, RequestJoinBattlePayloadType>;
export const requestJoinBattle = (request: JoinBattleRequest):RequestJoinBattleActionType => ({type: CombatActionTypes.RequestJoinBattle, payload: { request } });

export type RequestJoinBattleCompletedPayloadType = { response: PostRespondToCombatResponse; };
export type RequestJoinBattleCompletedActionType = Action<CombatActionTypes.RequestJoinBattleCompleted, RequestJoinBattleCompletedPayloadType>;
export const requestJoinBattleCompleted = (response: PostRespondToCombatResponse): RequestJoinBattleCompletedActionType => ({type: CombatActionTypes.RequestJoinBattleCompleted, payload: { response } });

export type RequestJoinBattleFailedPayloadType = { error: Error; };
export type RequestJoinBattleFailedActionType = Action<CombatActionTypes.RequestJoinBattleFailed, RequestJoinBattleFailedPayloadType>;
export const requestJoinBattleFailed = (error: Error): RequestJoinBattleFailedActionType => ({type: CombatActionTypes.RequestJoinBattleFailed, payload: { error } });

export type OpenBattleModalPayloadType = { openBattles: Battle[], lockBattleType: boolean, targetUserKingdomId: string, targetKingdomUserId: number, targetKingdomUsername: string };
export type OpenBattleModalActionType = Action<CombatActionTypes.OpenBattleModal, OpenBattleModalPayloadType>;
export const openBattleModal = (openBattles: Battle[], lockBattleType: boolean, targetUserKingdomId: string, targetKingdomUserId: number, targetKingdomUsername: string  ):OpenBattleModalActionType => ({type: CombatActionTypes.OpenBattleModal, payload: { openBattles, lockBattleType, targetUserKingdomId, targetKingdomUserId, targetKingdomUsername } });

export type CloseBattleModalPayloadType = { };
export type CloseBattleModalActionType = Action<CombatActionTypes.CloseBattleModal, CloseBattleModalPayloadType>;
export const closeBattleModal = ():CloseBattleModalActionType => ({type: CombatActionTypes.CloseBattleModal, payload: { } });

export type UpdateBattleGoalPayloadType = { battleGoal: string };
export type UpdateBattleGoalActionType = Action<CombatActionTypes.UpdateBattleGoal, UpdateBattleGoalPayloadType>;
export const updateBattleGoal = (battleGoal: string):UpdateBattleGoalActionType => ({type: CombatActionTypes.UpdateBattleGoal, payload: { battleGoal } });
