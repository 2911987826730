import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";

export interface OwnProps {
  page: number,
  pageSize: number,
  totalEntries: number,
  pageUp: () => void,
  pageDown: () => void,
  useLightBackgroud: boolean
}

export const Pager = (props: OwnProps) => {
  const { t } = useTranslation();

  const handlePageDown = () => {
    if (props.page !== 1) {
      props.pageDown();
    }
  }

  const handlePageUp = () => {
    if ((props.page) * props.pageSize < props.totalEntries) {
      props.pageUp();
    }
  }

  return (
    <Row className={`pager ${props.useLightBackgroud ? "light-background" : "dark-background"} morris-md`}>
      <Col onClick={handlePageDown} className="pager-button">
        <FontAwesomeIcon icon={faArrowLeft} />
      </Col>
      <Col>
        {t("Components.Pager.Page")} {props.page} / {Math.ceil(props.totalEntries / props.pageSize)}
      </Col>
      <Col onClick={handlePageUp} className="pager-button">
        <FontAwesomeIcon icon={faArrowRight} />
      </Col>
    </Row>
  );
}