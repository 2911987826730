import { Row, Col } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import SearchedUserKingdomDetail from '../../common/types/SearchedUserKingdomDetail';
import RenderManageSearchUserKingdomComponent from '../landsSearchComponents/RenderManageSearchUserKingdomComponent';

interface StateProps {
  vassals: SearchedUserKingdomDetail[];
  landsSearchState: LandsSearchState;
};

interface DispatchProps {

}

interface OwnProps {

}

export type VassalsPanelProps = DispatchProps & StateProps & OwnProps;

export const VassalsPanel = (props: VassalsPanelProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();

  const [expandedUserKingdomId, setExpandedUserKingdomId] = useState("");

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const onTabSelectorClick = (input: string) => {
    if (input === expandedUserKingdomId) {
      setExpandedUserKingdomId("");
    } else {
      setExpandedUserKingdomId(input); 
    }
  }

  const renderExpandToggle = (isExpanded: boolean) => {
    return (
      <Row>
        <Col className="align-to-right morris-lg">
          {t("CommonWords.Details")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={isExpanded ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }

  const renderManage = (x: SearchedUserKingdomDetail) => {
    return (
      <>
        <RenderManageSearchUserKingdomComponent detail={x} />
      </>);
  }

  const renderTabView = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col>{t("CommonWords.Username")}</Col>
          <Col>{t("CommonWords.Plots")}</Col>
          <Col>{t("CommonWords.Size")}</Col>
          <Col>{t("CommonWords.Vassal", { count: 2 })}</Col>
          <Col>{t("LandSearchPage.Workers")}</Col>
          <Col></Col>
        </Row>
        {props.vassals.map((x: SearchedUserKingdomDetail) => {

          let ownUp2 = x.plots.reduce((a,b) => a + b.size, 0);

          return (
            <div key={x.userId}>
              <Row onClick={() => onTabSelectorClick(x.userKingdomId)} className={`resource-table-sub-entry top-border ${expandedUserKingdomId === x.userKingdomId ? "apple-green-background" : "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
                <Col className="sub-entry-margin-class">{x.username}</Col>
                <Col className="sub-entry-margin-class">{x.plots.length}</Col>
                <Col className="sub-entry-margin-class">{ownUp2} up2</Col>
                <Col className="sub-entry-margin-class">{x.vassals.length}</Col>
                <Col className="sub-entry-margin-class">{x.groupedWorkers.reduce((a,b) => a + b.numberEmployed + b.numberUnemployed, 0)}</Col>
                <Col className="sub-entry-margin-class">
                  {renderExpandToggle(expandedUserKingdomId === x.userKingdomId)}
                </Col>
              </Row>
              {expandedUserKingdomId === x.userKingdomId && renderManage(x)}
            </div>
          );
        })}
      </>
    );
  }

  if (!props.vassals || props.vassals.length === 0) {
    return <></>;
  } else {
    return renderTabView();
  }
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    vassals: state.UserKingdomState.userKingdom.vassals,
    landsSearchState: state.LandsSearchState
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(VassalsPanel);