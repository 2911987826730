import { Dispatch } from 'redux';
import { Footer } from '../../components/footerComponent/footer';
import Header from '../../components/headerComponent/header';
import { connect } from 'react-redux';
import { Container, Image, Row, Col } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";

interface StateProps {

};

interface DispatchProps {

}

export type AboutViewProps = DispatchProps & StateProps;

export const AboutView = (props: AboutViewProps) => {
  const { t } = useTranslation();

  const renderAboutView = () => {
    return (
      <Container fluid="md">
        <Row>
          <div className="primary-header">{t("AppName")}</div>
        </Row>
        <Row className="m-auto logo-image-large">
          <Image src="/images/UplandKingdoms_Logo.png"/>
        </Row>
        <br></br>
        <Row className="standard-text-box">
          <div className="tertiary-header">{t("Motto")}</div>
          <p className="pt-2 standard-text">
            {t("AboutPage.Description")}
            <br></br><br></br>
          </p>
          <p className="pt-2 standard-text">
            {t("AboutPage.Activity1")}<br></br>
            {t("AboutPage.Activity2")}<br></br>
            {t("AboutPage.Activity3")}<br></br>
            {t("AboutPage.Activity4")}<br></br>
          </p>
          <div className="tertiary-header">{t("AboutPage.WhatIsUpland")}</div>
          <p className="pt-2 standard-text">
            {t("AboutPage.UplandDescription")}
          </p>
          <Row>
            <Col></Col>
            <Col className="darkStone-button">
              <a href="https://r.upland.me/B2PB" className="clear-link-text-decoration" rel="noreferrer" target="_blank">
                <div className="tertiary-header">{t("Buttons.JoinUpland")}</div>
              </a>
            </Col>
            <Col></Col>
          </Row>
          <p className="pt-2 standard-text">
            <i>
              {t("AboutPage.ThirdPartyDesclaimer")}
            </i>
          </p>
          <div className="tertiary-header">{t("AboutPage.AboutGrombrindal")}</div>
          <p className="pt-2 standard-text">
            {t("AboutPage.GrombrindalDescription")}
          </p>
          <Row>
            <Col></Col>
            <Col className="darkStone-button">
              <a href="https://www.uplandoptimizer.com/" className="clear-link-text-decoration" rel="noreferrer" target="_blank">
                <div className="tertiary-header">{t("Buttons.UplandOptimizer")}</div>
              </a>
            </Col>
            <Col></Col>
          </Row>
          <br></br>
          <Row>
            <Col></Col>
            <Col className="darkStone-button">
              <a href="https://upx.world/" className="clear-link-text-decoration" rel="noreferrer" target="_blank">
                <div className="tertiary-header">{t("Buttons.UPXWorld")}</div>
              </a>
            </Col>
            <Col></Col>
          </Row>
          <br></br>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <div className="footer-padding">
        <Header />
        {renderAboutView()}
      </div>
      <Footer />
    </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {

  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutView);
