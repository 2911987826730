import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/actions/authenticationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { getUserKingdom, toggleUserSettingsModal } from '../../store/actions/userKingdomActions';

interface StateProps {
  authTokenSet: boolean,
  kingdomId: number,
  isLoadingUserKingdom: boolean,
  hasLoadedUserKingdom: boolean,
};

interface DispatchProps {
  logout: () => void;
  getUserKingdom: (kingdomId: number) => void;
  toggleUserSettingsModal: () => void;
}

export type MenuButtonProps = DispatchProps & StateProps;

export const MenuButton = (props: MenuButtonProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [menuExpanded, setMenuExpanded] = useState(false);

  const handleLogout = () => {
    props.logout();
    navigate("/")
  }

  const handleKingdomClick = () => {
    if (!props.isLoadingUserKingdom && props.kingdomId) {
      props.getUserKingdom(props.kingdomId);
    }

    navigate("/Play");
  }

  const handleToggleSettings = () => {
    props.toggleUserSettingsModal();
  }

  const renderMenu = () => {
    return (
      <div className="menu-button-menu tertiary-header">
        <div className="entry" onClick={() => handleKingdomClick()}>
          {t("UserKingdomPage.Kingdom")}
        </div>
        <div className="entry" onClick={() => navigate("/LandSearch")}>
          {t("LandSearchPage.Title")}
        </div>
        <div className="entry" onClick={() => navigate("/Market")}>
          {t("MarketPage.Title")}
        </div>
        <div className="delineator"></div>
        <div className="entry" onClick={() => navigate("/About")}>
          {t("AboutPage.Title")}
        </div>
        <div className="entry" onClick={() => navigate("/News")}>
          {t("NewsPage.Title")}
        </div>
        <div className="entry" onClick={() => navigate("/Guide")}>
          {t("GuidePage.Title")}
        </div>
        <div className="delineator"></div>
        <div className="entry" onClick={handleToggleSettings}>
          {t("Components.UserSettings.Settings")}
        </div>
        <div className="entry" onClick={handleLogout}>
          {t("Buttons.Logout")}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="menu-button-container left">
        <div className="menu-button morris-lg"  onClick={() => setMenuExpanded(!menuExpanded)}>
          <FontAwesomeIcon icon={faBars}/>
        </div>
        {menuExpanded && renderMenu()}
      </div>
    </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet,
    kingdomId: state.UserKingdomState?.userKingdom?.kingdomId,
    isLoadingUserKingdom: state.UserKingdomState.isLoading,
    hasLoadedUserKingdom: state.UserKingdomState.hasLoaded,
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    logout: () => dispatch(logout()),
    getUserKingdom: (kingdomId: number) => dispatch(getUserKingdom(kingdomId)),
    toggleUserSettingsModal: () => dispatch(toggleUserSettingsModal())
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);