import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, ModalTitle, ModalBody, Modal, Form, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import PostPurchaseRequest from '../../common/types/messages/PostPurchaseRequest';
import { closePurchaseForm, postPurchase, updatePurchaseForm } from '../../store/actions/purchaseActions';
import { purchaseOptionsEnum } from '../../common/types/enums/purchaseOptionsEnum';
import { formatNumberMaxTwoDecimal } from '../../common/helperFunctions/formattingFunctions';

interface StateProps {
  showPurchaseModal: boolean,
  form: PostPurchaseRequest,
  isPurchasing: boolean,
  hasError: boolean,
  errorMessage: string
};

interface DispatchProps {
  closeModal: () => void;
  updateForm: (request: PostPurchaseRequest) => void;
  postPurchase: (request: PostPurchaseRequest) => void;
}

export type PurchaseModalProps = DispatchProps & StateProps;

const PurchaseModal = (props: PurchaseModalProps) => {
  const { t } = useTranslation();

  const GetPurchaseOptionsDropdownOptions = () => {
    return purchaseOptionsEnum.map((x) => {
      return <option key={x.key} value={x.value}>{t(`PurchaseTypes.${x.key}`)}</option>
    })
  }

  const renderRedTextBox = (message: string) => {
    
    if (message.match(/.*::.*/)) {
      return (
        <Row>
          <Col>
            <div className="red-text-box">
              <b>{t(`Exceptions.${message.split("::")[0]}`)}</b>
            </div>
            <div className="red-text-box">
              <b>{message.split("::")[1]}</b>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t(`Exceptions.${message}`)}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const getDescription = () => {
    if (props.form.purchaseType === 0) {
      return t(`PurchaseTypes.RecruitPeasantDescription`)
    }

    if (props.form.purchaseType === 1) {
      return t(`PurchaseTypes.NewCityPackageDescription`)
    }

    return "";
  }

  const getAmount = () => {
    if (props.form.purchaseType === 0) {
      return formatNumberMaxTwoDecimal(500 * props.form.amount * 1.05);
    }

    if (props.form.purchaseType === 1) {
      return formatNumberMaxTwoDecimal(5000 * props.form.amount * 1.05);
    }

    return 1;
  }

  const renderModal = () => {
    return (
      <Modal show={props.showPurchaseModal} onHide={() => props.closeModal()} animation={false} centered>
        <ModalTitle className="primary-header">{t("Components.PurchaseModal.Title")}</ModalTitle>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <ModalBody className="standard-text">
          {props.hasError && renderRedTextBox(props.errorMessage)}
          <Row>
            <Col>
              <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
                <Form.Group>
                  <Form.Select className="drop-down-style wheat_dark" value={props.form.purchaseType} onChange={
                    (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateForm({
                      ...props.form,
                      purchaseType: parseInt(changeEvent.currentTarget.value),
                      amount: 1
                    })}>
                    {GetPurchaseOptionsDropdownOptions()}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          {props.form.purchaseType !== 1 && <Row>
            <Col className="sub-entry-margin-class login-form">
              <Form.Label>{t("Components.PurchaseModal.Amount", { amount: props.form.amount})}</Form.Label>
              <Form.Range 
                value={props.form.amount} 
                onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                  props.updateForm({
                    ...props.form,
                    amount: parseInt(changeEvent.currentTarget.value)})}}
                min={1}
                max={10}
                />
            </Col>
          </Row>}
          <Row>
            <Col>
              {getDescription()}
            </Col>
          </Row>
          <Row className={`resource-table-sub-entry bottom-border`}>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.closeModal()}>
                {t("Buttons.Cancel")}
              </div>
            </Col>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.postPurchase(props.form)}>
                {t("Components.PurchaseModal.Buy", { amount: getAmount() })}
              </div>
            </Col>
          </Row>
          <Row className={`resource-table-sub-entry`}>
            <Col>
              {t("Components.PurchaseModal.Instructions", { amount: getAmount() })}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showPurchaseModal && renderModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closeModal: () => dispatch(closePurchaseForm()),
    updateForm: (request: PostPurchaseRequest) => dispatch(updatePurchaseForm(request)),
    postPurchase: (request: PostPurchaseRequest) => dispatch(postPurchase(request))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    showPurchaseModal: state.PurchaseState.showPurchaseModal,
    form: state.PurchaseState.purchaseForm,
    isPurchasing: state.PurchaseState.isPurchasing,
    hasError: state.PurchaseState.errorPurchasing,
    errorMessage: state.PurchaseState.errorPurchasingMessage
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseModal);