import { Store } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './app/styles/style.scss';
import PlayerKingdomPage from './app/areas/playerKingdomPage/playerKingdomPage';
import AboutView from './app/areas/aboutPage/aboutView';
import NewsView from './app/areas/newsPage/newsView';
import GuideView from './app/areas/guidePage/guideView';
import LoginView from './app/areas/loginPage/loginView';
import { NotFoundPage } from './app/areas/notFoundPage/notFoundPage';
import MarketView from './app/areas/marketPage/marketView';
import LandSearchView from './app/areas/landSearchPage/landSearchView';

interface AppProps {
  store: Store
}

const App = ({ store }: AppProps) =>  {
  return (
      <Router>
        <Provider store={store}>
          <Routes>
            <Route
              path="/"
              element={<LoginView />}
            />
            <Route
              path="/About"
              element={<AboutView />}
            />
            <Route
              path="/News"
              element={<NewsView />}
            />
            <Route
              path="/Guide"
              element={<GuideView />}
            />
            <Route
               path="/Play"
               element={<PlayerKingdomPage />}
            />
            <Route
               path="/Market"
               element={<MarketView />}
            />
            <Route
               path="/LandSearch"
               element={<LandSearchView />}
            />
            <Route path='*' element={<NotFoundPage />}/>
          </Routes>
        </Provider> 
      </Router>
  );
};

export default App;