import { Row, Col, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import SearchPlot from '../../common/types/SearchPlot';
import { NaturalResourcesDisplay } from '../userKingdomDisplayTableComponents/naturalResourcesDisplay';
import { SearchImprovementDisplay } from './searchImprovementDisplayComponent';
import SearchImprovement from '../../common/types/SearchImprovement';


interface StateProps {

};

interface DispatchProps {

}

interface OwnProps {
  plot: SearchPlot,
  showOwner: boolean,
}

export type SearchPlotDisplayProps = DispatchProps & StateProps & OwnProps;

export const SearchPlotDisplay = (props: SearchPlotDisplayProps) => {
  const [manage, setManage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleManagePanel = (event: any) => {
    setManage(!manage);
  }

  const renderExpandToggle = () => {
    return (
      <Row>
        <Col className="align-to-right morris-lg">
          {t("CommonWords.Expand")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={manage ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }

  const renderImprovementsManagement = () => {
    if (props.plot.improvements.length === 0) {
      return <></>;
    }
  
    return (
      <>
        <Row className="resource-table-sub-entry dark-wheat-background standard-text-md">
          <Col>{t("UserKingdomPage.Tabs.Improvements")}</Col>
        </Row>
        {props.plot.improvements.map((x: SearchImprovement) => 
          <SearchImprovementDisplay 
            key={x.id}
            improvement={x}
            address={props.plot.address}
          />)}
      </>
    );
  }

  const getColorOveride = () => {
    if (manage) {
      return "apple-green-background";
    }

    return "clickable";
  }

  const renderManage = () => {

    if (manage) {
      return (
        <div className="mx-2 mb-2">
          <NaturalResourcesDisplay naturalResources={props.plot.naturalResources} />
          {renderImprovementsManagement()}
        </div>
      )
    }
    return (<></>);
  }
  
  if (!props.plot || !props.plot.improvements)
  {
    return <></>;
  }

  let usedSize = props.plot.improvements ? props.plot.improvements.reduce((a,b) => a + b.size, 0) : 0;

  return (
    <div>
      <Row onClick={toggleManagePanel} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"} ${getColorOveride()}`}>
        <Row>
          <Col><Image className="thumbnail-image" src={`/images/terrain/${props.plot.landTypeString}.png`}/></Col>
          {props.showOwner && <Col className="sub-entry-margin-class">{props.plot.owner}</Col>}
          {!isMobile && <Col className="sub-entry-margin-class">{props.plot.address}</Col>}
          <Col className="sub-entry-margin-class">{usedSize > 0 ? `${usedSize} / ` : ""}{props.plot.size} up2</Col>
          <Col className="sub-entry-margin-class">{props.plot.improvements.length}</Col>
          {!isMobile && <Col className="sub-entry-margin-class">
            {renderExpandToggle()}
          </Col>}
        </Row>
        {isMobile && 
          <Row>
            <Col className="sub-entry-margin-class">{props.plot.address}</Col>
            <Col className="sub-entry-margin-class">{renderExpandToggle()}</Col>
          </Row>}
      </Row>
      {renderManage()}
    </div>
  );
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const stateProps: StateProps = {

  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlotDisplay);