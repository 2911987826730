import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, ModalTitle, ModalBody, Modal, Form, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { toggleUserSettingsModal, updateUserSettings } from '../../store/actions/userKingdomActions';
import PostUpdateUserSettingsRequest from '../../common/types/messages/PostUpdateUserSettingsRequest';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import Purchase from '../../common/types/userKingdom/purchase';

interface StateProps {
  showUserSettingsModal: boolean,
  defaultKingdom: number,
  useMaleTitles: boolean,
  language: number,
  errorMessage: string,
  hasError: boolean,
  activeKingdoms: KeyValuePair[],
  previousPurchases: Purchase[]
};

interface DispatchProps {
  closeModal: () => void;
  updateUserSettings: (request: PostUpdateUserSettingsRequest) => void;
}

export type UserSettingsModalProps = DispatchProps & StateProps;

const UserSettingsModal = (props: UserSettingsModalProps) => {
  const { t } = useTranslation();

  const GetKingdomDropDownOptions = () => {
    return props.activeKingdoms.map((x) => {
      return <option key={x.key} value={x.key}>{x.value}</option>
    })
  }

  const renderRedTextBox = (message: string) => {
    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t(`Exceptions.${message}`)}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const renderPeasantPurchases = () => {
    if (props.previousPurchases.filter(x => x.type === 0).length === 0) {
      return <></>;
    }

    let peasantPurchase = props.previousPurchases.filter(x => x.type === 0);

    return (
      <>
        <Row className={`resource-table-sub-entry top-border my-3`}>
          <Col>
            {t("PurchaseTypes.RecruitPeasant")} {peasantPurchase.reduce((a,b) => b.amount + a, 0)} / 50
          </Col>
        </Row>
        {peasantPurchase
          .map(x => {
            return (
              <Row>
                <Col>{x.amount} {t("WorkerTypes.Peasant", { count: x.amount })}</Col>
                <Col>{t("Components.UserSettings.ExpiresHours", { count: x.hoursTilExpiration })}</Col>
              </Row>
            )
          })}
      </>
    )
  }

  const renderNewCityPurchases = () => {
    if (props.previousPurchases.filter(x => x.type === 1).length === 0) {
      return <></>;
    }

    let newCityPackages = props.previousPurchases.filter(x => x.type === 1);

    return (
      <>
        <Row className={`resource-table-sub-entry top-border my-3`}>
          <Col>
            {t("PurchaseTypes.NewCityPackage")} {newCityPackages.reduce((a,b) => b.amount + a, 0)} / 5
          </Col>
        </Row>
        {newCityPackages
          .map(x => {
            return (
              <Row>
                <Col>{x.amount} {t("PurchaseTypes.NewCityPackage")}</Col>
                <Col>{t("Components.UserSettings.ExpiresHours", { count: x.hoursTilExpiration })}</Col>
              </Row>
            )
          })}
      </>
    )
  }

  const renderModal = () => {
    return (
      <Modal show={props.showUserSettingsModal} onHide={() => props.closeModal()} animation={false} centered>
        <ModalTitle className="primary-header">{t("Components.UserSettings.Settings")}</ModalTitle>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <ModalBody className="standard-text">
          {props.hasError && renderRedTextBox(props.errorMessage)}
          <Row>
            <Col className="m-auto morris-lg">
             {t("Components.UserSettings.DefaultCity")}
            </Col>
            <Col>
              <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
                <Form.Group>
                  <Form.Select className="drop-down-style wheat_dark" value={props.defaultKingdom} onChange={
                    (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateUserSettings({
                      useMaleTitles: props.useMaleTitles,
                      language: props.language,
                      defaultKingdom: parseInt(changeEvent.currentTarget.value),
                    })}>
                    {GetKingdomDropDownOptions()}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col className="m-auto morris-lg">
             {t("Components.UserSettings.Titles")}
            </Col>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.updateUserSettings({
                useMaleTitles: !props.useMaleTitles,
                language: props.language,
                defaultKingdom: props.defaultKingdom
              })}>
                {props.useMaleTitles ? t("Components.UserSettings.MaleTitles") : t("Components.UserSettings.FemaleTitles") }
              </div>
            </Col>
          </Row>
          {renderNewCityPurchases()}
          {renderPeasantPurchases()}
          <Row className={`resource-table-sub-entry top-border`}>
            <Col>
            </Col>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.closeModal()}>
                {t("Buttons.Ok")}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showUserSettingsModal && renderModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closeModal: () => dispatch(toggleUserSettingsModal()),
    updateUserSettings: (request: PostUpdateUserSettingsRequest) => dispatch(updateUserSettings(request)),
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    showUserSettingsModal: state.UserKingdomState.showUserSettingsModal,
    defaultKingdom: state.UserKingdomState.userKingdom?.defaultKingdom,
    useMaleTitles: state.UserKingdomState.userKingdom?.useMaleTitles,
    language: state.UserKingdomState.userKingdom?.language,
    errorMessage: state.UserKingdomState.updatingUserSettingsError,
    hasError: state.UserKingdomState.updatingUserSettingsFailed,
    activeKingdoms: state.UserKingdomState.activeKingdoms,
    previousPurchases: state.UserKingdomState.userKingdom?.purchases
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsModal);