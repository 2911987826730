import { Reducer } from 'redux';
import { NewsState } from '../states/newsState';
import {
  NewsActionTypes,
  GetNewsActionType,
  GetNewsCompletedActionType,
  GetNewsFailedActionType
} from '../actions/newsActions';

export type NewsActions = 
  GetNewsActionType
  | GetNewsCompletedActionType
  | GetNewsFailedActionType;

const initialState: NewsState = {
  hasLoadedNews: false,
  page: 1,
  newsEntries: [],
  totalEntries: 0,
  showPager: true,
  isLoading: false,
  hasError: false,
  errorMessage: ''
}

export const NewsReducer: Reducer<NewsState> = (state = initialState, action: NewsActions): NewsState => {
  switch(action.type) {
    case NewsActionTypes.GetNews:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',

        newsEntries: [],
        page: action.payload!.page
      };
    case NewsActionTypes.GetNewsCompleted:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        errorMessage: '',

        hasLoadedNews: true,
        newsEntries: action.payload!.response.news,
        totalEntries: action.payload!.response.total,
        showPager: action.payload!.response.total > action.payload!.response.news.length
      };
    case NewsActionTypes.GetNewsFailed:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any,

        newsEntries: []   
      };
    default:
      return state;
  }
}

export default NewsReducer;