import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner, Form } from 'react-bootstrap'
import { Footer } from '../../components/footerComponent/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import UserKingdom from '../../common/types/userKingdom/userKingdom';
import UserKingdomLoadingComponent from '../../components/userKingdomLoadingComponent/userKingdomLoadingComponent';
import MenuButton from '../../components/menuButtonComponent/menuButton';
import { getKingdomLands, updateSearchPlotsForm, getUserKingdomDetails, closeExpandedDetails, updateUserSearchFilters } from '../../store/actions/landsSearchActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import LandsSearchEntry from '../../common/types/LandsSearchEntry';
import { TabSelector } from '../../components/tabSelectorComponent/tabSelector';
import SearchPlot from '../../common/types/SearchPlot';
import { SearchPlotDisplay } from '../../components/landsSearchComponents/searchPlotDisplayComponent';
import { Pager } from '../../components/pagerComponent/pager';
import { userSearchOrderByEnum } from '../../common/types/enums/userSearchOrderByEnum';
import RenderManageSearchUserKingdomComponent from '../../components/landsSearchComponents/RenderManageSearchUserKingdomComponent';
import CombatModal from '../../components/combatModal/combatModal';
import { useNavigate } from 'react-router-dom';

interface StateProps {
  authTokenSet: boolean,
  isLoadingUserKingdom: boolean,
  hasLoadedUserKingdom: boolean,
  hasErrorLoadingUserKingdom: boolean,
  errorMessage: string,
  userKingdom: UserKingdom,

  landsSearchState: LandsSearchState
};

interface DispatchProps {
  getKingdomLands: (kingdomId: number) => void;
  updateLandsAddress: (kingdomId: number, address: string) => void;
  getUserKingdomDetails: (userKingdomId: string) => void;
  closeExpandedDetails: () => void;
  updateUserSearchFilters: (username: string, orderBy: string, ascending: boolean) => void;
}

export type LandSearchViewProps = DispatchProps & StateProps;

export const LandSearchView = (props: LandSearchViewProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const availableTabs = [
    t("LandSearchPage.SearchUsersTab"),
    t("LandSearchPage.SearchLandsTab")
   ];
  const [activeTab, setActiveTab] = useState(t("LandSearchPage.SearchUsersTab").toString());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 20;

  useEffect(() => {
    if (props.userKingdom
      && props.landsSearchState
      && !props.landsSearchState.isLoading
      && props.landsSearchState.kingdomId !== props.userKingdom.kingdomId) {
      props.getKingdomLands(props.userKingdom.kingdomId);
    }
  }, [props])

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const onTabSelectorClick = (input: string) => {
    if (input === activeTab) {
      setActiveTab("");
    } else {
      setActiveTab(input); 
    }
  }

  const renderTitle = () => {
    if (props.hasLoadedUserKingdom) {
      return (
        <Row>
          <div className="primary-header">
            {t("LandSearchPage.Title")}
          </div>
          <div className="outer-fancy-border">
            <div className="inner-fancy-border">
            </div>
          </div>
        </Row>
      );
    }

    if (props.isLoadingUserKingdom) {
      return (
        <Row>
          <div className="primary-header">
            {t("UserKingdomPage.Loading")}
          </div>
          <div className="outer-fancy-border">
            <div className="inner-fancy-border">
            </div>
          </div>
        </Row>
      );
    }

    if (props.hasErrorLoadingUserKingdom) {
      return (
        <Row>
          <div className="primary-header">
            {t("Exceptions.ErrorLoadingKingdom")}
          </div>
          <div className="outer-fancy-border">
            <div className="inner-fancy-border">
            </div>
          </div>
        </Row>
      );
    }
  }

  const renderExpandToggle = (isExpanded: boolean) => {
    return (
      <Row>
        <Col className="align-to-right morris-lg">
          {t("CommonWords.Details")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={isExpanded ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }
  
  const renderPager = () => {
    if (isMobile) {
      return (
        <Pager
          page={currentPage}
          pageSize={pageSize}
          totalEntries={props.landsSearchState.entries ? props.landsSearchState.entries.length : 0}
          pageUp={() => setCurrentPage(currentPage + 1)}
          pageDown={() => setCurrentPage(currentPage - 1)}
          useLightBackgroud={true}
        />
      );
    } else {
      return (
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Pager
              page={currentPage}
              pageSize={pageSize}
              totalEntries={props.landsSearchState.entries ? props.landsSearchState.entries.length : 0}
              pageUp={() => setCurrentPage(currentPage + 1)}
              pageDown={() => setCurrentPage(currentPage - 1)}
              useLightBackgroud={true}
            />
          </Col>
        </Row>
      );
    }
  }

  const renderManage = (overlord?: string) => {
    return (
      <>
        {renderLoading(props.landsSearchState.isLoadingUserDetails)}
        {!props.landsSearchState.isLoadingUserDetails && props.landsSearchState.userKingdomDetail &&
          <RenderManageSearchUserKingdomComponent detail={props.landsSearchState.userKingdomDetail} overlord={overlord} />
        }
      </>);
  }

  const toggleManagePanel = (userKingdomId: string) => {
    if (props.landsSearchState.expandedUserKingdomId === userKingdomId) {
      props.closeExpandedDetails();
    } else {
      props.getUserKingdomDetails(userKingdomId);
    }
  }

  const renderLoading = (isLoading: boolean) => {
    if (isLoading) {
      return (
        <Row>
          <Col>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  }

  const renderEntries = (user: LandsSearchEntry) => {
    if (!props.landsSearchState.isLoading && props.landsSearchState.entries.length > 0) {
      return (
          <div key={user.userId}>
            <Row onClick={() => toggleManagePanel(user.userKingdomId)} className={`resource-table-sub-entry ${ props.landsSearchState.expandedUserKingdomId === user.userKingdomId ? "apple-green-background" : "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
               <Row>
                <Col className="sub-entry-margin-class">{t(`TitleTypes_${user.useMaleTitles ? "Male": "Female"}.${user.title}`)} {user.username}{user.overlordUsername && `*`}</Col>
                <Col className="sub-entry-margin-class">{user.ownProperties}</Col>
                {!isMobile && <Col className="sub-entry-margin-class">{user.totalLand} up2</Col>}
                <Col className="sub-entry-margin-class">{user.hasTruce ? t("LandSearchPage.Truce") : user.vassalCount}</Col>
                <Col className="sub-entry-margin-class">{user.ownWorkers}</Col>
                {!isMobile && <Col className="sub-entry-margin-class">
                  {renderExpandToggle(props.landsSearchState.expandedUserKingdomId === user.userKingdomId)}
                </Col>}
               </Row>
               {isMobile && 
                <Row>
                  <Col className="sub-entry-margin-class">{user.totalLand} up2</Col>
                  <Col className="sub-entry-margin-class">{renderExpandToggle(props.landsSearchState.expandedUserKingdomId === user.userKingdomId)}</Col>
                </Row>} 
            </Row>
            {props.landsSearchState.expandedUserKingdomId === user.userKingdomId && renderManage(user.overlordUsername)}
        </div>
      );
    } else {
      return <></>;
    }
  }

  const updateAddress = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    props.updateLandsAddress(props.userKingdom.kingdomId, changeEvent.currentTarget.value)
  }

  const updateUsernameFilter = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    props.updateUserSearchFilters(changeEvent.currentTarget.value, props.landsSearchState.orderBy, props.landsSearchState.ascending);
  }

  const updateOrderByFilter = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    props.updateUserSearchFilters(props.landsSearchState.usernameFilter, changeEvent.currentTarget.value, props.landsSearchState.ascending);
  }

  const updateAscendingFilter = (value: boolean) => {
    props.updateUserSearchFilters(props.landsSearchState.usernameFilter, props.landsSearchState.orderBy, value);
  }

  const renderLandsSearchTable = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry bottom-border ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col></Col>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("CommonWords.Address")}</Form.Label>
                <Form.Control className="form-text-box" type="text"
                  value={props.landsSearchState.searchAddress} onChange={updateAddress}/>
              </Form.Group>
            </Form>
          </Col>
          <Col></Col>
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col></Col>
          <Col>{t("CommonWords.Owner")}</Col>
          {!isMobile && <Col>{t("CommonWords.Address")}</Col>}
          <Col>{t("CommonWords.Size")}</Col>
          <Col>{t("CommonWords.Improvements")}</Col>
          {!isMobile && <Col></Col>}
        </Row>
        {renderLoading(props.landsSearchState.isSearchingPlots)}
        {!props.landsSearchState.isSearchingPlots 
          && props.landsSearchState.searchedPlots 
          && props.landsSearchState.searchedPlots.length > 0
          && props.landsSearchState.searchedPlots.map((x: SearchPlot) => <SearchPlotDisplay key={x.id} plot={x} showOwner={true}/>)}
      </>
    )
  }

  const GetOrderByDropDownOptions = () => {
    return userSearchOrderByEnum.map((x) => {
      return <option key={x.key} value={x.value}>{t(`LandSearchPage.OrderByValues.${x.value}`)}</option>
    })
  }

  const sortLandSearchEntries = (a: LandsSearchEntry, b: LandsSearchEntry) => {
    if (props.landsSearchState.ascending) {
      switch (props.landsSearchState.orderBy) {
        case "Username":
          return a.username > b.username ? 1 : -1;
        case "OwnedPlots":
          return a.ownProperties > b.ownProperties ? 1 : -1;
        case "Vassals":
          return a.vassalCount > b.vassalCount ? 1 : -1;
        case "OwnedUp2":
          return a.ownedLand > b.ownedLand ? 1 : -1;
        case "VassalUp2":
          return a.vassalLand > b.vassalLand ? 1 : -1;
        case "TotalUp2":
          return a.totalLand > b.totalLand ? 1 : -1;
        case "Rank":
          return a.rank > b.rank ? 1 : -1;
        default:
          return a.ownWorkers > b.ownWorkers ? 1 : -1;
      }
    } else {
      switch (props.landsSearchState.orderBy) {
        case "Username":
          return b.username > a.username ? 1 : -1;
        case "OwnedPlots":
          return b.ownProperties > a.ownProperties ? 1 : -1;
        case "Vassals":
          return b.vassalCount > a.vassalCount ? 1 : -1;
        case "OwnedUp2":
          return b.ownedLand > a.ownedLand ? 1 : -1;
        case "VassalUp2":
          return b.vassalLand > a.vassalLand ? 1 : -1;
        case "TotalUp2":
          return b.totalLand > a.totalLand ? 1 : -1;
        case "Rank":
          return b.rank > a.rank ? 1 : -1;
        default:
          return b.ownWorkers > a.ownWorkers ? 1 : -1;
      }
    }
  }

  const renderUsersSearchTable = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry bottom-border ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("CommonWords.Username")}</Form.Label>
                <Form.Control className="form-text-box" type="text"
                  value={props.landsSearchState.usernameFilter} onChange={updateUsernameFilter}/>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("LandSearchPage.OrderBy")}</Form.Label>
                <Form.Select className="drop-down-style wheat_dark" value={props.landsSearchState.orderBy} onChange={updateOrderByFilter}>
                  {GetOrderByDropDownOptions()}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("LandSearchPage.Ascending")}</Form.Label>
                <div className={`m-1 sub-entry-margin-class morris-md ${props.landsSearchState.ascending ? "appleGreen-button" : "errorRed-button"}`} onClick={() => updateAscendingFilter(!props.landsSearchState.ascending)}>
                  {props.landsSearchState.ascending ? t("CommonWords.Yes") : t("CommonWords.No")}
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col>{t("CommonWords.Username")}</Col>
          <Col>{t("CommonWords.Plots")}</Col>
          {!isMobile && <Col>{t("CommonWords.Size")}</Col>}
          <Col>{t("CommonWords.Vassal", { count: 2 })}</Col>
          <Col>{t("LandSearchPage.Workers")}</Col>
          {!isMobile && <Col></Col>}
        </Row>
        <Row className="pb-2">
            {renderLoading(props.landsSearchState.isLoading)}
            {!props.landsSearchState.isLoading && props.landsSearchState.entries && props.landsSearchState.entries
              .filter((x: LandsSearchEntry) => x.username.includes(props.landsSearchState.usernameFilter))
              .sort(sortLandSearchEntries)
              .slice((currentPage - 1) * pageSize, (currentPage * pageSize))
              .map((x: LandsSearchEntry) => renderEntries(x))}
        </Row>
        {renderPager()}
      </>
    );
  }

  const renderTabView = () => {
    if (activeTab === t("LandSearchPage.SearchUsersTab")) {
      return renderUsersSearchTable();
    } else if (activeTab === t("LandSearchPage.SearchLandsTab")) {
      return renderLandsSearchTable();
    }
  }

  const renderLandSearchView = () => {
    return (
      <>
        <Row>
          <TabSelector tabs={availableTabs} activeTab={activeTab} clickEvent={onTabSelectorClick}/>
        </Row>
        <Row className="pb-2">
          <div className="tabSelectorContainer">
            {renderTabView()}
          </div>
        </Row>
      </>
    );
  }

  return (
      <>
        <UserKingdomLoadingComponent />
        <MenuButton />
        <CombatModal />
        <div className="footer-padding">
          <Container fluid="md">
            {renderTitle()}
            <br></br>
            <div className="standard-text-box">
              <Row>
                <Col className="sub-entry-margin-class morris-lg m-2">
                  <div className="woodBrown-button max-width-300" onClick={() => navigate("/Market")}>
                    {t("UserKingdomPage.Market")}
                  </div>
                </Col>
                <Col className="sub-entry-margin-class morris-lg m-2">
                  <div className="woodBrown-button max-width-300" onClick={() => navigate("/Play")}>
                    {t("UserKingdomPage.Kingdom")}
                  </div>
                </Col>
              </Row>
            </div>
            {renderLandSearchView()}
          </Container>
        </div>
        <Footer />
      </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet,
    isLoadingUserKingdom: state.UserKingdomState.isLoading,
    hasLoadedUserKingdom: state.UserKingdomState.hasLoaded,
    hasErrorLoadingUserKingdom: state.UserKingdomState.hasError,
    errorMessage: state.UserKingdomState.errorMessage,
    userKingdom: state.UserKingdomState.userKingdom,

    landsSearchState: state.LandsSearchState
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    getKingdomLands: (kingdomId: number) => dispatch(getKingdomLands(kingdomId)),
    updateLandsAddress: (kingdomId: number, address: string) => dispatch(updateSearchPlotsForm(kingdomId, address)),
    getUserKingdomDetails: (userKingdomId: string) => dispatch(getUserKingdomDetails(userKingdomId)),
    closeExpandedDetails: () => dispatch(closeExpandedDetails()),
    updateUserSearchFilters: (username: string, orderBy: string, ascending: boolean) => dispatch(updateUserSearchFilters(username, orderBy, ascending))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(LandSearchView);