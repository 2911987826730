import { Row, Col, Image, } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../styles/style.scss"
import GroupedWorkers from '../../common/types/userKingdom/groupedWorkers';
import { useTranslation } from "react-i18next";

export interface OwnProps {
  workerClass: string,
  workers: GroupedWorkers[],
}

export const WorkerDisplayTableEntry = (props: OwnProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleExpanded = (event: any) => {
    setExpanded(!expanded);
  }

  const renderExpandToggle = () => {
    if (expanded) {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleDown}/></Col>
      )
    } else {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleRight}/></Col>
      );
    }
  }

  const renderExpanded = () => {
    if (expanded) {
      return (
        <>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col></Col>
              <Col className="sub-entry-margin-class">{t("CommonWords.Type")}</Col>
              <Col className="sub-entry-margin-class">
                {props.workerClass === "Migrated" ? 
                  t("UserKingdomPage.WorkersTab.Migrated") :
                  t("UserKingdomPage.WorkersTab.Employed")}
              </Col>
              <Col className="sub-entry-margin-class">
                {props.workerClass === "Migrated" ? 
                  t("UserKingdomPage.WorkersTab.Died") :
                  t("UserKingdomPage.WorkersTab.Unemployed")}
              </Col>
              {props.workerClass === "Migrated" && <Col className="sub-entry-margin-class">Reasons</Col>}
          </Row>
          {props.workers.map((x) => 
            <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col><Image className="thumbnail-image" src={`/images/workers/${x.typeString}.png`}/></Col>
              <Col className="sub-entry-margin-class">{t(`WorkerTypes.${x.typeString}`, { count: props.workerClass === "Migrated" ? 
                x.numberDied + x.numberLeft :
                x.numberEmployed + x.numberUnemployed})}</Col>
              <Col className="sub-entry-margin-class">{ props.workerClass === "Migrated" ? x.numberLeft : x.numberEmployed }</Col>
              <Col className="sub-entry-margin-class">{ props.workerClass === "Migrated" ? x.numberDied : x.numberUnemployed }</Col>
              {props.workerClass === "Migrated" && <Col className="sub-entry-margin-class">{x.leftReasons.map(z => t(`MigrationReasons.${z}`)).join(', ')}</Col>}
            </Row>)}
        </>
      )
    }
    return (<></>);
  }

  return (
    <>
      <Row onClick={toggleExpanded} className="resource-table standard-text-md">
        <Col>
          {t(`WorkerClasses.${props.workerClass}`)}
        </Col>
        <Col>
          {props.workerClass === "Migrated" && props.workers.reduce((a, b) => a = a + b.numberDied + b.numberLeft, 0)}
          {props.workerClass !== "Migrated" && props.workers.reduce((a, b) => a = a + b.numberEmployed + b.numberUnemployed, 0)}
        </Col>
        {renderExpandToggle()}
      </Row>
      {renderExpanded()}
    </>
  );
}