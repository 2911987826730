import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import GetActiveKingdomsResponse from '../../common/types/messages/GetActiveKingdomsResponse';
import GetUserKingdomResponse from '../../common/types/messages/GetUserKingdomResponse';
import Improvement from '../../common/types/userKingdom/improvement';
import {
  UserKingdomActionTypes,
  GetUserKingdomActionType,
  GetUserKingdomCompletedActionType,
  GetUserKingdomFailedActionType,
  getUserKingdomCompleted,
  getUserKingdomFailed,
  DeleteJobActionType,
  DeleteJobCompletedActionType,
  DeleteJobFailedActionType,
  deleteJobCompleted,
  deleteJobFailed,
  cityUserEntityExpired,
  CityUserEntityExpiredActionType,
  ManageJobWorkersActionType,
  ManageJobWorkersCompletedActionType,
  ManageJobWorkersFailedActionType,
  manageJobWorkersCompleted,
  manageJobWorkersFailed,
  ManageImprovementWorkersActionType,
  ManageImprovementWorkersCompletedActionType,
  ManageImprovementWorkersFailedActionType,
  manageImprovementWorkersCompleted,
  manageImprovementWorkersFailed,
  DemolishImprovementActionType,
  DemolishImprovementCompletedActionType,
  DemolishImprovementFailedActionType,
  demolishImprovementCompleted,
  demolishImprovementFailed,
  ChangeProductionTypeFailedActionType,
  ChangeCropTypeCompletedActionType,
  ChangeProductionTypeCompletedActionType,
  changeProductionTypeFailed,
  changeProductionTypeCompleted,
  ChangeCropTypeActionType,
  ChangeCropTypeFailedActionType,
  changeCropTypeCompleted,
  changeCropTypeFailed,
  ChangeProductionTypeActionType,
  DebugProcessTickActionType,
  DebugProcessTickCompletedActionType,
  DebugProcessTickFailedActionType,
  debugProcessTickCompleted,
  debugProcessTickFailed,
  RequestTrainingActionType,
  RequestTrainingCompletedActionType,
  RequestTrainingFailedActionType,
  requestTrainingCompleted,
  requestTrainingFailed,
  CancelTrainingRequestActionType,
  CancelTrainingRequestCompletedActionType,
  CancelTrainingRequestFailedActionType,
  cancelRequestTrainingCompleted,
  cancelRequestTrainingFailed,
  GetActiveKingdomsCompletedActionType,
  GetActiveKingdomsActionType,
  getActiveKingdomsCompleted,
  getActiveKingdomsFailed,
  ToggleGrowthActionType,
  ToggleGrowthCompletedActionType,
  ToggleGrowthFailedActionType,
  toggleGrowthCompleted,
  toggleGrowthFailed,
  RequestChangeProductionTypeValuesActionType,
  RequestChangeProductionTypeValuesCompletedActionType,
  RequestChangeProductionTypeValuesFailedActionType,
  requestChangeProductionTypeValuesCompleted,
  requestChangeProductionTypeValuesFailed,
  RequestChangeCropTypeValuesActionType,
  RequestChangeCropTypeValuesCompletedActionType,
  RequestChangeCropTypeValuesFailedActionType,
  requestChangeCropTypeValuesCompleted,
  requestChangeCropTypeValuesFailed,
  UpdateUserSettingsActionType,
  UpdateUserSettingsCompletedActionType,
  UpdateUserSettingsFailedActionType,
  updateUserSettingsCompleted,
  updateUserSettingsFailed
} from '../actions/userKingdomActions';

export const getUserKingdomEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.GetUserKingdom),
    mergeMap((action) => onGetUserKingdom(action, dependencies))
  );
}

export const onGetUserKingdom: (action: GetUserKingdomActionType, dependencies: AppDependencies) 
  => Observable<GetUserKingdomCompletedActionType | GetUserKingdomFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getUserKingdom$(action.payload!.kingdomId);

  return callResponse$.pipe(
    map(response => {
      return getUserKingdomCompleted(response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(getUserKingdomFailed(error.response.value));
    })
  );
}

export const getActiveKingdomsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.GetActiveKingdoms),
    mergeMap((action) => onGetActiveKingdoms(action, dependencies))
  );
}

export const onGetActiveKingdoms: (action: GetActiveKingdomsActionType, dependencies: AppDependencies) 
  => Observable<GetActiveKingdomsCompletedActionType | GetUserKingdomFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getActiveKingdoms$();

  return callResponse$.pipe(
    map(response => {
      return getActiveKingdomsCompleted(response as GetActiveKingdomsResponse);
    }),
    catchError(error => {
      return of(getActiveKingdomsFailed(error.response.value));
    })
  );
}

export const deleteJobEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.DeleteJob),
    mergeMap((action) => onDeleteJob(action, dependencies))
  );
}

export const onDeleteJob: (action: DeleteJobActionType, dependencies: AppDependencies) 
  => Observable<DeleteJobCompletedActionType | DeleteJobFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postCancelJob$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return deleteJobCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(deleteJobFailed(error.response.value));
    })
  );
}

export const manageJobWorkersEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.ManageJobWorkers),
    mergeMap((action) => onManageJobWorkers(action, dependencies))
  );
}

export const onManageJobWorkers: (action: ManageJobWorkersActionType, dependencies: AppDependencies) 
  => Observable<ManageJobWorkersCompletedActionType | ManageJobWorkersFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postManageJobWorkers$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return manageJobWorkersCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(manageJobWorkersFailed(error.response.value));
    })
  );
}

export const manageImprovementWorkersEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.ManageImprovementWorkers),
    mergeMap((action) => onManageImprovementWorkers(action, dependencies))
  );
}

export const onManageImprovementWorkers: (action: ManageImprovementWorkersActionType, dependencies: AppDependencies) 
  => Observable<ManageImprovementWorkersCompletedActionType | ManageImprovementWorkersFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postManageImprovementWorkers$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return manageImprovementWorkersCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(manageImprovementWorkersFailed(error.response.value));
    })
  );
}

export const demolishImprovementEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.DemolishImprovement),
    mergeMap((action) => onDemolishImprovement(action, dependencies))
  );
}

export const onDemolishImprovement: (action: DemolishImprovementActionType, dependencies: AppDependencies) 
  => Observable<DemolishImprovementCompletedActionType | DemolishImprovementFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postDemolishImprovement$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return demolishImprovementCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(demolishImprovementFailed(error.response.value));
    })
  );
}

export const changeProductionTypeEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.ChangeProductionType),
    mergeMap((action) => onChangeProductionType(action, dependencies))
  );
}

export const onChangeProductionType: (action: ChangeProductionTypeActionType, dependencies: AppDependencies) 
  => Observable<ChangeProductionTypeCompletedActionType | ChangeProductionTypeFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postChangeProductionType$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return changeProductionTypeCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(changeProductionTypeFailed(error.response.value));
    })
  );
}

export const requestChangeProductionTypeValuesEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.RequestChangeProductionTypeValues),
    mergeMap((action) => onRequestChangeProductionTypeValues(action, dependencies))
  );
}

export const onRequestChangeProductionTypeValues: (action: RequestChangeProductionTypeValuesActionType, dependencies: AppDependencies) 
  => Observable<RequestChangeProductionTypeValuesCompletedActionType | RequestChangeProductionTypeValuesFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postRequestChangeProductionTypeValues$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return requestChangeProductionTypeValuesCompleted(action.payload!.request, response as Improvement);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(requestChangeProductionTypeValuesFailed(error.response.value));
    })
  );
}

export const requestChangeCropTypeValuesEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.RequestChangeCropTypeValues),
    mergeMap((action) => onRequestChangeCropTypeValues(action, dependencies))
  );
}

export const onRequestChangeCropTypeValues: (action: RequestChangeCropTypeValuesActionType, dependencies: AppDependencies) 
  => Observable<RequestChangeCropTypeValuesCompletedActionType | RequestChangeCropTypeValuesFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postRequestChangeCropTypeValues$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return requestChangeCropTypeValuesCompleted(action.payload!.request, response as Improvement);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(requestChangeCropTypeValuesFailed(error.response.value));
    })
  );
}

export const changeCropTypeEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.ChangeCropType),
    mergeMap((action) => onChangeCropType(action, dependencies))
  );
}

export const onChangeCropType: (action: ChangeCropTypeActionType, dependencies: AppDependencies) 
  => Observable<ChangeCropTypeCompletedActionType | ChangeCropTypeFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postChangeCropType$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return changeCropTypeCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(changeCropTypeFailed(error.response.value));
    })
  );
}

export const requestTrainingEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.RequestTraining),
    mergeMap((action) => onRequestTraining(action, dependencies))
  );
}

export const onRequestTraining: (action: RequestTrainingActionType, dependencies: AppDependencies) 
  => Observable<RequestTrainingCompletedActionType | RequestTrainingFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postTrainingRequest$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return requestTrainingCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(requestTrainingFailed(error.response.value));
    })
  );
}

export const cancelTrainingRequestEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.CancelTrainingRequest),
    mergeMap((action) => onCancelTrainingRequest(action, dependencies))
  );
}

export const onCancelTrainingRequest: (action: CancelTrainingRequestActionType, dependencies: AppDependencies) 
  => Observable<CancelTrainingRequestCompletedActionType | CancelTrainingRequestFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postCancelTrainingRequest$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return cancelRequestTrainingCompleted(action.payload!.request, response as GetUserKingdomResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(cancelRequestTrainingFailed(error.response.value));
    })
  );
}

export const toggleGrowthEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.ToggleGrowth),
    mergeMap((action) => onToggleGrowth(action, dependencies))
  );
}

export const onToggleGrowth: (action: ToggleGrowthActionType, dependencies: AppDependencies) 
  => Observable<ToggleGrowthCompletedActionType | ToggleGrowthFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postToggleGrowth$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return toggleGrowthCompleted();
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(toggleGrowthFailed(error.response.value));
    })
  );
}

export const updateUserSettingsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.UpdateUserSettings),
    mergeMap((action) => onUpdateUserSettings(action, dependencies))
  );
}

export const onUpdateUserSettings: (action: UpdateUserSettingsActionType, dependencies: AppDependencies) 
  => Observable<UpdateUserSettingsCompletedActionType | UpdateUserSettingsFailedActionType > = (action, { apiService }) => {
    
  const callResponse$ = apiService.postUpdateUserSettings$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return updateUserSettingsCompleted();
    }),
    catchError(error => {
      return of(updateUserSettingsFailed(error.response.value));
    })
  );
}

export const debugProcessTickEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(UserKingdomActionTypes.DebugProcessTick),
    mergeMap((action) => onDebugProcessTick(action, dependencies))
  );
}

export const onDebugProcessTick: (action: DebugProcessTickActionType, dependencies: AppDependencies) 
  => Observable<DebugProcessTickCompletedActionType | DebugProcessTickFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getDEBUGProcessTick$(action.payload!.count);

  return callResponse$.pipe(
    map(response => {
      return debugProcessTickCompleted(response as GetUserKingdomResponse);
    }),
    catchError(error => {
      return of(debugProcessTickFailed(error.response.value));
    })
  );
}