import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import SearchedUserKingdomDetail from '../../common/types/SearchedUserKingdomDetail';
import SearchPlot from '../../common/types/SearchPlot';
import { SearchPlotDisplay } from './searchPlotDisplayComponent';
import { SearchGroupedWorkersDisplay } from './searchGroupedWorkersDisplayComponent';
import LandsSearchEntry from '../../common/types/LandsSearchEntry';
import { openBattleModal } from '../../store/actions/combatActions';
import { Tree, TreeNode } from 'react-organizational-chart';
import VassalEntry from '../../common/types/VassalEntry';
import Battle from '../../common/types/userKingdom/battle';
import DisplayCombat from '../combatPanel/displayCombat';


interface StateProps {
  landsSearchEntries: LandsSearchEntry[];
  userId: number;
  userKingdomId: string;
};

interface DispatchProps {
  openBattleModal: (openBattles: Battle[], lockBattleType: boolean, targetUserKingdomId: string, targetKingdomUserId: number, targetKingdomUsername: string) => void;
}

interface OwnProps {
  detail: SearchedUserKingdomDetail;
  overlord?: string;
}

export type RenderManageSearchUserKingdomComponentProps = DispatchProps & StateProps & OwnProps;

export const RenderManageSearchUserKingdomComponent = (props: RenderManageSearchUserKingdomComponentProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [expandPlots, setExpandPlots] = useState(false);
  const [expandWorkers, setExpandWorkers] = useState(false);
  const [expandVassals, setExpandVassals] = useState(false);
  const [expandCombats, setExpandCombats] = useState(false);

  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const renderOverlordPanel = () => {
    if (props.overlord) {
      return (
        <>
          <Row className="red-text-box morris-lg">
            <Col>{t("CommonWords.VassalOf", { overlordName: props.overlord })}</Col>
          </Row>
        </>
      )
    }
  }

  const renderVassalTree = () => {
    if (props.detail.vassals.length <= 0) {
      return (<></>);
    }

    let usedVassals: number[] = [];
    usedVassals.push(props.detail.userId);
    let nextVassals = props.detail.vassals.filter((x: VassalEntry) => x.overlordId === props.detail.userId);
    
    return (
      <Row className={`resource-table-sub-entry py-2 vassalTreeScroll`}>
        <Col>
          <Tree
            lineWidth={'5px'}
            lineColor={'#86a626'}
            lineBorderRadius={'10px'} 
            label={<div className="vassalTree standard-text-sm">{`${t(`TitleTypes_${props.detail.useMaleTitles ? "Male": "Female"}.${props.detail.title}`)} ${props.detail.username}`}</div>}>
            {nextVassals.map(x => renderVassalTreeNode(x, props.detail.vassals, usedVassals))}
          </Tree>
        </Col>
      </Row>
    )
  }

  const renderVassalTreeNode = (nextVassal: VassalEntry, vassals: VassalEntry[], usedVassalIds: number[]) => {
    let nextVassals = vassals.filter((x: VassalEntry) => x.overlordId === nextVassal.id && !usedVassalIds.includes(x.overlordId));

    if (nextVassals.length === 0) {
      return <TreeNode label={<div className="vassalTree standard-text-sm">{`${t(`TitleTypes_${nextVassal.useMaleTitles ? "Male": "Female"}.${nextVassal.title}`)} ${nextVassal.username}`}</div>} />
    }

    usedVassalIds.push(nextVassal.id);

    return (
      <TreeNode label={<div className="vassalTree standard-text-sm">{`${t(`TitleTypes_${nextVassal.useMaleTitles ? "Male": "Female"}.${nextVassal.title}`)} ${nextVassal.username}`}</div>}>
        {nextVassals.map(x => renderVassalTreeNode(x, vassals, usedVassalIds))}
      </TreeNode>
    );
  }

  const renderSearchBattles = () => {
    if (!props.detail || !props.detail.openBattles || props.detail.openBattles.length === 0) {
      return <></>;
    }

    return (
      <>
        <Row onClick={() => setExpandCombats(!expandCombats)} className={`resource-table-sub-entry top-border ${ expandCombats ? "apple-green-background" : "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t("CombatPanel.Battles", { count: props.detail.openBattles.length })}</Col>
          <Col className="sub-entry-margin-class">{props.detail.openBattles.length}</Col>
          <Col className="sub-entry-margin-class morris-lg">
            <FontAwesomeIcon className="" size="1x" icon={expandVassals ? faAngleDown : faAngleRight}/>
          </Col>
        </Row>
        {expandCombats && 
          <>
            <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col>{t("CombatPanel.Battle")}</Col>
              <Col>{t("CombatPanel.Soldiers")}</Col>
              <Col>{t("CommonWords.Status")}</Col>
              <Col>{t("CombatPanel.ResolvedOn")}</Col>
              <Col></Col>
            </Row>
            {props.detail.openBattles.map((x: Battle) => {
              return (
                <DisplayCombat key={x.id} battle={x} userId={props.userId} userKingdomId={props.userKingdomId} />
              );
            })}
          </>
        }
      </>
    );
  }

  const renderSearchVassals = () => {
    if (!props.detail || props.landsSearchEntries.filter(x => x.overlordId === props.detail!.userId).length === 0) {
      return <></>;
    }

    let vassalIds = props.detail.vassals.map(x => x.id);

    let vassals = props.landsSearchEntries.filter(x => vassalIds.includes(x.userId));
    return (
      <>
        <Row onClick={() => setExpandVassals(!expandVassals)} className={`resource-table-sub-entry top-border ${ expandVassals ? "apple-green-background" : "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t("CommonWords.Vassal", { count: vassals.length })}</Col>
          <Col className="sub-entry-margin-class">{vassals.length}</Col>
          <Col className="sub-entry-margin-class morris-lg">
            <FontAwesomeIcon className="" size="1x" icon={expandVassals ? faAngleDown : faAngleRight}/>
          </Col>
        </Row>
        {expandVassals && props.detail!.plots.length > 0 &&
          <>
            <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col>{t("CommonWords.Username")}</Col>
              <Col>{t("LandSearchPage.Workers")}</Col>
              <Col>{t("CommonWords.Plots")}</Col>
              <Col>{t("CommonWords.Size")}</Col>
            </Row>
            {vassals.map(x => 
              <Row key={x.userId} className={`resource-table-sub-entry standard-text`}>
                <Col>{x.username}</Col>
                <Col>{x.ownWorkers}</Col>
                <Col>{x.ownProperties}</Col>
                <Col>{x.ownedLand}  up2</Col>
              </Row>
            )}
          </>
        }
      </>
    );
  }

  const renderUserSearchPlots = () => {
    return (
      <>
        <Row onClick={() => setExpandPlots(!expandPlots)} className={`resource-table-sub-entry top-border ${ expandPlots ? "apple-green-background" : "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t("CommonWords.Plots")}</Col>
          <Col className="sub-entry-margin-class">{props.detail ? props.detail.plots.length : 0}</Col>
          <Col className="sub-entry-margin-class morris-lg">
            <FontAwesomeIcon className="" size="1x" icon={expandPlots ? faAngleDown : faAngleRight}/>
          </Col>
        </Row>
        {expandPlots && props.detail!.plots.length > 0 &&
          <>
            <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col></Col>
              <Col>{t("CommonWords.Address")}</Col>
              <Col>{t("CommonWords.Size")}</Col>
              <Col>{t("CommonWords.Improvements")}</Col>
              <Col></Col>
            </Row>
            {props.detail!.plots.map((x: SearchPlot) => <SearchPlotDisplay key={x.id} plot={x} showOwner={false}/>)}
          </>
        }
      </>
    );
  }

  const renderUserSearchWorkers = () => {
    if (!props.detail) {
      return <></>;
    }

    const workerClasses: string[] = [];
    props.detail!.groupedWorkers.map(x => workerClasses.indexOf(x.class) === -1 ?
        workerClasses.push(x.class) : undefined
    );

    return (
      <>
        <Row onClick={() => setExpandWorkers(!expandWorkers)} className={`resource-table-sub-entry top-border ${ expandWorkers ? "apple-green-background" : "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t("LandSearchPage.Workers")}</Col>
          <Col className="sub-entry-margin-class">{props.detail ? props.detail.groupedWorkers.reduce((a,b) => a + b.numberUnemployed + b.numberEmployed, 0) : 0}</Col>
          <Col className="sub-entry-margin-class morris-lg">
            <FontAwesomeIcon className="" size="1x" icon={expandWorkers ? faAngleDown : faAngleRight}/>
          </Col>
        </Row>
        {expandWorkers && props.detail!.groupedWorkers.length > 0 &&
          <>
            {workerClasses.map((x) => 
              <SearchGroupedWorkersDisplay
                key={x}
                workerClass={x}
                workers={props.detail!.groupedWorkers.filter(r => r.class === x)}
              />)}
          </>
        }
      </>
    );
  }

  const renderAttackPanel = () => {
    return (
      <Row className="resource-table-sub-entry top-border wheat-background standard-text-md">
        <Col></Col>
        <Col>
          <Row><Col>{props.detail.hasTruce && t("LandSearchPage.TruceExpires")}</Col></Row>
          <Row><Col>{props.detail.hasTruce && props.detail.truceExpiresOn && new Date(Date.parse(props.detail.truceExpiresOn)).toLocaleDateString()}</Col></Row>
        </Col>
        <Col className="sub-entry-margin-class morris-md py-2">
          {!props.detail.hasTruce && <div className="appleGreen-button" onClick={() => props.openBattleModal(props.detail.openBattles, false, props.detail.userKingdomId, props.detail.userId, props.detail.username)}>
            {t("CommonWords.Attack")}
          </div>}
          {props.detail.hasTruce && <div className="appleGreen-button disabled">
            {t("CommonWords.Attack")}
          </div>}
        </Col>
      </Row>
    );
  }

  return (
    <div key={props.detail.userKingdomId} className="mx-2 mb-2">
      {renderOverlordPanel()}
      {renderVassalTree()}
      {renderUserSearchWorkers()}
      {renderUserSearchPlots()}
      {renderSearchVassals()}
      {renderSearchBattles()}
      {renderAttackPanel()}
    </div>
  );
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const stateProps: StateProps = {
    landsSearchEntries: state.LandsSearchState.entries,
    userId: state.UserKingdomState.userKingdom ? state.UserKingdomState.userKingdom.userId : -1,
    userKingdomId: state.UserKingdomState.userKingdom ? state.UserKingdomState.userKingdom.userKingdomId : ""
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    openBattleModal: (openBattles: Battle[], lockBattleType: boolean, targetUserKingdomId: string, targetKingdomUserId: number, targetKingdomUsername: string) => dispatch(openBattleModal(openBattles, lockBattleType, targetUserKingdomId, targetKingdomUserId, targetKingdomUsername))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderManageSearchUserKingdomComponent);