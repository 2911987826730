import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Image, Spinner } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import GroupedWorkers from '../../common/types/userKingdom/groupedWorkers';
import CombatUnitLocation from '../../common/types/combat/CombatUnitLocation';

interface StateProps {
  combatState: CombatState;
  soldiers: GroupedWorkers[];
};

interface DispatchProps {

}

interface OwnProps {
  isAttacker: boolean;

  requestJoinBattle: (units: CombatUnitLocation[], isAttacker: boolean, numberOfFormations: number) => void;
  cancelJoinCombat: () => void;
}

export type UnitPlacementComponentProps = DispatchProps & StateProps & OwnProps;

const UnitPlacementComponent = (props: UnitPlacementComponentProps) => {

  const GetAttackerBlankCombatGrid = () => {
    let grid = []
    for (let x = 0; x < 5; x++) {
      for (let y = 0; y < 20; y++) {
        grid.push({
          unitType: -1,
          x: x,
          y: y
        } as CombatUnitLocation)
      }
    }
  
    return grid;
  }
  
  const GetDefenderBlankCombatGrid = () => {
    let grid = []
    for (let x = 35; x < 40; x++) {
      for (let y = 0; y < 20; y++) {
        grid.push({
          unitType: -1,
          x: x,
          y: y
        } as CombatUnitLocation)
      }
    }
  
    return grid;
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [isRightMouseDown, setIsRightMouseDown] = useState(false);
  const [isLeftMouseDown, setIsLeftMouseDown] = useState(false);
  const [combatGrid, setCombatGrid] = useState(props.isAttacker ? GetAttackerBlankCombatGrid() : GetDefenderBlankCombatGrid());
  const [selectedUnitType, setSelectedUnitType] = useState(-1);
  const [numberOfFormations, setNumberOfFormations] = useState(1);
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // handle mouse down
  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();

    // Left Button
    if (event.button === 0) {
      setIsLeftMouseDown(true);
    }

    // Right Button
    if (event.button === 2) {
      setIsRightMouseDown(true);
    }

    return false;
  }

   // handle mouse up
   const handleMouseUp = (event: MouseEvent) => {
    event.preventDefault();

    // Left Button
    if (event.button === 0) {
      setIsLeftMouseDown(false);
    }

    // Right Button
    if (event.button === 2) {
      setIsRightMouseDown(false);
    }

    return false;
  }

  // create an event listener
  useEffect(() => {
    const combatModal = document.getElementById("combatModalId");

    if (combatModal) {
    combatModal!.addEventListener("resize", handleResize);
    combatModal!.addEventListener("mousedown", handleMouseDown)
    combatModal!.addEventListener("mouseup", handleMouseUp)
    combatModal!.addEventListener("contextmenu", ( e )=> { e.preventDefault(); return false; } );
    }
  })
  
  const resetCombatGrid = () => {
    setNumberOfFormations(1);
    if (props.isAttacker) {
      setCombatGrid(GetAttackerBlankCombatGrid());
    } else {
      setCombatGrid(GetDefenderBlankCombatGrid());
    }
  }

  const setUnitOnGrid = (x: number, y: number, isAdd: boolean) => {
    let newCombatGrid = combatGrid.map((entry: CombatUnitLocation) => {
      let unitType = entry.unitType;

      if (entry.x === x && entry.y === y) {
        if (!isAdd) {
          unitType = -1;
        } else {
          unitType = selectedUnitType;
        }
      }

      return {
        x: entry.x,
        y: entry.y,
        unitType: unitType
      } as CombatUnitLocation
    });

    setCombatGrid(newCombatGrid);
  }

  const handleSaveClick = () => {
    props.requestJoinBattle(combatGrid, props.isAttacker, numberOfFormations);
  }

  const handleUpdateNumberOfFormations = (add: boolean) => {
    if (!add) {
      if (numberOfFormations >= 2) {
        setNumberOfFormations(numberOfFormations - 1);
      }
    } else {
      let gridUnitTypes = getUnitTypesInGrid();

      if (gridUnitTypes && gridUnitTypes.length > 0 && !gridUnitTypes.some(x => getUnitTotal(x) < getUnitTypeCountInGrid(x) * (numberOfFormations + 1))) {
        setNumberOfFormations(numberOfFormations + 1);
      }
    }
  }

  const renderNumberOfFormations = () => {
    return (
      <>
        <Row>
          <Col className="sub-entry-margin-class morris-lg m-2">
            {t("CombatPanel.NumberOfFormations")} - {numberOfFormations}
          </Col>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="errorRed-button" onClick={() => 
              handleUpdateNumberOfFormations(false)}>
              -
            </div>
          </Col>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="appleGreen-button" onClick={() => 
              handleUpdateNumberOfFormations(true)}>
              +
            </div>
          </Col>
        </Row>
      </>
    );
  }

  const renderButtons = () => {
    return (
      <Row>
        <Col className="sub-entry-margin-class morris-lg">
          <div className="darkStone-button" onClick={() => props.cancelJoinCombat()}>
            {t("Buttons.Done")}
          </div>
        </Col>
        <Col className="sub-entry-margin-class morris-lg">
          <div className="darkStone-button" onClick={() => resetCombatGrid()}>
            {t("Buttons.Reset")}
          </div>
        </Col>
        <Col className="sub-entry-margin-class morris-lg">
          {props.combatState.isSubmitting && 
            <div className="darkStone-button">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            }
          {!props.combatState.isSubmitting && 
            <div className="darkStone-button" onClick={() => handleSaveClick()}>
              {t("Buttons.Save")}
            </div>
          }
        </Col>
      </Row>
    );
  }

  const getUnitTypesInGrid = () => {
    let unitTypes: number[] = [];

    combatGrid.forEach(x => {
      if (x.unitType !== -1 && !unitTypes.includes(x.unitType)) {
        unitTypes.push(x.unitType);
      }
    });

    return unitTypes;
  }

  const getUnitTypeCountInGrid = (unitType: number) => {
    return combatGrid.filter(x => x.unitType === unitType).reduce((a,b) => a + 1, 0);
  }

  const getAvailableUnits = (unitType: number) => {
    let unitsInGrid = getUnitTypeCountInGrid(unitType) * numberOfFormations;

    return getUnitTotal(unitType) - unitsInGrid;
  }

  const getUnitTotal = (unitType: number) => {
    let soldier = props.soldiers.filter((x: GroupedWorkers) => x.type === unitType);

    return !soldier || soldier.length === 0 ? 0 : soldier[0].numberUnemployed;
  }

  const renderWorkers = () => {
    return (
      <>
        {[4, 5, 8, 9, 2, 3, 0, 6, 7].map((unitType) => {
          return (
            <Col key={unitType} className={`combat-unit-selector ${selectedUnitType === unitType && "selected"} ${getAvailableUnits(unitType) === 0 && selectedUnitType !== unitType && "none-available"}`} onClick={() => setSelectedUnitType(unitType)}>
              <Row><Col><Image className={`thumbnail-image ${isMobile ? "is-mobile" : ""}`} src={`/images/workers/${getGridUnitString(unitType)}.png`}/></Col></Row>
              <Row><Col>{t(`WorkerTypes.${getGridUnitString(unitType)}`, { count: 2})}</Col></Row>
              <Row><Col className="sub-entry-margin-class">{getAvailableUnits(unitType)}</Col></Row>
            </Col>
          );
        })}
      </>  
    );
  }

  const getGridUnitNumber = (x: number, y: number) => {
    return combatGrid.filter((z: CombatUnitLocation) => z.x === x && z.y === y)[0].unitType;
  }

  const getGridUnitString = (unitNumber: number) => {
    switch (unitNumber) {
      case 0:
        return "Peasant";
      case 2:
        return "LightArcher";
      case 3:
        return "Archer";
      case 4:
        return "Spearmen";
      case 5:
        return "Pikemen";
      case 6:
        return "Skirmisher";
      case 7:
        return "Swordsmen";
      case 8:
        return "LightCavalry";
      case 9:
        return "Knight";
      default:
        return "";
    }
  }

  const handleGridClick = (unitNumber: number, x: number, y:number) => {
    if (selectedUnitType === -1
        || unitNumber === selectedUnitType) {
      setUnitOnGrid(x, y, false)
    } else if (getAvailableUnits(selectedUnitType) > 0) {
      setUnitOnGrid(x, y, true)
    }
  }

  const handleClickDrag = (unitNumber: number, x: number, y:number) => {
    if (isRightMouseDown && unitNumber !== -1) {
      setUnitOnGrid(x, y, false)
    } else if (isLeftMouseDown && unitNumber !== selectedUnitType && getAvailableUnits(selectedUnitType) > 0) {
      setUnitOnGrid(x, y, true)
    }
  }

  const renderCombatGrid = () => {
    if (!combatGrid) {
      return <></>;
    }

    let rows = [];
    for (let i = 0; i < 20; i++) {
      rows.push(
        <tr key={i}>
          {!props.isAttacker && <td className="combat-grid-component blue-edge"></td>}
          {combatGrid.filter(z => z.y === i).map((x) => {
              let unitNumber = getGridUnitNumber(x.x, x.y);
              let unitString = getGridUnitString(unitNumber);

              return (
                <td key={`${x.x} - ${x.y}`} 
                  className="combat-grid-component" 
                  onClick={() => handleGridClick(unitNumber, x.x, x.y)}
                  onMouseLeave={() => handleClickDrag(unitNumber, x.x, x.y)}  
                  onMouseEnter={() => handleClickDrag(unitNumber, x.x, x.y)}>
                  {unitString === "" ? <></> : <Image className="combat-grid-image" src={`/images/workers/${unitString}.png`}/>}
                </td>
              );
            })}
            {props.isAttacker && <td className="combat-grid-component red-edge"></td>}
        </tr>)
    }

    return (
      <Row>
        <Col>
          <table className="combat-grid-table">
            <tbody>{rows}</tbody>
          </table>
        </Col>
      </Row>
    );
  }

  const renderTitle = () => {
    return (
      <>
      <Row className={"resource-table-sub-entry bottom-border top-border tertiary-header m-2"}>
        <Col>{props.isAttacker ? t("CombatPanel.Attacker", {count: 1}) : t("CombatPanel.Defender", {count: 1})}</Col>
      </Row>
      </>
    )
  }

  return (
    <>
      {renderTitle()}
      {renderButtons()}
      {renderNumberOfFormations()}
      <br></br>
      <Row>
        <Col>
          <Row className={`${isMobile ? "standard-text-sm" : "standard-text"}`}>
            {renderWorkers()}
          </Row>
        </Col>
        <Col>
          {renderCombatGrid()}
        </Col>
      </Row>
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
 
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    combatState: state.CombatState,
    soldiers: state.UserKingdomState.userKingdom?.groupedWorkers.filter((x: GroupedWorkers) => (x.class === "Military" || x.typeString === "Peasant") && x.numberUnemployed > 0),
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitPlacementComponent);