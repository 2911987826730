import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, Spinner, Image } from 'react-bootstrap'
import { Footer } from '../../components/footerComponent/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import UserKingdom from '../../common/types/userKingdom/userKingdom';
import UserKingdomLoadingComponent from '../../components/userKingdomLoadingComponent/userKingdomLoadingComponent';
import MenuButton from '../../components/menuButtonComponent/menuButton';
import { TabSelector } from '../../components/tabSelectorComponent/tabSelector';
import { useEffect, useState } from 'react';
import PostMarketSearchRequest from '../../common/types/messages/PostMarketSearchRequest';
import { getOwnMarketSales, postMarketBuyRequest, postMarketCancelRequest, postMarketSearchRequest, postMarketSellRequest, updateMarketSearchForm, updateMarketSellForm } from '../../store/actions/marketActions';
import { Pager } from '../../components/pagerComponent/pager';
import ResourceMarketEntry from '../../common/types/ResourceMarketEntry';
import { resourceMarketOrderByEnum } from '../../common/types/enums/resourceMarketOrderByEnum';
import { currencyEnum } from '../../common/types/enums/currencyEnum';
import ResourceRules from '../../common/types/rules/ResourceRules';
import { formatNumberMaxTwoDecimal, formatNumberNoDecimal, translateStringToNumber } from '../../common/helperFunctions/formattingFunctions';
import UpxSaleModal from '../../components/upxSaleModal/upxSaleModal';
import PostMarketSellRequest from '../../common/types/messages/PostMarketSellRequest';
import ConfirmModal from '../../components/confirmModal';
import { getUserKingdom } from '../../store/actions/userKingdomActions';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import { useNavigate } from 'react-router-dom';

interface StateProps {
  authTokenSet: boolean,
  isLoadingUserKingdom: boolean,
  hasLoadedUserKingdom: boolean,
  hasErrorLoadingUserKingdom: boolean,
  errorMessage: string,
  userKingdom: UserKingdom,
  marketState: MarketState,
  resourceRules?: ResourceRules[],
  activeKingdoms: KeyValuePair[],
  currentKingdomId: number
};

interface DispatchProps {
  searchMarket: (request: PostMarketSearchRequest) => void;
  buySale: (saleId: string) => void;
  cancelSale: (saleId: string) => void;
  updateMarketSearchForm: (request: PostMarketSearchRequest) => void;
  getOwnSales: (kingdomId: number) => void;
  updateMarketSellForm: (request: PostMarketSellRequest) => void;
  sellSale: (request: PostMarketSellRequest) => void;
  getUserKingdom: (kingdomId: number) => void;
}

export type MarketViewProps = DispatchProps & StateProps;

export const MarketView = (props: MarketViewProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const availableTabs = [
    t("MarketPage.SearchMarketTab"),
    t("MarketPage.OwnSalesTab")
   ];
  const [activeTab, setActiveTab] = useState(t("MarketPage.SearchMarketTab").toString());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
  const [isPerUnit, setIsPerUnit] = useState(true);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmEntry, setConfirmEntry] = useState("");

  const onTabSelectorClick = (input: string) => {
    let shouldLoadOwnSales = !props.marketState.isLoadingOwnSales
      && input === t("MarketPage.OwnSalesTab")
      && input !== activeTab;

    if (input === activeTab) {
      setActiveTab("");
    } else {
      setActiveTab(input); 
    }

    if (shouldLoadOwnSales) {
      props.getOwnSales(props.marketState.searchRequest.kingdomId);
    }
    
  }

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const updateKingdom = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    props.getUserKingdom(parseInt(changeEvent.currentTarget.value));
  }

  const renderTitle = () => {
    if (props.hasLoadedUserKingdom) {
      return (
        <Row>
          <div className="primary-header">
            {t("MarketPage.MarketOf")} {t(`Kingdoms.${props.userKingdom.kingdomId}`)}
          </div>
          <div className="outer-fancy-border">
            <div className="inner-fancy-border">
            </div>
          </div>
        </Row>
      );
    }

    if (props.isLoadingUserKingdom) {
      return (
        <Row>
          <div className="primary-header">
            {t("UserKingdomPage.Loading")}
          </div>
          <div className="outer-fancy-border">
            <div className="inner-fancy-border">
            </div>
          </div>
        </Row>
      );
    }

    if (props.hasErrorLoadingUserKingdom) {
      return (
        <Row>
          <div className="primary-header">
            {t("Exceptions.ErrorLoadingKingdom")}
          </div>
          <div className="outer-fancy-border">
            <div className="inner-fancy-border">
            </div>
          </div>
        </Row>
      );
    }
  }

  const renderLoading = (isLoading: boolean) => {
    if (isLoading) {
      return (
        <Row>
          <Col>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  }

  const GetResourceDropDownOptions = () => {
    if (props.resourceRules) {
      let resourceRules = [
        {
          type: -1,
          typeString: "All"
        } as ResourceRules,
        ...props.resourceRules];
      return resourceRules.map((x) => {
        return <option key={x.typeString} value={x.type}>{t(`Resources.${x.typeString}`, { count: 2})}</option>
      })
    }
  }

  const GetCurrencyDropDownOptions = () => {
    return currencyEnum.map((x) => {
      return <option key={x.key} value={x.value}>{t(`MarketPage.CurrencyTypes.${x.key}`)}</option>
    })
  }

  const GetOrderByDropDownOptions = () => {
    return resourceMarketOrderByEnum.map((x) => {
      return <option key={x.key} value={x.value}>{t(`MarketPage.OrderByValues.${x.key}`)}</option>
    })
  }


  const setCurrentPage = (newPage: number) => {
    props.searchMarket({
      ...props.marketState.searchRequest,
      page: newPage
    })
  }

  const renderPager = () => {
    if (isMobile) {
      return (
        <Pager
          page={props.marketState.searchRequest.page}
          pageSize={props.marketState.searchRequest.pageSize}
          totalEntries={props.marketState.totalEntries}
          pageUp={() => setCurrentPage(props.marketState.searchRequest.page + 1)}
          pageDown={() => setCurrentPage(props.marketState.searchRequest.page - 1)}
          useLightBackgroud={true}
        />
      );
    } else {
      return (
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Pager
              page={props.marketState.searchRequest.page}
              pageSize={props.marketState.searchRequest.pageSize}
              totalEntries={props.marketState.totalEntries}
              pageUp={() => setCurrentPage(props.marketState.searchRequest.page + 1)}
              pageDown={() => setCurrentPage(props.marketState.searchRequest.page - 1)}
              useLightBackgroud={true}
            />
          </Col>
        </Row>
      );
    }
  }

  const renderRedTextBox = (message: string) => {
    if (message.match(/.*::.*/)) {
      return (
        <Row>
          <Col>
            <div className="red-text-box">
              <b>{t(`Exceptions.${message.split("::")[0]}`)}</b>
            </div>
            <div className="red-text-box">
              <b>{message.split("::")[1]}</b>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t(`Exceptions.${message}`)}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const getCurrencyString = (currency: string, amount: number, copperDecimals: boolean) => {
    if (currency === "Coins") {
      let gold = Math.floor(amount/10000);
      let silver = Math.floor(amount/100 % 100);
      let copper = amount % 100;
      let returnString = "";

      if (gold > 0) {
        returnString += formatNumberNoDecimal(gold) + `${t("CommonWords.Currency.Gold")} `
        returnString += formatNumberNoDecimal(silver) + `${t("CommonWords.Currency.Silver")} `
        returnString += formatNumberNoDecimal(copper) + `${t("CommonWords.Currency.Copper")}`
      } else if (silver > 0) {
        returnString += formatNumberNoDecimal(silver) + `${t("CommonWords.Currency.Silver")} `
        returnString += formatNumberNoDecimal(copper) + `${t("CommonWords.Currency.Copper")}`
      } else if (copper > 0) {
        if (copperDecimals) {
          returnString += formatNumberMaxTwoDecimal(copper) + `${t("CommonWords.Currency.Copper")}`
        } else {
          returnString += formatNumberNoDecimal(copper) + `${t("CommonWords.Currency.Copper")}`
        }
      }

      return returnString;
    } else {
      return formatNumberMaxTwoDecimal(amount) + `${t("CommonWords.Currency.UPX")}`
    }
  }

  const renderOwnResourceMarketEntry = (entry: ResourceMarketEntry) => {
    return (
      <div key={entry.id}>
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">
            <Row><Col><Image className="thumbnail-image" src={`/images/resources/${props.resourceRules!.filter(x => x.type === entry.resource)[0].typeString}.png`}/></Col></Row>
            <Row><Col>{t(`Resources.${props.resourceRules!.filter((x: ResourceRules) => x.type === entry.resource)[0].typeString}`, { count: entry.amount })}</Col></Row>
          </Col>
          <Col className="sub-entry-margin-class">{entry.amount}</Col>
          <Col className="sub-entry-margin-class">{getCurrencyString(entry.currencyTypeString, entry.price, false)}</Col>
          <Col className="sub-entry-margin-class">{getCurrencyString(entry.currencyTypeString, entry.pricePerUnit, true)}</Col>
          <Col className="sub-entry-margin-class">{entry.sellerUsername}</Col>
          <Col className="sub-entry-margin-class">
            {!props.marketState.isSendingRequest && <div className="darkStone-button morris-md my-4" onClick={() => props.cancelSale(entry.id)}>
              {t("MarketPage.Cancel")}
            </div>}
            {props.marketState.isSendingRequest && <div className="darkStone-button disabled morris-md my-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>}
          </Col>
        </Row>
      </div>
    );
  }

  const renderResourceMarketEntry = (entry: ResourceMarketEntry) => {
    return (
      <div key={entry.id}>
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">
            <Row><Col><Image className="thumbnail-image" src={`/images/resources/${props.resourceRules!.filter(x => x.type === entry.resource)[0].typeString}.png`}/></Col></Row>
            <Row><Col>{t(`Resources.${props.resourceRules!.filter((x: ResourceRules) => x.type === entry.resource)[0].typeString}`, { count: entry.amount })}</Col></Row>
          </Col>
          <Col className="sub-entry-margin-class">{entry.amount}</Col>
          <Col className="sub-entry-margin-class">{getCurrencyString(props.marketState.searchRequest.currency === 0 ? "Coins": "UPX", entry.price, false)}</Col>
          <Col className="sub-entry-margin-class">{getCurrencyString(props.marketState.searchRequest.currency === 0 ? "Coins": "UPX", entry.pricePerUnit, true)}</Col>
          <Col className="sub-entry-margin-class">{entry.sellerUsername}</Col>
          <Col className="sub-entry-margin-class">
            {!props.marketState.isSendingRequest ? 
              <div className="darkStone-button morris-md my-4" onClick={() => { setConfirmEntry(entry.id); setShowConfirmModal(true); }}>
                {t("MarketPage.Buy")}
              </div>
              :
              <div className="darkStone-button disabled morris-md my-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>}
          </Col>
        </Row>
      </div>
    );
  }

  const renderMarketSearchTab = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("MarketPage.Resource")}</Form.Label>
                <Form.Select className="drop-down-style wheat_dark" value={props.marketState.searchRequest.resource} onChange={
                  (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateMarketSearchForm({
                    ...props.marketState.searchRequest,
                    resource: parseInt(changeEvent.currentTarget.value),
                    page: 1
                  })}>
                  {GetResourceDropDownOptions()}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("MarketPage.Currency")}</Form.Label>
                <Form.Select className="drop-down-style wheat_dark" value={props.marketState.searchRequest.currency} onChange={
                  (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateMarketSearchForm({
                    ...props.marketState.searchRequest,
                    currency: parseInt(changeEvent.currentTarget.value),
                    page: 1
                  })}>
                  {GetCurrencyDropDownOptions()}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.searchMarket(props.marketState.searchRequest)}>
                {t("Buttons.Search")}
              </div>
          </Col>
        </Row>
        <Row className={`resource-table-sub-entry bottom-border ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("MarketPage.OrderBy")}</Form.Label>
                <Form.Select className="drop-down-style wheat_dark" value={props.marketState.searchRequest.orderBy} onChange={
                  (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateMarketSearchForm({
                    ...props.marketState.searchRequest,
                    orderBy: parseInt(changeEvent.currentTarget.value),
                    page: 1
                  })}>
                  {GetOrderByDropDownOptions()}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Label className="morris-md">{t("MarketPage.MinimumItems")}</Form.Label>
                <Form.Control className="form-text-box" type="text" 
                  value={props.marketState.searchRequest.minimumItemCount} onChange={
                  (changeEvent) => props.updateMarketSearchForm({
                    ...props.marketState.searchRequest,
                    minimumItemCount: translateStringToNumber(changeEvent.currentTarget.value),
                    page: 1
                  })} />
              </Form.Group>
            </Form>
          </Col>
          <Col>
          </Col>
        </Row>
        {(props.marketState.hasMarketError || props.marketState.errorMarketMessage !== '') && renderRedTextBox(props.marketState.errorMarketMessage)}
        {(props.marketState.hasSendingRequestError || props.marketState.sendingRequestError !== '') && renderRedTextBox(props.marketState.sendingRequestError)}
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col></Col>
          <Col>{t("MarketPage.Amount")}</Col>
          <Col>{t("MarketPage.Price")}</Col>
          <Col>{t("MarketPage.PerUnit")}</Col>
          <Col>{t("MarketPage.Seller")}</Col>
          <Col></Col>
        </Row>
        <Row className="pb-2">
            {renderLoading(props.marketState.isLoadingMarket)}
            {!props.marketState.isLoadingMarket && props.marketState.marketEntries && props.marketState.marketEntries
              .map((x: ResourceMarketEntry) => renderResourceMarketEntry(x))}
        </Row>
        {renderPager()}
      </>
    );
  }

  const getNumberValue = (input: string) => {
    let num = parseInt(input);

    if (num) {
      return num;
    }

    return 0;
  }

  const renderOwnSalesTab = () => {
    let totalPrice = isPerUnit ? props.marketState.newSellRequest.price * props.marketState.newSellRequest.amount : props.marketState.newSellRequest.price;

    return (
      <>
        <Row className="morris-lg"><Col>{t("MarketPage.NewSale")}</Col></Row>
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            <Row>
              <Col>
                <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
                  <Form.Group>
                    <Form.Label className="morris-md">{t("MarketPage.Resource")}</Form.Label>
                    <Form.Select className="drop-down-style wheat_dark" value={props.marketState.newSellRequest.resource} onChange={
                      (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateMarketSellForm({
                        ...props.marketState.newSellRequest,
                        resource: parseInt(changeEvent.currentTarget.value)
                      })}>
                      {GetResourceDropDownOptions()}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
                  <Form.Group>
                    <Form.Label className="morris-md">{t("MarketPage.Currency")}</Form.Label>
                    <Form.Select className="drop-down-style wheat_dark" value={props.marketState.newSellRequest.currencyType} onChange={
                      (changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateMarketSellForm({
                        ...props.marketState.newSellRequest,
                        currencyType: parseInt(changeEvent.currentTarget.value)
                      })}>
                      {GetCurrencyDropDownOptions()}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
                  <Form.Group>
                    <Form.Label className="morris-md">{t("MarketPage.Amount")}</Form.Label>
                    <Form.Control className="form-text-box" type="text"
                      value={props.marketState.newSellRequest.amount} onChange={
                        (changeEvent: React.ChangeEvent<HTMLInputElement>) => props.updateMarketSellForm({
                          ...props.marketState.newSellRequest,
                          amount: getNumberValue(changeEvent.currentTarget.value)
                        })}/>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
                  <Form.Group>
                    <Form.Label className="morris-md">{t("MarketPage.Price")}</Form.Label>
                    <Form.Control className="form-text-box" type="text"
                      value={props.marketState.newSellRequest.price} onChange={
                        (changeEvent: React.ChangeEvent<HTMLInputElement>) => props.updateMarketSellForm({
                          ...props.marketState.newSellRequest,
                          price: getNumberValue(changeEvent.currentTarget.value)
                        })}/>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                {!props.marketState.isSendingRequest && <div className="darkStone-button morris-md my-4" onClick={() => props.sellSale(
                  {
                    ...props.marketState.newSellRequest,
                    price: totalPrice
                  })}>
                  {t("Buttons.Save")}
                </div>}
                {props.marketState.isSendingRequest && <div className="darkStone-button disabled morris-md my-4">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="darkStone-button morris-md my-4" onClick={() => setIsPerUnit(!isPerUnit)}>
                  {t(isPerUnit ? "MarketPage.PerUnit" : "MarketPage.Total")}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {props.marketState.newSellRequest.currencyType === 0 && 
          <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
            <Col>
                <Row><Col className="center-image"><Image className="thumbnail-image" src={`/images/GoldCoins.png`}/></Col></Row>
                <Row><Col className="morris-lg">{formatNumberNoDecimal(Math.floor(totalPrice/10000))}</Col></Row>
              </Col>
              <Col>
                <Row><Col className="center-image"><Image className="thumbnail-image" src={`/images/SilverCoins.png`}/></Col></Row>
                <Row><Col className="morris-lg">{formatNumberNoDecimal(Math.floor(totalPrice/100 % 100))}</Col></Row>
              </Col>
              <Col>
                <Row><Col className="center-image"><Image className="thumbnail-image" src={`/images/CopperCoins.png`}/></Col></Row>
                <Row><Col className="morris-lg">{formatNumberNoDecimal(Math.floor(totalPrice % 100))}</Col></Row>
              </Col>
          </Row>}
        {
          props.marketState.newSellRequest.currencyType === 1 &&
          <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
            <Col>
              {t("MarketPage.UPXSaleDisclaimer")}
            </Col>
          </Row>
        }
        {(props.marketState.hasOwnSalesError || props.marketState.errorOwnSalesMessage !== '') && renderRedTextBox(props.marketState.errorOwnSalesMessage)}
        {(props.marketState.hasSendingRequestError || props.marketState.sendingRequestError !== '') && renderRedTextBox(props.marketState.sendingRequestError)}
        <Row className="morris-lg"><Col>{t("MarketPage.ActiveSales")}</Col></Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col></Col>
          <Col>{t("MarketPage.Amount")}</Col>
          <Col>{t("MarketPage.Price")}</Col>
          <Col>{t("MarketPage.PerUnit")}</Col>
          <Col>{t("MarketPage.Seller")}</Col>
          <Col></Col>
        </Row>
        <Row className="pb-2">
          {renderLoading(props.marketState.isLoadingOwnSales)}
          {!props.marketState.isLoadingOwnSales && props.marketState.ownSales && props.marketState.ownSales
            .map((x: ResourceMarketEntry) => renderOwnResourceMarketEntry(x))}
        </Row>
      </>
    );
  }

  const renderTabView = () => {
    if (activeTab === t("MarketPage.SearchMarketTab")) {
      return renderMarketSearchTab();
    } else if (activeTab === t("MarketPage.OwnSalesTab")) {
      return renderOwnSalesTab();
    }
  }

  const renderMarketView = () => {
    if (props.isLoadingUserKingdom) {
      return (
        <Container fluid="md">
          {renderTitle()}
          <br></br>
        </Container>
      );
    }

    return (
      <Container fluid="md">
        {renderTitle()}
        <div className="standard-text-box">
          <Row>
            <Col className="standard-text mx-4">
              <Form.Group>
                <Form.Select className="drop-down-style wheat_dark" value={props.activeKingdoms.length === 0 ? -1 : props.currentKingdomId} onChange={updateKingdom}>
                  {props.activeKingdoms.map(x => <option key={x.key} value={x.key}>{x.value}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="sub-entry-margin-class morris-lg m-2">
              <div className="woodBrown-button max-width-300" onClick={() => navigate("/LandSearch")}>
                {t("UserKingdomPage.LandSearch")}
              </div>
            </Col>
            <Col className="sub-entry-margin-class morris-lg m-2">
              <div className="woodBrown-button max-width-300" onClick={() => navigate("/Play")}>
                {t("UserKingdomPage.Kingdom")}
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <TabSelector tabs={availableTabs} activeTab={activeTab} clickEvent={onTabSelectorClick}/>
        </Row>
        <Row className="pb-2">
          <div className="tabSelectorContainer">
            {renderTabView()}
          </div>
        </Row>
      </Container>
    );
  }

  return (
      <>
        {showConfirmModal && <ConfirmModal showModal={showConfirmModal} denyAction={() => setShowConfirmModal(false)} confirmAction={() => {props.buySale(confirmEntry); setShowConfirmModal(false)}}/>}
        <UserKingdomLoadingComponent />
        <MenuButton />
        <UpxSaleModal />
        <div className="footer-padding">
          {renderMarketView()}
        </div>
        <Footer />
      </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet,
    isLoadingUserKingdom: state.UserKingdomState.isLoading,
    hasLoadedUserKingdom: state.UserKingdomState.hasLoaded,
    hasErrorLoadingUserKingdom: state.UserKingdomState.hasError,
    errorMessage: state.UserKingdomState.errorMessage,
    userKingdom: state.UserKingdomState.userKingdom,
    marketState: state.MarketState,
    resourceRules: state.RulesState.gameRules?.resourceRules,
    activeKingdoms: state.UserKingdomState.activeKingdoms,
    currentKingdomId: state.UserKingdomState?.userKingdom?.kingdomId
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    searchMarket: (request: PostMarketSearchRequest) => dispatch(postMarketSearchRequest(request)),
    buySale: (saleId: string) => dispatch(postMarketBuyRequest(saleId)),
    cancelSale: (saleId: string) => dispatch(postMarketCancelRequest(saleId)),
    updateMarketSearchForm: (request: PostMarketSearchRequest) => dispatch(updateMarketSearchForm(request)),
    getOwnSales: (kingdomId: number) => dispatch(getOwnMarketSales(kingdomId)),
    updateMarketSellForm: (request: PostMarketSellRequest) => dispatch(updateMarketSellForm(request)),
    sellSale: (request: PostMarketSellRequest) => dispatch(postMarketSellRequest(request)),
    getUserKingdom: (kingdomId: number) => dispatch(getUserKingdom(kingdomId)),
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketView);