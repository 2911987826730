import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useNavigate } from 'react-router-dom';
import { getActiveKingdoms, getUserKingdom } from '../../store/actions/userKingdomActions';
import UserKingdom from '../../common/types/userKingdom/userKingdom';
import { getGameRules } from '../../store/actions/rulesActions';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import { getKingdomLands } from '../../store/actions/landsSearchActions';
import { stat } from 'fs/promises';

interface StateProps {
  authTokenSet: boolean,
  isLoadingUserKingdom: boolean,
  hasLoadedUserKingdom: boolean,
  hasErrorLoadingUserKingdom: boolean,
  errorMessage: string,
  userKingdom: UserKingdom,
  rulesState: RulesState,
  activeUserKingdoms: KeyValuePair[],
  isLoadingActiveKingdoms: boolean,
  isSearchingKingdoms: boolean,
  searchKingdom: number,
  hasloadedKingdomLands: boolean,
  errorLoadingKingdomLands: boolean,
  loadingKingdomLandsError: string,
};

interface DispatchProps {
  getUserKingdom: (kingdomId: number) => void;
  getGameRules: () => void;
  getActiveKingdoms: () => void;
  getKingdomLands: (kingdomId: number) => void;
}

export type UserKingdomLoadingComponentProps = DispatchProps & StateProps;

export const UserKingdomLoadingComponent = (props: UserKingdomLoadingComponentProps) => {
 const navigate = useNavigate();

  useEffect(() => {
    if (!props.authTokenSet) {
      navigate("/");
    }
  }, [navigate, props]);

  useEffect(() => {
    if(!props.rulesState.isLoading && (!props.rulesState.gameRules || props.rulesState.gameRules!.improvementConstructionRules.length === 0)) {
      props.getGameRules();
    }
    
    if((!props.activeUserKingdoms || props.activeUserKingdoms.length === 0) && !props.isLoadingActiveKingdoms) {
      props.getActiveKingdoms()
    }

    if(!props.hasLoadedUserKingdom && !props.isLoadingUserKingdom) {
      props.getUserKingdom(-1);
    }
  }, [props])

  return (
    <></>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet,
    isLoadingUserKingdom: state.UserKingdomState.isLoading,
    hasLoadedUserKingdom: state.UserKingdomState.hasLoaded,
    hasErrorLoadingUserKingdom: state.UserKingdomState.hasError,
    errorMessage: state.UserKingdomState.errorMessage,
    userKingdom: state.UserKingdomState.userKingdom,
    rulesState: state.RulesState,
    activeUserKingdoms: state.UserKingdomState.activeKingdoms,
    isLoadingActiveKingdoms: state.UserKingdomState.isLoadingActiveKingdoms,
    isSearchingKingdoms: state.LandsSearchState.isLoading,
    searchKingdom: state.LandsSearchState.kingdomId,
    hasloadedKingdomLands: state.LandsSearchState.hasLoadedList,
    errorLoadingKingdomLands: state.LandsSearchState.hasError,
    loadingKingdomLandsError: state.LandsSearchState.errorMessage,
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
    const dispatchProps: DispatchProps = {
      getUserKingdom: (kingdomId: number) => dispatch(getUserKingdom(kingdomId)),
      getGameRules: () => dispatch(getGameRules()),
      getActiveKingdoms: () => dispatch(getActiveKingdoms()),
      getKingdomLands: (kingdomId: number) => dispatch(getKingdomLands(kingdomId)),
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(UserKingdomLoadingComponent);
