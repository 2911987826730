import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import GetNewsResponse from '../../common/types/messages/GetNewsResponse';
import {
  NewsActionTypes,
  GetNewsActionType,
  GetNewsCompletedActionType,
  GetNewsFailedActionType,
  getNewsCompleted,
  getNewsFailed
} from '../actions/newsActions';

export const getNewsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(NewsActionTypes.GetNews),
    mergeMap((action) => onGetNews(action, dependencies))
  );
}

export const onGetNews: (action: GetNewsActionType, dependencies: AppDependencies) 
  => Observable<GetNewsCompletedActionType | GetNewsFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getNews$(action.payload!.page);

  return callResponse$.pipe(
    map(response => {
      return getNewsCompleted(response as GetNewsResponse);
    }),
    catchError(error => {
      return of(getNewsFailed(error.response.value));
    })
  );
}

