import { Row, Col, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/style.scss"
import { useTranslation } from "react-i18next";
import Improvement from '../../../common/types/userKingdom/improvement';
import Job from '../../../common/types/userKingdom/job';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Plot from '../../../common/types/userKingdom/plot';
import ImprovementDisplay from '../ImprovementTab/ImprovementDisplay';
import JobDisplay from '../jobDisplay';
import Worker from '../../../common/types/userKingdom/worker';
import { NaturalResourcesDisplay } from '../naturalResourcesDisplay';
import NewJobButton from '../newJobButton';

interface StateProps {
  plotImprovements: Improvement[];
  plotJobs: Job[];
  workers: Worker[];
};

interface DispatchProps {

}

interface OwnProps {
  plot: Plot
}

export type LandsDisplayProps = DispatchProps & StateProps & OwnProps;

export const LandsDisplay = (props: LandsDisplayProps) => {
  const [manage, setManage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleManagePanel = (event: any) => {
    setManage(!manage);
  }

  const renderExpandToggle = () => {
    return (
      <Row>
        <Col className="align-to-right morris-lg">
          {t("UserKingdomPage.ImprovementsTab.Manage")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={manage ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }

  const renderImprovementsManagement = () => {
    if (props.plotImprovements.length === 0) {
      return <></>;
    }

    const uniqueImprovementClasses: string[] = [];

    props.plotImprovements.map(x => uniqueImprovementClasses.indexOf(x.improvementClassString) === -1 ?
      uniqueImprovementClasses.push(x.improvementClassString) : undefined
    );
  
    return (
      <>
        <Row className="resource-table-sub-entry dark-wheat-background standard-text-md">
          <Col>{t("UserKingdomPage.Tabs.Improvements")}</Col>
        </Row>
        {props.plotImprovements.map((x: Improvement) => 
          <ImprovementDisplay 
            key={x.id}
            improvement={x}
            workers={props.workers.filter(w => w.improvementId === x.id)}
          />)}
      </>
    );
  }

  const renderJobsManagement = () => {
    if (props.plotJobs.length === 0) {
      return <></>;
    }

    const uniqueJobIds: string[] = [];

    props.plotJobs.map(x => uniqueJobIds.indexOf(x.id) === -1 ?
      uniqueJobIds.push(x.id) : undefined
    );

    return (
      <>
      <Row className="resource-table-sub-entry dark-wheat-background standard-text-md">
        <Col>{t("UserKingdomPage.Tabs.Jobs")}</Col>
      </Row>
      {uniqueJobIds.map((x) => 
        <JobDisplay 
          key={x}
          jobId={x}
          jobs={props.plotJobs.filter(r => r.id === x).sort((a, b) => b.priorityNumber - a.priorityNumber)}
          plot={props.plot}
        />)}
      </>
    );
  }

  const getColorOveride = () => {
    if (manage) {
      return "apple-green-background";
    }

    return "clickable";
  }

  const renderManage = () => {

    if (manage) {
      return (
        <div className="mx-2 mb-2">
          <NaturalResourcesDisplay naturalResources={props.plot.naturalResources} />
          {renderImprovementsManagement()}
          {renderJobsManagement()}
          <NewJobButton plotId={props.plot.id}/>
        </div>
      )
    }
    return (<></>);
  }
  
  let usedSize = props.plotImprovements.reduce((a,b) => a + b.size, 0);

  return (
    <div key={props.plot.id}>
      <Row onClick={toggleManagePanel} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"} ${getColorOveride()}`}>
        <Col>
          <Row>
            <Col><Image className="thumbnail-image" src={`/images/terrain/${props.plot.landTypeString}.png`}/></Col>
            {!isMobile && <Col className="sub-entry-margin-class">{props.plot.address}</Col>}
            <Col className="sub-entry-margin-class">{usedSize > 0 ? `${usedSize} / ` : ""}{props.plot.size} up2</Col>
            <Col className="sub-entry-margin-class">{props.plotImprovements.length}</Col>
            <Col className="sub-entry-margin-class">{props.plotJobs.filter(x => x.active).length}</Col>
            {!isMobile && <Col className="sub-entry-margin-class">
              {renderExpandToggle()}
            </Col>}
          </Row>
          {isMobile && 
            <Row>
              <Col className="sub-entry-margin-class">{props.plot.address}</Col>
              <Col className="sub-entry-margin-class">{renderExpandToggle()}</Col>
            </Row>}
        </Col>
      </Row>
      {renderManage()}
    </div>
  );
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const stateProps: StateProps = {
    plotImprovements: state.UserKingdomState.userKingdom.improvements.filter((x: Improvement) => x.plotId === ownProps.plot.id),
    plotJobs: state.UserKingdomState.userKingdom.jobs.filter((x: Job) => x.plotId === ownProps.plot.id),
    workers: state.UserKingdomState.userKingdom.workers
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(LandsDisplay);