import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Footer } from '../../components/footerComponent/footer';
import Header from '../../components/headerComponent/header';
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react';
import { getNews } from '../../store/actions/newsActions';
import { NewsState } from '../../store/states/newsState';
import NewsEntry from '../../common/types/NewsEntry';
import { Pager } from '../../components/pagerComponent/pager';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { LoadingComponent } from '../../components/loadingComponent/loadingComponent';
import { useTranslation } from "react-i18next";

interface StateProps {
  newsState: NewsState,
};

interface DispatchProps {
  getNews: (page: number) => void;
};

export type NewsViewProps = DispatchProps & StateProps;

export const NewsView = (props: NewsViewProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.newsState.hasLoadedNews && !props.newsState.isLoading && !props.newsState.hasError) {
      props.getNews(props.newsState.page);
    }
  }, [props]);

  const renderNewsEntry = (entry: NewsEntry) => {
    return (
      <div key={entry.id}>
        <Row className="standard-text-box">
          <div className="tertiary-header">{new Date(entry.timestamp).toLocaleString('default', { dateStyle: 'long' })} - {entry.title}</div>
          <div className="pt-2 standard-text">
            <div dangerouslySetInnerHTML={{ __html: entry.newsString }}></div>
          </div>
        </Row>
        <br></br>
      </div>
    );
  }

  const renderPager = () => {
    return (
      <Col className="align-to-right">
        <Pager 
          page={props.newsState.page}
          pageSize={5}
          totalEntries={props.newsState.totalEntries}
          pageUp={() => props.getNews(props.newsState.page + 1)}
          pageDown={() => props.getNews(props.newsState.page - 1)}
          useLightBackgroud={false}
        />
      </Col>
    );
  }

  const renderNewsView = () => {
    return (
      <Container fluid="md">
        <Row>
          <div className="primary-header">{t("NewsPage.Title")}</div>
        </Row>
        <Row>
          <br></br>
          {props.newsState.isLoading && <LoadingComponent /> }
        </Row>
          {!props.newsState.isLoading && props.newsState.newsEntries && props.newsState.newsEntries.map((e) => renderNewsEntry(e))}
        <Row>
          {!props.newsState.isLoading && props.newsState.showPager && renderPager()}
        </Row>
      </Container>
    );
  }

  return (
    <>
      <div className="footer-padding">
        <Header />
        {renderNewsView()}
      </div>
      <Footer />
    </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    newsState: state.NewsState
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    getNews: (page: number) => dispatch(getNews(page)),
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsView);