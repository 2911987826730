import { Reducer } from 'redux';
import { NotificationsState } from '../states/notificationsState';
import Notification from '../../common/types/Notification';
import {
  NotificationsActionTypes,
  GetNotificationsActionType,
  GetNotificationsCompletedActionType,
  GetNotificationsFailedActionType,
  GetRedeemPackageActionType,
  GetRedeemPackageCompletedActionType,
  GetRedeemPackageFailedActionType,
  AcknowledgeNotificationActionType,
  AcknowledgeNotificationCompletedActionType,
  AcknowledgeNotificationFailedActionType,
  CloseAllNotificationsFailedActionType,
  CloseAllNotificationsActionType,
  CloseAllNotificationsCompletedActionType
} from '../actions/notificationsActions';
import { AuthenticationActionTypes, LogoutActionType } from '../actions/authenticationActions';

export type NotificationsActions = 
  GetNotificationsActionType
  | GetNotificationsCompletedActionType
  | GetNotificationsFailedActionType
  | GetRedeemPackageActionType
  | GetRedeemPackageCompletedActionType
  | GetRedeemPackageFailedActionType
  | AcknowledgeNotificationActionType
  | AcknowledgeNotificationCompletedActionType
  | AcknowledgeNotificationFailedActionType
  | CloseAllNotificationsActionType
  | CloseAllNotificationsCompletedActionType
  | CloseAllNotificationsFailedActionType
  | LogoutActionType;

const initialState: NotificationsState = {
  notifications: [],
  battles: [],
  reloadAt: new Date(Date.now()),
  isLoading: false,
  hasError: false,
  errorMessage: '',

  isRespondingToNotification: false,
  hasRespondingToNotificationError: false,
  respondingToNotificaitonError: '',
  respondingNotificationIdError: '',
  isClearingNotifications: false,
}

export const NotificationsReducer: Reducer<NotificationsState> = (state = initialState, action: NotificationsActions): NotificationsState => {
  switch(action.type) {
    case NotificationsActionTypes.GetNotifications:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        notifications: [],
        battles: [],
        isRespondingToNotification: false,
        hasRespondingToNotificationError: false,
        respondingToNotificaitonError: '',
      };
    case NotificationsActionTypes.GetNotificationsCompleted:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        errorMessage: '',

        notifications: action.payload!.response.notifications,
        battles: action.payload!.response.battles,
        reloadAt: action.payload!.response.checkAgainAt
      };
    case NotificationsActionTypes.GetNotificationsFailed:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any,
        notifications: [],
        battles: [],
        reloadAt: new Date(Date.now())
      };
    case NotificationsActionTypes.GetRedeemPackage:
      return {
        ...state,
        isRespondingToNotification: true,
        hasRespondingToNotificationError: false,
        respondingToNotificaitonError: '',
        respondingNotificationIdError: '',
      };
    case NotificationsActionTypes.GetRedeemPackageCompleted:
      return {
        ...state,
        isRespondingToNotification: false,
        notifications: state.notifications.filter(x => x.id !== action.payload!.notificationId),
      };
    case NotificationsActionTypes.GetRedeemPackageFailed:
      return {
        ...state,
        isRespondingToNotification: false,
        hasRespondingToNotificationError: true,
        respondingToNotificaitonError: action.payload!.error as any,
        respondingNotificationIdError: action.payload!.notificationId
      };
    case NotificationsActionTypes.AcknowledgeNotificationCompleted:
      return {
        ...state,
        notifications: state.notifications.filter(x => x.id !== action.payload!.notificationId)
      };
    case NotificationsActionTypes.CloseAllNotifications:
      return {
        ...state,
        isClearingNotifications: true,
        hasRespondingToNotificationError: false,
        respondingToNotificaitonError: '',
        notifications: []
      };
    case NotificationsActionTypes.CloseAllNotificationsCompleted:
      return {
        ...state,
        isClearingNotifications: false,
        notifications: action.payload!.response.notifications,
        battles: action.payload!.response.battles,
        reloadAt: action.payload!.response.checkAgainAt,
      }
    case NotificationsActionTypes.CloseAllNotificationsFailed:
      return {
        ...state,
        isClearingNotifications: false,
        hasRespondingToNotificationError: true,
        respondingToNotificaitonError: action.payload!.error as any,
        reloadAt: new Date(Date.now())
      }
    case AuthenticationActionTypes.Logout:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default NotificationsReducer;