import { Spinner, Row } from 'react-bootstrap';
import "../../styles/style.scss"

export const LoadingComponent = () => {
  return (
    <>
    <Row className="justify-content-center pt-5">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Row>
    <br></br>
    </>
  );
}