import { Row, Col, Image, Form, Spinner } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import Worker from '../../common/types/userKingdom/worker';
import GroupedWorkers from '../../common/types/userKingdom/groupedWorkers';
import PostManageJobWorkersRequest from '../../common/types/messages/PostManageJobWorkersRequest';
import PostManageImprovementWorkersRequest from '../../common/types/messages/PostManageImprovementWorkersRequest';
import { manageJobWorkers, manageImprovementWorkers } from '../../store/actions/userKingdomActions';

interface StateProps {
  userKingdomWorkers: Worker[];
  groupedWorkers: GroupedWorkers[];
  kingdomId: number;
  currentTick: number;
  isManagingWorkers: boolean;
};

interface DispatchProps {
  manageJobWorkers: (request: PostManageJobWorkersRequest) => void;
  manageImprovementWorkers: (request: PostManageImprovementWorkersRequest) => void;
}

interface OwnProps {
  workers: Worker[],
  maxWorkers?: number,
  allowedWorkerTypes: string[],
  managingImprovementWorkers: boolean,
  idManaging: string;
}

export type ExpandedWorkersEntryProps = DispatchProps & StateProps & OwnProps;

export const ExpandedWorkersEntry = (props: ExpandedWorkersEntryProps) => {
  const getInitialWorkerType = () => {
    return props.allowedWorkerTypes && props.allowedWorkerTypes.length > 0 ? props.allowedWorkerTypes[0] : "";
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [addWorkerNumber, setAddWorkerNumber] = useState(0);
  const [addWorkerType, setAddWorkerType] = useState(getInitialWorkerType())
  const { t } = useTranslation();


  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const handleSaveButtonClick = () => {
    let workerNumber = addWorkerNumber;

    if (!workerNumber || !addWorkerType) {
      return;
    }

    if (addWorkerNumber > 0) {
      if (props.groupedWorkers.filter(x => x.typeString === addWorkerType).length > 0) {
        if (props.groupedWorkers.filter(x => x.typeString === addWorkerType)[0].numberUnemployed < workerNumber) {
          workerNumber = props.groupedWorkers.filter(x => x.typeString === addWorkerType)[0].numberUnemployed;
        }
      } else {
        workerNumber = 0;
      }

      if (props.maxWorkers && props.maxWorkers - props.workers.reduce((a, b) => a = a + b.number, 0) < workerNumber) {
        workerNumber = props.maxWorkers - props.workers.reduce((a, b) => a = a + b.number, 0);
      }
    } else {
      if (props.workers.filter(x => x.typeString === addWorkerType).length > 0) {
        if (props.workers.filter(x => x.typeString === addWorkerType)[0].number < workerNumber) {
          workerNumber = props.workers.filter(x => x.typeString === addWorkerType)[0].number;
        }
      } else {
        workerNumber = 0;
      }
    }

    if (workerNumber !== 0) {

      if (addWorkerNumber < 0) {
        workerNumber = workerNumber * -1;
      }
      
      if (props.managingImprovementWorkers) {
        handleImprovementSaveButtonClick(workerNumber, addWorkerNumber > 0);
      } else {
        handleJobSaveButtonClick(workerNumber, addWorkerNumber > 0);
      }
    }
  }

  const handleImprovementSaveButtonClick = (amount: number, addWorker: boolean) => {
    props.manageImprovementWorkers({
      kingdomId: props.kingdomId,
      currentTick: props.currentTick,
      improvementId: props.idManaging,
      workerType: props.groupedWorkers.filter(x => x.typeString === addWorkerType)[0].type,
      amount: amount,
      add: addWorker
    } as PostManageImprovementWorkersRequest)
  }

  const handleJobSaveButtonClick = (amount: number, addWorker: boolean) => {
    props.manageJobWorkers({
      kingdomId: props.kingdomId,
      currentTick: props.currentTick,
      jobId: props.idManaging,
      workerType: props.groupedWorkers.filter(x => x.typeString === addWorkerType)[0].type,
      amount: amount,
      add: addWorker
    } as PostManageJobWorkersRequest)
  }

  const handleUpdateAddRemoveNumber = (min: number, max: number, newValue?: number) => {
    if (newValue) {
      if (newValue > max) {
        newValue = max
      }

      if (newValue < min) {
        newValue = min
      }
      setAddWorkerNumber(newValue);
    } else {
      setAddWorkerNumber(0);
    }
  }

  const HandleAddWorkerDropDownChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    setAddWorkerType(changeEvent.target.value)
  }

  const GetDropDownOptions = () => {
    if (!!props.allowedWorkerTypes) {
      return (
        <>
          {props.allowedWorkerTypes.map((x) => {
            return <option key={x} value={x}>{t(`WorkerTypes.${x}`, { count: 2 })}</option>
          })}
        </>
      );
    }
  }

  const GetMaxWorkersByWorkerType = () => {
    let numberUnemployed = props.groupedWorkers.filter(x => x.typeString === addWorkerType)[0] ? 
      props.groupedWorkers.filter(x => x.typeString === addWorkerType)[0].numberUnemployed
      : 0;

    if (props.maxWorkers) {
      let newMax = props.maxWorkers - props.workers.reduce((a, b) => a = a + b.number, 0);

      if (newMax > numberUnemployed) {
        return numberUnemployed;
      } else {
        return newMax;
      }

    } else {
      return numberUnemployed;
    }
  }

  return (
    <>
      <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
        <Col>{t("CommonWords.Workers", { count: props.workers.reduce((a, b) => a = a + b.number, 0)})} {props.workers.reduce((a, b) => a = a + b.number, 0) }{props.maxWorkers && ` / ${props.maxWorkers}`}</Col>
      </Row>
      {props.workers.map((x) => 
        <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/workers/${x.typeString}.png`}/></Col>
          <Col className="sub-entry-margin-class">{t(`WorkerTypes.${x.typeString}`, { count: x.number })}</Col>
          <Col className="sub-entry-margin-class">{ x.number }</Col>
        </Row>)}
      <Row className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text-sm": "standard-text"}`}>
        <Col className="sub-entry-margin-class">
          <Form.Select className="drop-down-style wheat_dark" value={addWorkerType} onChange={HandleAddWorkerDropDownChange} onSelect={HandleAddWorkerDropDownChange}>
            {GetDropDownOptions()}
          </Form.Select>
        </Col>
        {!isMobile && 
        <Col>
          <Row>
            <Form.Label>
              {addWorkerNumber}
            </Form.Label>
            <Form.Range 
              value={addWorkerNumber} 
              onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateAddRemoveNumber(
                  props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0,
                  GetMaxWorkersByWorkerType(),
                  parseInt(changeEvent.currentTarget.value))}}
              min={props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0}
              max={GetMaxWorkersByWorkerType()}
              />
            </Row>
            <Row>
              <Col className="sub-entry-margin-class morris-lg m-2">
                <div className="errorRed-button" onClick={() => 
                  handleUpdateAddRemoveNumber( 
                    props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0,
                    GetMaxWorkersByWorkerType(),
                    addWorkerNumber - 1)}>
                  -
                </div>
              </Col>
              <Col className="sub-entry-margin-class morris-lg m-2">
                <div className="appleGreen-button" onClick={() => 
                  handleUpdateAddRemoveNumber(
                    props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0,
                    GetMaxWorkersByWorkerType(),
                    addWorkerNumber + 1)}>
                  +
                </div>
              </Col>
            </Row>
        </Col>}
        <Col className="sub-entry-margin-class morris-lg">
          {props.isManagingWorkers && 
            <div className="darkStone-button">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            }
          {!props.isManagingWorkers && 
          <div className="darkStone-button" onClick={() => handleSaveButtonClick()}>
            {t("Buttons.Save")}
          </div>
          }
        </Col>
      </Row> 
      {isMobile &&
      <>
        <Row className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text-sm": "standard-text"}`} >
          <Col>
            <Form.Label>
              {addWorkerNumber}
            </Form.Label>
            <Form.Range 
              value={addWorkerNumber} 
              onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateAddRemoveNumber(
                  props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0,
                  GetMaxWorkersByWorkerType(),
                  parseInt(changeEvent.currentTarget.value))}}
              min={props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0}
              max={GetMaxWorkersByWorkerType()}
              />
          </Col>
        </Row>
        <Row className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="errorRed-button" onClick={() => 
              handleUpdateAddRemoveNumber( 
                props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0,
                GetMaxWorkersByWorkerType(),
                addWorkerNumber - 1)}>
              -
            </div>
          </Col>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="appleGreen-button" onClick={() => 
              handleUpdateAddRemoveNumber(
                props.workers.filter(x => x.typeString === addWorkerType)[0] ? props.workers.filter(x => x.typeString === addWorkerType)[0].number * -1 : 0,
                GetMaxWorkersByWorkerType(),
                addWorkerNumber + 1)}>
              +
            </div>
          </Col>
        </Row>
      </>}
    </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    groupedWorkers: state.UserKingdomState.userKingdom.groupedWorkers ? state.UserKingdomState.userKingdom.groupedWorkers.filter((x: any) => x.class !== "Migrated") : [],
    userKingdomWorkers: state.UserKingdomState.userKingdom.workers,
    kingdomId: state.UserKingdomState.userKingdom.kingdomId,
    currentTick: state.UserKingdomState.userKingdom.currentTick,
    isManagingWorkers: state.UserKingdomState.isManagingWorkers
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    manageJobWorkers: (request: PostManageJobWorkersRequest) => dispatch(manageJobWorkers(request)),
    manageImprovementWorkers: (request: PostManageImprovementWorkersRequest) => dispatch(manageImprovementWorkers(request))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandedWorkersEntry);