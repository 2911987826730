import { Row, Col, Image } from 'react-bootstrap';
import "../../styles/style.scss"
import NaturalResource from '../../common/types/userKingdom/naturalResource'
import { formatNumberMaxTwoDecimal } from '../../common/helperFunctions/formattingFunctions';
import { useTranslation } from "react-i18next";

export interface OwnProps {
  naturalResources: NaturalResource[],
}

export const NaturalResourcesDisplay = (props: OwnProps) => {
  const { t } = useTranslation();

  if (props.naturalResources.filter(x => x.amount > 0).length === 0) {
    return <></>;
  }

  const createResourceRow = (resource: NaturalResource) => {
    let maxAmountString = formatNumberMaxTwoDecimal(resource.amount)

    if (resource.maxAmount > 0) {
      maxAmountString += " / ";
      maxAmountString += formatNumberMaxTwoDecimal(resource.maxAmount)
    }

    return (
      <Row key={resource.type} className="resource-table-sub-entry standard-text-md">
        <Col><Image className="thumbnail-image" src={`/images/naturalResources/${resource.typeString}.png`}/></Col>
        <Col className="sub-entry-margin-class">{t(`NaturalResourceTypes.${resource.typeString}`, { count: resource.amount })}</Col>
        <Col className="sub-entry-margin-class">{maxAmountString}</Col>
    </Row>)
  }

  return (
    <>
      <Row className="resource-table-sub-entry dark-wheat-background standard-text-md">
        <Col>{t("UserKingdomPage.LandsTab.NaturalResources")}</Col>
      </Row>
      {props.naturalResources.filter(x => x.amount > 0).map((x: NaturalResource) => createResourceRow(x))}
    </>
  );
}