import { Row, Col } from 'react-bootstrap';
import "../../styles/style.scss"

export interface OwnProps {
  tabs: string[],
  activeTab: string,
  clickEvent: (input: string) => void,
}

export const TabSelector = (props: OwnProps) => {

  const renderTab = (tabName: string) => {
    if (props.activeTab === tabName) {
      return (
        <Col key={tabName} className="tabSelector active"
          onClick={() => props.clickEvent(tabName)}>
          {tabName}
        </Col>
      );
    } else {
      return (
        <Col key={tabName} className="tabSelector"
          onClick={() => props.clickEvent(tabName)}>
          {tabName}
        </Col>
      );
    }
  }

  return (
    <div>
      <Row className="pt-1">
        {props.tabs.map((x) => renderTab(x))}
      </Row>
    </div>
  );
}