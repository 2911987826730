import GetOwnSalesResponse from '../../common/types/messages/GetOwnSalesResponse';
import PostMarketSearchRequest from '../../common/types/messages/PostMarketSearchRequest';
import PostMarketSellRequest from '../../common/types/messages/PostMarketSellRequest';
import PostSearchResourceMarketResponse from '../../common/types/messages/PostSearchResourceMarketResponse';
import Action from './baseAction';

export enum MarketActionTypes {
  PostMarketSellRequest = "POST_MARKET_SELL_REQUEST",
  PostMarketSellRequestCompleted = "POST_MARKET_SELL_REQUEST_COMPLETED",
  PostMarketSellRequestFailed = "POST_MARKET_SELL_REQUEST_FAILED",
  PostMarketCancelRequest = "POST_MARKET_CANCEL_REQUEST",
  PostMarketCancelRequestCompleted = "POST_MARKET_CANCEL_REQUEST_COMPLETED",
  PostMarketCancelRequestFailed = "POST_MARKET_CANCEL_REQUEST_FAILED",
  PostMarketBuyRequest= "POST_MARKET_BUY_REQUEST",
  PostMarketBuyRequestCompleted = "POST_MARKET_BUY_REQUEST_COMPLETED",
  PostMarketBuyRequestFailed = "POST_MARKET_BUY_REQUEST_FAILED",
  GetOwnMarketSales= "GET_OWN_MARKET_SALES",
  GetOwnMarketSalesCompleted = "GET_OWN_MARKET_SALES_COMPLETED",
  GetOwnMarketSalesFailed = "GET_OWN_MARKET_SALES_FAILED",
  PostMarketSearchRequest = "POST_MARKET_SEARCH_REQUEST",
  PostMarketSearchRequestCompleted = "POST_MARKET_SEARCH_REQUEST_COMPLETED",
  PostMarketSearchRequestFailed = "POST_MARKET_SEARCH_REQUEST_FAILED",
  UpdateMarketSearchForm = "UPDATE_MARKET_SEARCH_FORM",
  CloseSaleUPXModal = "CLOSE_SALE_UPX_MODAL",
  UpdateMarketSellForm = "UPDATE_MARKET_SELL_FORM"
}

export type PostMarketSellRequestPayloadType = { request: PostMarketSellRequest };
export type PostMarketSellRequestActionType = Action<MarketActionTypes.PostMarketSellRequest, PostMarketSellRequestPayloadType>;
export const postMarketSellRequest = (request: PostMarketSellRequest):PostMarketSellRequestActionType => ({type: MarketActionTypes.PostMarketSellRequest, payload: { request } });

export type PostMarketSellRequestCompletedPayloadType = { response: GetOwnSalesResponse };
export type PostMarketSellRequestCompletedActionType = Action<MarketActionTypes.PostMarketSellRequestCompleted, PostMarketSellRequestCompletedPayloadType>;
export const postMarketSellRequestCompleted = (response: GetOwnSalesResponse): PostMarketSellRequestCompletedActionType => ({type: MarketActionTypes.PostMarketSellRequestCompleted, payload: { response } });

export type PostMarketSellRequestFailedPayloadType = { error: Error; };
export type PostMarketSellRequestFailedActionType = Action<MarketActionTypes.PostMarketSellRequestFailed, PostMarketSellRequestFailedPayloadType>;
export const postMarketSellRequestFailed = (error: Error): PostMarketSellRequestFailedActionType => ({type: MarketActionTypes.PostMarketSellRequestFailed, payload: { error } });

export type PostMarketCancelRequestPayloadType = { saleId: string };
export type PostMarketCancelRequestActionType = Action<MarketActionTypes.PostMarketCancelRequest, PostMarketCancelRequestPayloadType>;
export const postMarketCancelRequest = (saleId: string):PostMarketCancelRequestActionType => ({type: MarketActionTypes.PostMarketCancelRequest, payload: { saleId } });

export type PostMarketCancelRequestCompletedPayloadType = { saleId: string  };
export type PostMarketCancelRequestCompletedActionType = Action<MarketActionTypes.PostMarketCancelRequestCompleted, PostMarketCancelRequestCompletedPayloadType>;
export const postMarketCancelRequestCompleted = (saleId: string ): PostMarketCancelRequestCompletedActionType => ({type: MarketActionTypes.PostMarketCancelRequestCompleted, payload: { saleId } });

export type PostMarketCancelRequestFailedPayloadType = { error: Error; };
export type PostMarketCancelRequestFailedActionType = Action<MarketActionTypes.PostMarketCancelRequestFailed, PostMarketCancelRequestFailedPayloadType>;
export const postMarketCancelRequestFailed = (error: Error): PostMarketCancelRequestFailedActionType => ({type: MarketActionTypes.PostMarketCancelRequestFailed, payload: { error } });

export type PostMarketBuyRequestPayloadType = { saleId: string };
export type PostMarketBuyRequestActionType = Action<MarketActionTypes.PostMarketBuyRequest, PostMarketBuyRequestPayloadType>;
export const postMarketBuyRequest = (saleId: string):PostMarketBuyRequestActionType => ({type: MarketActionTypes.PostMarketBuyRequest, payload: { saleId } });

export type PostMarketBuyRequestCompletedPayloadType = { saleId: string  };
export type PostMarketBuyRequestCompletedActionType = Action<MarketActionTypes.PostMarketBuyRequestCompleted, PostMarketBuyRequestCompletedPayloadType>;
export const postMarketBuyRequestCompleted = (saleId: string ): PostMarketBuyRequestCompletedActionType => ({type: MarketActionTypes.PostMarketBuyRequestCompleted, payload: { saleId } });

export type PostMarketBuyRequestFailedPayloadType = { error: Error; };
export type PostMarketBuyRequestFailedActionType = Action<MarketActionTypes.PostMarketBuyRequestFailed, PostMarketBuyRequestFailedPayloadType>;
export const postMarketBuyRequestFailed = (error: Error): PostMarketBuyRequestFailedActionType => ({type: MarketActionTypes.PostMarketBuyRequestFailed, payload: { error } });

export type GetOwnMarketSalesPayloadType = { kingdomId: number };
export type GetOwnMarketSalesActionType = Action<MarketActionTypes.GetOwnMarketSales, GetOwnMarketSalesPayloadType>;
export const getOwnMarketSales = (kingdomId: number):GetOwnMarketSalesActionType => ({type: MarketActionTypes.GetOwnMarketSales, payload: { kingdomId } });

export type GetOwnMarketSalesCompletedPayloadType = { response: GetOwnSalesResponse};
export type GetOwnMarketSalesCompletedActionType = Action<MarketActionTypes.GetOwnMarketSalesCompleted, GetOwnMarketSalesCompletedPayloadType>;
export const getOwnMarketSalesCompleted = ( response: GetOwnSalesResponse): GetOwnMarketSalesCompletedActionType => ({type: MarketActionTypes.GetOwnMarketSalesCompleted, payload: { response } });

export type GetOwnMarketSalesFailedPayloadType = { error: Error; };
export type GetOwnMarketSalesFailedActionType = Action<MarketActionTypes.GetOwnMarketSalesFailed, GetOwnMarketSalesFailedPayloadType>;
export const getOwnMarketSalesFailed = (error: Error): GetOwnMarketSalesFailedActionType => ({type: MarketActionTypes.GetOwnMarketSalesFailed, payload: { error } });

export type PostMarketSearchRequestPayloadType = { request: PostMarketSearchRequest };
export type PostMarketSearchRequestActionType = Action<MarketActionTypes.PostMarketSearchRequest, PostMarketSearchRequestPayloadType>;
export const postMarketSearchRequest = (request: PostMarketSearchRequest):PostMarketSearchRequestActionType => ({type: MarketActionTypes.PostMarketSearchRequest, payload: { request } });

export type PostMarketSearchRequestCompletedPayloadType = { response: PostSearchResourceMarketResponse};
export type PostMarketSearchRequestCompletedActionType = Action<MarketActionTypes.PostMarketSearchRequestCompleted, PostMarketSearchRequestCompletedPayloadType>;
export const postMarketSearchRequestCompleted = (response: PostSearchResourceMarketResponse): PostMarketSearchRequestCompletedActionType => ({type: MarketActionTypes.PostMarketSearchRequestCompleted, payload: { response } });

export type PostMarketSearchRequestFailedPayloadType = { error: Error; };
export type PostMarketSearchRequestFailedActionType = Action<MarketActionTypes.PostMarketSearchRequestFailed, PostMarketSearchRequestFailedPayloadType>;
export const postMarketSearchRequestFailed = (error: Error): PostMarketSearchRequestFailedActionType => ({type: MarketActionTypes.PostMarketSearchRequestFailed, payload: { error } });

export type UpdateMarketSearchFormPayloadType = { request: PostMarketSearchRequest };
export type UpdateMarketSearchFormActionType = Action<MarketActionTypes.UpdateMarketSearchForm, UpdateMarketSearchFormPayloadType>;
export const updateMarketSearchForm = (request: PostMarketSearchRequest):UpdateMarketSearchFormActionType => ({type: MarketActionTypes.UpdateMarketSearchForm, payload: { request } });

export type CloseSaleUPXModalPayloadType = { };
export type CloseSaleUPXModaActionType = Action<MarketActionTypes.CloseSaleUPXModal, CloseSaleUPXModalPayloadType>;
export const closeSaleUPXModal = ():CloseSaleUPXModaActionType => ({type: MarketActionTypes.CloseSaleUPXModal, payload: { } });

export type UpdateMarketSellFormPayloadType = { request: PostMarketSellRequest };
export type UpdateMarketSellFormActionType = Action<MarketActionTypes.UpdateMarketSellForm, UpdateMarketSellFormPayloadType>;
export const updateMarketSellForm = (request: PostMarketSellRequest):UpdateMarketSellFormActionType => ({type: MarketActionTypes.UpdateMarketSellForm, payload: { request } });

