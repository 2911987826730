import { Reducer } from 'redux';
import { UserKingdomState } from '../states/userKingdomState';
import {
  UserKingdomActionTypes,
  GetUserKingdomActionType,
  GetUserKingdomCompletedActionType,
  GetUserKingdomFailedActionType,
  UpdateResourceAmountsActionType,
  DeleteJobActionType,
  DeleteJobCompletedActionType,
  DeleteJobFailedActionType,
  CityUserEntityExpiredActionType,
  ManageJobWorkersActionType,
  ManageJobWorkersCompletedActionType,
  ManageJobWorkersFailedActionType,
  ManageImprovementWorkersActionType,
  ManageImprovementWorkersCompletedActionType,
  ManageImprovementWorkersFailedActionType,
  DemolishImprovementActionType,
  DemolishImprovementCompletedActionType,
  DemolishImprovementFailedActionType,
  ChangeProductionTypeActionType,
  ChangeProductionTypeCompletedActionType,
  ChangeProductionTypeFailedActionType,
  ChangeCropTypeActionType,
  ChangeCropTypeCompletedActionType,
  ChangeCropTypeFailedActionType,
  DebugProcessTickActionType,
  DebugProcessTickCompletedActionType,
  DebugProcessTickFailedActionType,
  RequestTrainingActionType,
  RequestTrainingCompletedActionType,
  RequestTrainingFailedActionType,
  CancelTrainingRequestActionType,
  CancelTrainingRequestCompletedActionType,
  CancelTrainingRequestFailedActionType,
  GetActiveKingdomsCompletedActionType,
  GetActiveKingdomsActionType,
  GetActiveKingdomsFailedActionType,
  UpdateLandsOrderByActionType,
  OpenDemolishImprovementConfirmModalActionType,
  CloseDemolishImprovementConfirmModalActionType,
  ToggleGrowthActionType,
  ToggleGrowthCompletedActionType,
  ToggleGrowthFailedActionType,
  RequestChangeProductionTypeValuesActionType,
  RequestChangeProductionTypeValuesCompletedActionType,
  RequestChangeProductionTypeValuesFailedActionType,
  RequestChangeCropTypeValuesActionType,
  RequestChangeCropTypeValuesCompletedActionType,
  RequestChangeCropTypeValuesFailedActionType,
  UpdateUserSettingsActionType,
  UpdateUserSettingsCompletedActionType,
  UpdateUserSettingsFailedActionType,
  ToggleUserSettingsModalActionType,
} from '../actions/userKingdomActions';
import {
  AuthenticationActionTypes, 
  LogoutActionType
} from '../actions/authenticationActions';
import {
  JobRequestActionTypes,
  RequestJobCompletedActionType
} from '../actions/jobRequestActions';
import { UpdateBattles } from './helpers/userKingdomReducerHelpers';
import { GetRedeemPackageCompletedActionType, NotificationsActionTypes, GetNotificationsCompletedActionType } from '../actions/notificationsActions';
import { CombatActionTypes, RequestJoinBattleCompletedActionType } from '../actions/combatActions';

export type UserKingdomActions = 
  GetUserKingdomActionType
  | GetUserKingdomCompletedActionType
  | GetUserKingdomFailedActionType
  | UpdateResourceAmountsActionType
  | DeleteJobActionType
  | DeleteJobCompletedActionType
  | DeleteJobFailedActionType
  | CityUserEntityExpiredActionType
  | ManageJobWorkersActionType
  | ManageJobWorkersCompletedActionType
  | ManageJobWorkersFailedActionType
  | ManageImprovementWorkersActionType
  | ManageImprovementWorkersCompletedActionType
  | ManageImprovementWorkersFailedActionType
  | DemolishImprovementActionType
  | DemolishImprovementCompletedActionType
  | DemolishImprovementFailedActionType
  | ChangeProductionTypeActionType
  | ChangeProductionTypeCompletedActionType
  | ChangeProductionTypeFailedActionType
  | RequestChangeProductionTypeValuesActionType
  | RequestChangeProductionTypeValuesCompletedActionType
  | RequestChangeProductionTypeValuesFailedActionType
  | RequestChangeCropTypeValuesActionType
  | RequestChangeCropTypeValuesCompletedActionType
  | RequestChangeCropTypeValuesFailedActionType
  | ChangeCropTypeActionType
  | ChangeCropTypeCompletedActionType
  | ChangeCropTypeFailedActionType
  | RequestJobCompletedActionType
  | RequestTrainingActionType
  | RequestTrainingCompletedActionType
  | RequestTrainingFailedActionType
  | CancelTrainingRequestActionType
  | CancelTrainingRequestCompletedActionType
  | CancelTrainingRequestFailedActionType
  | GetActiveKingdomsActionType
  | GetActiveKingdomsCompletedActionType
  | GetActiveKingdomsFailedActionType
  | UpdateLandsOrderByActionType
  | CloseDemolishImprovementConfirmModalActionType
  | OpenDemolishImprovementConfirmModalActionType
  | ToggleGrowthActionType
  | ToggleGrowthCompletedActionType
  | ToggleGrowthFailedActionType
  | LogoutActionType
  | UpdateUserSettingsActionType
  | UpdateUserSettingsCompletedActionType
  | UpdateUserSettingsFailedActionType
  | ToggleUserSettingsModalActionType

  | RequestJoinBattleCompletedActionType

  | GetRedeemPackageCompletedActionType
  | GetNotificationsCompletedActionType

  | DebugProcessTickActionType
  | DebugProcessTickCompletedActionType
  | DebugProcessTickFailedActionType;

const initialState: UserKingdomState = {
  userKingdom: undefined,
  isLoading: false,
  hasError: false,
  errorMessage: '',
  hasLoaded: false,
  isManagingWorkers: false,
  isDemolishingImprovement: false,
  isChangingProductionType: false,
  isRequestProductionChangeValues: false,
  isRequestCropChangeValues: false,
  showExpiredUserKingdomModal: false,
  showNewJobModal: false,
  newJobPlotId: 0,
  isRequestingJob: false,
  isUpdatingTraining: false,
  isTogglingGrowth: false,
  requestJobError: '',
  updatingTrainingError: '',
  debug_IsProcessingTick: false,
  isLoadingActiveKingdoms: false,
  activeKingdoms: [],
  orderBy: "",
  orderByDirection: 0,
  showDemolishImprovementConfirmModal: false,
  demolishImprovementId: "",
  isUpdatingUserSettings: false,
  updatingUserSettingsFailed: false,
  updatingUserSettingsError: "",
  showUserSettingsModal: false,
}

export const UserKingdomReducer: Reducer<UserKingdomState> = (state = initialState, action: UserKingdomActions): UserKingdomState => {
  switch(action.type) {
    case UserKingdomActionTypes.GetUserKingdom:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        hasLoaded: false,
        userKingdom: undefined,
        isManagingWorkers: false,
        isDemolishingImprovement: false,
        isChangingProductionType: false,
        showExpiredUserKingdomModal: false,
        showNewJobModal: false,
        newJobPlotId: 0,
        isRequestingJob: false,
        isUpdatingTraining: false,
        updatingTrainingError: '',
        requestJobError: '',
      };
    case UserKingdomActionTypes.GetUserKingdomCompleted:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: false,
        errorMessage: '',
        showExpiredUserKingdomModal: false,

        userKingdom: action.payload!.response.userKingdom
      };
    case UserKingdomActionTypes.GetUserKingdomFailed:
      return {
        ...state,
        isLoading: false,
        hasLoaded: false,
        hasError: true,
        errorMessage: action.payload!.error as any,

        userKingdom: undefined 
      };
    case UserKingdomActionTypes.UpdateResourceAmounts:
      if (state.userKingdom && action.payload)
      {
        let resourceIndex = state.userKingdom.resources.findIndex(x => x.type === action.payload!.resourceType);
        state.userKingdom.resources[resourceIndex].upkeepPriority = action.payload!.upkeepPriority;
        state.userKingdom.resources[resourceIndex].userSetMaximum = action.payload!.maxAmount;
        state.userKingdom.resources[resourceIndex].amount = action.payload!.amount;

        return {
          ...state,
          userKingdom: {
            ...state.userKingdom,
            resources: state.userKingdom.resources
          }
        }
      }

      return state;
    case UserKingdomActionTypes.DeleteJobCompleted:
      return {
        ...state,
        userKingdom: {
          ...state.userKingdom!,
          workers: action.payload!.response.userKingdom.workers,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          jobs: action.payload!.response.userKingdom.jobs,
          improvements: action.payload!.response.userKingdom.improvements,
        }
      }
    case UserKingdomActionTypes.CityUserEntityExpired:
      return {
        ...state,
        showExpiredUserKingdomModal: true,
        isManagingWorkers: false,
        showNewJobModal: false,
      }
    case UserKingdomActionTypes.ManageImprovementWorkers:
    case UserKingdomActionTypes.ManageJobWorkers:
      return {
        ...state,
        isManagingWorkers: true
      }
    case UserKingdomActionTypes.ManageJobWorkersCompleted:
      return {
        ...state,
        isManagingWorkers: false,
        userKingdom:{
          ...state.userKingdom!,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          jobs: action.payload!.response.userKingdom!.jobs
        }
      }
    case UserKingdomActionTypes.ManageImprovementWorkersCompleted:
      return {
        ...state,
        isManagingWorkers: false,
        userKingdom:{
          ...state.userKingdom!,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          improvements: action.payload!.response.userKingdom!.improvements
        }
      }
    case UserKingdomActionTypes.ManageImprovementWorkersFailed:
    case UserKingdomActionTypes.ManageJobWorkersFailed:
      return {
        ...state,
        isManagingWorkers: false
      }
    case UserKingdomActionTypes.DemolishImprovement:
      return {
        ...state,
        isDemolishingImprovement: true,
        showDemolishImprovementConfirmModal: false,
        demolishImprovementId: ""
      }
    case UserKingdomActionTypes.DemolishImprovementCompleted:
      return {
        ...state,
        isDemolishingImprovement: false,
        userKingdom:{
          ...state.userKingdom!,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          improvements: action.payload!.response.userKingdom!.improvements
        }
      }
    case UserKingdomActionTypes.DemolishImprovementFailed:
      return {
        ...state,
        isDemolishingImprovement: false
      }
    case UserKingdomActionTypes.RequestChangeProductionTypeValues:
      return {
        ...state,
        isRequestProductionChangeValues: true,
      }
    case UserKingdomActionTypes.RequestChangeProductionTypeValuesCompleted:
      let productionIndex = state.userKingdom!.improvements.findIndex(x => x.id === action.payload!.request.improvementId);
      let productionImprovements = [...state.userKingdom!.improvements];

      productionImprovements.splice(productionIndex, 1, action.payload!.response);

      return {
        ...state,
        isRequestProductionChangeValues: false,
        userKingdom: {
          ...state.userKingdom!,
          improvements: productionImprovements
        }
      }
    case UserKingdomActionTypes.RequestChangeProductionTypeValuesFailed:
      return {
        ...state,
        isRequestProductionChangeValues: false,
      }
    case UserKingdomActionTypes.RequestChangeCropTypeValues:
      return {
        ...state,
        isRequestCropChangeValues: true,
      }
    case UserKingdomActionTypes.RequestChangeCropTypeValuesCompleted:
      let farmIndex = state.userKingdom!.improvements.findIndex(x => x.id === action.payload!.request.improvementId);
      let farmImprovements = [...state.userKingdom!.improvements];

      farmImprovements.splice(farmIndex, 1, action.payload!.response);
      return {
        ...state,
        isRequestCropChangeValues: false,
        userKingdom: {
          ...state.userKingdom!,
          improvements: farmImprovements
        }
      }
    case UserKingdomActionTypes.RequestChangeCropTypeValuesFailed:
      return {
        ...state,
        isRequestCropChangeValues: false,
      }
    
    case UserKingdomActionTypes.ChangeCropType:
    case UserKingdomActionTypes.ChangeProductionType:
      return {
        ...state,
        isChangingProductionType: true
      }
    case UserKingdomActionTypes.ChangeCropTypeCompleted:
    case UserKingdomActionTypes.ChangeProductionTypeCompleted:
      return {
        ...state,
        userKingdom:{
          ...state.userKingdom!,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          improvements: action.payload!.response.userKingdom!.improvements
        }
      }
    case UserKingdomActionTypes.ChangeCropTypeFailed:
    case UserKingdomActionTypes.ChangeProductionTypeFailed:
      return {
        ...state,
        isChangingProductionType: false
      }
    case UserKingdomActionTypes.RequestTraining:
      return {
        ...state,
        isUpdatingTraining: true,
        updatingTrainingError: ''
      }
    case UserKingdomActionTypes.RequestTrainingCompleted:
      return {
        ...state,
        isUpdatingTraining: false,
        userKingdom: {
          ...state.userKingdom!,
          improvements: action.payload!.response.userKingdom.improvements,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          resources: action.payload!.response.userKingdom.resources
        }
      }
    case UserKingdomActionTypes.RequestTrainingFailed:
      return {
        ...state,
        isUpdatingTraining: false,
        updatingTrainingError: action.payload!.error as any
      }
      case UserKingdomActionTypes.CancelTrainingRequest:
      return {
        ...state,
        isUpdatingTraining: true,
        updatingTrainingError: ''
      }
      case UserKingdomActionTypes.CancelTrainingRequestCompleted:
      return {
        ...state,
        isUpdatingTraining: false,
        userKingdom: {
          ...state.userKingdom!,
          improvements: action.payload!.response.userKingdom.improvements,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          
        }
      }
      case UserKingdomActionTypes.CancelTrainingRequestFailed:
      return {
        ...state,
        isUpdatingTraining: false,
        updatingTrainingError: action.payload!.error as any
      }

    case JobRequestActionTypes.RequestJobCompleted:
      return {
        ...state,
        userKingdom: {
          ...state.userKingdom!,
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          improvements: action.payload!.response.userKingdom!.improvements,
          jobs: action.payload!.response.userKingdom!.jobs,
          resources: action.payload!.response.userKingdom.resources
        },
      }
    case UserKingdomActionTypes.GetActiveKingdoms:
      return {
        ...state,
        activeKingdoms: [],
        isLoadingActiveKingdoms: true,
      }
    case UserKingdomActionTypes.GetActiveKingdomsCompleted:
      return {
        ...state,
        activeKingdoms: action.payload!.response.activeKingdoms,
        isLoadingActiveKingdoms: false,
      }
    case UserKingdomActionTypes.GetActiveKingdomsFailed:
      return {
        ...state,
        activeKingdoms: [],
        isLoadingActiveKingdoms: false,
      }
    case UserKingdomActionTypes.UpdateLandsOrderBy:
      let orderBy = "";
      let orderByDirection = 0;
      
      if (action.payload!.orderBy === "") {
        orderBy = "";
        orderByDirection = 0;
      } else {
        if (state.orderBy === action.payload!.orderBy) {
          orderBy = state.orderBy;
          orderByDirection = (state.orderByDirection + 1) % 3;
          if (orderByDirection === 0) {
            orderByDirection = 1;
          }
        } else {
          orderBy = action.payload!.orderBy;
          orderByDirection = 1;
        }
      }

      return {
        ...state,
        orderBy: orderBy,
        orderByDirection: orderByDirection,
      }
    case UserKingdomActionTypes.OpenDemolishImprovementConfirmModal:
      return {
        ...state,
        showDemolishImprovementConfirmModal: true,
        demolishImprovementId: action.payload!.improvementId
      }
    case UserKingdomActionTypes.CloseDemolishImprovementConfirmModal:
      return {
        ...state,
        showDemolishImprovementConfirmModal: false,
        demolishImprovementId: ""
      }
    case UserKingdomActionTypes.ToggleGrowth:
      return {
        ...state,
        isTogglingGrowth: true,
      }
    case UserKingdomActionTypes.ToggleGrowthCompleted:
      return {
        ...state,
        isTogglingGrowth: false,
        userKingdom: {
          ...state.userKingdom!,
          allowGrowth: !state.userKingdom!.allowGrowth
        }
      }
    case UserKingdomActionTypes.ToggleGrowthFailed:
      return {
        ...state,
        isTogglingGrowth: false,
      }
    case UserKingdomActionTypes.UpdateUserSettings:
      return {
        ...state,
        isUpdatingUserSettings: true,
        updatingUserSettingsFailed: false,
        updatingUserSettingsError: '',
        userKingdom: {
          ...state.userKingdom!,
          defaultKingdom: action.payload!.request.defaultKingdom,
          useMaleTitles: action.payload!.request.useMaleTitles,
          language: action.payload!.request.language
        }
      }
    case UserKingdomActionTypes.UpdateUserSettingsCompleted:
      return {
        ...state,
        isUpdatingUserSettings: false,
        updatingUserSettingsFailed: false,
        updatingUserSettingsError: '',
      }
    case UserKingdomActionTypes.UpdateUserSettingsFailed:
      return {
        ...state,
        isUpdatingUserSettings: false,
        updatingUserSettingsFailed: true,
        updatingUserSettingsError: action.payload!.error as any,
      }
    case UserKingdomActionTypes.ToggleUserSettingsModal:
      return {
        ...state,
        showUserSettingsModal: !state.showUserSettingsModal
      }

    case UserKingdomActionTypes.DebugProcessTick:
      return {
        ...state,
        debug_IsProcessingTick: true
      }
    case UserKingdomActionTypes.DebugProcessTickCompleted:
      return {
        ...state,
        debug_IsProcessingTick: false,
        userKingdom: {
          ...state.userKingdom!,
          kingdomSize: action.payload!.response.userKingdom!.kingdomSize,
          kingdomPopulation: action.payload!.response.userKingdom!.kingdomPopulation,
          gameDate: action.payload!.response.userKingdom!.gameDate,
          currentTick: action.payload!.response.userKingdom!.currentTick,
          plots: action.payload!.response.userKingdom!.plots,
          improvements: action.payload!.response.userKingdom!.improvements,
          resources: action.payload!.response.userKingdom!.resources,
          jobs: action.payload!.response.userKingdom!.jobs,
          workers: action.payload!.response.userKingdom!.workers,
          groupedWorkers: action.payload!.response.userKingdom!.groupedWorkers,
          upkeepForNextTurn: action.payload!.response.userKingdom!.upkeepForNextTurn,
          resourceStorageLimits: action.payload!.response.userKingdom!.resourceStorageLimits
        }
      }
    case AuthenticationActionTypes.Logout:
      return {
        ...initialState
      };

    case NotificationsActionTypes.GetRedeemPackageCompleted:
      return {
        ...state,
        userKingdom: action.payload!.response.userKingdom
      }

    case CombatActionTypes.RequestJoinBattleCompleted:
      if (!state.userKingdom) {
        return state;
      }

      return {
        ...state,
        userKingdom: {
          ...state.userKingdom!,
          battles: UpdateBattles(state.userKingdom!.battles, action.payload!.response.notificationsResponse.battles),
          groupedWorkers: action.payload!.response.userKingdom.groupedWorkers,
          workers: action.payload!.response.userKingdom.workers,
          vassals: action.payload!.response.userKingdom!.vassals,
        }
      }
    case NotificationsActionTypes.GetNotificationsCompleted:
      if (!state.userKingdom) {
        return state;
      }

      return {
        ...state,
        userKingdom: {
          ...state.userKingdom!,
          battles: UpdateBattles(state.userKingdom!.battles, action.payload!.response.battles)
        }
      }
    
    case UserKingdomActionTypes.DebugProcessTickFailed:
      return {
        ...state,
        debug_IsProcessingTick: false
      }
    default:
      return state;
  }
}

export default UserKingdomReducer;