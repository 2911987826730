import Action from './baseAction';
import GetUserKingdomResponse from '../../common/types/messages/GetUserKingdomResponse';
import PostRequestJobRequest from '../../common/types/messages/PostRequestJobRequest';
import NaturalResource from '../../common/types/userKingdom/naturalResource';
import ImprovementConstructionRules from '../../common/types/rules/ImprovementConstructionRules';

export enum JobRequestActionTypes {
  OpenRequestJobModal = "OPEN_REQUEST_JOB_MODAL",
  CloseRequestJobModal = "CLOSE_REQUEST_JOB_MODAL",
  UpdateRequestJobForm = "UPDATE_REQUEST_JOB_FORM",
  RequestJob = "REQUEST_JOB",
  RequestJobCompleted = "REQUEST_JOB_COMPLETED",
  RequestJobFailed = "REQUEST_JOB_FAILED",
}

export type OpenRequestJobModalPayloadType = { 
  plotId: number,
  kingdomId: number,
  currentTick: number,
  naturalResources: NaturalResource[],
  allowedBuildings: ImprovementConstructionRules[]
};
export type OpenRequestJobModalActionType = Action<JobRequestActionTypes.OpenRequestJobModal, OpenRequestJobModalPayloadType>;
export const openRequestJobModal = (
  plotId: number,
  kingdomId: number,
  currentTick: number,
  naturalResources: NaturalResource[],
  allowedBuildings: ImprovementConstructionRules[] 
  ): OpenRequestJobModalActionType => ({type: JobRequestActionTypes.OpenRequestJobModal, payload: { 
    plotId: plotId, 
    kingdomId: kingdomId, 
    currentTick: currentTick,
    naturalResources: naturalResources,
    allowedBuildings: allowedBuildings
   } });

export type CloseRequestJobModalPayloadType = { };
export type CloseRequestJobModalActionType = Action<JobRequestActionTypes.CloseRequestJobModal, CloseRequestJobModalPayloadType>;
export const closeRequestJobModal = (): CloseRequestJobModalActionType => ({type: JobRequestActionTypes.CloseRequestJobModal, payload: { } });

export type UpdateRequestJobFormPayloadType = { request: PostRequestJobRequest };
export type UpdateRequestJobFormActionType = Action<JobRequestActionTypes.UpdateRequestJobForm, UpdateRequestJobFormPayloadType>;
export const updateRequestJobForm = (request: PostRequestJobRequest):UpdateRequestJobFormActionType => ({type: JobRequestActionTypes.UpdateRequestJobForm, payload: { request } });

export type RequestJobPayloadType = { request: PostRequestJobRequest };
export type RequestJobActionType = Action<JobRequestActionTypes.RequestJob, RequestJobPayloadType>;
export const requestJob = (request: PostRequestJobRequest):RequestJobActionType => ({type: JobRequestActionTypes.RequestJob, payload: { request } });

export type RequestJobCompletedPayloadType = { request: PostRequestJobRequest, response: GetUserKingdomResponse };
export type RequestJobCompletedActionType = Action<JobRequestActionTypes.RequestJobCompleted, RequestJobCompletedPayloadType>;
export const requestJobCompleted = (request: PostRequestJobRequest, response: GetUserKingdomResponse): RequestJobCompletedActionType => ({type: JobRequestActionTypes.RequestJobCompleted, payload: { request, response } });

export type RequestJobFailedPayloadType = { error: Error; };
export type RequestJobFailedActionType = Action<JobRequestActionTypes.RequestJobFailed, RequestJobFailedPayloadType>;
export const requestJobFailed = (error: Error): RequestJobFailedActionType => ({type: JobRequestActionTypes.RequestJobFailed, payload: { error } });
