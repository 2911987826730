import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { 
  PostPurchaseActionType, 
  postPurchaseCompleted, 
  PostPurchaseCompletedActionType, 
  postPurchaseFailed, 
  PostPurchaseFailedActionType, 
  PurchaseActionTypes } from '../actions/purchaseActions';

export const postPurchaseRequestEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(PurchaseActionTypes.PostPurchase),
    mergeMap((action) => onPostPurchase(action, dependencies))
  );
}

export const onPostPurchase: (action: PostPurchaseActionType, dependencies: AppDependencies) 
  => Observable<PostPurchaseCompletedActionType | PostPurchaseFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postPurchase$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return postPurchaseCompleted();
    }),
    catchError(error => {
      return of(postPurchaseFailed(error.response.value));
    })
  );
}

