import { Reducer } from 'redux';
import { JobRequestActionTypes, CloseRequestJobModalActionType, OpenRequestJobModalActionType, RequestJobActionType, RequestJobCompletedActionType, RequestJobFailedActionType, UpdateRequestJobFormActionType } from '../actions/jobRequestActions';
import { CityUserEntityExpiredActionType, UserKingdomActionTypes } from '../actions/userKingdomActions';
import { JobRequestState } from '../states/jobRequestState';

export type JobRequestActions = 
  OpenRequestJobModalActionType
  | CloseRequestJobModalActionType
  | UpdateRequestJobFormActionType
  | RequestJobActionType
  | RequestJobCompletedActionType
  | RequestJobFailedActionType
  | CityUserEntityExpiredActionType;

const initialState: JobRequestState = {
  request: {
    kingdomId: 0,
    currentTick: 0,
  
    jobType: 0,
    plotId: 0,
  
    size: 0,
    improvementType: 0,

    improvementId: '',
  
    resourceToHarvest: 0,
    amount: 0,
    
    workers: []
  },
  isModalOpen: false,
  isRequestingJob: false,
  requestJobError: '',

  availableResources: [],
  allowedBuildings: []
}

export const JobRequestReducer: Reducer<JobRequestState> = (state = initialState, action: JobRequestActions): JobRequestState => {
  switch(action.type) {
    case JobRequestActionTypes.OpenRequestJobModal:
      let allowedResources = action.payload!.naturalResources ? action.payload!.naturalResources.filter(x => x.amount > 0) : [];
      let allowedBuildings = action.payload!.allowedBuildings ? action.payload!.allowedBuildings : [];

      return {
        ...initialState,
        request: {
          ...initialState.request,
          kingdomId: action.payload!.kingdomId,
          currentTick: action.payload!.currentTick,
        
          jobType: 0,
          plotId: action.payload!.plotId,
        
          size: 0,
          improvementType: allowedBuildings.length > 0 ? action.payload!.allowedBuildings[0].type : 0,
        
          resourceToHarvest: allowedResources.length > 0 ? allowedResources[0].type : 0,
          amount: 0,
          
          workers: []
        },

        isModalOpen: true,
        isRequestingJob: false,
        requestJobError: '',

        availableResources: allowedResources,
        allowedBuildings: allowedBuildings
      }
    case JobRequestActionTypes.UpdateRequestJobForm:
      return {
        ...state,
        request: action.payload!.request
      }
    case JobRequestActionTypes.RequestJob:
      return {
        ...state,
        isRequestingJob: true,
        requestJobError: '',
      }
    case UserKingdomActionTypes.CityUserEntityExpired:
    case JobRequestActionTypes.CloseRequestJobModal:
    case JobRequestActionTypes.RequestJobCompleted:
      return {
        ...state,
        isRequestingJob: false,
        isModalOpen: false
      }
    case JobRequestActionTypes.RequestJobFailed:
      return {
        ...state,
        isRequestingJob: false,
        requestJobError: action.payload!.error.toString()
      }
    default:
      return state;
  }
}

export default JobRequestReducer;