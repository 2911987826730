import { Container, Row, Col } from 'react-bootstrap'
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container fluid={true} className="footer">
      <Row>
        <Col className="align-to-left">
          <div className="footer-text">
            Grombrindal
          </div>
        </Col>
        <Col></Col>
        <Col className="align-to-right">
          <a href="https://play.upland.me/" target="_blank" rel="noreferrer" className="clear-link-text-decoration">
            <div className="footer-button">
              {t("Components.Footer.Upland")}
            </div>
          </a>
          <a href="https://discord.gg/hsrxQb7UQb" target="_blank" rel="noreferrer" className="clear-link-text-decoration">
            <div className="footer-button">
              {t("Components.Footer.Discord")}
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  );
}