import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, ModalTitle, ModalBody, Modal, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

interface StateProps {
  
};

interface DispatchProps {

}

interface OwnProps {
  denyAction: () => void;
  confirmAction: () => void;
  showModal: boolean;
}

export type ConfirmModalProps = OwnProps & DispatchProps & StateProps;

const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation();

  const renderModal = () => {
    return (
      <Modal show={props.showModal} onHide={() => props.denyAction()} animation={false} centered>
        <ModalTitle className="primary-header">{t("Components.ConfirmModal.AreYouSure")}</ModalTitle>
        <ModalBody className="standard-text">
          <Row className={`resource-table-sub-entry top-border`}>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => { props.confirmAction() }}>
                {t("Buttons.Ok")}
              </div>
            </Col>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.denyAction()}>
                {t("Buttons.Cancel")}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showModal && renderModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {

  }

  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);