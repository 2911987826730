import { Row, Col, Spinner } from 'react-bootstrap';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { openRequestJobModal } from '../../store/actions/jobRequestActions';
import NaturalResource from '../../common/types/userKingdom/naturalResource';
import Plot from '../../common/types/userKingdom/plot';
import ImprovementConstructionRules from '../../common/types/rules/ImprovementConstructionRules';
import Improvement from '../../common/types/userKingdom/improvement';

interface DispatchProps {
  openRequestJobModal: (plotId: number, kingdomId: number, currentTick: number, naturalResources: NaturalResource[], allowedBuildings: ImprovementConstructionRules[]) => void;
}

interface StateProps {
  kingdomId: number,
  currentTick: number,
  naturalResources: NaturalResource[],
  allowedBuildings: ImprovementConstructionRules[]
}

export interface OwnProps {
  plotId: number,
}

export type NewJobButtonProps = DispatchProps & StateProps & OwnProps;

export const NewJobButton = (props: NewJobButtonProps) => {
  const { t } = useTranslation();

  const renderNewJobButton = () => {
    return (
      <Row className="resource-table-sub-entry top-border wheat-background standard-text-md">
        <Col></Col>
        <Col></Col>
        <Col></Col>
        {!props.plotId && 
        <Col className="sub-entry-margin-class morris-md py-2">
          <div className="appleGreen-button">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </Col>
        }
        {props.plotId && 
        <Col className="sub-entry-margin-class morris-md py-2">
          <div className="appleGreen-button" onClick={() => props.openRequestJobModal(
            props.plotId, props.kingdomId, props.currentTick, props.naturalResources, props.allowedBuildings
            )}>
            {t("UserKingdomPage.LandsTab.Add")}
          </div>
        </Col>
        }
      </Row>
    );
  }

  return renderNewJobButton();
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  let plot = state.UserKingdomState.userKingdom.plots.filter((x: Plot) => x.id === ownProps.plotId)[0];
  let freespace = plot.size - state.UserKingdomState.userKingdom.improvements.filter((x: Improvement) => x.plotId === ownProps.plotId).reduce((a : number, b: Improvement) => a + b.size, 0);

  return {
    kingdomId: state.UserKingdomState.userKingdom.kingdomId,
    currentTick: state.UserKingdomState.userKingdom.currentTick,
    naturalResources: state.UserKingdomState.userKingdom.plots.filter((x: Plot) => x.id === ownProps.plotId)[0].naturalResources,
    allowedBuildings: state.RulesState.gameRules!.improvementConstructionRules
      .filter(x => (x.allowedLandTypes.length === 0 || x.allowedLandTypes.indexOf(plot.landType) !== -1)
      && freespace > 0 
      && freespace >= x.minimumSize
      && (x.maximumSize === 0 || x.maximumSize <= freespace))
   };
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    openRequestJobModal: (plotId: number, kingdomId: number, currentTick: number, naturalResources: NaturalResource[], allowedBuildings: ImprovementConstructionRules[]) => 
      dispatch(openRequestJobModal(plotId, kingdomId, currentTick, naturalResources, allowedBuildings))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(NewJobButton);