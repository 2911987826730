import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, filter } from 'rxjs/operators';
import GetLandsSearchResponse from '../../common/types/messages/GetLandsSearchResponse';
import GetSearchedUserKingdomDetailResponse from '../../common/types/messages/GetSearchedUserKingdomDetailResponse';
import GetSearchPlotsResponse from '../../common/types/messages/GetSearchPlotsResponse';
import {
  LandsSearchActionTypes,
  GetKingdomLandsActionType,
  GetKingdomLandsCompletedActionType,
  GetKingdomLandsFailedActionType,
  getKingdomLandsCompleted,
  getKingdomLandsFailed,
  GetSearchPlotsCompletedActionType,
  GetSearchPlotsFailedActionType,
  getSearchPlotsCompleted,
  getSearchPlotsFailed,
  UpdateSearchPlotsFormActionType,
  GetUserKingdomDetailsActionType,
  GetUserKingdomDetailsCompletedActionType,
  GetUserKingdomDetailsFailedActionType,
  getUserKingdomDetailsCompleted,
  getUserKingdomDetailsFailed
} from '../actions/landsSearchActions';

export const getKindomLandsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(LandsSearchActionTypes.GetKingdomLands),
    mergeMap((action) => onGetKingdomLands(action, dependencies))
  );
}

export const onGetKingdomLands: (action: GetKingdomLandsActionType, dependencies: AppDependencies) 
  => Observable<GetKingdomLandsCompletedActionType | GetKingdomLandsFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getKingdomsLand$(action.payload!.kingdomId);

  return callResponse$.pipe(
    map(response => {
      return getKingdomLandsCompleted(response as GetLandsSearchResponse);
    }),
    catchError(error => {
      return of(getKingdomLandsFailed(error.response.value));
    })
  );
}

export const getSearchPlotsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    filter((action: any) => action.payload && action.payload!.address && action.payload!.address.length >= 3),
    ofType(LandsSearchActionTypes.UpdateSearchPlotsForm),
    mergeMap((action) => onSearchPlotsEpic(action, dependencies))
  );
}

export const onSearchPlotsEpic: (action: UpdateSearchPlotsFormActionType, dependencies: AppDependencies) 
  => Observable<GetSearchPlotsCompletedActionType | GetSearchPlotsFailedActionType> = (action, { apiService }) => {
  
  const callResponse$ = apiService.getSearchPlots$(action.payload!.kingdomId, action.payload!.address);

  return callResponse$.pipe(
    map(response => {
      return getSearchPlotsCompleted(response as GetSearchPlotsResponse);
    }),
    catchError(error => {
      return of(getSearchPlotsFailed(error.response.value));
    })
  );
}

export const getUserKingdomDetailsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(LandsSearchActionTypes.GetUserKingdomDetails),
    mergeMap((action) => onGetUserKingdomDetiails(action, dependencies))
  );
}

export const onGetUserKingdomDetiails: (action: GetUserKingdomDetailsActionType, dependencies: AppDependencies) 
  => Observable<GetUserKingdomDetailsCompletedActionType | GetUserKingdomDetailsFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getSearchUserKingdomDetail$(action.payload!.userKingdomId);

  return callResponse$.pipe(
    map(response => {
      return getUserKingdomDetailsCompleted(response as GetSearchedUserKingdomDetailResponse);
    }),
    catchError(error => {
      return of(getUserKingdomDetailsFailed(error.response.value));
    })
  );
}


