import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, ModalTitle, ModalBody, Modal, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import PostDemolishImprovementRequest from '../../common/types/messages/PostDemolishImprovementRequest';
import { closeDemolishImprovementConfirmModal, demolishImprovement } from '../../store/actions/userKingdomActions';
import Improvement from '../../common/types/userKingdom/improvement';
import UserKingdom from '../../common/types/userKingdom/userKingdom';

interface StateProps {
  showDemolishImprovementConfirmModal: boolean,
  improvementId: string;
  userKingdom: UserKingdom;
};

interface DispatchProps {
  demolishImprovement: (request: PostDemolishImprovementRequest) => void;
  closeModal: () => void;
}

export type DemolishImprovementModalProps = DispatchProps & StateProps;

const DemolishImprovementConfirmModal = (props: DemolishImprovementModalProps) => {
  const { t } = useTranslation();

  const renderModal = () => {
    return (
      <Modal show={props.showDemolishImprovementConfirmModal} onHide={() => props.closeModal()} animation={false} centered>
        <ModalTitle className="primary-header">{t("Components.DemolishImprovementModal.Title")}</ModalTitle>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <ModalBody className="standard-text">
          <Row>
            <Col>
              {t("Components.DemolishImprovementModal.Body", { improvement: t(`ImprovementTypes.${props.userKingdom.improvements.filter((x: Improvement) => x.id === props.improvementId)[0].typeString}`)})}
            </Col>
          </Row>
          <Row className={`resource-table-sub-entry`}>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.demolishImprovement({
                  improvementId: props.improvementId,
                  kingdomId: props.userKingdom.kingdomId,
                  currentTick: props.userKingdom.currentTick
                } as PostDemolishImprovementRequest)}>
                {t("CommonWords.Yes")}
              </div>
            </Col>
            <Col>
              <div className="darkStone-button morris-md my-4" onClick={() => props.closeModal()}>
                {t("CommonWords.No")}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showDemolishImprovementConfirmModal && renderModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closeModal: () => dispatch(closeDemolishImprovementConfirmModal()),
    demolishImprovement: (request: PostDemolishImprovementRequest) => dispatch(demolishImprovement(request))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    userKingdom: state.UserKingdomState.userKingdom,
    showDemolishImprovementConfirmModal: state.UserKingdomState.showDemolishImprovementConfirmModal,
    improvementId: state.UserKingdomState.demolishImprovementId,
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(DemolishImprovementConfirmModal);