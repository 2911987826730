export function formatNumberNoDecimal(input: number) {
  return input.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0});
}

export function formatNumberMaxOneDecimal(input: number) {
  return input.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1});
}

export function formatNumberMaxTwoDecimal(input: number) {
  return input.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2});
}

export function translateStringToNumber(input: string) {
  const parsed = parseInt(input);

  if (isNaN(parsed)) {
    return 0;
  }

  return parsed;
}