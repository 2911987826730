import { Row, Col, Image } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Battle, { BattleSkirmish } from '../../common/types/userKingdom/battle';
import { openBattleModal } from '../../store/actions/combatActions';
import WorkerAmount from '../../common/types/userKingdom/workerAmount';
import { combatWorkerTypeEnum } from '../../common/types/enums/combatWorkerTypeEnum';
import { KeyValuePair } from '../../common/types/KeyValuePair';

interface StateProps {
  
};

interface DispatchProps {
  openBattleModal: (openBattles: Battle[], lockBattleType: boolean, targetUserKingdomId: string, targetKingdomUserId: number, targetKingdomUsername: string) => void;
}

interface OwnProps {
  battle: Battle;
  userId: number;
  userKingdomId: string;
}

export type DisplayCombatProps = DispatchProps & StateProps & OwnProps;

export const DisplayCombat = (props: DisplayCombatProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
  const [expanded, setExpanded] = useState(false);
  const [expandedSkirmishId, setExpandedSkirmishId] = useState("");
  const [expandTotalTroops, setExpandTotalTroops] = useState(false);
  const { t } = useTranslation();

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const renderExpandToggle = (isExpanded: boolean) => {
    return (
      <Row >
        <Col className="align-to-right morris-lg">
          {t("CommonWords.Details")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={isExpanded ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }

  const renderBattleHeader = () => {
    let totalAttackers = props.battle.battleCombatants.filter(x => x.isAttacker).length;
    let totalDefenders = props.battle.battleCombatants.filter(x => !x.isAttacker).length;
    let totalAttackerTroops = props.battle.battleCombatants.filter(x => x.isAttacker).reduce((a,b) => a + b.unitStartCount, 0);
    let totalDefenderTroops = props.battle.battleCombatants.filter(x => !x.isAttacker).reduce((a,b) => a + b.unitStartCount, 0);
    let totalAttackerDeadTroops = props.battle.battleCombatants.filter(x => x.isAttacker).reduce((a,b) => a + b.unitDeadCount, 0);
    let totalDefenderDeadTroops = props.battle.battleCombatants.filter(x => !x.isAttacker).reduce((a,b) => a + b.unitDeadCount, 0);

    return (
      <>
        <Row className={`resource-table-sub-entry tertiary-header`}>
          <Col>{getBattleTitleString()}</Col>
        </Row>
        <Row className="resource-table-sub-entry px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <Row className={`resource-table-sub-entry morris-lg`}>
          <Col>{getBattleStatusString()}</Col>
        </Row>
        <Row className={`resource-table-sub-entry morris-lg`}>
          <Col>
            <Row><Col>{totalAttackers} {t("CombatPanel.Attacker", { count: totalAttackers })}</Col></Row>
            <Row><Col>{totalAttackerTroops} {t("CombatPanel.Troops")}</Col></Row>
            <Row><Col>{totalAttackerDeadTroops} {t("CombatPanel.Dead")}</Col></Row>
          </Col>
          <Col>
            <Row><Col>{totalDefenders} {t("CombatPanel.Defender", { count: totalDefenders })}</Col></Row>
            <Row><Col>{totalDefenderTroops} {t("CombatPanel.Troops")}</Col></Row>
            <Row><Col>{totalDefenderDeadTroops} {t("CombatPanel.Dead")}</Col></Row>
          </Col>
        </Row>
      </>
    );
  }

  const renderDivisionsTable = () => {
    let attackerLonger = props.battle.battleCombatants.filter(x => x.isAttacker).length >= props.battle.battleCombatants.filter(x => !x.isAttacker).length;

    return (
      <>
        <Row className={`resource-table-sub-entry dark-wheat-background bottom-border standard-text`}>
          <Col>{t("CombatPanel.Name")}</Col>
          <Col>{t("CombatPanel.Troops")}</Col>
          <Col>{t("CombatPanel.Dead")}</Col>
          <Col>{t("CombatPanel.Name")}</Col>
          <Col>{t("CombatPanel.Troops")}</Col>
          <Col>{t("CombatPanel.Dead")}</Col>
        </Row>
        <Row className={`resource-table-sub-entry bottom-border standard-text`}>
          <Col className={`resource-table-sub-entry ${attackerLonger && "right-border"}`}>
            {props.battle!.battleCombatants
              .filter(x => x.isAttacker)
              .map(x => {
                return (
                  <Row className={`${x.isDefeated && "background-color-red-wheat-blend"}`}>
                    <Col>
                      {x.username}
                    </Col>
                    <Col>
                      {x.unitStartCount}
                    </Col>
                    <Col>
                      {x.unitDeadCount}
                    </Col>
                  </Row>
                );
              })}
          </Col>
          <Col className={`resource-table-sub-entry ${!attackerLonger && "left-border"}`}>
            {props.battle!.battleCombatants
              .filter(x => !x.isAttacker)
              .map(x => {
                return (
                  <Row className={`${x.isDefeated && "background-color-red-wheat-blend"}`}>
                    <Col>
                      {x.username}
                    </Col>
                    <Col>
                      {x.unitStartCount}
                    </Col>
                    <Col>
                      {x.unitDeadCount}
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </>
    );
  }

  const renderTroopTable = (
    attackerUnits: WorkerAmount[], 
    defenderUnits: WorkerAmount[],
    attackerDeadUnits: WorkerAmount[], 
    defenderDeadUnits: WorkerAmount[]) => {
    let attackerLonger = attackerUnits.length >= defenderUnits.length;

    return (
      <>
        <Row className={`resource-table-sub-entry bottom-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col>
            <Row className="resource-table-sub-entry dark-wheat-background  bottom-border">
              <Col></Col>
              <Col>{t("CombatPanel.StartingForce")}</Col>
              <Col>{t("CombatPanel.Dead")}</Col>
              <Col>{t("CombatPanel.Remaining")}</Col>
            </Row>
            {combatWorkerTypeEnum.map((x: KeyValuePair) => {
              let workerType = parseInt(x.value);
              let aliveCount = attackerUnits.filter(z => z.type === workerType).reduce((a,b) => a + b.amount, 0)
              let deadCount = attackerDeadUnits.filter(z => z.type === workerType).reduce((a,b) => a + b.amount, 0);

              if (aliveCount > 0 || deadCount > 0) {
                return (
                  <Row key={x.key} className={`resource-table-sub-entry ${attackerLonger && "right-border"}`}>
                    <Col><Image className="thumbnail-image" src={`/images/workers/${x.key}.png`}/></Col>
                    <Col>{aliveCount}</Col>
                    <Col>{deadCount}</Col>
                    <Col>{aliveCount - deadCount}</Col>
                  </Row>
                );
              } else {
                return (
                  <></>
                );
              }
            })}
          </Col>
          <Col>
            <Row className="resource-table-sub-entry dark-wheat-background  bottom-border">
              <Col></Col>
              <Col>{t("CombatPanel.StartingForce")}</Col>
              <Col>{t("CombatPanel.Dead")}</Col>
              <Col>{t("CombatPanel.Remaining")}</Col>
            </Row>
            {combatWorkerTypeEnum.map((x: KeyValuePair) => {
              let workerType = parseInt(x.value);
              let aliveCount = defenderUnits.filter(z => z.type === workerType).reduce((a,b) => a + b.amount, 0)
              let deadCount = defenderDeadUnits.filter(z => z.type === workerType).reduce((a,b) => a + b.amount, 0);

              if (aliveCount > 0 || deadCount > 0) {
                return (
                  <Row key={x.key} className={`resource-table-sub-entry ${attackerLonger && "right-border"}`}>
                    <Col><Image className="thumbnail-image" src={`/images/workers/${x.key}.png`}/></Col>
                    <Col>{aliveCount}</Col>
                    <Col>{deadCount}</Col>
                    <Col>{aliveCount - deadCount}</Col>
                  </Row>
                );
              } else {
                return (
                  <></>
                );
              }
            })}
          </Col>
        </Row>
        <Row className="resource-table-sub-entry dark-wheat-background standard-text">
          <Col>
            <Row>
            <Col>{t("CommonWords.Total")}</Col>
            <Col>{attackerUnits.reduce((a,b) => a + b.amount, 0)}</Col>
            <Col>{attackerDeadUnits.reduce((a,b) => a + b.amount, 0)}</Col>
            <Col>{attackerUnits.reduce((a,b) => a + b.amount, 0) - attackerDeadUnits.reduce((a,b) => a + b.amount, 0)}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
            <Col>{t("CommonWords.Total")}</Col>
            <Col>{defenderUnits.reduce((a,b) => a + b.amount, 0)}</Col>
            <Col>{defenderDeadUnits.reduce((a,b) => a + b.amount, 0)}</Col>
            <Col>{defenderUnits.reduce((a,b) => a + b.amount, 0) - defenderDeadUnits.reduce((a,b) => a + b.amount, 0)}</Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  const renderTotalTroops = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry clickable tertiary-header`}
          onClick={() => setExpandTotalTroops(!expandTotalTroops)}>
          <Col>
            {t("CombatPanel.TotalTroops")}
            <FontAwesomeIcon className="" size="1x" icon={expandTotalTroops ? faAngleDown : faAngleRight}/>
          </Col>
        </Row>
        {expandTotalTroops && renderTroopTable(
          props.battle.attackerStartingUnits,
          props.battle.defenderStartingUnits,
          props.battle.attackerDeadUnits,
          props.battle.defenderDeadUnits
        )}
      </>
    );
  }

  const renderExpandedSkirmish = (skirmish: BattleSkirmish) => {
    return (
      <>
        {renderTroopTable(
          skirmish.attackerActiveUnits,
          skirmish.defenderActiveUnits,
          skirmish.attackerDeadUnits,
          skirmish.defenderDeadUnits
        )}
        {skirmish.combatGifLocation !== "NoGif" &&
          <Row className={`resource-table-sub-entry mb-2 ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col>
              <Image className="combat-gif-image" src={`/battles/${skirmish.id}.gif`}/>
            </Col>
          </Row>}
      </>
    );
  }

  const renderSkirmishes = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry tertiary-header`}>
          <Col>{t("CombatPanel.Skirmishes")}</Col>
        </Row>
        <Row className="resource-table-sub-entry dark-wheat-background standard-text bottom-border">
          <Col>{t("CombatPanel.Attacker", { count: 1})}</Col>
          <Col>{t("CombatPanel.Defender", { count: 1})}</Col>
          <Col>{t("CombatPanel.Troops")}</Col>
          <Col></Col>
        </Row>
        {props.battle.battleSkirmishes
          .sort((a,b) => { return a.sequenceNumber - b.sequenceNumber; })
          .map((x) => {
          let attackerCombatant = props.battle.battleCombatants.filter(z => z.id === x.attackerCombantantId)[0];
          let defenderCombatant = props.battle.battleCombatants.filter(z => z.id === x.defenderCombantantId)[0];

          return (
            <>
            <Row key={x.id} className="resource-table-sub-entry standard-text bottom-border clickable"
              onClick={() => x.id === expandedSkirmishId ? setExpandedSkirmishId("") : setExpandedSkirmishId(x.id)}>
              <Col className={`${x.resultTypeString !== "AttackerVictory" && "background-color-red-wheat-blend"}`}>{attackerCombatant.username}</Col>
              <Col className={`${x.resultTypeString === "AttackerVictory" && "background-color-red-wheat-blend"}`}>{defenderCombatant.username}</Col>
              <Col>{x.attackerStartingCount} {t("CombatPanel.VersusShort")} {x.defenderStartingCount}</Col>
              <Col>
                <Row >
                  <Col className="align-to-right morris-lg">
                    {t("CommonWords.Details")}
                  </Col>
                  <Col className="morris-lg">
                    <FontAwesomeIcon className="" size="1x" icon={expandedSkirmishId === x.id ? faAngleDown : faAngleRight}/>
                  </Col>
                </Row>
              </Col>
            </Row>
            {x.id === expandedSkirmishId && renderExpandedSkirmish(x)}
            </>
          );
        })}
      </>
    );
  }

  const renderExpanded = () => {
    return (
      <div className="mx-2 mb-2">
        {renderBattleHeader()}
        {renderDivisionsTable()}
        {renderSkirmishes()}
        {renderTotalTroops()}
      </div>
    );
  }

  const renderOpenCombatModalButton = () => {
    return (
      <div className="darkStone-button morris-md my-4" onClick={() => { props.openBattleModal([props.battle], true, props.battle.targetUserKingdomId, props.battle.targetUserId, props.battle.targetUsername) }}>
        {t("CombatPanel.Join")}
      </div>
    );
  }

  const getBattleTitleString = () => {
    let isTarget = props.battle.targetUserId === props.userId;

    return t("CombatPanel.BattleTitleString", {
      goal: t(`BattleGoals.${props.battle.battleGoalTypeString}`),
      name: isTarget ? t("CommonWords.You") : props.battle.targetUsername
    })
  }

  const getBattleStatusString = () => {
    if (props.battle.battleStatusTypeString !== "Resolved") {
      return t(`BattleStatusTypes.${props.battle.battleStatusTypeString}`);
    } 
    
    if (props.battle.battleResultTypeString ==="AttackerVictory") {
      return t("CombatPanel.AttackerVictory");
    } else {
      return t("CombatPanel.DefenderVictory");
    }
  }

  const canExpand = () => {
    if (props.battle.battleStatus === 0 || props.battle.battleStatus === 1 || props.battle.battleStatus === 3) {
      return false;
    }

    return true;
  }

  if (!props.battle) {
    return <></>;
  } else {
    return (
      <>
        <Row onClick={() => canExpand() && setExpanded(!expanded)} className={`resource-table-sub-entry ${expanded ? "apple-green-background" : canExpand() && "clickable"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{getBattleTitleString()}</Col>
          <Col className="sub-entry-margin-class">{props.battle.battleCombatants.filter(x => x.isAttacker).reduce((a,b) => b.unitStartCount + a, 0)} {t("CombatPanel.VersusShort")} {props.battle.battleCombatants.filter(x => !x.isAttacker).reduce((a,b) => b.unitStartCount + a, 0)}</Col>
          <Col className="sub-entry-margin-class">{getBattleStatusString()}</Col>
          <Col className="sub-entry-margin-class">{t("GameDate.Day", { count: props.battle.resolveOnTickDate.gameDayOfMonth, ordinal: true })}{t(`GameDate.${props.battle.resolveOnTickDate.gameSeason}`, { count: props.battle.resolveOnTickDate.gameYear })}</Col>
          <Col className="sub-entry-margin-class morris-md py-2">
            {canExpand() && renderExpandToggle(expanded)}
            {!canExpand() && renderOpenCombatModalButton()}
          </Col>
        </Row>
        {expanded && renderExpanded()}
      </>
    );
  }
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {

  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    openBattleModal: (openBattles: Battle[], lockBattleType: boolean, targetUserKingdomId: string, targetKingdomUserId: number, targetKingdomUsername: string) => dispatch(openBattleModal(openBattles, lockBattleType, targetUserKingdomId, targetKingdomUserId, targetKingdomUsername))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayCombat);