import { Row, Col, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import Job from '../../common/types/userKingdom/job';
import { formatNumberNoDecimal } from '../../common/helperFunctions/formattingFunctions';
import Improvement from '../../common/types/userKingdom/improvement';
import Worker from '../../common/types/userKingdom/worker';
import ExpandedWorkersEntry from './expandedWorkersEntry';
import PostCancelJobRequest from '../../common/types/messages/PostCancelJobRequest';
import { deleteJob } from '../../store/actions/userKingdomActions';
import ConfirmModal from '../confirmModal';
import Plot from '../../common/types/userKingdom/plot';

export interface OwnProps {
  jobId: string,
  jobs: Job[],
  plot: Plot
}

interface StateProps {
  improvements: Improvement[];
  kingdomId: number;
  currentTick: number;
  workers: Worker[];
};

interface DispatchProps {
  deleteJob: (request: PostCancelJobRequest) => void
}

export type JobDisplayProps = DispatchProps & StateProps & OwnProps;

export const JobDisplay = (props: JobDisplayProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleExpanded = (event: any) => {
    setExpanded(!expanded);
  }

  const [showCancelConfirm, setShowCancelConfirm] = useState(false)

  const renderExpandToggle = () => {
    return (
      <Row>
        <Col className="align-to-right morris-lg">
          {t("UserKingdomPage.ImprovementsTab.Manage")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={expanded ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }

  const GetJobSpecificColumn = (job: Job) => {
    if (job.typeString === "Harvest") {
      return <Col className="sub-entry-margin-class">{formatNumberNoDecimal(job.amount!)} {t(`NaturalResourceTypes.${job.naturalResourceTypeString}`, { count: job.amount})}</Col>
    } else if (job.typeString === "Construct") {
      return <Col className="sub-entry-margin-class">{t(`ImprovementTypes.${
        props.improvements.filter(x => x.id === job.improvementId)[0].typeString}`)}</Col>
    } else {
      return <Col></Col>;
    }
  }

  const renderExpanded = () => {
    if (expanded) {
      return (
        <>
          <div className="mx-2 mb-2">
            <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
                <Col></Col>
                <Col className="sub-entry-margin-class">{t("UserKingdomPage.JobsTab.JobType")}</Col>
                <Col className="sub-entry-margin-class">{t("UserKingdomPage.JobsTab.Days")}</Col>
                <Col className="sub-entry-margin-class"></Col>
            </Row>
            {props.jobs.map((x) => 
              <Row key={x.priorityNumber} className={`resource-table-sub-entry ${!x.active && "dark"} ${isMobile ? "standard-text-sm": "standard-text"}`}>
                <Col><Image className="thumbnail-image" src={`/images/jobs/${x.typeString}.png`}/></Col>
                <Col className="sub-entry-margin-class">{t(`JobTypes.${x.typeString}${x.active ? "_active" : "" }`)} {GetJobSpecificColumn(x)}</Col>
                <Col className="sub-entry-margin-class">{x.daysRemaining === -1 ? t("UserKingdomPage.JobsTab.Never") : x.daysRemaining}</Col>
                {!x.active && <Col className="sub-entry-margin-class"></Col>}
                {x.active && <Col className="sub-entry-margin-class morris-lg">
                  <div className="errorRed-button" onClick={() => setShowCancelConfirm(true)}>
                    {t("UserKingdomPage.JobsTab.Cancel")}
                  </div>
                </Col>}
              </Row>)}
              {props.jobs.filter(x => x.id === props.jobId)[0].typeString !== "Reproduce" && <ExpandedWorkersEntry 
                workers={props.workers} 
                maxWorkers={props.jobs.filter(x => x.active)[0].maxWorkers} 
                allowedWorkerTypes={props.jobs.filter(x => x.id === props.jobId).sort((a,b) => a.priorityNumber - b.priorityNumber)[0].allowedWorkerTypes}
                managingImprovementWorkers={false}
                idManaging={props.jobId}/>}
          </div>
        </>
      )
    }
    return (<></>);
  }

  const getTotalDaysRemaining = () => {
    if (props.jobs.filter(x => x.daysRemaining === -1).length > 0) {
      return t("UserKingdomPage.JobsTab.Never");
    } else {
      return t("UserKingdomPage.JobsTab.DaysRemaining", { count: props.jobs.reduce((a, b) => a = a + b.daysRemaining, 0) })
    }
  }

  const getTitleString = () => {
    if (props.jobs.length < 1) {
      return '';
    }
    let titleString = t(`JobTypes.${props.jobs[props.jobs.length -1].typeString}`);

    if (props.jobs[props.jobs.length -1].typeString === "Construct") {
      titleString += ` ${t(`ImprovementTypes.${props.improvements.filter(x => x.id === props.jobs[props.jobs.length -1].improvementId)[0].typeString}`)}`
    } else if (props.jobs[props.jobs.length -1].typeString === "Harvest") {
      titleString += ` ${t(`NaturalResourceTypes.${props.jobs[props.jobs.length -1].naturalResourceTypeString}`, { count: props.jobs[props.jobs.length -1].amount })}`
    } else if (props.jobs[props.jobs.length -1].typeString === "Expand") {
      titleString += ` ${t(`ImprovementTypes.${props.improvements.filter(x => x.id === props.jobs[props.jobs.length -1].improvementId)[0].typeString}`)}`
    }

    return titleString
  }

  return (
    <>
      {showCancelConfirm && <ConfirmModal showModal={showCancelConfirm} confirmAction={() => {
         props.deleteJob({
            currentTick: props.currentTick,
            jobId: props.jobId,
            kingdomId: props.kingdomId
          } as PostCancelJobRequest);
          setShowCancelConfirm(false);
        }} denyAction={() => setShowCancelConfirm(false)}/> }
      <Row onClick={toggleExpanded} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"} ${expanded ? "apple-green-background" : "clickable"}`}>
        <Col>
          <Row>
            <Col><Image className="thumbnail-image" src={`/images/jobs/${props.jobs[props.jobs.length -1].typeString}.png`}/></Col>
            <Col>
              <Row><Col>{getTitleString()}</Col></Row>
              <Row><Col>{props.plot.address}</Col></Row>
            </Col>
            <Col>
              {props.workers.reduce((a, b) => a = a + b.number, 0)} {t("CommonWords.Workers", { count: props.workers.reduce((a, b) => a = a + b.number, 0)})}
            </Col>
            <Col>
              {getTotalDaysRemaining()}
            </Col>
            {!isMobile && <Col>
              {renderExpandToggle()}
            </Col>}
          </Row>
          {isMobile && 
            <Row>
              <Col>
                {renderExpandToggle()}
              </Col>
            </Row>}
        </Col>
      </Row>
      {renderExpanded()}
    </>
  );
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {

  const stateProps: StateProps = {
    improvements: state.UserKingdomState.userKingdom.improvements.filter((x: Improvement) => ownProps.jobs.some(z => z.id === ownProps.jobId && z.improvementId === x.id)),
    kingdomId: state.UserKingdomState.userKingdom.kingdomId,
    currentTick: state.UserKingdomState.userKingdom.currentTick,
    workers: state.UserKingdomState.userKingdom.workers.filter((x: Worker) => x.jobId === ownProps.jobId)    
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    deleteJob: (request: PostCancelJobRequest) => dispatch(deleteJob(request))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDisplay);