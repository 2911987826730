import Action from './baseAction';
import GetNotificationsResponse from '../../common/types/messages/GetNotificationsResponse';
import GetUserKingdomResponse from '../../common/types/messages/GetUserKingdomResponse';

export enum NotificationsActionTypes {
  GetNotifications = "GET_NOTIFICATIONS",
  GetNotificationsCompleted = "GET_NOTIFICATIONS_COMPLETED",
  GetNotificationsFailed = "GET_NOTIFICATIONS_FAILED",
  GetRedeemPackage = "GET_REDEEM_PACKAGE",
  GetRedeemPackageCompleted = "GET_REDEEM_PACKAGE_COMPLETED",
  GetRedeemPackageFailed = "GET_REDEEM_PACKAGE_FAILED",
  AcknowledgeNotification = "POST_ACKNOWLEDGE_NOTIFICATION",
  AcknowledgeNotificationCompleted = "POST_ACKNOWLEDGE_NOTIFICATION_COMPLETED",
  AcknowledgeNotificationFailed = "POST_ACKNOWLEDGE_NOTIFICATION_FAILED",
  CloseAllNotifications = "POST_CLOSE_ALL_NOTIFICATIONS",
  CloseAllNotificationsCompleted = "POST_CLOSE_ALL_NOTIFICATIONS_COMPLETED",
  CloseAllNotificationsFailed = "POST_CLOSE_ALL_NOTIFICATIONS_FAILED"
}

export type GetNotificationsPayloadType = { };
export type GetNotificationsActionType = Action<NotificationsActionTypes.GetNotifications, GetNotificationsPayloadType>;
export const getNotifications = ():GetNotificationsActionType => ({type: NotificationsActionTypes.GetNotifications, payload: { } });

export type GetNotificationsCompletedPayloadType = { response: GetNotificationsResponse; };
export type GetNotificationsCompletedActionType = Action<NotificationsActionTypes.GetNotificationsCompleted, GetNotificationsCompletedPayloadType>;
export const getNotificationsCompleted = (response: GetNotificationsResponse): GetNotificationsCompletedActionType => ({type: NotificationsActionTypes.GetNotificationsCompleted, payload: { response } });

export type GetNotificationsFailedPayloadType = { error: Error; };
export type GetNotificationsFailedActionType = Action<NotificationsActionTypes.GetNotificationsFailed, GetNotificationsFailedPayloadType>;
export const getNotificationsFailed = (error: Error): GetNotificationsFailedActionType => ({type: NotificationsActionTypes.GetNotificationsFailed, payload: { error } });

export type GetRedeemPackagePayloadType = { notificationId: string; kingdomId: number; };
export type GetRedeemPackageActionType = Action<NotificationsActionTypes.GetRedeemPackage, GetRedeemPackagePayloadType>;
export const getRedeemPackage = (notificationId: string, kingdomId: number):GetRedeemPackageActionType => ({type: NotificationsActionTypes.GetRedeemPackage, payload: { notificationId, kingdomId } });

export type GetRedeemPackageCompletedPayloadType = { response: GetUserKingdomResponse; notificationId: string; };
export type GetRedeemPackageCompletedActionType = Action<NotificationsActionTypes.GetRedeemPackageCompleted, GetRedeemPackageCompletedPayloadType>;
export const getRedeemPackageCompleted = (response: GetUserKingdomResponse, notificationId: string): GetRedeemPackageCompletedActionType => ({type: NotificationsActionTypes.GetRedeemPackageCompleted, payload: { response, notificationId } });

export type GetRedeemPackageFailedPayloadType = { error: Error; notificationId: string; };
export type GetRedeemPackageFailedActionType = Action<NotificationsActionTypes.GetRedeemPackageFailed, GetRedeemPackageFailedPayloadType>;
export const getRedeemPackageFailed = (error: Error, notificationId: string ): GetRedeemPackageFailedActionType => ({type: NotificationsActionTypes.GetRedeemPackageFailed, payload: { error, notificationId } });

export type AcknowledgeNotificationPayloadType = { notificationId: string };
export type AcknowledgeNotificationActionType = Action<NotificationsActionTypes.AcknowledgeNotification, AcknowledgeNotificationPayloadType>;
export const acknowledgeNotification = (notificationId: string):AcknowledgeNotificationActionType => ({type: NotificationsActionTypes.AcknowledgeNotification, payload: { notificationId } });

export type AcknowledgeNotificationCompletedPayloadType = { notificationId: string };
export type AcknowledgeNotificationCompletedActionType = Action<NotificationsActionTypes.AcknowledgeNotificationCompleted, AcknowledgeNotificationCompletedPayloadType>;
export const acknowledgeNotificationCompleted = (notificationId: string): AcknowledgeNotificationCompletedActionType => ({type: NotificationsActionTypes.AcknowledgeNotificationCompleted, payload: { notificationId } });

export type AcknowledgeNotificationFailedPayloadType = { error: Error; };
export type AcknowledgeNotificationFailedActionType = Action<NotificationsActionTypes.AcknowledgeNotificationFailed, AcknowledgeNotificationFailedPayloadType>;
export const acknowledgeNotificationFailed = (error: Error): AcknowledgeNotificationFailedActionType => ({type: NotificationsActionTypes.AcknowledgeNotificationFailed, payload: { error } });

export type CloseAllNotificationsPayloadType = { };
export type CloseAllNotificationsActionType = Action<NotificationsActionTypes.CloseAllNotifications, CloseAllNotificationsPayloadType>;
export const closeAllNotifications = ():CloseAllNotificationsActionType => ({type: NotificationsActionTypes.CloseAllNotifications, payload: { } });

export type CloseAllNotificationsCompletedPayloadType = { response: GetNotificationsResponse; };
export type CloseAllNotificationsCompletedActionType = Action<NotificationsActionTypes.CloseAllNotificationsCompleted, CloseAllNotificationsCompletedPayloadType>;
export const closeAllNotificationsCompleted = (response: GetNotificationsResponse): CloseAllNotificationsCompletedActionType => ({type: NotificationsActionTypes.CloseAllNotificationsCompleted, payload: { response } });

export type CloseAllNotificationsFailedPayloadType = { error: Error; };
export type CloseAllNotificationsFailedActionType = Action<NotificationsActionTypes.CloseAllNotificationsFailed, CloseAllNotificationsFailedPayloadType>;
export const closeAllNotificationsFailed = (error: Error): CloseAllNotificationsFailedActionType => ({type: NotificationsActionTypes.CloseAllNotificationsFailed, payload: { error } });
