import { Row, Col, Image, Spinner } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import Notification from '../../common/types/Notification';
import { getRedeemPackage, acknowledgeNotification, closeAllNotifications } from '../../store/actions/notificationsActions';
import { TabSelector } from '../tabSelectorComponent/tabSelector';
import { formatNumberMaxTwoDecimal, formatNumberNoDecimal } from '../../common/helperFunctions/formattingFunctions';

interface StateProps {
  notifications: Notification[],
  notificationState: NotificationsState,
  kingdomId: number,
  username: string,
  currentTick: number,
  userId: number
};

interface DispatchProps {
  redeemPackage: (notificationId: string, kingdomId: number) => void;
  acknowledgeNotification: (notificationId: string) => void;
  closeAllNotifications: () => void;
}

interface OwnProps {

}

export type NotificationsPanelProps = DispatchProps & StateProps & OwnProps;

export const NotificationsPanel = (props: NotificationsPanelProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();


  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const getCurrencyString = (currency: string, amount: number) => {
    if (currency === "Coins") {
      let gold = Math.floor(amount/10000);
      let silver = Math.floor(amount/100 % 100);
      let copper = Math.floor(amount % 100);
      let returnString = "";

      if (gold > 0) {
        returnString += formatNumberNoDecimal(gold) + `${t("CommonWords.Currency.Gold")} `
      }

      if (silver > 0) {
        returnString += formatNumberNoDecimal(silver) + `${t("CommonWords.Currency.Silver")} `
      }

      if (copper > 0) {
        returnString += formatNumberNoDecimal(copper) + `${t("CommonWords.Currency.Copper")}`
      }

      return returnString;
    } else {
      return formatNumberMaxTwoDecimal(amount) + `${t("CommonWords.Currency.UPX")}`
    }
  }

  const renderHandleNotificationError = (notification: Notification) => {
    if (props.notificationState.hasRespondingToNotificationError && props.notificationState.respondingNotificationIdError === notification.id) {
      return (
        <>
          <Row className={`resource-table-vassalized ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col>{t(`Exceptions.${props.notificationState.respondingToNotificaitonError}`)}</Col>
          </Row>
        </>
      );
    }
  }

  const renderOkButton = (notificationId: string) => {
    return (
      <>
        {props.notificationState.isRespondingToNotification && 
          <>
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="appleGreen-button">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </Col>
          </>}
        {!props.notificationState.isRespondingToNotification &&
          <>
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="appleGreen-button" onClick={() => props.acknowledgeNotification(notificationId)}>
                {t("Buttons.Ok")}
              </div>
            </Col>
          </> 
          }
        </>
    );
  }


  const renderBattleTarget = (notification: Notification) => {
    if (props.notificationState.battles.filter(x => x.id === notification.battleId).length === 0 || !props.username) {
      return (
        <>
        </>
      );
    }
    
    let battle = props.notificationState.battles.filter(x => x.id === notification.battleId)[0];
    let isTarget = props.notificationState.battles.filter(x => x.id === notification.battleId)[0].targetUserId === props.userId;

    return (
      <>
        <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/notifications/Battle.png`}/></Col>
          <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.BattleTargetNotification`, { kingdomName: t(`Kingdoms.${battle.kingdomId}`) })}</Col>
          {renderOkButton(notification.id)}
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t(`NotificationsPanel.BattleTargetSubtextNotification`, { 
            goal: t(`BattleGoals.${battle.battleGoalTypeString}`),
            name: isTarget ? t("CommonWords.You") : battle.targetUsername
            })}</Col>
        </Row>
      </>
    );
  }

  const renderBattleJoin = (notification: Notification) => {
    if (props.notificationState.battles.filter(x => x.id === notification.battleId).length === 0 || !props.username) {
      return (
        <>
        </>
      );
    }
    
    let battle = props.notificationState.battles.filter(x => x.id === notification.battleId)[0];
    let isTarget = props.notificationState.battles.filter(x => x.id === notification.battleId)[0].targetUserId === props.userId;

    return (
      <>
        <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/notifications/Battle.png`}/></Col>
          <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.BattleJoinNotification`, { kingdomName: t(`Kingdoms.${battle.kingdomId}`) })}</Col>
          {renderOkButton(notification.id)}
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t(`NotificationsPanel.BattleJoinSubtextNotification`, { 
            goal: t(`BattleGoals.${battle.battleGoalTypeString}`),
            name: isTarget ? t("CommonWords.You") : battle.targetUsername
            })}</Col>
        </Row>
      </>
    );
  }

  const renderBattleResolution = (notification: Notification) => {
    if (props.notificationState.battles.filter(x => x.id === notification.battleId).length === 0 || !props.username) {
      return (
        <>
        </>
      );
    }
    
    let battle = props.notificationState.battles.filter(x => x.id === notification.battleId)[0];
    let isTarget = props.notificationState.battles.filter(x => x.id === notification.battleId)[0].targetUserId === props.userId;

    return (
      <>
        <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/notifications/CombatResolution.png`}/></Col>
          <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.BattleResolution`, { kingdomName: t(`Kingdoms.${battle.kingdomId}`) })}</Col>
          {renderOkButton(notification.id)}
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t(`NotificationsPanel.BattleResolutionSubtextNotification`, { 
            goal: t(`BattleGoals.${battle.battleGoalTypeString}`),
            name: isTarget ? t("CommonWords.You") : battle.targetUsername,
            outcome: battle.battleResultTypeString === "AttackerVictory" ? t("NotificationsPanel.Outcome_Success") : t("NotificationsPanel.Outcome_Failed")
            })}</Col>
        </Row>
      </>
    );
  }

  const renderNewCityPackage = (notification: Notification) => {
    return (
      <>
        <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/notifications/NewUserPackage.png`}/></Col>
          <Col className="sub-entry-margin-class morris-lg">{t(`NotificationTypes.${notification.typeString}`)}</Col>
          {props.notificationState.isRespondingToNotification && 
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="appleGreen-button">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </Col>}
            {!props.notificationState.isRespondingToNotification && 
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="appleGreen-button" onClick={() => props.redeemPackage(notification.id, props.kingdomId)}>
                {t("NotificationsPanel.Redeem")}
              </div>
            </Col>
            }
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t(`NotificationsPanel.${notification.typeString}`)}</Col>
        </Row>
      </>
    );
  }

  const renderNewWorkersPackage = (notification: Notification) => {
    if (notification.purchase) {
      return (
        <>
          <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col><Image className="thumbnail-image" src={`/images/notifications/NewUserPackage.png`}/></Col>
            <Col className="sub-entry-margin-class morris-lg">{t(`NotificationTypes.${notification.typeString}`)}</Col>
            {props.notificationState.isRespondingToNotification && 
              <Col className="sub-entry-margin-class morris-md py-2">
                <div className="appleGreen-button">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </Col>}
              {!props.notificationState.isRespondingToNotification && 
              <Col className="sub-entry-margin-class morris-md py-2">
                <div className="appleGreen-button" onClick={() => props.redeemPackage(notification.id, props.kingdomId)}>
                  {t("NotificationsPanel.Redeem")}
                </div>
              </Col>
              }
          </Row>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col className="sub-entry-margin-class">{t(`NotificationsPanel.${notification.typeString}`, { count: notification.purchase!.amount})}</Col>
          </Row>
        </>
      );
    }
  }

  const renderErrorNotification = (notification: Notification) => {
    // this is a combat error notification
    if (notification.battleId && notification.message) {
      return (
        <>
          <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col><Image className="thumbnail-image" src={`/images/notifications/${notification.typeString}.png`}/></Col>
            <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.CombatError`, { kingdomName: t(`Kingdoms.${props.kingdomId}`)})}</Col>
            {renderOkButton(notification.id)}
          </Row>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col className="sub-entry-margin-class">{t(`Exceptions.${notification.message}`)}</Col>
          </Row>
        </>
      );
    }
  }


  const renderOverlordGone = (notification: Notification) => {
    return (
      <>
        <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/notifications/${notification.typeString}.png`}/></Col>
          <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.OverlordGone`)}</Col>
          {renderOkButton(notification.id)}
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col className="sub-entry-margin-class">{t(`NotificationsPanel.OverlordGoneMessage`)}</Col>
        </Row>
      </>
    );
  }

  const renderSaleExpired = (notification: Notification) => {
    if (notification.sale) {
      return (
        <>
          <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col><Image className="thumbnail-image" src={`/images/notifications/${notification.typeString}.png`}/></Col>
            <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.SaleExpired`, { 
              amount: formatNumberNoDecimal(notification.sale.amount),
              resource: t(`Resources.${notification.sale.resourceTypeString}`, { count: notification.sale.amount}),
              price: getCurrencyString(notification.sale.currencyTypeString, notification.sale.price),
              kingdom: notification.sale.kingdom})}</Col>
            {renderOkButton(notification.id)}
          </Row>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col className="sub-entry-margin-class">{t(`NotificationsPanel.SaleExpiredMessage`, { 
              amount: formatNumberNoDecimal(notification.sale.amount),
              resource: t(`Resources.${notification.sale.resourceTypeString}`, { count: notification.sale.amount}),
              price: getCurrencyString(notification.sale.currencyTypeString, notification.sale.price),
              kingdom: notification.sale.kingdom})}</Col>
          </Row>
        </>
      );
    }
  }

  const renderSaleSold = (notification: Notification) => {
    if (notification.sale) {
      return (
        <>
          <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col><Image className="thumbnail-image" src={`/images/GoldCoins.png`}/></Col>
            <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.SaleSold`, { 
              amount: formatNumberNoDecimal(notification.sale.amount),
              resource: t(`Resources.${notification.sale.resourceTypeString}`, { count: notification.sale.amount}),
              price: getCurrencyString(notification.sale.currencyTypeString, notification.sale.price),
              buyer: notification.sale.buyer,
              kingdom: notification.sale.kingdom})}</Col>
            {renderOkButton(notification.id)}
          </Row>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col className="sub-entry-margin-class">{t(`NotificationsPanel.SaleSoldMessage`, { 
              amount: formatNumberNoDecimal(notification.sale.amount),
              resource: t(`Resources.${notification.sale.resourceTypeString}`, { count: notification.sale.amount}),
              price: getCurrencyString(notification.sale.currencyTypeString, notification.sale.price),
              buyer: notification.sale.buyer,
              kingdom: notification.sale.kingdom})}</Col>
          </Row>
        </>
      );
    }
  }

  const renderWanderingPeasant = (notification: Notification) => {
    return (
      <>
        <Row className={`resource-table-sub-entry top-border ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/workers/Peasant.png`}/></Col>
          <Col className="sub-entry-margin-class morris-lg">{t(`NotificationsPanel.WanderingPeasant`)}</Col>
          {renderOkButton(notification.id)}
        </Row>
      </>
    );
  }

  const renderClearAllButton = () => {
    return (
      <>
        {props.notificationState.isClearingNotifications && 
          <>
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="appleGreen-button">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </Col>
          </>}
        {!props.notificationState.isClearingNotifications &&
          <>
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="appleGreen-button dark" onClick={() => props.closeAllNotifications()}>
                {t("Buttons.ClearAll")}
              </div>
            </Col>
          </> 
          }
        </>
    );
  }

  const renderTabView = () => {
    return (
      <>
        {props.notificationState.isClearingNotifications && 
          <Row className="resource-table-sub-entry morris-md py-2">
            <Col>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>}
        {props.notifications && props.notifications.map((x: Notification) => {
          return (
            <div key={x.id}>
              {x.typeString === "NewCityPackage" && renderNewCityPackage(x)}
              {x.typeString === "NewWorkers" && renderNewWorkersPackage(x)}
              {x.typeString === "ErrorNotification" && renderErrorNotification(x)}
              {x.typeString === "SaleExpired" && renderSaleExpired(x)}
              {x.typeString === "SaleSold" && renderSaleSold(x)}
              {x.typeString === "OverlordGone" && renderOverlordGone(x)}
              {x.typeString === "WanderingPeasant" && renderWanderingPeasant(x)}
              {x.typeString === "BattleTarget" && renderBattleTarget(x)}
              {x.typeString === "BattleArmyJoined" && renderBattleJoin(x)}
              {x.typeString === "BattleResolution" && renderBattleResolution(x)}
              {renderHandleNotificationError(x)}
            </div>
          );
        })}
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>{renderClearAllButton()}</Col>
        </Row>
      </>
    );
  }

  if (!props.notificationState.isClearingNotifications && (!props.notifications || props.notifications.length === 0)) {
    return <></>;
  } else {
    return (
      <>
        <Row>
          <TabSelector tabs={[t("NotificationsPanel.Notifications")]} activeTab={t("NotificationsPanel.Notifications")} clickEvent={() => {}}/>
        </Row>
        <Row className="pb-2">
          <div className="tabSelectorContainer">
            {renderTabView()}
          </div>
        </Row>
      </>
    );
  }
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    notifications: state.NotificationsState.notifications,
    notificationState: state.NotificationsState,
    kingdomId: state.UserKingdomState.userKingdom.kingdomId,
    username: state.UserKingdomState.userKingdom.uplandUsername,
    currentTick: state.UserKingdomState.userKingdom.currentTick,
    userId: state.UserKingdomState.userKingdom.userId
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    redeemPackage: (notificationId: string, kingdomId: number) => dispatch(getRedeemPackage(notificationId, kingdomId)),
    acknowledgeNotification: (notificationId: string) => dispatch(acknowledgeNotification(notificationId)),
    closeAllNotifications: () => dispatch(closeAllNotifications()),
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPanel);