import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useEffect, useCallback } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { getNotifications } from '../../store/actions/notificationsActions';

interface StateProps {
  authTokenSet: boolean,
  notifications: NotificationsState,
};

interface DispatchProps {
  getNotifications: () => void;
}

export type NotificationsLoadingComponentProps = DispatchProps & StateProps;

export const NotificationsLoadingComponent = (props: NotificationsLoadingComponentProps) => {

  useEffect(() => {
    if (props.authTokenSet && !props.notifications.isLoading && new Date(props.notifications.reloadAt) < new Date(Date.now())) {
      props.getNotifications();
    }
  }, [props])

  const callNotifications = useCallback(async () => {
    if (props.authTokenSet && !props.notifications.isLoading) {
      props.getNotifications();
    }
  }, [props]);

  useEffect(() => {
    setInterval(callNotifications, 120000);
  }, []);

  return (
    <></>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet,
    notifications: state.NotificationsState,
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
    const dispatchProps: DispatchProps = {
      getNotifications: () => dispatch(getNotifications()),
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsLoadingComponent);
