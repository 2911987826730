import { Reducer } from 'redux';
import { MarketState } from '../states/marketState';
import {
  MarketActionTypes, 
  GetOwnMarketSalesActionType,
  GetOwnMarketSalesCompletedActionType,
  GetOwnMarketSalesFailedActionType,
  PostMarketBuyRequestActionType, 
  PostMarketBuyRequestCompletedActionType, 
  PostMarketBuyRequestFailedActionType, 
  PostMarketCancelRequestActionType, 
  PostMarketCancelRequestCompletedActionType, 
  PostMarketCancelRequestFailedActionType, 
  PostMarketSearchRequestActionType, 
  PostMarketSearchRequestCompletedActionType, 
  PostMarketSearchRequestFailedActionType, 
  PostMarketSellRequestActionType,
  PostMarketSellRequestCompletedActionType,
  PostMarketSellRequestFailedActionType,
  UpdateMarketSearchFormActionType,
  CloseSaleUPXModaActionType,
  UpdateMarketSellFormActionType,
} from '../actions/marketActions';
import { GetUserKingdomCompletedActionType, UserKingdomActionTypes } from '../actions/userKingdomActions';

export type MarketActions = 
  PostMarketSellRequestActionType
  | PostMarketSellRequestCompletedActionType
  | PostMarketSellRequestFailedActionType
  | PostMarketCancelRequestActionType
  | PostMarketCancelRequestCompletedActionType
  | PostMarketCancelRequestFailedActionType
  | PostMarketBuyRequestActionType
  | PostMarketBuyRequestCompletedActionType
  | PostMarketBuyRequestFailedActionType
  | PostMarketSearchRequestActionType
  | PostMarketSearchRequestCompletedActionType
  | PostMarketSearchRequestFailedActionType
  | GetOwnMarketSalesActionType
  | GetOwnMarketSalesCompletedActionType
  | GetOwnMarketSalesFailedActionType
  | UpdateMarketSearchFormActionType
  | CloseSaleUPXModaActionType
  | UpdateMarketSellFormActionType
  | GetUserKingdomCompletedActionType;

const initialState: MarketState = {
  totalEntries: 0,
  marketEntries: [],
  ownSales: [],
  searchRequest: {
    kingdomId: 42,
    resource: -1,
    currency: 0,
    orderBy: 0,
    minimumItemCount: 1,
    page: 1,
    pageSize: 20
  },
  newSellRequest: {
    kingdomId: 42,
    userId: 0,
    currencyType: 0,
    resource: 0,
    amount: 0,
    price: 0
  },
  isSendingRequest: false,
  hasSendingRequestError: false,
  sendingRequestError: '',
  hasLoadedMarket: false,
  hasLoadedOwnSales: false,
  isLoadingMarket: false,
  isLoadingOwnSales: false,
  hasMarketError: false,
  errorMarketMessage: '',
  hasOwnSalesError: false,
  errorOwnSalesMessage: '',

  showUpxModal: false,
  upxModalAmount: 0
}

export const MarketReducer: Reducer<MarketState> = (state = initialState, action: MarketActions): MarketState => {
  switch(action.type) {
    case MarketActionTypes.PostMarketBuyRequest:
    case MarketActionTypes.PostMarketCancelRequest:
    case MarketActionTypes.PostMarketSellRequest:
      return {
        ...state,
        isSendingRequest: true,
        hasSendingRequestError: false,
        sendingRequestError: ''
      }
    case MarketActionTypes.PostMarketBuyRequestCompleted:
      let sale = state.marketEntries.filter(x => x.id === action.payload!.saleId)[0];
      let showModal = state.searchRequest.currency === 1 && sale.price > 0;

      return {
        ...state,
        isSendingRequest: false,
        hasSendingRequestError: false,
        sendingRequestError: '',
        marketEntries: state.marketEntries.filter(x => x.id !== action.payload!.saleId),
        totalEntries: state.totalEntries - 1,

        showUpxModal: showModal,
        upxModalAmount: sale.price,
      }
    case MarketActionTypes.PostMarketCancelRequestCompleted:
      return {
        ...state,
        isSendingRequest: false,
        hasSendingRequestError: false,
        sendingRequestError: '',
        ownSales: state.ownSales.filter(x => x.id !== action.payload!.saleId),
      }
    case MarketActionTypes.PostMarketSellRequestCompleted:
      let showSellModal = state.newSellRequest.currencyType === 1 && state.newSellRequest.price > 0;

      return {
        ...state,
        isSendingRequest: false,
        hasSendingRequestError: false,
        sendingRequestError: '',
        showUpxModal: showSellModal,
        upxModalAmount: 10.5,
        ownSales: action.payload!.response.sales
      }
    case MarketActionTypes.PostMarketBuyRequestFailed:
    case MarketActionTypes.PostMarketCancelRequestFailed:
    case MarketActionTypes.PostMarketSellRequestFailed:
      return {
        ...state,
        isSendingRequest: false,
        hasSendingRequestError: true,
        sendingRequestError: action.payload!.error as any,
      }
    case MarketActionTypes.UpdateMarketSearchForm:
      if (action.payload!.request.currency !== state.searchRequest.currency) {
        return {
          ...state,
          searchRequest: action.payload!.request,
          marketEntries: [],
          totalEntries: 0
        }
      }
      return {
        ...state,
        searchRequest: action.payload!.request
      }
    case MarketActionTypes.UpdateMarketSellForm:
      return {
        ...state,
        newSellRequest: action.payload!.request
      }
    case MarketActionTypes.PostMarketSearchRequest:
      return {
        ...state,
        isLoadingMarket: true,
        hasMarketError: false,
        errorMarketMessage: '',
        hasLoadedMarket: false,

        marketEntries: [],
        searchRequest: action.payload!.request
      };
    case MarketActionTypes.PostMarketSearchRequestCompleted:
      return {
        ...state,
        isLoadingMarket: false,
        hasMarketError: false,
        errorMarketMessage: '',

        hasLoadedMarket: true,
        marketEntries: action.payload!.response.sales,
        totalEntries: action.payload!.response.total
      };
    case MarketActionTypes.PostMarketSearchRequestFailed:
      return {
        ...state,
        isLoadingMarket: false,
        hasMarketError: true,
        errorMarketMessage: action.payload!.error as any,
        hasLoadedMarket: false,

        marketEntries: []   
      };
    case MarketActionTypes.GetOwnMarketSales:
      return {
        ...state,
        isLoadingOwnSales: true,
        hasOwnSalesError: false,
        errorOwnSalesMessage: '',
        hasLoadedOwnSales: false,

        ownSales: [],
      };
    case MarketActionTypes.GetOwnMarketSalesCompleted:
      return {
        ...state,
        isLoadingOwnSales: false,
        hasOwnSalesError: false,
        errorOwnSalesMessage: '',

        hasLoadedOwnSales: true,
        ownSales: action.payload!.response.sales
      };
    case MarketActionTypes.GetOwnMarketSalesFailed:
      return {
        ...state,
        isLoadingOwnSales: false,
        hasOwnSalesError: true,
        errorOwnSalesMessage: action.payload!.error as any,
        hasLoadedOwnSales: false,

        ownSales: []   
      };
    case MarketActionTypes.CloseSaleUPXModal:
      return {
        ...state,
        upxModalAmount: 0,
        showUpxModal: false
      }
    case UserKingdomActionTypes.GetUserKingdomCompleted:
      return {
        ...initialState,
        searchRequest: {
          ...initialState.searchRequest,
          kingdomId: action.payload!.response.userKingdom.kingdomId
        },
        newSellRequest: {
          ...initialState.newSellRequest,
          kingdomId: action.payload!.response.userKingdom.kingdomId
        }
      }
    default:
      return state;
  }
}

export default MarketReducer;