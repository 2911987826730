import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Image, Col, Form } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { Footer } from '../../components/footerComponent/footer';
import MenuButton from '../../components/menuButtonComponent/menuButton';
import DebugButton from '../../components/menuButtonComponent/debugButton';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useNavigate } from 'react-router-dom';
import { cityUserEntityExpired, deleteJob, updateResourceAmounts, getUserKingdom, toggleGrowth } from '../../store/actions/userKingdomActions';
import UserKingdom from '../../common/types/userKingdom/userKingdom';
import { LoadingComponent } from '../../components/loadingComponent/loadingComponent';
import { ResourceDisplayTableEntry } from '../../components/userKingdomDisplayTableComponents/resourceDisplayTableEntry';
import { formatNumberNoDecimal } from '../../common/helperFunctions/formattingFunctions';
import { TabSelector } from '../../components/tabSelectorComponent/tabSelector';
import { useAppContext } from  '../../common/app-context';
import { ApiService } from '../../common/api/api';
import PostUserKingdomResourceRequest, { UserKingdomResourceUpdate } from '../../common/types/messages/PostUserKingdomResourceRequest';
import { useTranslation } from "react-i18next";
import { WorkerDisplayTableEntry } from '../../components/userKingdomDisplayTableComponents/workerDisplayTableEntry';
import { UpkeepDisplayTableEntry } from '../../components/userKingdomDisplayTableComponents/upkeepDisplayTableEntry';
import JobDisplay from '../../components/userKingdomDisplayTableComponents/jobDisplay';
import { ImprovementDisplayTable } from '../../components/userKingdomDisplayTableComponents/ImprovementTab/improvementDisplayTable';
import PostSubtractResourcesRequest from '../../common/types/messages/PostSubtractResourcesRequest';
import PostCancelJobRequest from '../../common/types/messages/PostCancelJobRequest';
import Resource from '../../common/types/userKingdom/resource';
import ReloadCityUserEntityComponent from '../../components/reloadCityUserEntityComponent/reloadCityUserEntityModal';
import LandDisplayTable from '../../components/userKingdomDisplayTableComponents/LandTab/landDisplayTable';
import NewJobModal from '../../components/newJobModal/newJobModal';
import UserKingdomLoadingComponent from '../../components/userKingdomLoadingComponent/userKingdomLoadingComponent';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import NotificationsPanel from '../../components/notificationsPanel/notificationsPanel';
import VassalsPanel from '../../components/vassalsPanel/vassalsPanel';
import CombatPanel from '../../components/combatPanel/combatPanel';
import CombatModal from '../../components/combatModal/combatModal';
import NotificationsLoadingComponent from '../../components/userKingdomLoadingComponent/NotificationsLoadingComponent';
import PurchaseModal from '../../components/purchaseModal/purchaseModal';
import { openPurchaseForm } from '../../store/actions/purchaseActions';
import DemolishImprovementConfirmModal from '../../components/demolishImprovementConfirmModal/demolishImprovementConfirmModal';
import PostToggleGrowthRequest from '../../common/types/messages/PostToggleGrowthRequest';
import UserSettingsModal from '../../components/userSettingsModal/userSettingsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

interface StateProps {
  authTokenSet: boolean,
  isLoadingUserKingdom: boolean,
  hasLoadedUserKingdom: boolean,
  hasErrorLoadingUserKingdom: boolean,
  errorMessage: string,
  userKingdom: UserKingdom,
  isLoadingActiveKingdoms: boolean,
  isTogglingGrowth: boolean,
  activeKingdoms: KeyValuePair[]
};

interface DispatchProps {
  updateResourceAmounts: (resourceType: number, upkeepPriority: number, amount: number, maxAmount?: number) => void;
  deleteJob: (request: PostCancelJobRequest) => void;
  cityUserEntityExpired: () => void;
  getUserKingdom: (kingdomId: number) => void;
  openPurchaseModal: () => void;
  toggleGrowth: (request: PostToggleGrowthRequest) => void;
}

export type PlayerKingdomPageProps = DispatchProps & StateProps;

export const PlayerKingdomPage = (props: PlayerKingdomPageProps) => {
 const navigate = useNavigate();
 const context = useAppContext();
 const { t } = useTranslation();

 const availableTabsOne = [
  t("UserKingdomPage.Tabs.Workers"),
  t("UserKingdomPage.Tabs.Resources"),
  t("UserKingdomPage.Tabs.Upkeep")
 ];

 const availableTabsTwo = [
  t("UserKingdomPage.Tabs.Lands"),
  t("UserKingdomPage.Tabs.Improvements"),
  t("UserKingdomPage.Tabs.Jobs")
 ];

 const availableTabsThree = [
  t("VassalsPanel.Vassals"),
  t("CombatPanel.Battles"),
 ]

 const [mapExpanded, setMapExpanded] = useState(false);
 const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
 const [activeTabOne, setActiveTabOne] = useState(t("UserKingdomPage.Tabs.Resources").toString());
 const [activeTabTwo, setActiveTabTwo] = useState(t("UserKingdomPage.Tabs.Lands").toString());
 const [activeTabThree, setActiveTabThree] = useState(t("VassalsPanel.Vassals").toString());
 const [savingUserKingdomResources, setSavingUserKingdomResources] = useState(false);

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const onTabSelectorOneClick = (input: string) => {
    if (input === activeTabOne) {
      setActiveTabOne("");
    } else {
      setActiveTabOne(input); 
    }
  }

  const onTabSelectorTwoClick = (input: string) => {
    if (input === activeTabTwo) {
      setActiveTabTwo("");
    } else {
      setActiveTabTwo(input); 
    }
  }

  const onTabSelectorThreeClick = (input: string) => {
    if (input === activeTabThree) {
      setActiveTabThree("");
    } else {
      setActiveTabThree(input); 
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const subtractResources = async (resource: Resource, amount: number) => {
    const apiService: ApiService = context.apiService;
    setSavingUserKingdomResources(true);

    apiService.postSubtractUserKingdomResource$({
      kingdomId: props.userKingdom.kingdomId,
      currentTick: props.userKingdom.currentTick,
      resourceType: resource.type,
      subtractAmount: amount
      } as PostSubtractResourcesRequest)
    .toPromise()
    .then(
      () => {
        props.updateResourceAmounts(resource.type, resource.upkeepPriority, resource.amount - amount, resource.userSetMaximum);
      },
      (x) => {
        if (x.status === 409) {
          props.cityUserEntityExpired();
        }
      }
    )
    .finally(() => {
      setSavingUserKingdomResources(false);
    })
  }

  const saveResource = async (resource: Resource, upkeepPriority: number, maxAmount?: number) => {
    if (savingUserKingdomResources) {
      return;
    }

    const apiService: ApiService = context.apiService;
    setSavingUserKingdomResources(true);

    apiService.postUpdateUserKingdomResource$({
      kingdomId: props.userKingdom.kingdomId,
      currentTick: props.userKingdom.currentTick,
      resource: {
          resourceType: resource.type,
          upkeepPriority: upkeepPriority,
          userSetMax: resource.userSetMaximum
        } as UserKingdomResourceUpdate
    } as PostUserKingdomResourceRequest)
    .toPromise()
    .then(
      () => {
        props.updateResourceAmounts(resource.type, upkeepPriority, resource.amount, maxAmount);
      },
      (x) => {
        if (x.status === 409) {

          props.cityUserEntityExpired();
        }
      }
    )
    .finally(() => {
      setSavingUserKingdomResources(false);
    })
  }

  const updateUserSetMaxState = async (resource: Resource, maxAmount?: number) => {
    if (savingUserKingdomResources) {
      return;
    }

    props.updateResourceAmounts(resource.type, resource.upkeepPriority, resource.amount, maxAmount);
  }

  const renderKingdomMap = () => {
    if (isMobile) {
      return (
        <div onClick={() => setMapExpanded(!mapExpanded)}>
          <Row>
            <Col>
              {mapExpanded && <Image fluid className="kingdom-map-image" src={`/images/kingdomMaps/${props.userKingdom.kingdomId}_biomes.png`}/>}
            </Col>
          </Row>
          {mapExpanded &&
          <Row className="standard-text m-1">
            <Col><div className="mapKeyEntry iceCappedMountain">{t("LandTypes.IceCappedMountain")}</div></Col>
            <Col><div className="mapKeyEntry mountain">{t("LandTypes.Mountain")}</div></Col>
            <Col><div className="mapKeyEntry hills">{t("LandTypes.Hills")}</div></Col>
          </Row>}
          {mapExpanded && <Row className="standard-text m-1">
            <Col><div className="mapKeyEntry steppe">{t("LandTypes.Steppe")}</div></Col>
            <Col><div className="mapKeyEntry forest">{t("LandTypes.Forest")}</div></Col>
            <Col><div className="mapKeyEntry plains">{t("LandTypes.Plains")}</div></Col>
          </Row>}
          {mapExpanded && <Row className="standard-text m-1">
            <Col><div className="mapKeyEntry scrubland">{t("LandTypes.Scrubland")}</div></Col>
            <Col><div className="mapKeyEntry desert">{t("LandTypes.Desert")}</div></Col>
            <Col><div className="mapKeyEntry bog">{t("LandTypes.Bog")}</div></Col>
          </Row>}
        </div>
      );
    } else {
      return (
        <div onClick={() => setMapExpanded(!mapExpanded)}>
          <Row>
            <Col></Col>
            <Col>
              {mapExpanded && <Image fluid className="kingdom-map-image" src={`/images/kingdomMaps/${props.userKingdom.kingdomId}_biomes.png`}/>}
            </Col>
            <Col></Col>
          </Row>
          {mapExpanded &&
          <Row className="standard-text m-1">
            <Col><div className="mapKeyEntry iceCappedMountain">{t("LandTypes.IceCappedMountain")}</div></Col>
            <Col><div className="mapKeyEntry mountain">{t("LandTypes.Mountain")}</div></Col>
            <Col><div className="mapKeyEntry hills">{t("LandTypes.Hills")}</div></Col>
          </Row>}
          {mapExpanded && <Row className="standard-text m-1">
            <Col><div className="mapKeyEntry steppe">{t("LandTypes.Steppe")}</div></Col>
            <Col><div className="mapKeyEntry forest">{t("LandTypes.Forest")}</div></Col>
            <Col><div className="mapKeyEntry plains">{t("LandTypes.Plains")}</div></Col>
          </Row>}
          {mapExpanded && <Row className="standard-text m-1">
            <Col><div className="mapKeyEntry scrubland">{t("LandTypes.Scrubland")}</div></Col>
            <Col><div className="mapKeyEntry desert">{t("LandTypes.Desert")}</div></Col>
            <Col><div className="mapKeyEntry bog">{t("LandTypes.Bog")}</div></Col>
          </Row>}
        </div>
      );
    }
  }

  const updateKingdom = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    props.getUserKingdom(parseInt(changeEvent.currentTarget.value));
  }

  const renderKingdomDetailsPanel = () => {
    
    if (props.isLoadingUserKingdom) {
      return (
        <div className="standard-text-box">
          <Row>
            <div className="secondary-header">
            {t("UserKingdomPage.Loading")}
            </div>
          </Row>
          <Row className="px-5"> 
            <div className="outer-fancy-border-sm">
              <div className="inner-fancy-border-sm">
              </div>
            </div>
          </Row>
          <LoadingComponent />
        </div>
      );
    }
    
    if (props.hasErrorLoadingUserKingdom || !props.userKingdom) {
      return (
        <div className="standard-text-box">
          <Row>
            <div className="secondary-header">
              {t("Exceptions.ErrorLoadingKingdom")}
            </div>
          </Row>
          <Row className="px-5"> 
            <div className="outer-fancy-border-sm">
              <div className="inner-fancy-border-sm">
              </div>
            </div>
          </Row>
        </div>
      );
    }

    return (
      <div className="standard-text-box">
        <Row>
          <Col>
            <div className="secondary-header">
              {t("UserKingdomPage.KingdomTitle")} {t(`Kingdoms.${props.userKingdom.kingdomId}`)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="inline-block-display">
            <div className="tertiary-header inline-block-display mx-2">
              {t(`TitleTypes_${props.userKingdom.useMaleTitles ? "Male": "Female"}.${props.userKingdom.titleString}`)} {props.userKingdom.username}{props.userKingdom.overlord && `, ${t("CommonWords.VassalOf", { overlordName: props.userKingdom.overlord })}`}
            </div>
            <div className="stone-button stoneButtonMaxWidth morris-lg inline-block-display" onClick={() => props.getUserKingdom(props.userKingdom.kingdomId)}>
              <FontAwesomeIcon icon={faRefresh}/>
            </div>
          </Col>
        </Row>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <Row>
          <Col></Col>
          <Col>
            <Form className="search-lands-form" onSubmit={e => {e.preventDefault()}}>
              <Form.Group>
                <Form.Select className="drop-down-style wheat_dark" value={props.activeKingdoms.length === 0 ? -1 : props.userKingdom.kingdomId} onChange={updateKingdom}>
                  {props.activeKingdoms.map(x => <option key={x.key} value={x.key}>{x.value}</option>)}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="woodBrown-button max-width-300" onClick={() => setMapExpanded(!mapExpanded)}>
              {t("UserKingdomPage.Map")}
            </div>
          </Col>
          <Col></Col>
        </Row>
        {renderKingdomMap()}
        <Row>
          <div className="quaternary-header">
            {t("GameDate.Day", { count: props.userKingdom.gameDate.gameDayOfMonth, ordinal: true })}{t(`GameDate.${props.userKingdom.gameDate.gameSeason}`, { count: props.userKingdom.gameDate.gameYear })}
          </div>
        </Row>
        <Row className="standard-text-md">
          <Col>
            <Row>
              <Col>{t("UserKingdomPage.Population")} {formatNumberNoDecimal(props.userKingdom.kingdomPopulation)}</Col>
            </Row>
            <Row>
              <Col>{t("CommonWords.Size")} {formatNumberNoDecimal(props.userKingdom.kingdomSize)} up2</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                {t("UserKingdomPage.Subjects")} {formatNumberNoDecimal(props.userKingdom.workers.filter((x) => !x.migratedWorker).reduce((a, b) => a = a + b.number, 0))} / {formatNumberNoDecimal(props.userKingdom.maxPopulation)}
                <div className={`resource-upkeep-color inline-left ${props.userKingdom.allowGrowth ? "green" : "red"}`} onClick={ () => 
                  !props.isTogglingGrowth && props.toggleGrowth({
                    kingdomId: props.userKingdom.kingdomId,
                    allowGrowth: !props.userKingdom.allowGrowth
                  })}></div>
              </Col>
            </Row>
            <Row>
              <Col>{t("UserKingdomPage.DomainSize")} {formatNumberNoDecimal(props.userKingdom.plots.reduce((a, b) => a = a + b.size, 0))} up2</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row><Col className="center-image"><Image className="thumbnail-image" src={`/images/GoldCoins.png`}/></Col></Row>
            <Row><Col className="morris-lg">{props.userKingdom.currency < 0 ? formatNumberNoDecimal(Math.ceil(props.userKingdom.currency/10000)) : formatNumberNoDecimal(Math.floor(props.userKingdom.currency/10000))}</Col></Row>
          </Col>
          <Col>
            <Row><Col className="center-image"><Image className="thumbnail-image" src={`/images/SilverCoins.png`}/></Col></Row>
            <Row><Col className="morris-lg">{props.userKingdom.currency < 0 ? formatNumberNoDecimal(Math.ceil(props.userKingdom.currency/100 % 100)) : formatNumberNoDecimal(Math.floor(props.userKingdom.currency/100 % 100))}</Col></Row>
          </Col>
          <Col>
            <Row><Col className="center-image"><Image className="thumbnail-image" src={`/images/CopperCoins.png`}/></Col></Row>
            <Row><Col className="morris-lg">{props.userKingdom.currency < 0 ? formatNumberNoDecimal(Math.ceil(props.userKingdom.currency % 100)) : formatNumberNoDecimal(Math.floor(props.userKingdom.currency % 100))}</Col></Row>
          </Col>
        </Row>
        <Row>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="woodBrown-button max-width-300" onClick={() => navigate("/LandSearch")}>
              {t("UserKingdomPage.LandSearch")}
            </div>
          </Col>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="woodBrown-button max-width-300" onClick={() => navigate("/Market")}>
              {t("UserKingdomPage.Market")}
            </div>
          </Col>
          <Col className="sub-entry-margin-class morris-lg m-2">
            <div className="woodBrown-button max-width-300" onClick={() => props.openPurchaseModal()}>
              {t("UserKingdomPage.Store")}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  const renderLandsTab = () => {
    if (props.userKingdom.plots.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("UserKingdomPage.LandsTab.NoLands")}</p>
        </Row>
      );
    }
    
    return (
      <>
         <LandDisplayTable />
      </>
    );
  }

  const renderResourcesTab = () => {
    if (props.userKingdom.resources.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("UserKingdomPage.ResourceTab.NoResources")}</p>
        </Row>
      );
    }

    return (
      <>
        {props.userKingdom.resourceStorageLimits.map((x) => 
          <ResourceDisplayTableEntry 
            key={x.type}
            resourceStorage={x}
            resources={props.userKingdom.resources.filter(r => r.storageType === x.type)}
            saveResource={saveResource}
            updateResourceSetMax={updateUserSetMaxState}
            subtractResources={subtractResources}
          />)}
      </>
    );
  }

  const renderJobsTab = () => {
    if (props.userKingdom.jobs.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("UserKingdomPage.JobsTab.NoActiveJobs")}</p>
        </Row>
      );
    }

    const uniqueJobIds: string[] = [];
    props.userKingdom.jobs.map(x => uniqueJobIds.indexOf(x.id) === -1 ?
      uniqueJobIds.push(x.id) : undefined
    );

    return (
      <>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col className="sub-entry-margin-class"></Col>
            <Col className="sub-entry-margin-class">{t("UserKingdomPage.JobsTab.JobType")}</Col>
            <Col className="sub-entry-margin-class">{t("CommonWords.Workers", { count: 2 })}</Col>
            <Col className="sub-entry-margin-class">{t("UserKingdomPage.JobsTab.TimeRemaining")}</Col>
            {!isMobile && <Col className="sub-entry-margin-class"></Col>}
        </Row>
        {uniqueJobIds.map((x) => {
          let jobs = props.userKingdom.jobs.filter(r => r.id === x).sort((a, b) => b.priorityNumber - a.priorityNumber);

          return (
            <JobDisplay 
              key={x}
              jobId={x}
              jobs={jobs}
              plot={props.userKingdom.plots.filter(p => p.id === jobs[0].plotId)[0]}
            />);
        })}
      </>
    );
  }

  const renderUpkeepTab = () => {
    if (props.userKingdom.upkeepForNextTurn.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("UserKingdomPage.UpkeepTab.NoUpkeep")}</p>
        </Row>
      );
    }
    
    return (
      <>
        {props.userKingdom.upkeepForNextTurn.map((x) => 
          <UpkeepDisplayTableEntry 
            key={x.type}
            upkeep={x}
            resources={props.userKingdom.resources.filter(r => x.resourceTypes.indexOf(r.typeString) !== -1)}
            saveResource={saveResource}
          />)}
      </>
    );
  }

  const renderWorkersTab = () => {
    if (props.userKingdom.groupedWorkers.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("UserKingdomPage.WorkersTab.NoWorkers")}</p>
        </Row>
      );
    }

    const workerClasses: string[] = [];
    props.userKingdom.groupedWorkers.map(x => workerClasses.indexOf(x.class) === -1 ?
        workerClasses.push(x.class) : undefined
    );
    
    return (
      <>
        {workerClasses.sort().map((x) => 
          <WorkerDisplayTableEntry 
            key={x}
            workerClass={x}
            workers={props.userKingdom.groupedWorkers.filter(r => r.class === x)}
          />)}
      </>
    );
  }

  const renderImprovementsTab = () => {
    if (props.userKingdom.improvements.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("UserKingdomPage.ImprovementsTab.NoImprovements")}</p>
        </Row>
      );
    }
    
    const uniqueImprovementClasses: string[] = [];
    props.userKingdom.improvements.map(x => uniqueImprovementClasses.indexOf(x.improvementClassString) === -1 ?
    uniqueImprovementClasses.push(x.improvementClassString) : undefined
    );

    return (
      <>
        {uniqueImprovementClasses.sort().map((x) => 
          <ImprovementDisplayTable 
            key={x}
            improvementClass={x}
            improvements={props.userKingdom.improvements.filter(r => r.improvementClassString === x)}
            workers={props.userKingdom.workers.filter(w => w.improvementId)}
          />)}
      </>
    );
  }

  const renderVassalTab = () => {
    if (props.userKingdom.vassals.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("VassalsPanel.NoVassals")}</p>
        </Row>
      );
    }

    return <VassalsPanel />;
  }

  const renderCombatTab = () => {
    if (props.userKingdom.battles.length === 0) {
      return (
        <Row>
          <p className="secondary-header pt-3">{t("CombatPanel.NoBattles")}</p>
        </Row>
      );
    }

    return <CombatPanel />;
  }

  const renderActiveKingdomInfoTabOne = () => {
    if (activeTabOne === t("UserKingdomPage.Tabs.Workers")) {
      return renderWorkersTab();
    } else if (activeTabOne === t("UserKingdomPage.Tabs.Resources")) {
      return renderResourcesTab();
    } else if (activeTabOne === t("UserKingdomPage.Tabs.Upkeep")) {
      return renderUpkeepTab();
    }
  }

  const renderActiveKingdomInfoTabTwo = () => {
    if (activeTabTwo === t("UserKingdomPage.Tabs.Lands")) {
      return renderLandsTab();
    } else if (activeTabTwo === t("UserKingdomPage.Tabs.Improvements")) {
      return renderImprovementsTab();
    } else if (activeTabTwo === t("UserKingdomPage.Tabs.Jobs")) {
      return renderJobsTab();
    }
  }

  const renderActiveKingdomInfoTabThree = () => {
    if (activeTabThree === t("VassalsPanel.Vassals")) {
      return renderVassalTab();
    } else if (activeTabThree === t("CombatPanel.Battles")) {
      return renderCombatTab();
    }
  }

  const renderKingdomInfoTabs = () => {
     return (
       <>
        <Row>
          <TabSelector tabs={availableTabsOne} activeTab={activeTabOne} clickEvent={onTabSelectorOneClick}/>
        </Row>
        <Row className="pb-2">
          <div className="tabSelectorContainer">
            {renderActiveKingdomInfoTabOne()}
          </div>
        </Row>
        <br></br>
        <Row>
          <TabSelector tabs={availableTabsTwo} activeTab={activeTabTwo} clickEvent={onTabSelectorTwoClick}/>
        </Row>
        <Row className="pb-2">
          <div className="tabSelectorContainer">
            {renderActiveKingdomInfoTabTwo()}
          </div>
        </Row>
        <br></br>
        <Row>
          <TabSelector tabs={availableTabsThree} activeTab={activeTabThree} clickEvent={onTabSelectorThreeClick}/>
        </Row>
        <Row className="pb-2">
          <div className="tabSelectorContainer">
            {renderActiveKingdomInfoTabThree()}
          </div>
        </Row>
       </>
     );
  }

  return (
    <>
      <NotificationsLoadingComponent />
      <UserKingdomLoadingComponent />
      <ReloadCityUserEntityComponent />
      <CombatModal />
      <NewJobModal />
      <PurchaseModal />
      <UserSettingsModal />
      <DemolishImprovementConfirmModal />
      <MenuButton />
      <div className="footer-padding">
        <Container fluid="md">
          {renderKingdomDetailsPanel()}
          {props.hasLoadedUserKingdom && <NotificationsPanel />}
          {props.hasLoadedUserKingdom && renderKingdomInfoTabs()}
        </Container>
      </div>
      <Footer />
    </>
  )
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet,
    isLoadingUserKingdom: state.UserKingdomState.isLoading,
    hasLoadedUserKingdom: state.UserKingdomState.hasLoaded,
    hasErrorLoadingUserKingdom: state.UserKingdomState.hasError,
    errorMessage: state.UserKingdomState.errorMessage,
    userKingdom: state.UserKingdomState.userKingdom,
    isLoadingActiveKingdoms: state.UserKingdomState.isLoadingActiveKingdoms,
    activeKingdoms: state.UserKingdomState.activeKingdoms,
    isTogglingGrowth: state.UserKingdomState.isTogglingGrowth
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
    const dispatchProps: DispatchProps = {
      updateResourceAmounts: (resourceType: number, upkeepPriority: number, amount: number, maxAmount?: number) => dispatch(updateResourceAmounts(resourceType, upkeepPriority, amount, maxAmount)),
      deleteJob: (request: PostCancelJobRequest) => dispatch(deleteJob(request)),
      cityUserEntityExpired: () => dispatch(cityUserEntityExpired()),
      getUserKingdom: (kingdomId: number) => dispatch(getUserKingdom(kingdomId)),
      openPurchaseModal: () => dispatch(openPurchaseForm()),
      toggleGrowth: (request: PostToggleGrowthRequest) => dispatch(toggleGrowth(request))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerKingdomPage);
