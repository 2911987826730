import { Reducer } from 'redux';
import { PurchaseState } from '../states/purchaseState';
import {
  PurchaseActionTypes,
  PostPurchaseActionType,
  PostPurchaseCompletedActionType,
  PostPurchaseFailedActionType,
  UpdatePurchaseFormActionType,
  ClosePurchaseFormActionType,
  OpenPurchaseFormActionType
} from '../actions/purchaseActions';
import { UserKingdomActionTypes, GetUserKingdomCompletedActionType } from '../actions/userKingdomActions';

export type PurchaseActions = 
  PostPurchaseActionType
  | PostPurchaseCompletedActionType
  | PostPurchaseFailedActionType
  | UpdatePurchaseFormActionType
  | ClosePurchaseFormActionType
  | OpenPurchaseFormActionType
  | GetUserKingdomCompletedActionType;

const initialState: PurchaseState = {
  purchaseForm: {
    kingdomId: 42,
    userId: 1,
    amount: 1,
    purchaseType: 0
  },
  isPurchasing: false,
  errorPurchasing: false,
  errorPurchasingMessage: "",
  showPurchaseModal: false,
}

export const PurchaseReducer: Reducer<PurchaseState> = (state = initialState, action: PurchaseActions): PurchaseState => {
  switch(action.type) {
    case PurchaseActionTypes.PostPurchase:
      return {
        ...state,
        isPurchasing: true,
        errorPurchasing: false,
        errorPurchasingMessage: '',
      };
    case PurchaseActionTypes.PostPurchaseCompleted:
      return {
        ...state,
        isPurchasing: false,
        errorPurchasing: false,
        errorPurchasingMessage: '',
        showPurchaseModal: false,
      };
    case PurchaseActionTypes.PostPurchaseFailed:
      return {
        ...state,
        isPurchasing: false,
        errorPurchasing: true,
        errorPurchasingMessage: action.payload!.error as any,
      };
    case PurchaseActionTypes.UpdatePurchaseForm:
      return {
        ...state,
        purchaseForm: action.payload!.request
      }
    case PurchaseActionTypes.OpenPurchaseForm:
      return {
        ...state,
        showPurchaseModal: true
      }
    case PurchaseActionTypes.ClosePurchaseForm:
      return {
        ...state,
        showPurchaseModal: false
      }

    case UserKingdomActionTypes.GetUserKingdomCompleted:
      return {
        ...state,
        purchaseForm: {
          ...state.purchaseForm,
          kingdomId: action.payload!.response.userKingdom.kingdomId,
          userId: action.payload!.response.userKingdom.userId
        }
      }
    default:
      return state;
  }
}

export default PurchaseActions;