import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/style.scss"
import { useTranslation } from "react-i18next";
import Improvement from '../../../common/types/userKingdom/improvement';
import Worker from '../../../common/types/userKingdom/worker';
import ImprovementDisplay from './ImprovementDisplay';

export interface OwnProps {
  improvementClass: string,
  improvements: Improvement[],
  workers: Worker[]
}

export const ImprovementDisplayTable = (props: OwnProps) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleExpanded = (event: any) => {
    setExpanded(!expanded);
  }

  const renderExpandToggle = () => {
    if (expanded) {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleDown}/></Col>
      )
    } else {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleRight}/></Col>
      );
    }
  }

  const renderExpanded = () => {
    if (expanded) {
      return (
        <>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col className="sub-entry-margin-class"></Col>
            <Col className="sub-entry-margin-class">{t("CommonWords.Size")}</Col>
            {!isMobile && <Col className="sub-entry-margin-class">{t("CommonWords.Address")}</Col>}
            <Col className="sub-entry-margin-class">{t("CommonWords.Workers", { count: 2 })}</Col>
            <Col className="sub-entry-margin-class">{t("CommonWords.Status")}</Col>
            {!isMobile && <Col className="sub-entry-margin-class"></Col>}
          </Row>
          {props.improvements.map((x) => <ImprovementDisplay
            key={x.id}
            improvement={x}
            workers={props.workers.filter(z => z.improvementId === x.id)} />)}
        </>
      )
    }
    return (<></>);
  }

  return (
    <>
      <Row key={props.improvementClass} onClick={toggleExpanded} className="resource-table standard-text-md">
        <Col>
          {t(`ImprovementClasses.${props.improvementClass}`)}
        </Col>
        <Col>
          {props.improvements.length}
        </Col>
        {renderExpandToggle()}
      </Row>
      {renderExpanded()}
    </>
  );
}