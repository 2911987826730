import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, ModalTitle, ModalBody, Modal, Col, Form, Image, Spinner } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { closeRequestJobModal, requestJob, updateRequestJobForm } from '../../store/actions/jobRequestActions';
import { useState, useEffect } from 'react';
import UserKingdom from '../../common/types/userKingdom/userKingdom';
import GroupedWorkers from '../../common/types/userKingdom/groupedWorkers';
import PostRequestJobRequest from '../../common/types/messages/PostRequestJobRequest';
import ImprovementConstructionRules from '../../common/types/rules/ImprovementConstructionRules';
import { formatNumberNoDecimal } from '../../common/helperFunctions/formattingFunctions';
import NaturalResource from '../../common/types/userKingdom/naturalResource';
import GameRules from '../../common/types/rules/GameRules';

interface StateProps {
  userKingdom: UserKingdom;
  request: PostRequestJobRequest;
  showNewJobModal: boolean;
  isRequestingJob: boolean;
  requestJobError: string;
  availableResources: NaturalResource[];
  allowedBuildings: ImprovementConstructionRules[];
  gameRules: GameRules;
};

interface DispatchProps {
  closeRequestJobModal: () => void;
  requestJob: (request: PostRequestJobRequest) => void;
  updateRequestJobForm: (request: PostRequestJobRequest) => void;
}

interface OwnProps {

}

export type NewJobModalProps = DispatchProps & StateProps & OwnProps;

const NewJobModal = (props: NewJobModalProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    if (props.request.jobType === 0 && (!props.request.size || props.request.size === 0)) {
      let rule = props.allowedBuildings.filter(x => x.type === props.request.improvementType)[0];

      if (rule && rule.maximumSize === rule.minimumSize) {
        props.updateRequestJobForm({
          ...props.request,
          size: rule.maximumSize
        })
      }
    }
  }, [props]);

  const GetJobTypeDropDownOptions = () => {
    return (
      <>
        <option key="Construct" value={0}>{t(`JobTypes.${"Construct"}`, { count: 1 })}</option>
        <option key="Harvest" value={1}>{t(`JobTypes.${"Harvest"}`, { count: 1 })}</option>
        <option key="Expand" value={2}>{t(`JobTypes.${"Expand"}`, { count: 1 })}</option>
      </>
    );
  }

  const GetHarvestDropDownOptions = () => {
    return (
      <>
        {props.availableResources.map(x =>
          <option key={x.type} value={x.type}>{t(`NaturalResourceTypes.${x.typeString}`, { count: x.amount })}</option>)}
      </>
    );
  }

  const GetConstructDropDownOptions = () => {
    return (
      <>
        {props.allowedBuildings.map(x =>
            <option key={x.type} value={x.type}>{t(`ImprovementTypes.${x.typeString}`)}</option>)}
      </>
    );
  }

  const GetExpandImprovementsDropDownOptions = () => {
    return (
      <>
        {props.userKingdom.improvements
          .filter(x => x.plotId === props.request.plotId && x.isExpandable && !x.isExpanding)
          .map(x =>
            <option key={x.id} value={x.id}>{t(`ImprovementTypes.${x.typeString}`)} {t("UserKingdomPage.ImprovementsTab.Level")} {x.level}</option>)}
      </>
    );
  }

  const handleUpdateResourceAmountNumber = (newValue?: number) => {
    let max = Math.ceil(props.availableResources
      .filter(x => x.type === props.request.resourceToHarvest)[0].amount);

    if (newValue! > max) {
      newValue = max;
    }

    if (newValue! < 1) {
      newValue = 1;
    }

    if (newValue) {
      props.updateRequestJobForm({
        ...props.request,
        amount: newValue
      });
    } else {
      props.updateRequestJobForm({
        ...props.request,
        amount: 0
      });
    }
  }

  const getMaxSizeValue = () => {
    let plot = props.userKingdom.plots.filter(x => x.id === props.request.plotId)[0];
    let freespace = plot.size - props.userKingdom.improvements.filter(x => x.plotId === props.request.plotId).reduce((a,b) => a + b.size, 0);
    let rule = props.allowedBuildings.filter(x => x.type === props.request.improvementType)[0];

    if (!rule || rule.maximumSize === 0) {
      return freespace;
    } else {
      return rule.maximumSize;
    }
  }

  const getMinMaxString = () => {
    let maxSize = getMaxSizeValue();
    let rule = props.allowedBuildings.filter(x => x.type === props.request.improvementType)[0];

    if (rule.maximumSize === rule.minimumSize) {
      return `${t("CommonWords.Size")} ${rule.maximumSize}`;
    } else {
      return `${t("Components.NewJobModal.Min")} ${rule.minimumSize} - ${t("Components.NewJobModal.Size")} ${props.request.size} - ${t("Components.NewJobModal.Max")} ${maxSize}`;
    }
  }

  const handleUpdateSizeNumber = (newValue?: number) => {
    if (newValue) {
      props.updateRequestJobForm({
        ...props.request,
        size: newValue
      });
    } else {
      props.updateRequestJobForm({
        ...props.request,
        size: 0
      });
    }
  }

  const renderSaveButton = () => {
    return (
      <Row className="resource-table-sub-entry top-border">
        <Col className="sub-entry-margin-class morris-lg">
          <div className="darkStone-button" onClick={() => props.closeRequestJobModal()}>
            {t("Buttons.Cancel")}
          </div>
        </Col>
        <Col></Col>
        <Col className="sub-entry-margin-class morris-lg">
          {props.isRequestingJob && 
            <div className="darkStone-button">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            }
          {!props.isRequestingJob && 
          <div className="darkStone-button" onClick={() => props.requestJob(props.request)}>
            {t("Buttons.Save")}
          </div>
          }
        </Col>
      </Row>
    );
  }

  const manageFormWorkers = (type: number, amount: number, numberUnemployed: number) => {
    if (amount < 0) {
      amount = 0;
    }

    if (amount > numberUnemployed) {
      amount = numberUnemployed;
    }

    let workers = props.request.workers.filter(x => x.type !== type);

    workers.push({
      type: type,
      amount: amount
    });

    props.updateRequestJobForm({
      ...props.request,
      workers: workers
    })
  }

  const renderSelectWorkers = () => {
    return (
      <>
        <Row className="resource-table-sub-entry dark-wheat-background">
           <Col></Col>
           <Col>{t("CommonWords.Type")}</Col>
           <Col>{t("Components.NewJobModal.Assigned")}</Col>
           <Col></Col>
           <Col></Col>
        </Row>
        {props.userKingdom && props.userKingdom.groupedWorkers.filter((x: GroupedWorkers) => 
            x.numberUnemployed > 0
            && (((props.request.jobType === 0 || props.request.jobType === 2)
              && props.gameRules.improvementConstructionRules.filter(x => x.type === props.request.improvementType!).length > 0
              && props.gameRules.improvementConstructionRules.filter(x => x.type === props.request.improvementType!)[0].allowedWorkerTypes.includes(x.type))
            || (props.request.jobType === 1
              && props.gameRules.naturalResourceRules.filter(x => x.type === props.request.resourceToHarvest!).length > 0
              && props.gameRules.naturalResourceRules.filter(x => x.type === props.request.resourceToHarvest!)[0].allowedHarvestWorkerTypes.includes(x.type)))
            ).map((x) =>
          <>
          <Row key={x.type}>
            <Col><Image className="thumbnail-image" src={`/images/workers/${x.typeString}.png`}/></Col>
            <Col className="m-2">{t(`WorkerTypes.${x.typeString}`, { count: x.numberUnemployed })}</Col>
            <Col className="m-2">{ props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount }</Col>
            {!isMobile && 
            <Col>
              <Row>
                <Form.Range 
                  className="pt-4"
                  value={props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    manageFormWorkers(x.type, 
                      parseInt(e.target.value),
                      x.numberUnemployed)}}
                  min={0}
                  max={x.numberUnemployed}
                />
              </Row>
              <Row>
                <Col className="sub-entry-margin-class morris-lg m-2">
                  <div className="errorRed-button" onClick={() => 
                    manageFormWorkers(x.type, 
                      props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount - 1,
                      x.numberUnemployed)}>
                    -
                  </div>
                </Col>
                <Col className="sub-entry-margin-class morris-lg m-2">
                  <div className="appleGreen-button" onClick={() => 
                    manageFormWorkers(x.type, 
                      props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount + 1,
                      x.numberUnemployed)}>
                    +
                  </div>
                </Col>
              </Row>
            </Col> }
          </Row>
          {isMobile && 
          <>
          <Row>
            <Col>
             <Form.Range 
                className="pt-4"
                value={props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount} 
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  manageFormWorkers(x.type, 
                    parseInt(e.target.value),
                    x.numberUnemployed)}}
                min={0}
                max={x.numberUnemployed}
              />
            </Col>
          </Row>
          <Row>
            <Col className="sub-entry-margin-class morris-lg m-2">
              <div className="errorRed-button" onClick={() => 
                manageFormWorkers(x.type, 
                  props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount - 1,
                  x.numberUnemployed)}>
                -
              </div>
            </Col>
            <Col className="sub-entry-margin-class morris-lg m-2">
              <div className="appleGreen-button" onClick={() => 
                manageFormWorkers(x.type, 
                  props.request.workers.filter(z => x.type === z.type).length === 0 ? 0 : props.request.workers.filter(z => z.type === x.type)[0].amount + 1,
                  x.numberUnemployed)}>
                +
              </div>
            </Col>
          </Row>
          </>}
          </>)}
      </>
    );
  }

  const getResourceAmount = (amount: number, size: number | undefined) => {
    if (!!size) {
      return amount * size;
    } else {
      return 0;
    }
  }

  const renderTimeToHarvest = () => {
    let workerHoursToHarvest = props.gameRules.naturalResourceRules.filter(x => x.type === props.request.resourceToHarvest)[0].timeToHarvest;
    let workers = props.request.workers.reduce((a,b) => a + b.amount, 0);

    return (
      <>
        <Row className="resource-table-sub-entry dark-wheat-background">
            <Col className="sub-entry-margin-class">{ workers === 0  || !props.request.amount ? 
              t("Components.NewJobModal.Never") :
              t("Components.NewJobModal.TimeToHarvest", { count: Math.round(workerHoursToHarvest * props.request.amount / workers) })
            }</Col>
        </Row>
      </>
    );
  }

  const renderTimeToConstruct = (level: number) => {

    let workerHoursPerSize = props.gameRules.improvementConstructionRules.filter(x => x.type === props.request.improvementType)[0].workerHoursPerSize  * Math.pow(2, level);
    let workers = props.request.workers.reduce((a,b) => a + b.amount, 0);

    return (
      <>
        <Row className="resource-table-sub-entry dark-wheat-background">
            <Col className="sub-entry-margin-class">{ workers === 0 || !props.request.size ? 
              t("Components.NewJobModal.Never") :
              t("Components.NewJobModal.TimeToConstruct", { count: Math.round(workerHoursPerSize * props.request.size / workers) })
            }</Col>
        </Row>
      </>
    );
  }

  const renderConstructCost = (level: number) => {
    let rule = props.gameRules.improvementConstructionRules.filter(x => x.type === props.request.improvementType)[0];

    if (rule && rule.costs && rule.costs.length > 0) {
      return (
        <>
          <Row className="resource-table-sub-entry dark-wheat-background">
              <Col className="sub-entry-margin-class">{t("Components.NewJobModal.Cost")}</Col>
              <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Resource")}</Col>
              <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Amount")}</Col>
          </Row>
          {rule.costs.map((x) => {
            let resourceAmt = getResourceAmount(x.amount, props.request.size) * Math.pow(2, level);
            let ownedResourceAmt = props.userKingdom.resources.filter(z => z.type === x.type)[0] ? props.userKingdom.resources.filter(z => z.type === x.type)[0].amount : 0;

            return (
            <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"} ${resourceAmt > ownedResourceAmt ?  "errorRed-light-background" : ""}`}>
              <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
              <Col className="sub-entry-margin-class">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
              <Col className="sub-entry-margin-class">{ resourceAmt.toFixed(2)}</Col>
            </Row>);
            })}
        </>
      );
    }
  }
  
  const renderConstructForm = () => {
    if (props.allowedBuildings.length === 0) {
      return (
        <>
          <Row className="resource-table-sub-entry morris-lg p-2">
            <Col>{t("Components.NewJobModal.PlotFilled")}</Col>
          </Row>
        </>
      );
    }

    if (props.userKingdom.improvements.filter(x => x.plotId === props.request.plotId).reduce((a,b) => a + b.size, 0) === props.userKingdom.plots.filter(x => x.id === props.request.plotId)[0].size) {
      return (
        <>
          <Row className="resource-table-sub-entry morris-lg p-2">
            <Col>{t("Components.NewJobModal.PlotFilled")}</Col>
          </Row>
        </>
      );
    }

    let rule = props.allowedBuildings.filter(x => x.type === props.request.improvementType)[0];
    let maxSize = getMaxSizeValue();

    return (
      <>
        <Row>
          <Col className="sub-entry-margin-class login-form">
            <Row>
              <Form.Label>{getMinMaxString()}</Form.Label>
              {rule.maximumSize !== rule.minimumSize && <Form.Range 
                value={props.request.size || 0} 
                onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                  handleUpdateSizeNumber(parseInt(changeEvent.currentTarget.value))}}
                min={rule.minimumSize}
                max={maxSize}
                />}
            </Row>
            {rule.maximumSize !== rule.minimumSize && <Row>
              <Col className="sub-entry-margin-class morris-lg m-2">
                <div className="errorRed-button" onClick={() => 
                  handleUpdateSizeNumber((props.request.size || 1) - 1)}>
                  -
                </div>
              </Col>
              <Col className="sub-entry-margin-class morris-lg m-2">
                <div className="appleGreen-button" onClick={() => 
                  handleUpdateSizeNumber((props.request.size || 1) + 1)}>
                  +
                </div>
              </Col>
            </Row>}
          </Col>
          <Col>
            <Form.Select className="drop-down-style wheat_dark" value={props.request.improvementType} onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateRequestJobForm({
              ...props.request,
              improvementType: parseInt(changeEvent.target.value),
              size: props.allowedBuildings.filter(x => x.type === parseInt(changeEvent.target.value))[0].minimumSize})}>
              {GetConstructDropDownOptions()}
            </Form.Select>
          </Col>
        </Row>
        {renderTimeToConstruct(0)}
        {renderConstructCost(0)}
        {renderSelectWorkers()}
        {renderSaveButton()}
      </>
    );
  }

  const renderHarvestForm = () => {
    if (!props.availableResources || props.availableResources.length === 0) {
      return (
        <>
          <Row className="resource-table-sub-entry morris-lg p-2">
            <Col>{t("Components.NewJobModal.NoResourcesToHarvest")}</Col>
          </Row>
        </>
      );
    }

    let maxResource = Math.ceil(props.availableResources.filter(x => x.type === props.request.resourceToHarvest)[0].amount);
    return (
      <>
        <Row>
          <Col className="sub-entry-margin-class login-form">
            <Row>
              <Form.Label>{`${props.request.amount} ${t("Components.NewJobModal.OutOf")} ${ formatNumberNoDecimal(maxResource)}`}</Form.Label>
              <Form.Range 
                value={props.request.amount || maxResource} 
                onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                  handleUpdateResourceAmountNumber(parseInt(changeEvent.currentTarget.value))}}
                min={1}
                max={maxResource}
                />
            </Row>
            <Row>
              <Col className="sub-entry-margin-class morris-lg m-2">
                <div className="errorRed-button" onClick={() => 
                  handleUpdateResourceAmountNumber((props.request.amount || maxResource) - 1)}>
                  -
                </div>
              </Col>
              <Col className="sub-entry-margin-class morris-lg m-2">
                <div className="appleGreen-button" onClick={() => 
                  handleUpdateResourceAmountNumber((props.request.amount || maxResource) + 1)}>
                  +
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Form.Select className="drop-down-style wheat_dark" value={props.request.resourceToHarvest} onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => props.updateRequestJobForm({
              ...props.request,
              resourceToHarvest: parseInt(changeEvent.target.value),
              amount: Math.ceil(props.availableResources.filter(x => x.type === parseInt(changeEvent.target.value))[0].amount)})}>
              {GetHarvestDropDownOptions()}
            </Form.Select>
          </Col>
        </Row>
        {renderTimeToHarvest()}
        {renderSelectWorkers()}
        {renderSaveButton()}
      </>
    );
  }

  const renderExpandForm = () => {
    if (props.request.improvementId === '') {
      return (
        <>
          <Row className="resource-table-sub-entry morris-lg p-2">
            <Col>{t("Components.NewJobModal.NoExpandableBuildings")}</Col>
          </Row>
        </>
      );
    }

    let improvement = props.userKingdom.improvements.filter(x => x.id === props.request.improvementId)[0];

    return (
      <>
        <Row>
          <Col>
            <Form.Select className="drop-down-style wheat_dark" value={props.request.improvementId} onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
              let newImprovement = props.userKingdom.improvements.filter(x => x.id === changeEvent.target.value)[0];
              props.updateRequestJobForm({
              ...props.request,
              improvementId: newImprovement.id,
              improvementType: newImprovement.type,
              size: newImprovement.size});
            }}>
              {GetExpandImprovementsDropDownOptions()}
            </Form.Select>
          </Col>
        </Row>
        {renderTimeToConstruct(improvement.level + 1)}
        {renderConstructCost(improvement.level + 1)}
        {renderSelectWorkers()}
        {renderSaveButton()}
      </>
    );
  }

  const renderSpecificJobType = () => {
    if (props.request.jobType === 0) {
      return renderConstructForm();
    } else if (props.request.jobType === 1) {
      return renderHarvestForm();
    } else if (props.request.jobType === 2) {
      return renderExpandForm();
    }
  }

  const renderRedTextBox = () => {
    if (!props.requestJobError || props.requestJobError === '') {
      return <></>;
    }
    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t(`Exceptions.${props.requestJobError}`)}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const renderNotEnoughSpaceInStockpile = () => {
    if (!props.request || props.request.jobType !== 1 || !props.request.resourceToHarvest || !props.request.amount) {
      return <></>;
    }

    let firstResourceHarvest = props.gameRules.naturalResourceRules.filter(x => x.type === props.request.resourceToHarvest)[0].resourceTypesHarvestsTo[0];

    let storageType = props.gameRules.resourceRules.filter(x => x.type === firstResourceHarvest)[0].storageType;
    let spaceInStorage = props.userKingdom.resourceStorageLimits.filter(x => x.type === storageType)[0].amount - props.userKingdom.resources.filter(x => x.storageType === storageType).reduce((a, b) => a + b.amount, 0);

    if (spaceInStorage > props.request.amount) {
      return <></>;
    }

    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t("Components.NewJobModal.NotEnoughSpaceInStorage")}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const onUpdateJobType = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    let newJobType = parseInt(changeEvent.target.value);

    if (newJobType === 0) {
      props.updateRequestJobForm({
        ...props.request,
        jobType: newJobType,
        improvementType: props.allowedBuildings[0].type});
    } else if (newJobType === 1) {
      props.updateRequestJobForm({
        ...props.request,
        jobType: newJobType,
        amount: props.availableResources && props.availableResources.length > 0 ? Math.ceil(props.availableResources[0].amount) : 0,
        resourceToHarvest: props.availableResources && props.availableResources.length > 0 ? props.availableResources[0].type : 0});
    } else if (newJobType === 2) {
      let firstImprovements = props.userKingdom.improvements
        .filter(x => x.plotId === props.request.plotId && x.isExpandable && !x.isExpanding);

        props.updateRequestJobForm({
          ...props.request,
          jobType: newJobType,
          improvementId: firstImprovements.length > 0 ? firstImprovements[0].id : '',
          improvementType: firstImprovements.length > 0 ? firstImprovements[0].type : 0,
          size: firstImprovements.length > 0 ? firstImprovements[0].size : 0
      });
    }
  }

  const renderReloadModal = () => {
    return (
      <Modal show={props.showNewJobModal} onHide={props.closeRequestJobModal} animation={false} centered size="lg">
        <ModalTitle className="primary-header">{t("Components.NewJobModal.Title")}</ModalTitle>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <ModalBody className="standard-text">
          <Row>
            <Col></Col>
            <Col>
              <Form.Select className="drop-down-style wheat_dark" value={props.request.jobType} onChange={onUpdateJobType} >
                  {GetJobTypeDropDownOptions()}
              </Form.Select>
            </Col>
            <Col></Col>
          </Row>
          {renderRedTextBox()}
          {renderNotEnoughSpaceInStockpile()}
          {renderSpecificJobType()}
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showNewJobModal && renderReloadModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closeRequestJobModal: () => dispatch(closeRequestJobModal()),
    requestJob: (request: PostRequestJobRequest) => dispatch(requestJob(request)),
    updateRequestJobForm: (request: PostRequestJobRequest) => dispatch(updateRequestJobForm(request))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    userKingdom: state.UserKingdomState.userKingdom,
    request: state.JobRequestState.request,
    showNewJobModal: state.JobRequestState.isModalOpen,
    isRequestingJob: state.JobRequestState.isRequestingJob,
    requestJobError: state.JobRequestState.requestJobError,
    availableResources: state.JobRequestState.availableResources,
    allowedBuildings: state.JobRequestState.allowedBuildings,
    gameRules: state.RulesState.gameRules!,
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(NewJobModal);