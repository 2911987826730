import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

export interface IAjaxClient {
  get<T>(request: AjaxRequestBase): Observable<AjaxResponse<T>>;
  post<T>(request: AjaxRequestWithBody): Observable<AjaxResponse<T>>;
  put<T>(request: AjaxRequestWithBody): Observable<AjaxResponse<T>>;
  patch<T>(request: AjaxRequestWithBody): Observable<AjaxResponse<T>>;
  get<T>(request: AjaxRequestBase): Observable<AjaxResponse<T>>;
}

export interface AjaxRequestBase {
  url: string,
  headers:  Record<string, string>
}

export interface AjaxRequestWithBody extends AjaxRequestBase {
  body?: any
}

export class AjaxClient implements IAjaxClient {

  private createRequest = (method: string, url: string, body: any, headers: Record<string, string>): any => ({
    method,
    url,
    body,
    headers,
    crossDomain: true
  });

  private createAjax = <T>(method: string) => (url: string, headers: Record<string, string>) => {
    return ajax<T>(this.createRequest(method, url, null, headers));
  };
  
  private createAjaxWithBody = <T>(method: string) => (url: string, body: any, headers: Record<string, string>) => {
    return ajax<T>(this.createRequest(method, url, JSON.stringify(body), headers));
  };

  get<T>(request: AjaxRequestBase): Observable<AjaxResponse<T>> {
    return this.createAjax<T>('GET')(request.url, request.headers)
  }

  post<T>(request: AjaxRequestWithBody): Observable<AjaxResponse<T>> {
    return this.createAjaxWithBody<T>('POST')(request.url, request.body, request.headers)
  }

  put<T>(request: AjaxRequestWithBody): Observable<AjaxResponse<T>> {
    return this.createAjaxWithBody<T>('PUT')(request.url, request.body, request.headers)
  }

  patch<T>(request: AjaxRequestWithBody): Observable<AjaxResponse<T>> {
    return this.createAjaxWithBody<T>('PATCH')(request.url, request.body, request.headers)
  }

  delete<T>(request: AjaxRequestBase): Observable<AjaxResponse<T>> {
    return this.createAjax<T>('DELETE')(request.url, request.headers)
  }

  getJSON(url: string, headers: Record<string, string>) {
    ajax({
      url,
      method: 'GET',
      responseType: 'json',
      crossDomain: true,
      headers,
    }).pipe(
      map((response: any) => response.response)
    )
  }
}