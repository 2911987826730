import GetLandsSearchResponse from '../../common/types/messages/GetLandsSearchResponse';
import GetSearchedUserKingdomDetailResponse from '../../common/types/messages/GetSearchedUserKingdomDetailResponse';
import GetSearchPlotsResponse from '../../common/types/messages/GetSearchPlotsResponse';
import Action from './baseAction';

export enum LandsSearchActionTypes {
  GetKingdomLands = "GET_KINGDOM_LANDS",
  GetKingdomLandsCompleted = "GET_KINGDOM_LANDS_COMPLETED",
  GetKingdomLandsFailed = "GET_KINGDOM_LANDS_FAILED",
  GetSearchPlots = "GET_SEARCH_PLOTS",
  GetSearchPlotsCompleted = "GET_SEARCH_PLOTS_COMPLETED",
  GetSearchPlotsFailed = "GET_SEARCH_PLOTS_FAILED",
  UpdateSearchPlotsForm = "UPDATE_SEARCH_PLOTS_FORM",
  GetUserKingdomDetails = "GET_USER_KINGDOM_DETAILS",
  GetUserKingdomDetailsCompleted = "GET_USER_KINGDOM_DETAILS_COMPLETED",
  GetUserKingdomDetailsFailed = "GET_USER_KINGDOM_DETAILS_FAILED",
  CloseExpandedDetails = "CLOSE_EXPANDED_DETAILS",
  UpdateUserSearchFilters = "UPDATE_USER_SEARCH_FILTERS"
}

export type GetKingdomLandsPayloadType = { kingdomId: number };
export type GetKingdomLandsActionType = Action<LandsSearchActionTypes.GetKingdomLands, GetKingdomLandsPayloadType>;
export const getKingdomLands = (kingdomId: number):GetKingdomLandsActionType => ({type: LandsSearchActionTypes.GetKingdomLands, payload: { kingdomId } });

export type GetKingdomLandsCompletedPayloadType = { response: GetLandsSearchResponse; };
export type GetKingdomLandsCompletedActionType = Action<LandsSearchActionTypes.GetKingdomLandsCompleted, GetKingdomLandsCompletedPayloadType>;
export const getKingdomLandsCompleted = (response: GetLandsSearchResponse): GetKingdomLandsCompletedActionType => ({type: LandsSearchActionTypes.GetKingdomLandsCompleted, payload: { response } });

export type GetKingdomLandsFailedPayloadType = { error: Error; };
export type GetKingdomLandsFailedActionType = Action<LandsSearchActionTypes.GetKingdomLandsFailed, GetKingdomLandsFailedPayloadType>;
export const getKingdomLandsFailed = (error: Error): GetKingdomLandsFailedActionType => ({type: LandsSearchActionTypes.GetKingdomLandsFailed, payload: { error } });

export type GetSearchPlotsPayloadType = { kingdomId: number, address: string };
export type GetSearchPlotsActionType = Action<LandsSearchActionTypes.GetSearchPlots, GetSearchPlotsPayloadType>;
export const getSearchPlots = (kingdomId: number, address: string ):GetSearchPlotsActionType => ({type: LandsSearchActionTypes.GetSearchPlots, payload: { kingdomId, address } });

export type GetSearchPlotsCompletedPayloadType = { response: GetSearchPlotsResponse; };
export type GetSearchPlotsCompletedActionType = Action<LandsSearchActionTypes.GetSearchPlotsCompleted, GetSearchPlotsCompletedPayloadType>;
export const getSearchPlotsCompleted = (response: GetSearchPlotsResponse): GetSearchPlotsCompletedActionType => ({type: LandsSearchActionTypes.GetSearchPlotsCompleted, payload: { response } });

export type GetSearchPlotsFailedPayloadType = { error: Error; };
export type GetSearchPlotsFailedActionType = Action<LandsSearchActionTypes.GetSearchPlotsFailed, GetSearchPlotsFailedPayloadType>;
export const getSearchPlotsFailed = (error: Error): GetSearchPlotsFailedActionType => ({type: LandsSearchActionTypes.GetSearchPlotsFailed, payload: { error } });

export type UpdateSearchPlotsFormPayloadType = { kingdomId: number, address: string };
export type UpdateSearchPlotsFormActionType = Action<LandsSearchActionTypes.UpdateSearchPlotsForm, UpdateSearchPlotsFormPayloadType>;
export const updateSearchPlotsForm = (kingdomId: number, address: string ):UpdateSearchPlotsFormActionType => ({type: LandsSearchActionTypes.UpdateSearchPlotsForm, payload: { kingdomId, address } });

export type GetUserKingdomDetailsPayloadType = { userKingdomId: string };
export type GetUserKingdomDetailsActionType = Action<LandsSearchActionTypes.GetUserKingdomDetails, GetUserKingdomDetailsPayloadType>;
export const getUserKingdomDetails = (userKingdomId: string):GetUserKingdomDetailsActionType => ({type: LandsSearchActionTypes.GetUserKingdomDetails, payload: { userKingdomId } });

export type GetUserKingdomDetailsCompletedPayloadType = { response: GetSearchedUserKingdomDetailResponse; };
export type GetUserKingdomDetailsCompletedActionType = Action<LandsSearchActionTypes.GetUserKingdomDetailsCompleted, GetUserKingdomDetailsCompletedPayloadType>;
export const getUserKingdomDetailsCompleted = (response: GetSearchedUserKingdomDetailResponse): GetUserKingdomDetailsCompletedActionType => ({type: LandsSearchActionTypes.GetUserKingdomDetailsCompleted, payload: { response } });

export type GetUserKingdomDetailsFailedPayloadType = { error: Error; };
export type GetUserKingdomDetailsFailedActionType = Action<LandsSearchActionTypes.GetUserKingdomDetailsFailed, GetUserKingdomDetailsFailedPayloadType>;
export const getUserKingdomDetailsFailed = (error: Error): GetUserKingdomDetailsFailedActionType => ({type: LandsSearchActionTypes.GetUserKingdomDetailsFailed, payload: { error } });

export type CloseExpandedDetailsPayloadType = { };
export type CloseExpandedDetailsActionType = Action<LandsSearchActionTypes.CloseExpandedDetails, CloseExpandedDetailsPayloadType>;
export const closeExpandedDetails = ():CloseExpandedDetailsActionType => ({type: LandsSearchActionTypes.CloseExpandedDetails, payload: { } });

export type UpdateUserSearchFiltersPayloadType = { username: string, orderBy: string, ascending: boolean };
export type UpdateUserSearchFiltersActionType = Action<LandsSearchActionTypes.UpdateUserSearchFilters, UpdateUserSearchFiltersPayloadType>;
export const updateUserSearchFilters = (username: string, orderBy: string, ascending: boolean):UpdateUserSearchFiltersActionType => ({type: LandsSearchActionTypes.UpdateUserSearchFilters, payload: { username, orderBy, ascending} });
