import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Footer } from '../../components/footerComponent/footer';
import Header from '../../components/headerComponent/header';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';

interface StateProps {

};

interface DispatchProps {

}

export type GuideViewProps = DispatchProps & StateProps;

export const GuideView = (props: GuideViewProps) => {
  const { t } = useTranslation();

  const [expandedEntry, setExpandedEntry] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleExpandedEntry = (entryName: string) => {
    if (entryName === expandedEntry) {
      setExpandedEntry("");
    } else {
      setExpandedEntry(entryName);
    }
  }



  const renderConnectUpland = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <Row>
            <Col>
              <p className="pt-2 standard-text left">
                {t("GuidePage.ConnectUpland.ParagraphOne")}
              </p>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 1 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepOne")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/1.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 2 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepTwo")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/2.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 3 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepThree")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/3.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 4 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepFour")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/4.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 5 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepFive")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/5.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 6 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepSix")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/6.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 7 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepSeven")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/7.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 8 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepEight")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/8.png"}/></Col></Row>
            </Col>
          </Row>
          <Row className="py-2 standard-text left">
            <Col xs={2} className="morris-lg">{t("GuidePage.ConnectUpland.Step", { count: 9 })}</Col>
            <Col>
              <Row><Col>{t("GuidePage.ConnectUpland.StepNine")}</Col></Row>
              <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/connectUpland/9.png"}/></Col></Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  const renderOverview = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Overview.ParagraphOne")}
          </p>
          <div className="tertiary-header">{t("GuidePage.Overview.GameRules")}</div>
          {renderConceptEntry(t("GuidePage.Overview.Time"), t("GuidePage.Overview.TimeParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Kingdoms"), t("GuidePage.Overview.KingdomsParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Lands"), t("GuidePage.Overview.LandsParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.NaturalResources"), t("GuidePage.Overview.NaturalResourcesParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Improvements"), t("GuidePage.Overview.ImprovementsParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Workers"), t("GuidePage.Overview.WorkersParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Housing"), t("GuidePage.Overview.HousingParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Upkeep"), t("GuidePage.Overview.UpkeepParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Combat"), t("GuidePage.Overview.CombatParagraph"))}
          {renderConceptEntry(t("GuidePage.Overview.Vassals"), t("GuidePage.Overview.VassalsParagraph"))}
        </Col>
      </Row>
    );
  }

  const renderConceptEntry = (title: string, paragraph: string) => {
    return (
      <Row className="sub-entry-top-border p-1">
        <Col xs={4}>
          <Row><Col className="tertiary-header centered">{title}</Col></Row>
        </Col>
        <Col xs={8}>
          <p className="pt-2 standard-text left">
            {paragraph}
          </p>
        </Col>
      </Row>
    );
  }

  const renderSubEntry = (imagePath: string, title: string, paragraph: string) => {
    if (isMobile) {
      return (
        <Row className="sub-entry-top-border p-1">
          <Col xs={4}>
            <Row><Col className="center-image"><Image className="thumbnail-image-guide" src={imagePath}/></Col></Row>
            <Row><Col className="tertiary-header">{title}</Col></Row>
          </Col>
          <Col xs={8}>
            <p className="pt-2 standard-text left">
              {paragraph}
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <Row className="sub-entry-top-border p-1">
          <Col xs={2}>
            <Row><Col className="center-image"><Image className="thumbnail-image-guide" src={imagePath}/></Col></Row>
          </Col>
          <Col xs={2}>
            <Row><Col className="tertiary-header centered">{title}</Col></Row>
          </Col>
          <Col xs={8}>
            <p className="pt-2 standard-text left">
              {paragraph}
            </p>
          </Col>
        </Row>
    );
  }

  const renderWorkerTypeEntry = (workerType: string) => {
    return renderSubEntry(`/images/workers/${workerType}.png`, t(`WorkerTypes.${workerType}`, { count: 2 }),  t(`GuidePage.Workers.${workerType}`));
  }

  const renderWorkers = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Workers.ParagraphOne")}
          </p>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Workers.ParagraphTwo")}
          </p>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Workers.ParagraphThree")}
          </p>
          <div className="tertiary-header">{t("GuidePage.Workers.WorkerTypes")}</div>
          {renderWorkerTypeEntry("Peasant")}
          {renderWorkerTypeEntry("Farmer")} 
          {renderWorkerTypeEntry("Miner")}
          {renderWorkerTypeEntry("Hunter")}
          {renderWorkerTypeEntry("Cook")}
          {renderWorkerTypeEntry("Brewer")}
          {renderWorkerTypeEntry("Woodworker")}
          {renderWorkerTypeEntry("Blacksmith")}    
          {renderWorkerTypeEntry("Jeweler")}    
        </Col>
      </Row>
    );
  }

  const renderImprovementTypeEntry = (improvementType: string) => {
    return renderSubEntry(`/images/improvements/${improvementType}.png`, t(`ImprovementTypes.${improvementType}`),  t(`GuidePage.Improvements.${improvementType}`));
  }

  const renderImprovements = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Improvements.ParagraphOne")}
          </p>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Improvements.ParagraphTwo")}
          </p>
          <div className="tertiary-header">{t("GuidePage.Improvements.ImprovementTypes")}</div>
          {renderImprovementTypeEntry("Farm")}
          {renderImprovementTypeEntry("Mine")}
          {renderImprovementTypeEntry("Quarry")}
          {renderImprovementTypeEntry("Armory")}
          {renderImprovementTypeEntry("Granary")}
          {renderImprovementTypeEntry("Stockpile")}
          {renderImprovementTypeEntry("Smelter")}
          {renderImprovementTypeEntry("Blacksmith")}
          {renderImprovementTypeEntry("Bowyer")}
          {renderImprovementTypeEntry("CookPot")}
          {renderImprovementTypeEntry("Kitchen")}
          {renderImprovementTypeEntry("Windmill")}
          {renderImprovementTypeEntry("Brewery")}
          {renderImprovementTypeEntry("ChoppingBlock")}
          {renderImprovementTypeEntry("Sawmill")}
          {renderImprovementTypeEntry("PeasantAcademy")}
          {renderImprovementTypeEntry("TatteredBook")}
          {renderImprovementTypeEntry("TradeSchool")}
          {renderImprovementTypeEntry("SparringGrounds")}
          {renderImprovementTypeEntry("Barracks")}
          {renderImprovementTypeEntry("JewelersGuild")}
          {renderImprovementTypeEntry("HuntersCabin")}
          {renderImprovementTypeEntry("ForestersLodge")}
          {renderImprovementTypeEntry("GatherersHut")}
        </Col>
      </Row>
    );
  }

  const renderLandTypeEntry = (landType: string) => {
    return renderSubEntry(`/images/terrain/${landType}.png`, t(`LandTypes.${landType}`),  t(`GuidePage.Lands.${landType}`));
  }

  const renderLands = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Lands.ParagraphOne")}
          </p>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Lands.ParagraphTwo")}
          </p>
          <div className="tertiary-header">{t("GuidePage.Lands.ImprovementTypes")}</div>
          {renderLandTypeEntry("IceCappedMountain")}
          {renderLandTypeEntry("Mountain")}
          {renderLandTypeEntry("Hills")}
          {renderLandTypeEntry("Steppe")}
          {renderLandTypeEntry("Forest")}
          {renderLandTypeEntry("Plains")}
          {renderLandTypeEntry("Scrubland")}
          {renderLandTypeEntry("Desert")}
          {renderLandTypeEntry("Bog")}
        </Col>
      </Row>
    );
  }

  const renderCombatTypeEntry = (workerType: string) => {
    return renderSubEntry(`/images/workers/${workerType}.png`, t(`WorkerTypes.${workerType}`, { count: 2 }),  t(`GuidePage.Combat.${workerType}`));
  }

  const renderCombat = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Combat.ParagraphOne")}
          </p>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Combat.ParagraphTwo")}
          </p>
          <div className="tertiary-header">{t("GuidePage.Combat.BattleTypes")}</div>
          <Row>
            <Col xs={2} className="pt-2 morris-lg left">{t("BattleGoals.Vassalize")}</Col>
            <Col xs={isMobile ? 12 : 10} className="pt-2 standard-text left">
              <Row className="pt-2">
                <Col xs={2}>{t("GuidePage.Combat.Goal")}</Col>
                <Col>{t("GuidePage.Combat.VassalizeDescription")}</Col>
              </Row>
              <Row className="pt-2 guide-entry top-border">
                <Col xs={2}>{t("GuidePage.Combat.AttackersWin")}</Col>
                <Col>{t("GuidePage.Combat.VassalizeAttackersWin")}</Col>
              </Row>
              <Row className="pt-2 guide-entry top-border">
                <Col xs={2}>{t("GuidePage.Combat.DefendersWin")}</Col>
                <Col>{t("GuidePage.Combat.VassalizeDefendersWin")}</Col>
              </Row>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={2} className="pt-2 morris-lg left ">{t("BattleGoals.Liberate")}</Col>
            <Col xs={isMobile ? 12 : 10} className="pt-2 standard-text left">
              <Row className="pt-2">
                <Col xs={2}>{t("GuidePage.Combat.Goal")}</Col>
                <Col>{t("GuidePage.Combat.LiberationDescription")}</Col>
              </Row>
              <Row className="pt-2 guide-entry top-border">
                <Col xs={2}>{t("GuidePage.Combat.AttackersWin")}</Col>
                <Col>{t("GuidePage.Combat.LiberationAttackersWin")}</Col>
              </Row>
              <Row className="pt-2 guide-entry top-border">
                <Col xs={2}>{t("GuidePage.Combat.DefendersWin")}</Col>
                <Col>{t("GuidePage.Combat.LiberationDefendersWin")}</Col>
              </Row>
            </Col>
          </Row>
          <div className="tertiary-header">{t("GuidePage.Combat.UnitTypes")}</div>
          {renderCombatTypeEntry("Peasant")}
          {renderCombatTypeEntry("Skirmisher")}
          {renderCombatTypeEntry("Swordsmen")}
          {renderCombatTypeEntry("Spearmen")}
          {renderCombatTypeEntry("Pikemen")}
          {renderCombatTypeEntry("LightCavalry")}
          {renderCombatTypeEntry("Knight")}
          {renderCombatTypeEntry("LightArcher")}
          {renderCombatTypeEntry("Archer")}
        </Col>
      </Row>
    );
  }

  const renderFarmingTypeEntry = (cropType: string) => {
    return renderSubEntry(`/images/resources/${cropType}.png`, t(`Crops.${cropType}`, { count: 2 }),  t(`GuidePage.Farming.${cropType}`));
  }

  const getDayAndMonthString = (day: number) => {
    let season = "Spring";
    if (day >= 24 && day < 48) {
      season = "Summer";
    } else if (day >= 48 && day < 72) {
      season = "Fall";
    } else if (day >= 72 && day < 96) {
      season = "Winter";
    }

    return `${t("GameDate.Day", { count: (day % 24) + 1 , ordinal: true })} ${t(`GameDate.${season}_NoYear`)}`;
  }
  
  const renderFarming = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Farming.ParagraphOne")}
          </p>
          <Row className="resource-table-sub-entry bottom-border">
            <Col className = "standard-text">
              <Row className="resource-table-sub-entry dark-wheat-background"><Col>{t("GuidePage.Farming.CropTypes")}</Col><Col>{t("GuidePage.Farming.Sow")}</Col><Col>{t("GuidePage.Farming.Harvest")}</Col><Col>{t("GuidePage.Farming.Clear")}</Col></Row>
              <Row><Col>{t("Crops.Beet", { count: 2})}</Col><Col>{getDayAndMonthString(17)}</Col><Col>{getDayAndMonthString(53)}</Col><Col>{getDayAndMonthString(72)}</Col></Row>
              <Row><Col>{t("Crops.Carrot", { count: 2})}</Col><Col>{getDayAndMonthString(5)}</Col><Col>{getDayAndMonthString(31)}</Col><Col>{getDayAndMonthString(39)}</Col></Row>
              <Row><Col>{t("Crops.Carrot", { count: 2})}</Col><Col>{getDayAndMonthString(40)}</Col><Col>{getDayAndMonthString(65)}</Col><Col>{getDayAndMonthString(72)}</Col></Row>
              <Row><Col>{t("Crops.Grape", { count: 2})}</Col><Col>{getDayAndMonthString(11)}</Col><Col>{getDayAndMonthString(48)}</Col><Col>{getDayAndMonthString(60)}</Col></Row>
              <Row><Col>{t("Crops.Hop", { count: 2})}</Col><Col>{getDayAndMonthString(11)}</Col><Col>{getDayAndMonthString(59)}</Col><Col>{getDayAndMonthString(72)}</Col></Row>
              <Row><Col>{t("Crops.Potato", { count: 2})}</Col><Col>{getDayAndMonthString(11)}</Col><Col>{getDayAndMonthString(59)}</Col><Col>{getDayAndMonthString(72)}</Col></Row>
              <Row><Col>{t("Crops.Wheat", { count: 2})}</Col><Col>{getDayAndMonthString(11)}</Col><Col>{getDayAndMonthString(53)}</Col><Col>{getDayAndMonthString(58)}</Col></Row>
              <Row><Col>{t("Crops.Wheat", { count: 2})}</Col><Col>{getDayAndMonthString(59)}</Col><Col>{getDayAndMonthString(5)}</Col><Col>{getDayAndMonthString(10)}</Col></Row>
            </Col>
          </Row>
          <div className="tertiary-header">{t("GuidePage.Farming.CropTypes")}</div>
          {renderFarmingTypeEntry("Beet")}
          {renderFarmingTypeEntry("Carrot")}
          {renderFarmingTypeEntry("Grape")}
          {renderFarmingTypeEntry("Hop")}
          {renderFarmingTypeEntry("Potato")}
          {renderFarmingTypeEntry("Wheat")}
        </Col>
      </Row>
    );
  }

  const renderQuickStart = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.QuickStart.ParagraphOne")}
          </p>
          <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/quickstart/1.png"}/></Col></Row>
          <p className="pt-2 standard-text left">
            {t("GuidePage.QuickStart.ParagraphTwo")}
          </p>
          <p className="pt-2 standard-text left">
            {t("GuidePage.QuickStart.ParagraphThree")}
          </p>
          <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/quickstart/2.png"}/></Col></Row>
          <p className="pt-2 standard-text left">
            {t("GuidePage.QuickStart.ParagraphFour")}
          </p>
          <Row><Col className="center-image"><Image className="image-guide-quick-start" src={"/images/quickstart/3.png"}/></Col></Row>
          <p className="pt-2 standard-text left">
            {t("GuidePage.QuickStart.ParagraphFive")}
          </p>
        </Col>
      </Row>
    );
  }

  const renderDevShops = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.DevShops.ParagraphOne")}
          </p>
          <Row className="resource-table-sub-entry bottom-border">
            <Col className = "standard-text">
            <Row className="resource-table-sub-entry dark-wheat-background"><Col>{t("GuidePage.DevShops.City")}</Col><Col>{t("GuidePage.DevShops.Address")}</Col></Row>
              <Row><Col>{t("Kingdoms.42")}</Col><Col>1713 Florence St, Arlington, TX, USA</Col></Row>
              <Row><Col>{t("Kingdoms.40")}</Col><Col>2907 Maple Springs Blvd , Dallas, TX, USA</Col></Row>
              <Row><Col>{t("Kingdoms.14")}</Col><Col>129 NW North Shore Dr, Kansas City, KS, USA</Col></Row>
              <Row><Col>{t("Kingdoms.35")}</Col><Col>416 S 11th St, Las Vegas, NV, USA</Col></Row>
              <Row><Col>{t("Kingdoms.32")}</Col><Col>6840 Hollywood Blvd, Los Angeles, CA, USA</Col></Row>
              <Row><Col>{t("Kingdoms.4")}</Col><Col>16027 95TH ST, Queens, NY, USA</Col></Row>
              <Row><Col>{t("Kingdoms.36")}</Col><Col>Rua Domingos Segreto, 246A, Rio de Janeiro, Brazil</Col></Row>
              <Row><Col>{t("Kingdoms.59")}</Col><Col>9 WHITEHALL, London, UK</Col></Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  const renderTroubleshooting = () => {
    return (
      <Row className="guide-entry top-border">
        <Col>
          <p className="pt-2 standard-text left">
            {t("GuidePage.Troubleshooting.ParagraphOne")}
          </p>
          <Row className="resource-table-sub-entry">
            <Col className = "standard-text">
              <Row className="resource-table-sub-entry dark-wheat-background"><Col>{t("GuidePage.Troubleshooting.Problem")}</Col><Col>{t("GuidePage.Troubleshooting.Fix")}</Col></Row>
              <Row className="resource-table-sub-entry bottom-border"><Col>{t("GuidePage.Troubleshooting.PurchaseError")}</Col><Col>{t("GuidePage.Troubleshooting.PurchaseErrorFix")}</Col></Row>
              <Row className="resource-table-sub-entry bottom-border"><Col>{t("GuidePage.Troubleshooting.ReloadError")}</Col><Col>{t("GuidePage.Troubleshooting.ReloadErrorFix")}</Col></Row>
              <Row className="resource-table-sub-entry bottom-border"><Col>{t("GuidePage.Troubleshooting.LoadingArlington")}</Col><Col>{t("GuidePage.Troubleshooting.LoadingArlingtonFix")}</Col></Row>
              <Row className="resource-table-sub-entry bottom-border"><Col>{t("GuidePage.Troubleshooting.UPXPurchaseFailed")}</Col><Col>{t("GuidePage.Troubleshooting.UPXPurchaseFailedFix")}</Col></Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  const renderEntry = (entryTitle: string, entryRenderFunction: Function) => {
    return (
      <Row className="standard-text-box clickable p-1">
        <Col>
          <Row className="guide-entry clickable tertiary-header left p-1" onClick={() => toggleExpandedEntry(entryTitle)}>
            <Col>{t(`GuidePage.${entryTitle}.Title`)}</Col>
            <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={expandedEntry === entryTitle ? faAngleDown : faAngleRight}/></Col>
          </Row>
          {
            expandedEntry === entryTitle && entryRenderFunction()
          }
        </Col>
      </Row>
    );
  }

  const renderGuideView = () => {
    return (
      <Container fluid="md">
        <Row>
          <div className="primary-header">{t("GuidePage.Title")}</div>
        </Row>
        <br></br>
        {renderEntry("Overview", renderOverview)}
        {renderEntry("ConnectUpland", renderConnectUpland)}
        {renderEntry("QuickStart", renderQuickStart)}
        {renderEntry("Lands", renderLands)}
        {renderEntry("Improvements", renderImprovements)}
        {renderEntry("Workers", renderWorkers)}
        {renderEntry("Combat", renderCombat)}
        {renderEntry("Farming", renderFarming)}
        {renderEntry("DevShops", renderDevShops)}
        {renderEntry("Troubleshooting", renderTroubleshooting)}
      </Container>
    );
  }

  return (
      <>
        <div className="footer-padding">
          <Header />
          {renderGuideView()}
        </div>
        <Footer />
      </>
  );
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {

  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideView);