import React, { useContext } from 'react';
import { ApiService } from './api/api';

export interface AppContextValue {
  apiService: ApiService;
}

export const useAppContext = () => useContext(AppContext);

const AppContext = React.createContext<AppContextValue>({
  apiService: {} as ApiService
});

export default AppContext;