import { Reducer } from 'redux';
import { RulesState } from '../states/rulesState';
import {
  RulesActionTypes,
  GetGameRulesActionType,
  GetGameRulesCompletedActionType,
  GetGameRulesFailedActionType
} from '../actions/rulesActions';

export type RulesActions = 
  GetGameRulesActionType
  | GetGameRulesCompletedActionType
  | GetGameRulesFailedActionType;

const initialState: RulesState = {
  gameRules: undefined,
  isLoading: false
}

export const RulesReducer: Reducer<RulesState> = (state = initialState, action: RulesActions): RulesState => {
  switch(action.type) {
    case RulesActionTypes.GetGameRules:
      return {
        ...state,
        isLoading: true,
        gameRules: undefined,
      };
    case RulesActionTypes.GetGameRulesCompleted:
      return {
        ...state,
        isLoading: false,
        gameRules: action.payload!.response,
      };
    case RulesActionTypes.GetGameRulesFailed:
      return {
        ...state,
        isLoading: false,
        gameRules: undefined  
      };
    default:
      return state;
  }
}

export default RulesReducer;