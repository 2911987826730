import { Row, Col } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import DisplayCombat from './displayCombat';
import { Pager } from '../pagerComponent/pager';
import Battle from '../../common/types/userKingdom/battle';

interface StateProps {
  battles: Battle[],
  userKingdomState: UserKingdomState
};

interface DispatchProps {

}

interface OwnProps {

}

export type CombatPanelProps = DispatchProps & StateProps & OwnProps;

export const CombatPanel = (props: CombatPanelProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const { t } = useTranslation();

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const renderPager = () => {
    if (isMobile) {
      return (
        <Row className="pt-1">
          <Col>
            <Pager
              page={currentPage}
              pageSize={pageSize}
              totalEntries={props.battles ? props.battles.length : 0}
              pageUp={() => setCurrentPage(currentPage + 1)}
              pageDown={() => setCurrentPage(currentPage - 1)}
              useLightBackgroud={true} />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className="pt-1">
          <Col></Col>
          <Col></Col>
          <Col>
            <Pager
              page={currentPage}
              pageSize={pageSize}
              totalEntries={props.battles ? props.battles.length : 0}
              pageUp={() => setCurrentPage(currentPage + 1)}
              pageDown={() => setCurrentPage(currentPage - 1)}
              useLightBackgroud={true}
            />
          </Col>
        </Row>
      );
    }
  }

  const renderTabView = () => {
    if (!props.userKingdomState.isLoading && props.userKingdomState.userKingdom) {
      return (
        <>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col>{t("CombatPanel.Battle")}</Col>
            <Col>{t("CombatPanel.Soldiers")}</Col>
            <Col>{t("CommonWords.Status")}</Col>
            <Col>{t("CombatPanel.ResolvedOn")}</Col>
            <Col></Col>
          </Row>
          {props.battles
            .slice((currentPage - 1) * pageSize, (currentPage * pageSize))
            .map((x: Battle) => <DisplayCombat key={x.id} battle={x} userId={props.userKingdomState.userKingdom!.userId} userKingdomId={props.userKingdomState.userKingdom!.userKingdomId} />)}
          {renderPager()}
        </>
      );
    } else {
      return <></>;
    }
  }

  if (!props.battles || props.battles.length === 0) {
    return <></>;
  } else {
    return renderTabView();
  }
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    battles: state.UserKingdomState.userKingdom.battles,
    userKingdomState: state.UserKingdomState
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(CombatPanel);