import { createStore as createReduxStore, applyMiddleware, combineReducers, Action } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { ApiService } from '../common/api/api';
import { getNewsEpic } from './epics/newsEpic';
import NewsReducer from './reducers/newsReducer';
import AuthenticationReducer from './reducers/authenticationReducer';
import UserKingdomReducer from './reducers/userKingdomReducer';
import RulesReducer from './reducers/rulesReducer';
import JobRequestReducer from './reducers/jobRequestReducer';
import LandsSearchReducer from './reducers/landsSearchReducer';
import NotificationsReducer from './reducers/notificationsReducer';
import CombatReducer from './reducers/combatReducer';
import { loginEpic, resetPasswordEpic, registerUserEpic } from './epics/authenticationEpic';
import { 
  changeCropTypeEpic, 
  changeProductionTypeEpic, 
  deleteJobEpic, 
  demolishImprovementEpic, 
  getUserKingdomEpic, 
  manageImprovementWorkersEpic, 
  manageJobWorkersEpic, 
  requestTrainingEpic,
  cancelTrainingRequestEpic,
  getActiveKingdomsEpic,
  debugProcessTickEpic, 
  toggleGrowthEpic,
  requestChangeProductionTypeValuesEpic,
  requestChangeCropTypeValuesEpic,
  updateUserSettingsEpic} from './epics/userKingdomEpic';
import { getNotificationsEpic, getRedeemPackageEpic, postAcknowledgeNotificationEpic, postCloseAllNotificationsEpic } from './epics/notificationsEpic';
import { getGameRulesEpic } from './epics/rulesEpic';
import { requestJobEpic } from './epics/jobRequestEpic';
import { getKindomLandsEpic, getSearchPlotsEpic, getUserKingdomDetailsEpic } from './epics/landsSearchEpic';
import { requestJoinBattleEpic } from './epics/combatEpic';
import MarketReducer from './reducers/marketReducer';
import { getOwnMarketSalesEpic, postMarketBuyEpic, postMarketCancelEpic, postMarketSearchEpic, postMarketSellEpic } from './epics/marketEpic';
import { PurchaseReducer } from './reducers/purchaseReducer';
import { postPurchaseRequestEpic } from './epics/purchaseEpic';

export const createStore = (config: AppConfig, apiService: ApiService) => {
  const epicMiddleware = createEpicMiddleware<Action, Action, AppState, AppDependencies>({
    dependencies: {
      apiService,
      ...config
    }
  })

  const rootReducer = combineReducers<AppState>({
    NewsState: NewsReducer,
    AuthenticationState: AuthenticationReducer,
    UserKingdomState: UserKingdomReducer,
    RulesState: RulesReducer,
    JobRequestState: JobRequestReducer,
    LandsSearchState: LandsSearchReducer,
    NotificationsState: NotificationsReducer,
    CombatState: CombatReducer,
    MarketState: MarketReducer,
    PurchaseState: PurchaseReducer
  })

  const rootEpic =  combineEpics<Action, Action, AppState, AppDependencies>(
    getNewsEpic,
    loginEpic,
    resetPasswordEpic,
    registerUserEpic,
    getUserKingdomEpic,
    deleteJobEpic,
    manageJobWorkersEpic,
    manageImprovementWorkersEpic,
    demolishImprovementEpic,
    changeCropTypeEpic,
    changeProductionTypeEpic,
    requestChangeProductionTypeValuesEpic,
    requestChangeCropTypeValuesEpic,
    requestJobEpic,
    getGameRulesEpic,
    requestTrainingEpic,
    cancelTrainingRequestEpic,
    getKindomLandsEpic,
    getSearchPlotsEpic,
    getUserKingdomDetailsEpic,
    getActiveKingdomsEpic,
    getNotificationsEpic,
    getRedeemPackageEpic,
    postAcknowledgeNotificationEpic,
    requestJoinBattleEpic,
    debugProcessTickEpic,
    postMarketBuyEpic,
    postMarketCancelEpic,
    postMarketSellEpic,
    postMarketSearchEpic,
    getOwnMarketSalesEpic,
    postPurchaseRequestEpic,
    toggleGrowthEpic,
    updateUserSettingsEpic,
    postCloseAllNotificationsEpic
  )

  const middlewares = [epicMiddleware];

  const store = createReduxStore(
    rootReducer,
    applyMiddleware(...middlewares)
  );

  epicMiddleware.run(rootEpic);

  return store;
}

