import { Reducer } from 'redux';
import { LandsSearchState } from '../states/landsSearchState';
import {
  LandsSearchActionTypes,
  GetKingdomLandsActionType,
  GetKingdomLandsCompletedActionType,
  GetKingdomLandsFailedActionType,
  GetSearchPlotsActionType,
  GetSearchPlotsCompletedActionType,
  GetSearchPlotsFailedActionType,
  UpdateSearchPlotsFormActionType,
  GetUserKingdomDetailsActionType,
  GetUserKingdomDetailsCompletedActionType,
  GetUserKingdomDetailsFailedActionType,
  CloseExpandedDetailsActionType,
  UpdateUserSearchFiltersActionType
} from '../actions/landsSearchActions';
import { CombatActionTypes, RequestJoinBattleCompletedActionType } from '../actions/combatActions';

export type LandsSearchActions = 
GetKingdomLandsActionType
  | GetKingdomLandsCompletedActionType
  | GetKingdomLandsFailedActionType
  | GetSearchPlotsActionType
  | GetSearchPlotsCompletedActionType
  | GetSearchPlotsFailedActionType
  | UpdateSearchPlotsFormActionType
  | GetUserKingdomDetailsActionType
  | GetUserKingdomDetailsCompletedActionType
  | GetUserKingdomDetailsFailedActionType
  | CloseExpandedDetailsActionType
  | UpdateUserSearchFiltersActionType
  | RequestJoinBattleCompletedActionType;

const initialState: LandsSearchState = {
  hasLoadedList: false,
  currentTick: 0,
  kingdomId: 0,
  page: 1,
  entries: [],
  totalEntries: 0,
  showPager: true,
  isLoading: false,
  hasError: false,
  errorMessage: '',

  usernameFilter: '',
  orderBy: 'Rank',
  ascending: true,

  isSearchingPlots: false,
  hasSearchingPlotsError: false,
  searchingPlotsError: '',
  searchedPlots: [],
  searchAddress: '',

  isLoadingUserDetails: false,
  userKingdomDetail: undefined,
  hasLoadingUserDetailsError: false,
  loadingUserDetailsError: '',
  expandedUserKingdomId: ''
}

export const NewsReducer: Reducer<LandsSearchState> = (state = initialState, action: LandsSearchActions): LandsSearchState => {
  switch(action.type) {
    case LandsSearchActionTypes.GetKingdomLands:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        
        entries: [],
        totalEntries: 0,
        page: 1
      };
    case LandsSearchActionTypes.GetKingdomLandsCompleted:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        errorMessage: '',
        currentTick: action.payload!.response.currentTick,
        kingdomId: action.payload!.response.kingdomId,
        hasLoadedList: true,
        entries: action.payload!.response.results,
        totalEntries: action.payload!.response.results.length,
        showPager: action.payload!.response.results.length > 50
      };
    case LandsSearchActionTypes.GetKingdomLandsFailed:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any,

        entries: [],
        totalEntries: 0  
      };

    case LandsSearchActionTypes.GetSearchPlots:
      return {
        ...state,
        searchingPlotsError: '',
        isSearchingPlots: true,
        hasSearchingPlotsError: false,
        searchedPlots: []
      };
    case LandsSearchActionTypes.GetSearchPlotsCompleted:
      return {
        ...state,
        isSearchingPlots: false,
        searchedPlots: action.payload!.response.results
      };
    case LandsSearchActionTypes.GetSearchPlotsFailed:
      return {
        ...state,
        searchingPlotsError: action.payload!.error as any,
        isSearchingPlots: false,
        hasSearchingPlotsError: true,
        searchedPlots: []
      };
    case LandsSearchActionTypes.UpdateSearchPlotsForm:
      return {
        ...state,
        searchAddress: action.payload!.address
      }

    case LandsSearchActionTypes.GetUserKingdomDetails:
      return {
        ...state,
        loadingUserDetailsError: '',
        isLoadingUserDetails: true,
        hasLoadingUserDetailsError: false,
        userKingdomDetail: undefined,
        expandedUserKingdomId: action.payload!.userKingdomId
      };
    case LandsSearchActionTypes.GetUserKingdomDetailsCompleted:
      return {
        ...state,
        isLoadingUserDetails: false,
        userKingdomDetail: action.payload!.response.results
      };
    case LandsSearchActionTypes.GetUserKingdomDetailsFailed:
      return {
        ...state,
        loadingUserDetailsError: action.payload!.error as any,
        isLoadingUserDetails: false,
        hasLoadingUserDetailsError: true,
        expandedUserKingdomId: ''
      };
    case LandsSearchActionTypes.CloseExpandedDetails:
      return {
        ...state,
        expandedUserKingdomId: ''
      };

    case LandsSearchActionTypes.UpdateUserSearchFilters:
      return {
        ...state,
        usernameFilter: action.payload!.username,
        orderBy: action.payload!.orderBy,
        ascending: action.payload!.ascending
      }

    case CombatActionTypes.RequestJoinBattleCompleted:
      let newOpenBattles = [
        ...state.userKingdomDetail!.openBattles
      ];

      if (newOpenBattles.filter(x => x.battleGoal === action.payload!.response.battle.battleGoal).length > 0) {
        newOpenBattles = state.userKingdomDetail!.openBattles.map(x => {
          if (x.battleGoal === action.payload!.response.battle.battleGoal) {
            return action.payload!.response.battle;
          }

          return x;
        })
      } else {
        newOpenBattles.push(action.payload!.response.battle);
      }
      
      return {
        ...state,
        userKingdomDetail: {
          ...state.userKingdomDetail!,
          openBattles: newOpenBattles
        }
      }
    
    default:
      return state;
  }
}

export default NewsReducer;