import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import GetNotificationsResponse from '../../common/types/messages/GetNotificationsResponse';
import GetUserKingdomResponse from '../../common/types/messages/GetUserKingdomResponse';
import {
  NotificationsActionTypes,
  GetNotificationsActionType,
  GetNotificationsCompletedActionType,
  GetNotificationsFailedActionType,
  getNotificationsCompleted,
  getNotificationsFailed,
  GetRedeemPackageActionType,
  GetRedeemPackageCompletedActionType,
  getRedeemPackageCompleted,
  getRedeemPackageFailed,
  AcknowledgeNotificationActionType,
  AcknowledgeNotificationCompletedActionType,
  AcknowledgeNotificationFailedActionType,
  acknowledgeNotificationCompleted,
  acknowledgeNotificationFailed,
  CloseAllNotificationsActionType,
  CloseAllNotificationsFailedActionType,
  CloseAllNotificationsCompletedActionType,
  closeAllNotificationsCompleted,
  closeAllNotificationsFailed
} from '../actions/notificationsActions';

export const getNotificationsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(NotificationsActionTypes.GetNotifications),
    mergeMap((action) => onGetNotifications(action, dependencies))
  );
}

export const onGetNotifications: (action: GetNotificationsActionType, dependencies: AppDependencies) 
  => Observable<GetNotificationsCompletedActionType | GetNotificationsFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getNotifications$();

  return callResponse$.pipe(
    map(response => {
      return getNotificationsCompleted(response as GetNotificationsResponse);
    }),
    catchError(error => {
      return of(getNotificationsFailed(error.response.value));
    })
  );
}

export const getRedeemPackageEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(NotificationsActionTypes.GetRedeemPackage),
    mergeMap((action) => onRedeemPackage(action, dependencies))
  );
}

export const onRedeemPackage: (action: GetRedeemPackageActionType, dependencies: AppDependencies) 
  => Observable<GetRedeemPackageCompletedActionType | GetRedeemPackageCompletedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getRedeemPackage$(action.payload!.kingdomId, action.payload!.notificationId);

  return callResponse$.pipe(
    map(response => {
      return getRedeemPackageCompleted(response as GetUserKingdomResponse, action.payload!.notificationId);
    }),
    catchError(error => {
      return of(getRedeemPackageFailed(error.response.value, action.payload!.notificationId));
    })
  );
}

export const postAcknowledgeNotificationEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(NotificationsActionTypes.AcknowledgeNotification),
    mergeMap((action) => onAcknowledgeNotification(action, dependencies))
  );
}

export const onAcknowledgeNotification: (action: AcknowledgeNotificationActionType, dependencies: AppDependencies) 
  => Observable<AcknowledgeNotificationCompletedActionType | AcknowledgeNotificationFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postAcknowledgeNotification$(action.payload!.notificationId);

  return callResponse$.pipe(
    map(response => {
      return acknowledgeNotificationCompleted(action.payload!.notificationId);
    }),
    catchError(error => {
      return of(acknowledgeNotificationFailed(error.response.value));
    })
  );
}

export const postCloseAllNotificationsEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(NotificationsActionTypes.CloseAllNotifications),
    mergeMap((action) => onCloseAllNotifications(action, dependencies))
  );
}

export const onCloseAllNotifications: (action: CloseAllNotificationsActionType, dependencies: AppDependencies) 
  => Observable<CloseAllNotificationsCompletedActionType | CloseAllNotificationsFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postCloseAllNotifications$();

  return callResponse$.pipe(
    map(response => {
      return closeAllNotificationsCompleted(response as GetNotificationsResponse);
    }),
    catchError(error => {
      return of(closeAllNotificationsFailed(error.response.value));
    })
  );
}

