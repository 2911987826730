import { Row, Col, Image, Form, Spinner, ProgressBar } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/style.scss"
import { useTranslation } from "react-i18next";
import Improvement from '../../../common/types/userKingdom/improvement';
import Worker from '../../../common/types/userKingdom/worker';
import ExpandedWorkersEntry from '../expandedWorkersEntry';
import ResourceAmount from '../../../common/types/userKingdom/resourceAmount';
import Job from '../../../common/types/userKingdom/job';
import PostChangeProductionTypeRequest from '../../../common/types/messages/PostChangeProductionTypeRequest';
import PostChangeCropTypeRequest from '../../../common/types/messages/PostChangeCropTypeRequest';
import { cancelRequestTraining, changeCropType, changeProductionType, openDemolishImprovementConfirmModal, requestChangeCropTypeValues, requestChangeProductionTypeValues, requestTraining } from '../../../store/actions/userKingdomActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { formatNumberMaxOneDecimal } from '../../../common/helperFunctions/formattingFunctions';
import Plot from '../../../common/types/userKingdom/plot';
import { NaturalResourcesDisplay } from '../naturalResourcesDisplay';
import NewJobButton from '../newJobButton';
import JobDisplay from '../jobDisplay';
import ImprovementTraining from '../../../common/types/userKingdom/improvementTraining';
import PostTrainingRequest from '../../../common/types/messages/PostTrainingRequest';
import PostCancelTrainingRequest from '../../../common/types/messages/PostCancelTrainingRequest';
import TrainingRules from '../../../common/types/rules/TrainingRules';
import { formatNumberNoDecimal } from '../../../common/helperFunctions/formattingFunctions';
import GroupedWorkers from '../../../common/types/userKingdom/groupedWorkers';
import Resource from '../../../common/types/userKingdom/resource';
import NaturalResource from '../../../common/types/userKingdom/naturalResource';

interface StateProps {
  userKingdomWorkers: Worker[];
  groupedWorkers: GroupedWorkers[];
  kingdomId: number;
  currentTick: number;
  isDemolishingImprovement: boolean;
  plot: Plot;
  plotJobs: Job[];
  isUpdatingTraining: boolean;
  trainingRules: TrainingRules[];
  trainingError: string;
  userKingdomResources: Resource[];
};

interface DispatchProps {
  openDemolishImprovement: (improvementId: string) => void;
  changeProductionType: (request: PostChangeProductionTypeRequest) => void;
  requestChangeProductionTypeValues: (request: PostChangeProductionTypeRequest) => void;
  changeCropType: (request: PostChangeCropTypeRequest) => void;
  requestChangeCropValues: (request: PostChangeCropTypeRequest) => void;
  requestTraining: (request: PostTrainingRequest) => void;
  cancelTraining: (request: PostCancelTrainingRequest) => void;
}

interface OwnProps {
  improvement: Improvement,
  workers: Worker[]
}

export type ImprovementDisplayProps = DispatchProps & StateProps & OwnProps;

export const ImprovementDisplay = (props: ImprovementDisplayProps) => {
  const [manage, setManage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const [targetWorkerType, setTargetWorkerType] = useState(-1)
  const [currentWorkerType, setCurrentWorkerType] = useState(-1)
  const [trainCount, setTrainCount] = useState(0)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  
  useEffect(() => {
    if (props.groupedWorkers
      && props.groupedWorkers.filter(x => x.type !== 0 && x.numberUnemployed > 0).length > 0
      && currentWorkerType === -1) {
        setCurrentWorkerType(props.groupedWorkers.filter(x => x.type !== 0 && x.numberUnemployed > 0)[0].type)
    }

    if (props.trainingRules 
      && props.trainingRules.length > 0 
      && props.improvement
      && props.improvement.trainableWorkerTypes
      && targetWorkerType === -1) {
      setTargetWorkerType(props.improvement.trainableWorkerTypes[0].type)
    }
  }, [props, currentWorkerType, targetWorkerType]);

  const toggleManagePanel = (event: any) => {
    if (!props.improvement.underConstruction) {
      setManage(!manage);
    }
  }

  const renderRedTextBox = () => {
    if (!props.trainingError || props.trainingError === '') {
      return <></>;
    }
    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t(`Exceptions.${props.trainingError}`)}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const renderExpandToggle = () => {
    return (
      <Row>
        <Col className="align-to-right morris-lg">
          {t("UserKingdomPage.ImprovementsTab.Manage")}
        </Col>
        <Col className="morris-lg">
          <FontAwesomeIcon className="" size="1x" icon={manage ? faAngleDown : faAngleRight}/>
        </Col>
      </Row>
    );
  }

  const GetImprovementSpecificColumn = (improvement: Improvement) => {
    if (improvement.underConstruction) {
      return t("UserKingdomPage.ImprovementsTab.UnderConstruction")
    }

    if (improvement.improvementClassString === "Farm") {
      let returnString = t(`CropStatus.${improvement.cropStateString}`);

      if (improvement.cropStateString !== "Fallow") {
        returnString += " " + t(`Crops.${improvement.cropTypeString}`, { count: 2})
      } else {
        returnString += ", " + t(`Crops.${improvement.cropTypeString}`, { count: 2});
      }

      return returnString;
    } else if (improvement.improvementClassString === "Production") {
      return t("UserKingdomPage.ImprovementsTab.Producing") + " " + t(`ProductionTypes.${improvement.productionTypeString}`)
    }
  }

  const DisplayImprovementWorkers = () => {
    if (props.improvement.maxWorkers && props.improvement.maxWorkers > 0) {
      return (
        <>
          <ExpandedWorkersEntry 
            workers={props.workers} 
            maxWorkers={props.improvement.maxWorkers} 
            allowedWorkerTypes={props.improvement.allowedWorkerTypes} 
            managingImprovementWorkers={true}
            idManaging={props.improvement.id}/>
        </>
      );
    }
  }

  const HandleProductionTypeDropDownChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    let newVal = parseInt(changeEvent.target.value);

    props.requestChangeProductionTypeValues({
      improvementId: props.improvement.id,
      kingdomId: props.kingdomId,
      currentTick: props.currentTick,
      productionType: newVal
    } as PostChangeProductionTypeRequest)
  }

  const HandleProductionTypeSave = () => {
    if (props.improvement.changeProductionType !== props.improvement.productionType) {
      props.changeProductionType({
        improvementId: props.improvement.id,
        kingdomId: props.kingdomId,
        currentTick: props.currentTick,
        productionType: props.improvement.changeProductionType
      } as PostChangeProductionTypeRequest)
    }
  }


  const HandleTargetWorkerTypeDropDownChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    let newVal = parseInt(changeEvent.target.value);
    setTargetWorkerType(newVal);
  }

  const HandleCurrentWorkerTypeDropDownChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    let newVal = parseInt(changeEvent.target.value);
    setCurrentWorkerType(newVal);
  }

  const HandleCropTypeDropDownChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    let newVal = parseInt(changeEvent.target.value);

    props.requestChangeCropValues({
      improvementId: props.improvement.id,
      kingdomId: props.kingdomId,
      currentTick: props.currentTick,
      cropType: newVal
    } as PostChangeCropTypeRequest)
  }

  const HandleCropTypeSave = () => {
    if (props.improvement.changeCropType !== props.improvement.cropType) {
      props.changeCropType({
        improvementId: props.improvement.id,
        kingdomId: props.kingdomId,
        currentTick: props.currentTick,
        cropType: props.improvement.changeCropType
      } as PostChangeCropTypeRequest)
    }
  }

  const GetCropTypeDropDownOptions = () => {
    if (!!props.improvement.allowedCrops) {
      return (
        <>
          {props.improvement.allowedCrops
            .map((x) => {
            return <option key={x.type} value={x.type}>{t(`Crops.${x.typeString}`, { count: 2 })}</option>
          })}
        </>
      );
    }
  }

  const GetProductionTypeDropDownOptions = () => {
    if (!!props.improvement.allowedProductionTypes) {
      return (
        <>
          {props.improvement.allowedProductionTypes
            .map((x) => {
            return <option key={x.type} value={x.type}>{t(`ProductionTypes.${x.typeString}`, { count: 2 })}</option>
          })}
        </>
      );
    }
  }

  const GetTrainingRulesDropDownOptions = () => {
    if (!!props.improvement.trainableWorkerTypes) {
      if (props.improvement.typeString !== "PeasantAcademy") {
        return (
          <>
            {props.improvement.trainableWorkerTypes.map((x) => {
              return <option key={x.type} value={x.type}>{t(`WorkerTypes.${x.typeString}`, { count: 1 })}</option>
            })}
          </>
        );
      } else {
        return (
          <>
            {props.groupedWorkers.filter(x => x.numberUnemployed > 0 && x.type !== 0).map((x) => {
              return <option key={x.type} value={x.type}>{t(`WorkerTypes.${x.typeString}`, { count: 1 })}</option>
            })}
          </>
        );
      }
    }
  }

  const renderProducingConsumingResources = (changeValues: boolean) => {
    
    let consumed = changeValues ? props.improvement.changeConsumptionPerCompletion : props.improvement.consumptionPerCompletion;
    let production = changeValues ? props.improvement.changeProductionPerCompletion : props.improvement.productionPerCompletion;

    return (
      <Row className="resource-table-sub-entry">
        <Col>
          {consumed.map((x: ResourceAmount) => {
            let ownedResourceAmt = props.userKingdomResources.filter(z => z.type === x.type)[0] ? props.userKingdomResources.filter(z => z.type === x.type)[0].amount : 0;
            
            return (
              <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"} ${x.amount > ownedResourceAmt ?  "errorRed-light-background" : ""}`}>
                {isMobile ? 
                  <>
                    <Col>
                      <Row><Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col></Row>
                      <Row><Col className="pt-3 improvement-resource-table">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col></Row>
                    </Col>
                    <Col className="pt-3 improvement-resource-table right-border">{x.amount} / {ownedResourceAmt}</Col>
                  </> :
                  <>
                    <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
                    <Col className="pt-3">{x.amount} / {ownedResourceAmt}</Col>
                    <Col className="pt-3 improvement-resource-table right-border">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
                  </>}
              </Row>
            );
          })}
        </Col>
        <Col>
          {production.map((x: ResourceAmount) => {   
            return (
              <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
                 {isMobile ? 
                  <>
                    <Col>
                      <Row><Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col></Row>
                      <Row><Col className="pt-3 improvement-resource-table">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col></Row>
                    </Col>
                    <Col className="pt-3 improvement-resource-table">{x.amount}</Col>
                  </> :
                  <>
                    <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
                    <Col className="pt-3">{x.amount}</Col>
                    <Col className="pt-3 improvement-resource-table">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
                  </>}
              </Row>
            );
          })}
        </Col>
      </Row>
    )
  }

  const renderFarmManagePanel = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>Currently {GetImprovementSpecificColumn(props.improvement)}</Col>
        </Row>
        <Row className={`resource-table-sub-entry wheat-background pb-2 ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            {t('UserKingdomPage.ImprovementsTab.TilesPlanted')}
            <ProgressBar>
              {props.improvement.cropStateString !== "Harvesting" && <ProgressBar max={props.improvement.size} variant="success" now={props.improvement.tilesPlanted} label={props.improvement.tilesPlanted + " / " + props.improvement.size} key={1}/>}
              {props.improvement.cropStateString !== "Harvesting" && props.improvement.tilesPlanted && props.improvement.tilesPlanted < props.improvement.size 
                && <ProgressBar max={props.improvement.size} striped animated now={props.improvement.changePerTurn} label={"+ " + props.improvement.changePerTurn} key={2}/>
              }
              {props.improvement.cropStateString === "Harvesting" && props.improvement.tilesPlanted && <ProgressBar max={props.improvement.size} variant="success" now={props.improvement.tilesPlanted - props.improvement.changePerTurn} label={props.improvement.tilesPlanted + " / " + props.improvement.size} key={1}/>}
              {props.improvement.cropStateString === "Harvesting" && props.improvement.tilesPlanted
                && <ProgressBar max={props.improvement.size} striped animated variant="danger" now={props.improvement.changePerTurn} label={"- " + props.improvement.changePerTurn} key={2}/>
              }
            </ProgressBar>
          </Col>
        </Row>
        <Row className={`resource-table-sub-entry wheat-background pb-2 ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            {t('UserKingdomPage.ImprovementsTab.PercentTended')}
            <ProgressBar>
              <ProgressBar max={100} variant="success" now={props.improvement.percentTended! * 100} label={formatNumberMaxOneDecimal(props.improvement.percentTended! * 100) + " %"} key={1}/>
              {props.improvement.cropStateString === "Tending" && props.improvement.percentTended! < 1 && <ProgressBar max={100} striped animated now={props.improvement.changePerTurn * 100} label={"+ " + formatNumberMaxOneDecimal(props.improvement.changePerTurn * 100) + " %"} key={2}/>}
            </ProgressBar>
          </Col>
        </Row>
        {props.improvement.estimatedHarvest && props.improvement.estimatedHarvest.length > 0 &&
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t('UserKingdomPage.ImprovementsTab.ExpectedHarvest')}</Col>
        </Row>}
        {props.improvement.estimatedHarvest && props.improvement.estimatedHarvest.length > 0 &&
          props.improvement.estimatedHarvest.map((x) => {
            return (
              <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
                <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
                <Col className="pt-3">{Math.ceil(x.amount)}</Col>
                <Col className="pt-3">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
              </Row>
            );
          })}
        <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t("UserKingdomPage.ImprovementsTab.ChangeProduction")}</Col>
        </Row>
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col className="pt-2">{t('UserKingdomPage.ImprovementsTab.Crop')}</Col>
          <Col>
            <Form.Select className="drop-down-style wheat_dark" value={props.improvement.changeCropType!} onChange={HandleCropTypeDropDownChange}>
              {GetCropTypeDropDownOptions()}
            </Form.Select>
          </Col>
          <Col>
            <div className={`darkStone-button ${isMobile ? "morris-sm": "morris-md"}`} onClick={HandleCropTypeSave}>
              {t("Buttons.Save")}
            </div>
          </Col>
        </Row>
        {props.improvement.changeEstimatedHarvest && props.improvement.changeEstimatedHarvest.length > 0 &&
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t('UserKingdomPage.ImprovementsTab.ExpectedHarvest')}</Col>
        </Row>}
        {props.improvement.changeEstimatedHarvest && props.improvement.changeEstimatedHarvest.length > 0 &&
          props.improvement.changeEstimatedHarvest.map((x) => {
            return (
              <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
                <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
                <Col className="pt-3">{Math.ceil(x.amount)}</Col>
                <Col className="pt-3">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
              </Row>
            );
          })}
      </>
    );
  }

  const anyResourceConsumptionNotMet = () => {
    return props.improvement.consumptionPerCompletion && props.improvement.consumptionPerCompletion
      .filter(x => x.amount > (props.userKingdomResources.filter(z => z.type === x.type)[0] ? props.userKingdomResources.filter(z => z.type === x.type)[0].amount : 0)).length > 0
  }

  const shouldBeDarkWheatUnexpanded = () => {
    return !props.improvement.underConstruction && (
      (props.improvement.improvementClassString === "ResourceExtraction" && (getImprovementNatResources().length === 0))
      || (props.improvement.improvementClassString === "ResourceExtraction"
          && (props.improvement.typeString === "HuntersCabin" || props.improvement.typeString === "ForestersLodge" || props.improvement.typeString === "GatherersHut") 
          && !isImprovementNatResourceAtMax())
      || (props.improvement.improvementClassString === "Production" && props.workers.reduce((a,b) => a + b.number, 0) > 0 && (anyResourceConsumptionNotMet())));
  }

  const renderStorageManagePanel = () => {
    return <></>;
  }

  const getImprovementNatResources = () => {
    let natResources: NaturalResource[] = [];

    if (props.improvement.typeString === "Mine") {
      natResources = props.plot.naturalResources.filter(x => [1,2,3,4].includes(x.type));
    } else if (props.improvement.typeString === "Quarry") {
      natResources = props.plot.naturalResources.filter(x => [5,6].includes(x.type));
    } else if (props.improvement.typeString === "GatherersHut") {
      natResources = props.plot.naturalResources.filter(x => [8].includes(x.type));
    } else if (props.improvement.typeString === "HuntersCabin") {
      natResources = props.plot.naturalResources.filter(x => [7].includes(x.type));
    } else if (props.improvement.typeString === "ForestersLodge") {
      natResources = props.plot.naturalResources.filter(x => [0].includes(x.type));
    }

    return natResources;
  } 

  const isImprovementNatResourceAtMax = () => {
    let natResources: NaturalResource[] = [];

    if (props.improvement.typeString === "GatherersHut") {
      natResources = props.plot.naturalResources.filter(x => [8].includes(x.type));
    } else if (props.improvement.typeString === "HuntersCabin") {
      natResources = props.plot.naturalResources.filter(x => [7].includes(x.type));
    } else if (props.improvement.typeString === "ForestersLodge") {
      natResources = props.plot.naturalResources.filter(x => [0].includes(x.type));
    }

    if (!natResources) {
      return false;
    }

    return natResources[0].amount >= natResources[0].maxAmount;
  }

  const renderResourceExtractionManagePanel = () => {
    return (
      <>
        {props.improvement.typeString !== "Mine" && props.improvement.typeString !== "Quarry" && 
          <Row className={`resource-table-sub-entry pb-2 ${isMobile ? "standard-text": "standard-text-md"}`}>
            <Col><Image className="thumbnail-image" src={`/images/naturalResources/${props.improvement.naturalResourceTypeString}.png`}/></Col>
            <Col className="sub-entry-margin-class">{t(`NaturalResourceTypes.${props.improvement.naturalResourceTypeString}`, { count: 2 })} {t(`CommonWords.PerYear`)}</Col>
            <Col className="sub-entry-margin-class">{`${formatNumberMaxOneDecimal(props.improvement.expectedResourceAmountPerYear)}`}</Col>
          </Row>}
        <NaturalResourcesDisplay naturalResources={getImprovementNatResources()} />
        {renderJobsManagement()}
        {(props.improvement.typeString === "Mine" || props.improvement.typeString === "Quarry") && <NewJobButton plotId={props.plot.id}/>}
      </>
    );
  }

  const renderProductionManagePanel = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>Currently {GetImprovementSpecificColumn(props.improvement)}</Col>
        </Row>
        <Row className={`resource-table-sub-entry pb-2 ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            {t("UserKingdomPage.ImprovementsTab.Progress")}
            <ProgressBar>
              <ProgressBar max={props.improvement.workerHoursPerCompletion} variant="success" now={props.improvement.workerHoursCompleted} label={props.improvement.workerHoursCompleted + " / " + props.improvement.workerHoursPerCompletion} key={1}/>
              <ProgressBar max={props.improvement.workerHoursPerCompletion} striped animated now={props.improvement.workerHoursPerTick} label={"+ " + props.improvement.workerHoursPerTick} key={2}/>
            </ProgressBar>
          </Col>
        </Row>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t('UserKingdomPage.ImprovementsTab.Consuming')}</Col>
          <Col>{t('UserKingdomPage.ImprovementsTab.Producing')}</Col>
        </Row>
        {renderProducingConsumingResources(false)}
        <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t("UserKingdomPage.ImprovementsTab.ChangeProduction")}</Col>
        </Row>
        <Row className={`resource-table-sub-entry ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col className="pt-2">{t('UserKingdomPage.ImprovementsTab.ProductionType')}</Col>
          <Col>
            <Form.Select className="drop-down-style wheat_dark" value={props.improvement.changeProductionType} onChange={HandleProductionTypeDropDownChange}>
              {GetProductionTypeDropDownOptions()}
            </Form.Select>
          </Col>
          <Col>
            <div className={`darkStone-button ${isMobile ? "morris-md": "morris-lg"}`} onClick={HandleProductionTypeSave}>
              {t("Buttons.Save")}
            </div>
          </Col>
        </Row>
        {props.improvement.changeProductionTypeString !== "None" &&
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t('UserKingdomPage.ImprovementsTab.Consuming')}</Col>
          <Col>{t('UserKingdomPage.ImprovementsTab.Producing')}</Col>
        </Row>}
        {renderProducingConsumingResources(true)}
      </>
    );
  }

  const renderTrainings = () => {
    return (
      <>
        <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t("UserKingdomPage.ImprovementsTab.ActiveTrainings", { count: props.workers.reduce((a, b) => a = a + b.number, 0)})} {props.workers.reduce((a, b) => a = a + b.number, 0) }{` / ${props.improvement.slots}`}</Col>
        </Row>
        {props.improvement.trainings.length === 0 ? 
          <Row className={`resource-table-sub-entry morris-lg`}>
            <Col>{t("UserKingdomPage.ImprovementsTab.NoneTraining")}</Col>
          </Row> : 
          props.improvement.trainings.map((x: ImprovementTraining) => 
          <Row key={x.id} className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col><Image className="thumbnail-image" src={`/images/workers/${x.targetWorkerTypeString}.png`}/></Col>
            <Col>{t(`WorkerTypes.${x.currentWorkerTypeString}`, { count: 1 })} {t("UserKingdomPage.ImprovementsTab.To")} {t(`WorkerTypes.${x.targetWorkerTypeString}`, { count: 1 })}</Col>
            <Col>{t("UserKingdomPage.JobsTab.DaysRemaining", { count: x.hoursRemaining })}</Col>
            <Col className="sub-entry-margin-class morris-lg">
              {props.isUpdatingTraining && 
                <div className="errorRed-button">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
                }
              {!props.isUpdatingTraining && 
              <div className="errorRed-button" onClick={() => props.cancelTraining({
                improvementTrainingId: x.id,
                kingdomId: props.kingdomId,
                currentTick: props.currentTick
              })}>
                {t("Buttons.Cancel")}
              </div>
              }
            </Col>
          </Row>)
        }
      </>
    )
  }

  const renderTrainingForm = () => {
    if (props.improvement.trainings.length === props.improvement.slots) {
      return <></>;
    }

    let hasCosts = (targetWorkerType 
      && props.trainingRules 
      && props.trainingRules.filter(x => x.type === targetWorkerType).length > 0
      && props.trainingRules.filter(x => x.type === targetWorkerType)[0].costs.length > 0) || props.improvement.typeString === "PeasantAcademy";

    let maxTrainAmount = props.improvement.slots ? props.improvement.slots - props.workers.reduce((a, b) => a = a + b.number, 0) : 0;

    return (
      <>
        {renderRedTextBox()}
        <Row className={`resource-table-sub-entry top-border dark-wheat-background ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>{t("UserKingdomPage.ImprovementsTab.RequestTraining")}</Col>
        </Row>
        { props.improvement.typeString === "PeasantAcademy" && 
          <Row className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text": "standard-text-md"}`}>
            <Col>
              <Form.Select className="drop-down-style wheat_dark" value={currentWorkerType} onChange={HandleCurrentWorkerTypeDropDownChange}>
                {GetTrainingRulesDropDownOptions()}
              </Form.Select>
            </Col>
            <Col>{t("UserKingdomPage.ImprovementsTab.To")}</Col>
            <Col>{trainCount} {t("WorkerTypes.Peasant", { count: trainCount })}</Col>
            <Col className="sub-entry-margin-class morris-lg">
                {props.isUpdatingTraining && 
                  <div className="errorRed-button">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                  }
                {!props.isUpdatingTraining && trainCount > 0 &&
                <div className="appleGreen-button" onClick={() => props.requestTraining({
                  improvementId: props.improvement.id,
                  currentWorkerType: currentWorkerType,
                  targetWorkerType: 0,
                  amount: trainCount,
                  kingdomId: props.kingdomId,
                  currentTick: props.currentTick
                })}>
                  {t("UserKingdomPage.ImprovementsTab.Train")}
                </div>
                }
              </Col>
          </Row>
        }
        { props.improvement.typeString !== "PeasantAcademy" && 
          <Row className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text": "standard-text-md"}`}>
            <Col>{trainCount} {t("WorkerTypes.Peasant", { count: trainCount })}</Col>
            <Col>{t("UserKingdomPage.ImprovementsTab.To")}</Col>
            <Col>
              <Form.Select className="drop-down-style wheat_dark" value={targetWorkerType} onChange={HandleTargetWorkerTypeDropDownChange}>
                {GetTrainingRulesDropDownOptions()}
              </Form.Select>
            </Col>
            <Col className="sub-entry-margin-class morris-lg">
                {props.isUpdatingTraining && 
                  <div className="errorRed-button">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                  }
                {!props.isUpdatingTraining && trainCount > 0 &&
                <div className="appleGreen-button" onClick={() => props.requestTraining({
                  improvementId: props.improvement.id,
                  currentWorkerType: 0,
                  targetWorkerType: targetWorkerType,
                  amount: trainCount,
                  kingdomId: props.kingdomId,
                  currentTick: props.currentTick
                })}>
                  {t("UserKingdomPage.ImprovementsTab.Train")}
                </div>
                }
              </Col>
          </Row>
        }
        <Row className={`resource-table-sub-entry pb-1 pt-1 ${isMobile ? "standard-text": "standard-text-md"}`}>
          <Col>
            <Form.Range 
              value={trainCount} 
              onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                setTrainCount(parseInt(changeEvent.currentTarget.value))}}
              min={0}
              max={maxTrainAmount}
              />
          </Col>
        </Row>
        { hasCosts ?
          props.trainingRules.filter(x => x.type === targetWorkerType)[0].costs.map(x => {
            let ownedResourceAmt = props.userKingdomResources.filter(z => z.type === x.type)[0] ? props.userKingdomResources.filter(z => z.type === x.type)[0].amount : 0;
            
            return (
              <Row key={x.type} className={`resource-table-sub-entry standard-text-md ${x.amount > ownedResourceAmt ?  "errorRed-light-background" : ""}`}>
                <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
                <Col className="sub-entry-margin-class">{t(`Resources.${x.typeString}`, { count: x.amount * trainCount })}</Col>
                <Col className="sub-entry-margin-class">{formatNumberNoDecimal(x.amount * trainCount)}</Col>
              </Row>
            );
         }) : <></>}
      </>
    );
  }

  const renderTrainingManagePanel = () => {
    return  (
      <>
        {renderTrainings()}
        {renderTrainingForm()}
      </>
    )
  }

  const renderJobsManagement = () => {
    if (props.plotJobs.length === 0) {
      return <></>;
    }

    const uniqueJobIds: string[] = [];

    props.plotJobs.map(x => uniqueJobIds.indexOf(x.id) === -1 ?
      uniqueJobIds.push(x.id) : undefined
    );

    return (
      <>
      <Row className="resource-table-sub-entry dark-wheat-background standard-text-md">
        <Col>{t("UserKingdomPage.Tabs.Jobs")}</Col>
      </Row>
      {uniqueJobIds.map((x) => 
        <JobDisplay 
          key={x}
          jobId={x}
          jobs={props.plotJobs.filter(r => r.id === x).sort((a, b) => b.priorityNumber - a.priorityNumber)}
          plot={props.plot}
        />)}
      </>
    );
  }

  const renderSpecificManagePanel = () => {
    if (props.improvement.improvementClassString === "Farm") {
      return renderFarmManagePanel();
    } else if (props.improvement.improvementClassString === "Storage") {
      return renderStorageManagePanel();
    } else if (props.improvement.improvementClassString === "Production") {
      return renderProductionManagePanel();
    } else if (props.improvement.improvementClassString === "ResourceExtraction") {
      return renderResourceExtractionManagePanel();
    } else if (props.improvement.improvementClassString === "Training") {
      return renderTrainingManagePanel();
    }
  }

  const renderManage = () => {
    if (manage) {
      return (
        <>
        <div className="mx-2 mb-2">
          {renderSpecificManagePanel()}
          {DisplayImprovementWorkers()}
          <Row className="resource-table-sub-entry top-border standard-text-md">
            <Col></Col>
            <Col></Col>
            <Col></Col>
            {props.improvement.isDestroyable && props.isDemolishingImprovement && 
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="errorRed-button">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </Col>
            }
            {props.improvement.isDestroyable && !props.isDemolishingImprovement && 
            <Col className="sub-entry-margin-class morris-md py-2">
              <div className="errorRed-button" onClick={() => props.openDemolishImprovement(props.improvement.id)}>
                {t("UserKingdomPage.ImprovementsTab.Demolish")}
              </div>
            </Col>
            }
          </Row>
          </div>
        </>
      )
    }
    return (<></>);
  }

  const getWorkerCountStrings = () => {
    let workerCount = 0;

    if (props.improvement.improvementClassString === "ResourceExtraction"
        && (props.improvement.typeString === "Mine" || props.improvement.typeString === "Quarry")) {
      return "";
    }

    if (props.workers.length > 0) {
      workerCount = props.workers.reduce((a, b) => a = a + b.number, 0)
    }
    
    let workerString = `${workerCount}`;

    if (props.improvement.improvementClassString === "Training") {
      workerString += ` / ${props.improvement.slots}`;
    } else {
      if (props.improvement.maxWorkers) {
        workerString += ` / ${props.improvement.maxWorkers}`;
      } else {
        workerString += ' / 0'
      }
  
    }

    return workerString + " " + t("CommonWords.Workers", { count: workerCount });
  }

  const getImprovementTitle = () => {
    if (props.improvement.isExpandable) {
      return t(`ImprovementTypes.${props.improvement.typeString}`) + " " + t("UserKingdomPage.ImprovementsTab.Level") + " " + props.improvement.level;
    } else {
      return t(`ImprovementTypes.${props.improvement.typeString}`);
    }
  }

  return (
    <div key={props.improvement.id}>
      <Row onClick={toggleManagePanel} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"} ${manage ? "apple-green-background" : !props.improvement.underConstruction && "clickable"} ${shouldBeDarkWheatUnexpanded() && "dark-wheat-background"}`}>
        <Col>
          <Row>
            <Col>
              <Row><Col><Image className="thumbnail-image" src={`/images/improvements/${props.improvement.typeString}.png`}/></Col></Row>
              <Row><Col className="sub-entry-margin-class">{getImprovementTitle()}</Col></Row>
            </Col>
            <Col className="sub-entry-margin-class">{props.improvement.size} up2</Col>
            {!isMobile && <Col className="sub-entry-margin-class">{props.plot.address}</Col>}
            <Col className="sub-entry-margin-class">{getWorkerCountStrings()}</Col>
            <Col className="sub-entry-margin-class">{GetImprovementSpecificColumn(props.improvement)}</Col>
            {!isMobile && <Col className="sub-entry-margin-class">
              {!props.improvement.underConstruction && renderExpandToggle()}
            </Col>}
          </Row>
          {isMobile && 
            <Row>
              <Col className="sub-entry-margin-class">{props.plot.address}</Col>
              <Col className="sub-entry-margin-class">{!props.improvement.underConstruction && renderExpandToggle()}</Col>
            </Row>}
        </Col>
      </Row>
      {renderManage()}
    </div>
  );
}

export const mapStateToProps = (state: AppState, OwnProps: OwnProps) => {
  const stateProps: StateProps = {
    userKingdomWorkers: state.UserKingdomState.userKingdom.workers,
    groupedWorkers: state.UserKingdomState.userKingdom.groupedWorkers,
    kingdomId: state.UserKingdomState.userKingdom.kingdomId,
    currentTick: state.UserKingdomState.userKingdom.currentTick,
    isDemolishingImprovement: state.UserKingdomState.isDemolishingImprovement,
    plot: state.UserKingdomState.userKingdom.plots.filter((x: Plot) => OwnProps.improvement.plotId === x.id)[0],
    plotJobs: state.UserKingdomState.userKingdom.jobs.filter((x: Job) => OwnProps.improvement.plotId === x.plotId),
    isUpdatingTraining: state.UserKingdomState.isUpdatingTraining,
    trainingRules: state.RulesState.gameRules ? state.RulesState.gameRules!.trainingRules : [],
    trainingError: state.UserKingdomState.updatingTrainingError,
    userKingdomResources: state.UserKingdomState.userKingdom.resources
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    openDemolishImprovement: (improvementId: string) => dispatch(openDemolishImprovementConfirmModal(improvementId)),
    changeProductionType: (request: PostChangeProductionTypeRequest) => dispatch(changeProductionType(request)),
    requestChangeProductionTypeValues: (request: PostChangeProductionTypeRequest) => dispatch(requestChangeProductionTypeValues(request)),
    changeCropType: (request: PostChangeCropTypeRequest) => dispatch(changeCropType(request)),
    requestChangeCropValues: (request: PostChangeCropTypeRequest) => dispatch(requestChangeCropTypeValues(request)),
    requestTraining: (request: PostTrainingRequest) => dispatch(requestTraining(request)),
    cancelTraining: (request: PostCancelTrainingRequest) => dispatch(cancelRequestTraining(request))
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(ImprovementDisplay);