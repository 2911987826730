import { Container, Row, Col } from 'react-bootstrap'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";

interface StateProps {
  authTokenSet: boolean
};

interface DispatchProps {

}

export type HeaderProps = DispatchProps & StateProps;

export const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderLoginNavBar = () => {
    if (props.authTokenSet) {
      return (
        <Col className="nav-button"
            onClick={() => navigate("/Play")}>
            {t("Buttons.Play")}
          </Col>
      );
    } else {
      return (
        <Col className="nav-button"
          onClick={() => navigate("/")}>
          {t("LoginPage.Title")}
        </Col>
      );
    }
  }

  const renderNavBar = () => {
    return (
      <Container fluid={true}>
        <Row className="navigation-header">
          {renderLoginNavBar()}
          <Col className="nav-button"
            onClick={() => navigate("/About")}>
            {t("AboutPage.Title")}
          </Col>
          <Col className="nav-button"
            onClick={() => navigate("/News")}>
            {t("NewsPage.Title")}
          </Col>
          <Col className="nav-button"
            onClick={() => navigate("/Guide")}>
            {t("GuidePage.Title")}
          </Col>
        </Row>
      </Container>
    );
  }

  return renderNavBar();
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.AuthenticationState.authTokenSet
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
