import { Reducer } from 'redux';
import { GetAuthToken, SetAuthToken, RemoveAuthToken } from '../../common/api/authTokenHelpers';
import { AuthenticationState } from '../states/authenticationState';
import {
  AuthenticationActionTypes,
  RegisterUserActionType,
  RegisterUserCompletedActionType,
  RegisterUserFailedActionType,
  LoginActionType,
  LoginCompletedActionType,
  LoginFailedActionType,
  ResetPasswordActionType,
  ResetPasswordCompletedActionType,
  ResetPasswordFailedActionType,
  LogoutActionType
} from '../actions/authenticationActions';

export type AuthenticationActions = 
  RegisterUserActionType
  | RegisterUserCompletedActionType
  | RegisterUserFailedActionType
  | LogoutActionType
  | LoginActionType
  | LoginCompletedActionType
  | LoginFailedActionType
  | ResetPasswordActionType
  | ResetPasswordCompletedActionType
  | ResetPasswordFailedActionType

export const initialState: AuthenticationState = {
  username: '',
  isLoading: false,
  hasError: false,
  errorMessage: '',
  authTokenSet: GetAuthToken() ? true : false,
  accessCode: '',
}

export const LoginReducer: Reducer<AuthenticationState> = (state = initialState, action: AuthenticationActions): AuthenticationState => {
  
  switch(action.type) {
    case AuthenticationActionTypes.RegisterUser:
      return {
        ...state,
        username: action.payload!.request.username,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        authTokenSet: false,
        accessCode: undefined
      };

    case AuthenticationActionTypes.RegisterUserCompleted:
      return {
        ...state,
        isLoading: false,
        accessCode: action.payload?.response?.accessCode
      };

    case AuthenticationActionTypes.RegisterUserFailed:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any
      };

    case AuthenticationActionTypes.Logout:
      RemoveAuthToken();
      return {
        ...state,
        username: '',
        isLoading: false,
        hasError: false,
        errorMessage: '',
        authTokenSet: false,
        accessCode: undefined
      };

    case AuthenticationActionTypes.Login:
      return {
        ...state,
        username: action.payload!.request.username,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        authTokenSet: false
      };

    case AuthenticationActionTypes.LoginCompleted:
      if (action.payload?.response?.authToken) {
        SetAuthToken(action.payload.response.authToken);
      }
      return {
        ...state,
        isLoading: false,
        authTokenSet: action.payload?.response?.authToken ? true : false
      };

    case AuthenticationActionTypes.LoginFailed:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any
      }; 

    case AuthenticationActionTypes.ResetPassword:
      return {
        ...state,
        username: action.payload!.request.username,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        authTokenSet: false,
        accessCode: undefined
      };

    case AuthenticationActionTypes.ResetPasswordCompleted:
      return {
        ...state,
        isLoading: false,
        accessCode: action.payload?.response?.accessCode
      };

    case AuthenticationActionTypes.ResetPasswordFailed:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any,
      }; 
    
    default:
      return state;
  }
}

export default LoginReducer;