import GameRules from '../../common/types/rules/GameRules';
import Action from './baseAction';

export enum RulesActionTypes {
  GetGameRules = "GET_GAME_RULES",
  GetGameRulesCompleted = "GET_GAME_RULES_COMPLETED",
  GetGameRulesFailed = "GET_GAME_RULES_FAILED",
}

export type GetGameRulesPayloadType = { };
export type GetGameRulesActionType = Action<RulesActionTypes.GetGameRules, GetGameRulesPayloadType>;
export const getGameRules = ():GetGameRulesActionType => ({type: RulesActionTypes.GetGameRules, payload: { } });

export type GetGameRulesCompletedPayloadType = { response: GameRules; };
export type GetGameRulesCompletedActionType = Action<RulesActionTypes.GetGameRulesCompleted, GetGameRulesCompletedPayloadType>;
export const getGameRulesCompleted = (response: GameRules): GetGameRulesCompletedActionType => ({type: RulesActionTypes.GetGameRulesCompleted, payload: { response } });

export type GetGameRulesFailedPayloadType = { error: Error; };
export type GetGameRulesFailedActionType = Action<RulesActionTypes.GetGameRulesFailed, GetGameRulesFailedPayloadType>;
export const getGameRulesFailed = (error: Error): GetGameRulesFailedActionType => ({type: RulesActionTypes.GetGameRulesFailed, payload: { error } });