import { Reducer } from 'redux';
import { CombatState } from '../states/combatState';
import {
  CloseBattleModalActionType,
  OpenBattleModalActionType,
  CombatActionTypes,
  RequestJoinBattleActionType,
  RequestJoinBattleCompletedActionType,
  RequestJoinBattleFailedActionType,
  UpdateBattleGoalActionType
} from '../actions/combatActions';

export type CombatActions = 
  RequestJoinBattleActionType
  | RequestJoinBattleCompletedActionType
  | RequestJoinBattleFailedActionType
  | OpenBattleModalActionType
  | CloseBattleModalActionType
  | UpdateBattleGoalActionType

const initialState: CombatState = {
  openBattles: [],
  isSubmitting: false,
  hasError: false,
  errorMessage: "",
  showModal: false,
  successfulJoin: false,
  lockBattleType: false,
  targetUserKingdomId: "",
  targetKingdomUserId: -1,
  targetKingdomUsername: "",
  battleGoal: "0"
}

export const CombatReducer: Reducer<CombatState> = (state = initialState, action: CombatActions): CombatState => {
  switch(action.type) {
    case CombatActionTypes.RequestJoinBattle:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
        errorMessage: '',
        successfulJoin: false,
      };
    case CombatActionTypes.RequestJoinBattleCompleted:
      let newOpenBattles = [
        ...state.openBattles
      ];

      if (newOpenBattles.filter(x => x.battleGoal === action.payload!.response.battle.battleGoal).length > 0) {
        newOpenBattles = newOpenBattles.map(x => {
          if (x.battleGoal === action.payload!.response.battle.battleGoal) {
            return action.payload!.response.battle;
          }

          return x;
        })
      } else {
        newOpenBattles.push(action.payload!.response.battle);
      }
      
      return {
        ...state,
        successfulJoin: true,
        isSubmitting: false,
        openBattles:  newOpenBattles
      };
    case CombatActionTypes.RequestJoinBattleFailed:
      return {
        ...state,
        isSubmitting: false,
        hasError: true,
        errorMessage: (action.payload!.error as any).responseMessage,
      };
    case CombatActionTypes.OpenBattleModal:
      return {
        ...initialState,
        showModal: true,
        openBattles: action.payload!.openBattles,
        lockBattleType: action.payload!.lockBattleType,
        targetUserKingdomId: action.payload!.targetUserKingdomId,
        targetKingdomUserId: action.payload!.targetKingdomUserId,
        targetKingdomUsername: action.payload!.targetKingdomUsername,
        battleGoal: action.payload!.openBattles.length > 0 ? action.payload!.openBattles[0].battleGoal.toString() : "0"
      };
    case CombatActionTypes.CloseBattleModal:
      return initialState;
    case CombatActionTypes.UpdateBattleGoal:
      return {
        ...state,
        battleGoal: action.payload!.battleGoal
      }
    default:
      return state;
  }
}

export default CombatReducer;