import { KeyValuePair } from "../KeyValuePair";

export const userSearchOrderByEnum: KeyValuePair[] = [
  { key: "Username"     , value: "Username"     },
  { key: "OwnedPlots"   , value: "OwnedPlots"   },
  { key: "Vassals"      , value: "Vassals"      },
  { key: "OwnedUp2"     , value: "OwnedUp2"     },
  { key: "VassalUp2"    , value: "VassalUp2"    },
  { key: "TotalUp2"     , value: "TotalUp2"     },
  { key: "Workers"      , value: "Workers"      },
  { key: "Rank"         , value: "Rank"         }
];