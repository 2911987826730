import * as ReactDOMClient from 'react-dom/client';
import React from "react";
import { createStore } from './app/store/createStore';
import AppContext, { AppContextValue } from './app/common/app-context';
import App from './App';
import { ApiService } from './app/common/api/api';
import i18next from './localizationService';

export const bootstrap = async (config: AppConfig) => {
  const apiService = new ApiService(config.uplandKingdomsApiUrl);
  const store = createStore(config, apiService);
  const localizationService = i18next;
  localizationService.init();

  const context: AppContextValue = {
      apiService
  }

  const container = document.getElementById('root')!;
  const root = ReactDOMClient.createRoot(container);

  root.render(
    <AppContext.Provider value={context}>
      <React.Suspense fallback="Loading...">
        <App store={store} />
      </React.Suspense>
    </AppContext.Provider>
  );
}