import Action from './baseAction';
import PostRegisterResponse from '../../common/types/messages/PostRegisterResponse';
import PostRegisterRequest from '../../common/types/messages/PostRegisterRequest';
import PostLoginRequest from '../../common/types/messages/PostLoginRequest';
import PostLoginResponse from '../../common/types/messages/PostLoginResponse';
import PostResetPasswordRequest from '../../common/types/messages/PostResetPasswordRequest';
import PostResetPasswordResponse from '../../common/types/messages/PostResetPasswordResponse';

export enum AuthenticationActionTypes {
  RegisterUser = 'REGISTER_USER',
  RegisterUserCompleted = 'REGISTER_USER_COMPLETED',
  RegisterUserFailed = 'REGUSTER_USER_FAILED',
  Login = 'LOGIN',
  LoginCompleted = 'LOGIN_COMPLETED',
  LoginFailed = 'LOGIN_FAILED',
  ResetPassword = 'RESET_PASSWORD',
  ResetPasswordCompleted = 'RESET_PASSWORD_COMPLETED',
  ResetPasswordFailed = 'RESET_PASSWORD_FAILED',
  Logout = 'LOGOUT',
}

export type RegisterUserPayloadType = { request: PostRegisterRequest; };
export type RegisterUserActionType = Action<AuthenticationActionTypes.RegisterUser, RegisterUserPayloadType>;
export const registerUser = (request: PostRegisterRequest): RegisterUserActionType => ({type: AuthenticationActionTypes.RegisterUser, payload: { request } });

export type RegisterUserCompletedPayloadType = { response: PostRegisterResponse; };
export type RegisterUserCompletedActionType = Action<AuthenticationActionTypes.RegisterUserCompleted, RegisterUserCompletedPayloadType>;
export const registerUserCompleted = (response: PostRegisterResponse): RegisterUserCompletedActionType => ({type: AuthenticationActionTypes.RegisterUserCompleted, payload: { response } });

export type RegisterUserFailedPayloadType = { error: Error; };
export type RegisterUserFailedActionType = Action<AuthenticationActionTypes.RegisterUserFailed, RegisterUserFailedPayloadType>;
export const registerUserFailed = (error: Error): RegisterUserFailedActionType => ({type: AuthenticationActionTypes.RegisterUserFailed, payload: { error } });

export type LogoutPayloadType = { };
export type LogoutActionType = Action<AuthenticationActionTypes.Logout, LogoutPayloadType>;
export const logout = (): LogoutActionType => ({type: AuthenticationActionTypes.Logout, payload: { } });

export type LoginPayloadType = { request: PostLoginRequest; };
export type LoginActionType = Action<AuthenticationActionTypes.Login, LoginPayloadType>;
export const login = (request: PostLoginRequest): LoginActionType => ({type: AuthenticationActionTypes.Login, payload: { request } });

export type LoginCompletedPayloadType = { response: PostLoginResponse; };
export type LoginCompletedActionType = Action<AuthenticationActionTypes.LoginCompleted, LoginCompletedPayloadType>;
export const loginCompleted = (response: PostLoginResponse): LoginCompletedActionType => ({type: AuthenticationActionTypes.LoginCompleted, payload: { response } });

export type LoginFailedPayloadType = { error: Error; };
export type LoginFailedActionType = Action<AuthenticationActionTypes.LoginFailed, LoginFailedPayloadType>;
export const loginFailed = (error: Error): LoginFailedActionType => ({type: AuthenticationActionTypes.LoginFailed, payload: { error } });

export type ResetPasswordPayloadType = { request: PostResetPasswordRequest; };
export type ResetPasswordActionType = Action<AuthenticationActionTypes.ResetPassword, ResetPasswordPayloadType>;
export const resetPassword = (request: PostResetPasswordRequest): ResetPasswordActionType => ({type: AuthenticationActionTypes.ResetPassword, payload: { request } });

export type ResetPasswordCompletedPayloadType = { response: PostResetPasswordResponse; };
export type ResetPasswordCompletedActionType = Action<AuthenticationActionTypes.ResetPasswordCompleted, ResetPasswordCompletedPayloadType>;
export const resetPasswordCompleted = (response: PostResetPasswordResponse): ResetPasswordCompletedActionType => ({type: AuthenticationActionTypes.ResetPasswordCompleted, payload: { response } });

export type ResetPasswordFailedPayloadType = { error: Error; };
export type ResetPasswordFailedActionType = Action<AuthenticationActionTypes.ResetPasswordFailed, ResetPasswordFailedPayloadType>;
export const resetPasswordFailed = (error: Error): ResetPasswordFailedActionType => ({type: AuthenticationActionTypes.ResetPasswordFailed, payload: { error } });
