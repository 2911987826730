import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import GameRules from '../../common/types/rules/GameRules';
import {
  RulesActionTypes,
  GetGameRulesActionType,
  GetGameRulesCompletedActionType,
  GetGameRulesFailedActionType,
  getGameRulesCompleted,
  getGameRulesFailed
} from '../actions/rulesActions';

export const getGameRulesEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(RulesActionTypes.GetGameRules),
    mergeMap((action) => onGetGameRules(action, dependencies))
  );
}

export const onGetGameRules: (action: GetGameRulesActionType, dependencies: AppDependencies) 
  => Observable<GetGameRulesCompletedActionType | GetGameRulesFailedActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.getGameRules$();

  return callResponse$.pipe(
    map(response => {
      return getGameRulesCompleted(response as GameRules);
    }),
    catchError(error => {
      return of(getGameRulesFailed(error.response.value));
    })
  );
}

