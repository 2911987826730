import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, Form, ModalBody, Modal, Col} from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { closeBattleModal,  requestJoinBattle, updateBattleGoal } from '../../store/actions/combatActions';
import GroupedWorkers from '../../common/types/userKingdom/groupedWorkers';
import JoinBattleRequest from '../../common/types/messages/JoinBattleRequest';
import UnitPlacementComponent from './unitPlacementComponent';
import { battleGoalEnum } from '../../common/types/enums/battleGoal';
import CombatUnitLocation from '../../common/types/combat/CombatUnitLocation';

interface StateProps {
  combatState: CombatState;
  username?: string;
  overlordUsername? : string;
  soldiers: GroupedWorkers[];
  currentTick: number;
  kingdomId: number;
  userId: number;
  selfUserKingdomId: string;
};

interface DispatchProps {
  closeBattleModal: () => void;
  requestJoinBattle: (request: JoinBattleRequest) => void;
  updateBattleGoal: (battleGoal: string) => void;
}

interface OwnProps {

}

export type CombatModalProps = DispatchProps & StateProps & OwnProps;

const CombatModal = (props: CombatModalProps) => {
  const [setAttackerUnits, setSetAttackerUnits] = useState(false);
  const [setDefenderUnits, setSetDefenderUnits] = useState(false);
  
  const { t } = useTranslation();
  // create an event listener
  useEffect(() => {
    const combatModal = document.getElementById("combatModalId");

    if (combatModal) {
      combatModal!.addEventListener("contextmenu", ( e )=> { e.preventDefault(); return false; } );
    }
  })

  const displayOKButton = () => {
    return (
      <Row>
        <Col></Col>
        <Col></Col>
        <Col className="sub-entry-margin-class morris-lg pt-2">
          <div className="darkStone-button" onClick={() => props.closeBattleModal()}>
            {t("Buttons.Ok")}
          </div>
        </Col>
      </Row>
    );
  }

  const displayAttackersDefenders = () => {
    let battleGoalNumber = parseInt(props.combatState.battleGoal);

    let battleExists = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber).length > 0;
    let battle = undefined;

    let totalAttackers = 0;
    let totalDefenders = 0;

    if (battleExists) {
      battle = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber)[0];
      totalAttackers = battle.battleCombatants.filter(x => x.isAttacker).reduce((a,b) => a + b.unitStartCount, 0);
      totalDefenders = battle.battleCombatants.filter(x => !x.isAttacker).reduce((a,b) => a + b.unitStartCount, 0)
    }

    return (
      <>
        <Row className={`resource-table-sub-entry dark-wheat-background top-border side-borders bottom-border morris-lg`}>
          <Col>
            {t("CombatPanel.Attackers")} - {totalAttackers}
            <div className={`darkStone-button ${isAttackerJoinDisabled() && 'disabled'}`} onClick={() => !isAttackerJoinDisabled() && setSetAttackerUnits(true)}>
              {t("CombatPanel.Join")}
            </div>
          </Col>
          <Col>
            {t("CombatPanel.Defenders")} - {totalDefenders}
            <div className={`darkStone-button ${isDefenderJoinDisabled() && 'disabled'}`} onClick={() => !isDefenderJoinDisabled() && setSetDefenderUnits(true)}>
              {t("CombatPanel.Join")}
            </div>
          </Col>
        </Row>
        {battleExists && <Row className={`resource-table-sub-entry side-borders bottom-border standard-text`}>
          <Col className="resource-table-sub-entry right-border">
            {battle!.battleCombatants
              .filter(x => x.isAttacker)
              .map(x => {
                return (
                  <Row key={x.id}>
                    <Col>
                      {x.username}
                    </Col>
                    <Col>
                      {x.unitStartCount}
                    </Col>
                  </Row>
                );
              })}
          </Col>
          <Col>
            {battle!.battleCombatants
              .filter(x => !x.isAttacker)
              .map(x => {
                return (
                  <Row key={x.id}>
                    <Col>
                      {x.username}
                    </Col>
                    <Col>
                      {x.unitStartCount}
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>}
      </>
    );
  }

  const isAttackerJoinDisabled = () => {
    let battleGoalNumber = parseInt(props.combatState.battleGoal);

    let battleExists = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber).length > 0;

    if (props.combatState.targetKingdomUserId === props.userId) {
      return true;
    }

    if (battleExists) {
      let battle = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber)[0];

      if (battle.battleCombatants.filter(x => !x.isAttacker && x.userKingdomId === props.selfUserKingdomId).length > 0) {
        return true;
      }
    }

    return false;
  }

  const isDefenderJoinDisabled = () => {
    let battleGoalNumber = parseInt(props.combatState.battleGoal);

    let battleExists = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber).length > 0;

    if (!battleExists) {
      return true;
    }

    if (battleExists) {
      let battle = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber)[0];

      if (battle.battleCombatants.filter(x => x.isAttacker && x.userKingdomId === props.selfUserKingdomId).length > 0) {
        return true;
      }
    }

    return false;
  }

  const renderRedTextBox = () => {
    if (!props.combatState.errorMessage || props.combatState.errorMessage === '') {
      return <></>;
    }
    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{t(`Exceptions.${props.combatState.errorMessage}`)}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const renderSuccessTextBox = () => {
    if (props.combatState.successfulJoin) {
      return (
        <Row>
          <Col>
            <div className="green-text-box">
              <b>{t(`CombatPanel.JoinedCombat`)}</b>
            </div>
          </Col>
        </Row>
      );
    }
  }


  const GetBattleGoalDropDownOptions = () => {
    return battleGoalEnum.map((x) => {
      return <option key={x.key} value={x.value}>{t("CombatPanel.BattleOf")} {t(`BattleGoals.${x.key}`)}</option>
    })
  }

  const setNewBattleGoal = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    props.updateBattleGoal(changeEvent.currentTarget.value);
  }

  const displayBattleTypeDropdown = () => {
    let battleGoalNumber = parseInt(props.combatState.battleGoal);
    let resolveDateString = t("CombatPanel.NewBattleDate");
    
    if (props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber).length > 0) {
      let tickDate = props.combatState.openBattles.filter(x => x.battleGoal === battleGoalNumber)[0].resolveOnTickDate;
      resolveDateString = t("GameDate.Day", { count: tickDate.gameDayOfMonth, ordinal: true }) + t(`GameDate.${tickDate.gameSeason}`, { count: tickDate.gameYear })
    }

    return (
      <>
        <Row>
          <Col>
              <Form.Select 
                className="drop-down-style wheat_dark center-text" 
                value={props.combatState.battleGoal} 
                onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => setNewBattleGoal(changeEvent)}
                onClick={() => console.log("click")}
                disabled={props.combatState.lockBattleType}>
                {GetBattleGoalDropDownOptions()}
              </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col className="morris-lg">
            {t("CombatPanel.Against")} {props.combatState.targetKingdomUsername}
          </Col>
        </Row>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <div className="quaternary-header">
              {resolveDateString}
            </div>
          </Col>
        </Row>
      </>
    );
  }

  const sendSaveUnits = (units: CombatUnitLocation[], isAttacker: boolean, numberOfFormations: number) => {
    props.requestJoinBattle({
      userId: props.userId,
      goal: parseInt(props.combatState.battleGoal),
      isAttacker: isAttacker,
      targetUserKingdomId: props.combatState.targetUserKingdomId,
      units: units,
      numberOfFormations: numberOfFormations
    } as JoinBattleRequest)
  }

  const renderBody = () => {
    if (setAttackerUnits) {
      return (
        <UnitPlacementComponent 
          isAttacker={true}
          cancelJoinCombat={() => setSetAttackerUnits(false)}
          requestJoinBattle={sendSaveUnits} />
      );
    } else if (setDefenderUnits) {
      return (
        <UnitPlacementComponent 
          isAttacker={false}
          cancelJoinCombat={() => setSetDefenderUnits(false)}
          requestJoinBattle={sendSaveUnits} />
      );
    } else {
      return (
        <>
          {displayBattleTypeDropdown()}
          {displayAttackersDefenders()}
          {displayOKButton()}
        </>
      );
    }
  }

  const renderCombatModal = () => {
    return (
      <Modal id="combatModalId" show={props.combatState.showModal} onHide={props.closeBattleModal} animation={false} centered size="lg">
        <ModalBody className="standard-text">
          {renderRedTextBox()}
          {renderSuccessTextBox()}
          {renderBody()}
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.combatState.showModal && renderCombatModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closeBattleModal: () => dispatch(closeBattleModal()),
    requestJoinBattle: (request: JoinBattleRequest) => dispatch(requestJoinBattle(request)),
    updateBattleGoal: (battleGoal: string) => dispatch(updateBattleGoal(battleGoal))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    combatState: state.CombatState,
    username: state.UserKingdomState.userKingdom?.uplandUsername,
    overlordUsername: state.UserKingdomState.userKingdom?.overlord,
    soldiers: state.UserKingdomState.userKingdom?.groupedWorkers.filter((x: GroupedWorkers) => (x.class === "Military" || x.typeString === "Peasant") && x.numberUnemployed > 0),
    currentTick: state.UserKingdomState.userKingdom?.currentTick,
    kingdomId: state.UserKingdomState.userKingdom?.kingdomId,
    userId: state.UserKingdomState.userKingdom?.userId,
    selfUserKingdomId: state.UserKingdomState.userKingdom?.userKingdomId
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(CombatModal);