import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, ModalTitle, ModalBody, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { closeSaleUPXModal } from '../../store/actions/marketActions';

interface StateProps {
  showUpxSaleModal: boolean,
  amount: number,
};

interface DispatchProps {
  closeUpxSaleModal: () => void;
}

export type UPXSaleModalProps = DispatchProps & StateProps;

const UPXSaleModal = (props: UPXSaleModalProps) => {
  const { t } = useTranslation();

  const renderReloadModal = () => {
    return (
      <Modal show={props.showUpxSaleModal} onHide={() => props.closeUpxSaleModal()} animation={false} centered>
        <ModalTitle className="primary-header">{t("MarketPage.UpxSaleModalTitle")}</ModalTitle>
        <Row className="px-5"> 
          <div className="outer-fancy-border-sm">
            <div className="inner-fancy-border-sm">
            </div>
          </div>
        </Row>
        <ModalBody className="standard-text">
          {t("MarketPage.UpxSaleModalBody", { amount: props.amount })}
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {props.showUpxSaleModal && renderReloadModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closeUpxSaleModal: () => dispatch(closeSaleUPXModal())
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    showUpxSaleModal: state.MarketState.showUpxModal,
    amount: state.MarketState.upxModalAmount,
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(UPXSaleModal);