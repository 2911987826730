import { Container, Row } from 'react-bootstrap'
import { Footer } from '../../components/footerComponent/footer';
import Header from '../../components/headerComponent/header';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  const { t } = useTranslation();

  const renderNotFoundPage = () => {
    return (
      <Container fluid="md">
        <Row>
          <div className="primary-header">{t("NotFoundPage.Title")}</div>
        </Row>
      </Container>
    );
  }

  return (
      <>
        <div className="footer-padding">
          <Header />
          {renderNotFoundPage()}
        </div>
        <Footer />
      </>
  );
}