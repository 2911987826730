import Battle from '../../../common/types/userKingdom/battle';
import Job from '../../../common/types/userKingdom/job';

export function GetImprovementIdToDeleteOnJobCancel(jobId: string, jobs: Job[]) {
  jobs.forEach((x) => {
    if (x.id === jobId && x.improvementId) {
      return x.improvementId;
    }
  })

  return undefined;
}

export function UpdateBattles(existingCombats: Battle[], updatedCombats: Battle[]) {
  let newCombat = [];

  for (let i = 0; i< existingCombats.length; i++) {
    if (updatedCombats.filter((x: Battle) => x.id === existingCombats[i].id).length > 0) {
      newCombat.push(updatedCombats.filter((x: Battle) => x.id === existingCombats[i].id)[0]);
    } else {
      newCombat.push(existingCombats[i]);
    }
  }

  for (let i = 0; i< updatedCombats.length; i++) {
    if (newCombat.filter((x: Battle) => x.id === updatedCombats[i].id).length === 0) {
      newCombat.push(updatedCombats.filter((x: Battle) => x.id === updatedCombats[i].id)[0]);
    }
  }

  return newCombat;
}