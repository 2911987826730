import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import PostRespondToCombatResponse from '../../common/types/messages/PostRespondToCombatResponse';
import {
  CombatActionTypes, RequestJoinBattleActionType, requestJoinBattleCompleted, RequestJoinBattleCompletedActionType, requestJoinBattleFailed, RequestJoinBattleFailedActionType,

} from '../actions/combatActions';
import { cityUserEntityExpired, CityUserEntityExpiredActionType } from '../actions/userKingdomActions';

export const requestJoinBattleEpic: AppEpic = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(CombatActionTypes.RequestJoinBattle),
    mergeMap((action) => onRequestJoinBattle(action, dependencies))
  );
}

export const onRequestJoinBattle: (action: RequestJoinBattleActionType, dependencies: AppDependencies) 
  => Observable<RequestJoinBattleCompletedActionType | RequestJoinBattleFailedActionType | CityUserEntityExpiredActionType> = (action, { apiService }) => {
    
  const callResponse$ = apiService.postJoinBattleRequest$(action.payload!.request);

  return callResponse$.pipe(
    map(response => {
      return requestJoinBattleCompleted(response as PostRespondToCombatResponse);
    }),
    catchError(error => {
      if (error.status === 409) {
        return of(cityUserEntityExpired())
      }
      return of(requestJoinBattleFailed(error.response.value));
    })
  );
}


