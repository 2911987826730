import Action from './baseAction';
import GetUserKingdomResponse from '../../common/types/messages/GetUserKingdomResponse';
import PostCancelJobRequest from '../../common/types/messages/PostCancelJobRequest';
import PostManageImprovementWorkersRequest  from '../../common/types/messages/PostManageImprovementWorkersRequest';
import PostManageJobWorkersRequest from '../../common/types/messages/PostManageJobWorkersRequest';
import PostDemolishImprovementRequest from '../../common/types/messages/PostDemolishImprovementRequest';
import PostChangeProductionTypeRequest from '../../common/types/messages/PostChangeProductionTypeRequest';
import PostChangeCropTypeRequest from '../../common/types/messages/PostChangeCropTypeRequest';
import PostTrainingRequest from '../../common/types/messages/PostTrainingRequest';
import PostCancelTrainingRequest from '../../common/types/messages/PostCancelTrainingRequest';
import GetActiveKingdomsResponse from '../../common/types/messages/GetActiveKingdomsResponse';
import PostToggleGrowthRequest from '../../common/types/messages/PostToggleGrowthRequest';
import Improvement from '../../common/types/userKingdom/improvement';
import PostUpdateUserSettingsRequest from '../../common/types/messages/PostUpdateUserSettingsRequest';

export enum UserKingdomActionTypes {
  GetUserKingdom = "GET_USERKINGDOM",
  GetUserKingdomCompleted = "GET_USERKINGDOM_COMPLETED",
  GetUserKingdomFailed = "GET_USERKINGDOM_FAILED",
  UpdateResourceAmounts = "UPDATE_RESOURCE_AMOUNTS",
  DeleteJob = "DELETE_JOB",
  DeleteJobCompleted = "DELETE_JOB_COMPLETED",
  DeleteJobFailed = "DELETE_JOB_FAILED",
  CityUserEntityExpired = "CITY_USER_ENTITY_EXPIRED",
  ManageJobWorkers = "MANAGE_JOB_WORKERS",
  ManageJobWorkersCompleted = "MANAGE_JOB_WORKERS_COMPLETED",
  ManageJobWorkersFailed = "MANAGE_JOB_WORKERS_FAILED",
  ManageImprovementWorkers = "MANAGE_IMPROVEMENT_WORKERS",
  ManageImprovementWorkersCompleted = "MANAGE_IMPROVEMENT_WORKERS_COMPLETED",
  ManageImprovementWorkersFailed = "MANAGE_IMPROVEMENT_WORKERS_FAILED",
  DemolishImprovement = "DEMOLISH_IMPROVEMENT",
  DemolishImprovementCompleted = "DEMOLISH_IMPROVEMENT_COMPLETED",
  DemolishImprovementFailed = "DEMOLISH_IMPROVEMENT_FAILED",
  ChangeProductionType = "CHANGE_PRODUCTION_TYPE",
  ChangeProductionTypeCompleted = "CHANGE_PRODUCTION_TYPE_COMPLETED",
  ChangeProductionTypeFailed = "CHANGE_PRODUCTION_TYPE_FAILED",
  RequestChangeProductionTypeValues = "REQUEST_CHANGE_PRODUCTION_TYPE_VALUES",
  RequestChangeProductionTypeValuesCompleted = "REQUEST_CHANGE_PRODUCTION_TYPE_VALUES_COMPLETED",
  RequestChangeProductionTypeValuesFailed = "REQUEST_CHANGE_PRODUCTION_TYPE_VALUES_FAILED",
  RequestChangeCropTypeValues = "REQUEST_CHANGE_CROP_TYPE_VALUES",
  RequestChangeCropTypeValuesCompleted = "REQUEST_CHANGE_CROP_TYPE_VALUES_COMPLETED",
  RequestChangeCropTypeValuesFailed = "REQUEST_CHANGE_CROP_TYPE_VALUES_FAILED",
  ChangeCropType = "CHANGE_CROP_TYPE",
  ChangeCropTypeCompleted = "CHANGE_CROP_TYPE_COMPLETED",
  ChangeCropTypeFailed = "CHANGE_CROP_TYPE_FAILED",
  ToggleNewJobModal = "TOGGLE_NEW_JOB_MODAL",
  RequestJob = "REQUEST_JOB",
  RequestJobCompleted = "REQUEST_JOB_COMPLETED",
  RequestJobFailed = "REQUEST_JOB_FAILED",
  RequestTraining = "REQUEST_TRAINING",
  RequestTrainingCompleted = "REQUEST_TRAINING_COMPLETED",
  RequestTrainingFailed = "REQUEST_TRAINING_FAILED",
  CancelTrainingRequest = "CANCEL_TRAINING_REQUEST",
  CancelTrainingRequestCompleted = "CANCEL_TRAINING_REQUEST_COMPLETED",
  CancelTrainingRequestFailed = "CANCEL_TRAINING_REQUEST_FAILED",
  GetActiveKingdoms = "GET_ACTIVE_KINGDOMS",
  GetActiveKingdomsCompleted = "GET_ACTIVE_KINGDOMS_COMPLETED",
  GetActiveKingdomsFailed = "GET_ACTIVE_KINGDOMS_FAILED",
  UpdateLandsOrderBy = "UPDATE_LANDS_ORDER_BY",
  OpenDemolishImprovementConfirmModal = "OPEN_DEMOLISH_IMPROVEMENT_CONFIRM_MODAL",
  CloseDemolishImprovementConfirmModal = "CLOSE_DEMOLISH_IMPROVEMENT_CONFIRM_MODAL",
  ToggleGrowth = "TOGGLE_GROWTH",
  ToggleGrowthCompleted = "TOGGLE_GROWTH_COMPLETED",
  ToggleGrowthFailed = "TOGGLE_GROWTH_FAILED",
  UpdateUserSettings = "UPDATE_USER_SETTINGS",
  UpdateUserSettingsCompleted = "UPDATE_USER_SETTINGS_COMPLETED",
  UpdateUserSettingsFailed = "UPDATE_USER_SETTINGS_FAILED",
  ToggleUserSettingsModal = "TOGGLE_USER_SETTINGS_MODAL",

  DebugProcessTick = "DEBUG_PROCESS_TICK",
  DebugProcessTickCompleted = "DEBUG_PROCESS_TICK_COMPLETED",
  DebugProcessTickFailed = "DEBUG_PROCESS_TICK_FAILED"
}

export type GetUserKingdomPayloadType = { kingdomId: number };
export type GetUserKingdomActionType = Action<UserKingdomActionTypes.GetUserKingdom, GetUserKingdomPayloadType>;
export const getUserKingdom = (kingdomId: number): GetUserKingdomActionType => ({type: UserKingdomActionTypes.GetUserKingdom, payload: { kingdomId } });

export type GetUserKingdomCompletedPayloadType = { response: GetUserKingdomResponse; };
export type GetUserKingdomCompletedActionType = Action<UserKingdomActionTypes.GetUserKingdomCompleted, GetUserKingdomCompletedPayloadType>;
export const getUserKingdomCompleted = (response: GetUserKingdomResponse): GetUserKingdomCompletedActionType => ({type: UserKingdomActionTypes.GetUserKingdomCompleted, payload: { response } });

export type GetUserKingdomFailedPayloadType = { error: Error; };
export type GetUserKingdomFailedActionType = Action<UserKingdomActionTypes.GetUserKingdomFailed, GetUserKingdomFailedPayloadType>;
export const getUserKingdomFailed = (error: Error): GetUserKingdomFailedActionType => ({type: UserKingdomActionTypes.GetUserKingdomFailed, payload: { error } });

export type GetActiveKingdomsPayloadType = { };
export type GetActiveKingdomsActionType = Action<UserKingdomActionTypes.GetActiveKingdoms, GetActiveKingdomsPayloadType>;
export const getActiveKingdoms = (): GetActiveKingdomsActionType => ({type: UserKingdomActionTypes.GetActiveKingdoms, payload: { } });

export type GetActiveKingdomsCompletedPayloadType = { response: GetActiveKingdomsResponse; };
export type GetActiveKingdomsCompletedActionType = Action<UserKingdomActionTypes.GetActiveKingdomsCompleted, GetActiveKingdomsCompletedPayloadType>;
export const getActiveKingdomsCompleted = (response: GetActiveKingdomsResponse): GetActiveKingdomsCompletedActionType => ({type: UserKingdomActionTypes.GetActiveKingdomsCompleted, payload: { response } });

export type GetActiveKingdomsFailedPayloadType = { error: Error; };
export type GetActiveKingdomsFailedActionType = Action<UserKingdomActionTypes.GetActiveKingdomsFailed, GetActiveKingdomsFailedPayloadType>;
export const getActiveKingdomsFailed = (error: Error): GetActiveKingdomsFailedActionType => ({type: UserKingdomActionTypes.GetActiveKingdomsFailed, payload: { error } });

export type UpdateResourceAmountsPayloadType = { resourceType: number, maxAmount?: number, upkeepPriority: number, amount: number; };
export type UpdateResourceAmountsActionType = Action<UserKingdomActionTypes.UpdateResourceAmounts, UpdateResourceAmountsPayloadType>;
export const updateResourceAmounts = (resourceType: number, upkeepPriority: number, amount: number, maxAmount?: number): UpdateResourceAmountsActionType => ({type: UserKingdomActionTypes.UpdateResourceAmounts, payload: { resourceType: resourceType, maxAmount: maxAmount, upkeepPriority: upkeepPriority, amount: amount} });

export type CityUserEntityExpiredPayloadType = { };
export type CityUserEntityExpiredActionType = Action<UserKingdomActionTypes.CityUserEntityExpired, CityUserEntityExpiredPayloadType>;
export const cityUserEntityExpired = (): CityUserEntityExpiredActionType => ({type: UserKingdomActionTypes.CityUserEntityExpired, payload: { } });

export type DeleteJobPayloadType = { request: PostCancelJobRequest };
export type DeleteJobActionType = Action<UserKingdomActionTypes.DeleteJob, DeleteJobPayloadType>;
export const deleteJob = (request: PostCancelJobRequest):DeleteJobActionType => ({type: UserKingdomActionTypes.DeleteJob, payload: { request } });

export type DeleteJobCompletedPayloadType = { request: PostCancelJobRequest, response: GetUserKingdomResponse };
export type DeleteJobCompletedActionType = Action<UserKingdomActionTypes.DeleteJobCompleted, DeleteJobCompletedPayloadType>;
export const deleteJobCompleted = (request: PostCancelJobRequest, response: GetUserKingdomResponse): DeleteJobCompletedActionType => ({type: UserKingdomActionTypes.DeleteJobCompleted, payload: { request, response } });

export type DeleteJobFailedPayloadType = { error: Error; };
export type DeleteJobFailedActionType = Action<UserKingdomActionTypes.DeleteJobFailed, DeleteJobFailedPayloadType>;
export const deleteJobFailed = (error: Error): DeleteJobFailedActionType => ({type: UserKingdomActionTypes.DeleteJobFailed, payload: { error } });

export type ManageJobWorkersPayloadType = { request: PostManageJobWorkersRequest };
export type ManageJobWorkersActionType = Action<UserKingdomActionTypes.ManageJobWorkers, ManageJobWorkersPayloadType>;
export const manageJobWorkers = (request: PostManageJobWorkersRequest):ManageJobWorkersActionType => ({type: UserKingdomActionTypes.ManageJobWorkers, payload: { request } });

export type ManageJobWorkersCompletedPayloadType = { request: PostManageJobWorkersRequest, response: GetUserKingdomResponse };
export type ManageJobWorkersCompletedActionType = Action<UserKingdomActionTypes.ManageJobWorkersCompleted, ManageJobWorkersCompletedPayloadType>;
export const manageJobWorkersCompleted = (request: PostManageJobWorkersRequest, response: GetUserKingdomResponse): ManageJobWorkersCompletedActionType => ({type: UserKingdomActionTypes.ManageJobWorkersCompleted, payload: { request, response } });

export type ManageJobWorkersFailedPayloadType = { error: Error; };
export type ManageJobWorkersFailedActionType = Action<UserKingdomActionTypes.ManageJobWorkersFailed, ManageJobWorkersFailedPayloadType>;
export const manageJobWorkersFailed = (error: Error): ManageJobWorkersFailedActionType => ({type: UserKingdomActionTypes.ManageJobWorkersFailed, payload: { error } });

export type ManageImprovementWorkersPayloadType = { request: PostManageImprovementWorkersRequest };
export type ManageImprovementWorkersActionType = Action<UserKingdomActionTypes.ManageImprovementWorkers, ManageImprovementWorkersPayloadType>;
export const manageImprovementWorkers = (request: PostManageImprovementWorkersRequest):ManageImprovementWorkersActionType => ({type: UserKingdomActionTypes.ManageImprovementWorkers, payload: { request } });

export type ManageImprovementWorkersCompletedPayloadType = { request: PostManageImprovementWorkersRequest, response: GetUserKingdomResponse };
export type ManageImprovementWorkersCompletedActionType = Action<UserKingdomActionTypes.ManageImprovementWorkersCompleted, ManageImprovementWorkersCompletedPayloadType>;
export const manageImprovementWorkersCompleted = (request: PostManageImprovementWorkersRequest, response: GetUserKingdomResponse): ManageImprovementWorkersCompletedActionType => ({type: UserKingdomActionTypes.ManageImprovementWorkersCompleted, payload: { request, response } });

export type ManageImprovementWorkersFailedPayloadType = { error: Error; };
export type ManageImprovementWorkersFailedActionType = Action<UserKingdomActionTypes.ManageImprovementWorkersFailed, ManageImprovementWorkersFailedPayloadType>;
export const manageImprovementWorkersFailed = (error: Error): ManageImprovementWorkersFailedActionType => ({type: UserKingdomActionTypes.ManageImprovementWorkersFailed, payload: { error } });

export type DemolishImprovementPayloadType = { request: PostDemolishImprovementRequest };
export type DemolishImprovementActionType = Action<UserKingdomActionTypes.DemolishImprovement, DemolishImprovementPayloadType>;
export const demolishImprovement = (request: PostDemolishImprovementRequest):DemolishImprovementActionType => ({type: UserKingdomActionTypes.DemolishImprovement, payload: { request } });

export type DemolishImprovementCompletedPayloadType = { request: PostDemolishImprovementRequest, response: GetUserKingdomResponse };
export type DemolishImprovementCompletedActionType = Action<UserKingdomActionTypes.DemolishImprovementCompleted, DemolishImprovementCompletedPayloadType>;
export const demolishImprovementCompleted = (request: PostDemolishImprovementRequest, response: GetUserKingdomResponse): DemolishImprovementCompletedActionType => ({type: UserKingdomActionTypes.DemolishImprovementCompleted, payload: { request, response } });

export type DemolishImprovementFailedPayloadType = { error: Error; };
export type DemolishImprovementFailedActionType = Action<UserKingdomActionTypes.DemolishImprovementFailed, DemolishImprovementFailedPayloadType>;
export const demolishImprovementFailed = (error: Error): DemolishImprovementFailedActionType => ({type: UserKingdomActionTypes.DemolishImprovementFailed, payload: { error } });

export type ChangeProductionTypePayloadType = { request: PostChangeProductionTypeRequest };
export type ChangeProductionTypeActionType = Action<UserKingdomActionTypes.ChangeProductionType, ChangeProductionTypePayloadType>;
export const changeProductionType = (request: PostChangeProductionTypeRequest):ChangeProductionTypeActionType => ({type: UserKingdomActionTypes.ChangeProductionType, payload: { request } });

export type ChangeProductionTypeCompletedPayloadType = { request: PostChangeProductionTypeRequest, response: GetUserKingdomResponse };
export type ChangeProductionTypeCompletedActionType = Action<UserKingdomActionTypes.ChangeProductionTypeCompleted, ChangeProductionTypeCompletedPayloadType>;
export const changeProductionTypeCompleted = (request: PostChangeProductionTypeRequest, response: GetUserKingdomResponse): ChangeProductionTypeCompletedActionType => ({type: UserKingdomActionTypes.ChangeProductionTypeCompleted, payload: { request, response } });

export type ChangeProductionTypeFailedPayloadType = { error: Error; };
export type ChangeProductionTypeFailedActionType = Action<UserKingdomActionTypes.ChangeProductionTypeFailed, ChangeProductionTypeFailedPayloadType>;
export const changeProductionTypeFailed = (error: Error): ChangeProductionTypeFailedActionType => ({type: UserKingdomActionTypes.ChangeProductionTypeFailed, payload: { error } });

export type RequestChangeProductionTypeValuesPayloadType = { request: PostChangeProductionTypeRequest };
export type RequestChangeProductionTypeValuesActionType = Action<UserKingdomActionTypes.RequestChangeProductionTypeValues, RequestChangeProductionTypeValuesPayloadType>;
export const requestChangeProductionTypeValues = (request: PostChangeProductionTypeRequest):RequestChangeProductionTypeValuesActionType => ({type: UserKingdomActionTypes.RequestChangeProductionTypeValues, payload: { request } });

export type RequestChangeProductionTypeValuesCompletedPayloadType = { request: PostChangeProductionTypeRequest, response: Improvement };
export type RequestChangeProductionTypeValuesCompletedActionType = Action<UserKingdomActionTypes.RequestChangeProductionTypeValuesCompleted, RequestChangeProductionTypeValuesCompletedPayloadType>;
export const requestChangeProductionTypeValuesCompleted = (request: PostChangeProductionTypeRequest, response: Improvement): RequestChangeProductionTypeValuesCompletedActionType => ({type: UserKingdomActionTypes.RequestChangeProductionTypeValuesCompleted, payload: { request, response } });

export type RequestChangeProductionTypeValuesFailedPayloadType = { error: Error; };
export type RequestChangeProductionTypeValuesFailedActionType = Action<UserKingdomActionTypes.RequestChangeProductionTypeValuesFailed, RequestChangeProductionTypeValuesFailedPayloadType>;
export const requestChangeProductionTypeValuesFailed = (error: Error): RequestChangeProductionTypeValuesFailedActionType => ({type: UserKingdomActionTypes.RequestChangeProductionTypeValuesFailed, payload: { error } });

export type RequestChangeCropTypeValuesPayloadType = { request: PostChangeCropTypeRequest };
export type RequestChangeCropTypeValuesActionType = Action<UserKingdomActionTypes.RequestChangeCropTypeValues, RequestChangeCropTypeValuesPayloadType>;
export const requestChangeCropTypeValues = (request: PostChangeCropTypeRequest):RequestChangeCropTypeValuesActionType => ({type: UserKingdomActionTypes.RequestChangeCropTypeValues, payload: { request } });

export type RequestChangeCropTypeValuesCompletedPayloadType = { request: PostChangeCropTypeRequest, response: Improvement };
export type RequestChangeCropTypeValuesCompletedActionType = Action<UserKingdomActionTypes.RequestChangeCropTypeValuesCompleted, RequestChangeCropTypeValuesCompletedPayloadType>;
export const requestChangeCropTypeValuesCompleted = (request: PostChangeCropTypeRequest, response: Improvement): RequestChangeCropTypeValuesCompletedActionType => ({type: UserKingdomActionTypes.RequestChangeCropTypeValuesCompleted, payload: { request, response } });

export type RequestChangeCropTypeValuesFailedPayloadType = { error: Error; };
export type RequestChangeCropTypeValuesFailedActionType = Action<UserKingdomActionTypes.RequestChangeCropTypeValuesFailed, RequestChangeCropTypeValuesFailedPayloadType>;
export const requestChangeCropTypeValuesFailed = (error: Error): RequestChangeCropTypeValuesFailedActionType => ({type: UserKingdomActionTypes.RequestChangeCropTypeValuesFailed, payload: { error } });

export type ChangeCropTypePayloadType = { request: PostChangeCropTypeRequest };
export type ChangeCropTypeActionType = Action<UserKingdomActionTypes.ChangeCropType, ChangeCropTypePayloadType>;
export const changeCropType = (request: PostChangeCropTypeRequest):ChangeCropTypeActionType => ({type: UserKingdomActionTypes.ChangeCropType, payload: { request } });

export type ChangeCropTypeCompletedPayloadType = { request: PostChangeCropTypeRequest, response: GetUserKingdomResponse };
export type ChangeCropTypeCompletedActionType = Action<UserKingdomActionTypes.ChangeCropTypeCompleted, ChangeCropTypeCompletedPayloadType>;
export const changeCropTypeCompleted = (request: PostChangeCropTypeRequest, response: GetUserKingdomResponse): ChangeCropTypeCompletedActionType => ({type: UserKingdomActionTypes.ChangeCropTypeCompleted, payload: { request, response } });

export type ChangeCropTypeFailedPayloadType = { error: Error; };
export type ChangeCropTypeFailedActionType = Action<UserKingdomActionTypes.ChangeCropTypeFailed, ChangeCropTypeFailedPayloadType>;
export const changeCropTypeFailed = (error: Error): ChangeCropTypeFailedActionType => ({type: UserKingdomActionTypes.ChangeCropTypeFailed, payload: { error } });

export type RequestTrainingPayloadType = { request: PostTrainingRequest };
export type RequestTrainingActionType = Action<UserKingdomActionTypes.RequestTraining, RequestTrainingPayloadType>;
export const requestTraining = (request: PostTrainingRequest):RequestTrainingActionType => ({type: UserKingdomActionTypes.RequestTraining, payload: { request } });

export type RequestTrainingCompletedPayloadType = { request: PostTrainingRequest, response: GetUserKingdomResponse };
export type RequestTrainingCompletedActionType = Action<UserKingdomActionTypes.RequestTrainingCompleted, RequestTrainingCompletedPayloadType>;
export const requestTrainingCompleted = (request: PostTrainingRequest, response: GetUserKingdomResponse): RequestTrainingCompletedActionType => ({type: UserKingdomActionTypes.RequestTrainingCompleted, payload: { request, response } });

export type RequestTrainingFailedPayloadType = { error: Error; };
export type RequestTrainingFailedActionType = Action<UserKingdomActionTypes.RequestTrainingFailed, RequestTrainingFailedPayloadType>;
export const requestTrainingFailed = (error: Error): RequestTrainingFailedActionType => ({type: UserKingdomActionTypes.RequestTrainingFailed, payload: { error } });

export type CancelTrainingRequestPayloadType = { request: PostCancelTrainingRequest };
export type CancelTrainingRequestActionType = Action<UserKingdomActionTypes.CancelTrainingRequest, CancelTrainingRequestPayloadType>;
export const cancelRequestTraining = (request: PostCancelTrainingRequest):CancelTrainingRequestActionType => ({type: UserKingdomActionTypes.CancelTrainingRequest, payload: { request } });

export type CancelTrainingRequestCompletedPayloadType = { request: PostCancelTrainingRequest, response: GetUserKingdomResponse };
export type CancelTrainingRequestCompletedActionType = Action<UserKingdomActionTypes.CancelTrainingRequestCompleted, CancelTrainingRequestCompletedPayloadType>;
export const cancelRequestTrainingCompleted = (request: PostCancelTrainingRequest, response: GetUserKingdomResponse): CancelTrainingRequestCompletedActionType => ({type: UserKingdomActionTypes.CancelTrainingRequestCompleted, payload: { request, response } });

export type CancelTrainingRequestFailedPayloadType = { error: Error; };
export type CancelTrainingRequestFailedActionType = Action<UserKingdomActionTypes.CancelTrainingRequestFailed, CancelTrainingRequestFailedPayloadType>;
export const cancelRequestTrainingFailed = (error: Error): CancelTrainingRequestFailedActionType => ({type: UserKingdomActionTypes.CancelTrainingRequestFailed, payload: { error } });

export type UpdateLandsOrderByPayloadType = { orderBy: string };
export type UpdateLandsOrderByActionType = Action<UserKingdomActionTypes.UpdateLandsOrderBy, UpdateLandsOrderByPayloadType>;
export const updateLandsOrderBy = (orderBy: string):UpdateLandsOrderByActionType => ({type: UserKingdomActionTypes.UpdateLandsOrderBy, payload: { orderBy } });

export type OpenDemolishImprovementConfirmModalPayloadType = { improvementId: string };
export type OpenDemolishImprovementConfirmModalActionType = Action<UserKingdomActionTypes.OpenDemolishImprovementConfirmModal, OpenDemolishImprovementConfirmModalPayloadType>;
export const openDemolishImprovementConfirmModal = (improvementId: string):OpenDemolishImprovementConfirmModalActionType => ({type: UserKingdomActionTypes.OpenDemolishImprovementConfirmModal, payload: { improvementId } });

export type CloseDemolishImprovementConfirmModalPayloadType = { };
export type CloseDemolishImprovementConfirmModalActionType = Action<UserKingdomActionTypes.CloseDemolishImprovementConfirmModal, CloseDemolishImprovementConfirmModalPayloadType>;
export const closeDemolishImprovementConfirmModal = ():CloseDemolishImprovementConfirmModalActionType => ({type: UserKingdomActionTypes.CloseDemolishImprovementConfirmModal, payload: { } });

export type ToggleGrowthPayloadType = { request: PostToggleGrowthRequest };
export type ToggleGrowthActionType = Action<UserKingdomActionTypes.ToggleGrowth, ToggleGrowthPayloadType>;
export const toggleGrowth = (request: PostToggleGrowthRequest):ToggleGrowthActionType => ({type: UserKingdomActionTypes.ToggleGrowth, payload: { request } });

export type ToggleGrowthCompletedPayloadType = { };
export type ToggleGrowthCompletedActionType = Action<UserKingdomActionTypes.ToggleGrowthCompleted, ToggleGrowthCompletedPayloadType>;
export const toggleGrowthCompleted = (): ToggleGrowthCompletedActionType => ({type: UserKingdomActionTypes.ToggleGrowthCompleted, payload: { } });

export type ToggleGrowthFailedPayloadType = { error: Error; };
export type ToggleGrowthFailedActionType = Action<UserKingdomActionTypes.ToggleGrowthFailed, ToggleGrowthFailedPayloadType>;
export const toggleGrowthFailed = (error: Error): ToggleGrowthFailedActionType => ({type: UserKingdomActionTypes.ToggleGrowthFailed, payload: { error } });

export type UpdateUserSettingsPayloadType = { request: PostUpdateUserSettingsRequest };
export type UpdateUserSettingsActionType = Action<UserKingdomActionTypes.UpdateUserSettings, UpdateUserSettingsPayloadType>;
export const updateUserSettings = (request: PostUpdateUserSettingsRequest):UpdateUserSettingsActionType => ({type: UserKingdomActionTypes.UpdateUserSettings, payload: { request } });

export type UpdateUserSettingsCompletedPayloadType = { };
export type UpdateUserSettingsCompletedActionType = Action<UserKingdomActionTypes.UpdateUserSettingsCompleted, UpdateUserSettingsCompletedPayloadType>;
export const updateUserSettingsCompleted = (): UpdateUserSettingsCompletedActionType => ({type: UserKingdomActionTypes.UpdateUserSettingsCompleted, payload: { } });

export type UpdateUserSettingsFailedPayloadType = { error: Error; };
export type UpdateUserSettingsFailedActionType = Action<UserKingdomActionTypes.UpdateUserSettingsFailed, UpdateUserSettingsFailedPayloadType>;
export const updateUserSettingsFailed = (error: Error): UpdateUserSettingsFailedActionType => ({type: UserKingdomActionTypes.UpdateUserSettingsFailed, payload: { error } });

export type ToggleUserSettingsModalPayloadType = { };
export type ToggleUserSettingsModalActionType = Action<UserKingdomActionTypes.ToggleUserSettingsModal, ToggleUserSettingsModalPayloadType>;
export const toggleUserSettingsModal = (): ToggleUserSettingsModalActionType => ({type: UserKingdomActionTypes.ToggleUserSettingsModal, payload: { }})

export type DebugProcessTickPayloadType = { count: number };
export type DebugProcessTickActionType = Action<UserKingdomActionTypes.DebugProcessTick, DebugProcessTickPayloadType>;
export const debugProcessTick = (count: number):DebugProcessTickActionType => ({type: UserKingdomActionTypes.DebugProcessTick, payload: { count } });

export type DebugProcessTickCompletedPayloadType = { response: GetUserKingdomResponse };
export type DebugProcessTickCompletedActionType = Action<UserKingdomActionTypes.DebugProcessTickCompleted, DebugProcessTickCompletedPayloadType>;
export const debugProcessTickCompleted = (response: GetUserKingdomResponse): DebugProcessTickCompletedActionType => ({type: UserKingdomActionTypes.DebugProcessTickCompleted, payload: { response } });

export type DebugProcessTickFailedPayloadType = { error: Error; };
export type DebugProcessTickFailedActionType = Action<UserKingdomActionTypes.DebugProcessTickFailed, DebugProcessTickFailedPayloadType>;
export const debugProcessTickFailed = (error: Error): DebugProcessTickFailedActionType => ({type: UserKingdomActionTypes.DebugProcessTickFailed, payload: { error } });