import { Observable } from 'rxjs';
import { AjaxClient } from './ajax-client';
import { AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import PostRegisterRequest from '../types/messages/PostRegisterRequest';
import PostRegisterResponse from '../types/messages/PostRegisterResponse';
import PostLoginRequest from '../types/messages/PostLoginRequest';
import PostLoginResponse from '../types/messages/PostLoginResponse';
import PostResetPasswordRequest from '../types/messages/PostResetPasswordRequest';
import PostResetPasswordResponse from '../types/messages/PostResetPasswordResponse';
import PostUserKingdomResourceRequest from '../types/messages/PostUserKingdomResourceRequest';
import { GetAuthToken } from './authTokenHelpers';
import PostSubtractResourcesRequest from '../types/messages/PostSubtractResourcesRequest';
import PostCancelJobRequest from '../types/messages/PostCancelJobRequest';
import PostManageImprovementWorkersRequest from '../types/messages/PostManageImprovementWorkersRequest';
import PostManageJobWorkersRequest from '../types/messages/PostManageJobWorkersRequest';
import PostChangeCropTypeRequest from '../types/messages/PostChangeCropTypeRequest';
import PostDemolishImprovementRequest from '../types/messages/PostDemolishImprovementRequest';
import PostChangeProductionTypeRequest from '../types/messages/PostChangeProductionTypeRequest';
import PostRequestJobRequest from '../types/messages/PostRequestJobRequest';
import PostTrainingRequest from '../types/messages/PostTrainingRequest';
import PostCancelTrainingRequest from '../types/messages/PostCancelTrainingRequest';
import PostMarketSellRequest from '../types/messages/PostMarketSellRequest';
import PostMarketSearchRequest from '../types/messages/PostMarketSearchRequest';
import PostPurchaseRequest from '../types/messages/PostPurchaseRequest';
import PostToggleGrowthRequest from '../types/messages/PostToggleGrowthRequest';
import PostUpdateUserSettingsRequest from '../types/messages/PostUpdateUserSettingsRequest';
import JoinBattleRequest from '../types/messages/JoinBattleRequest';

export class ApiService {
  ajaxClient: AjaxClient
  baseUrl: string;
  headers: Record<string, string>

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.ajaxClient = new AjaxClient();
    this.headers = {
      "Authorization": "",
      "X-Clacks-Overhead": "GNU Terry Pratchett",
      "Content-Type": "application/json",
    }
  }

  private checkForToken = () => {
    if (this.headers.Authorization === "") {
      let authToken = GetAuthToken();
      if (authToken) {
        this.headers.Authorization = authToken;
      }
    }
  }

  healthCheck$ = () => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/HealthCheck`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response;
      })
    );

    return output$;
  }

  getNews$ = (page: number) => {
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/News/${page}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postRegisterUser$ = (request: PostRegisterRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<PostRegisterResponse>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/User/Register`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postLogin$ = (request: PostLoginRequest) => {
    //this.checkForToken();

    this.headers.Authorization = "";

    const response$: Observable<AjaxResponse<PostLoginResponse>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/User/Login`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postResetPassword$ = (request: PostResetPasswordRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<PostResetPasswordResponse>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/User/ResetPassword`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getUserKingdom$ = (userKingdomId: number) => {
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/UserKingdom/${userKingdomId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getActiveKingdoms$ = () => {
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/UserKingdom/ActiveKingdoms`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }


  postUpdateUserKingdomResource$ = (request: PostUserKingdomResourceRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Resource`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postSubtractUserKingdomResource$ = (request: PostSubtractResourcesRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Resource/Remove`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postCancelJob$ = (request: PostCancelJobRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Job/Cancel`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postManageJobWorkers$ = (request: PostManageJobWorkersRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Job/Workers`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postManageImprovementWorkers$ = (request: PostManageImprovementWorkersRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Improvement/Workers`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postDemolishImprovement$ = (request: PostDemolishImprovementRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Improvement/Demolish`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postChangeCropType$ = (request: PostChangeCropTypeRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Improvement/ChangeCropType`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postChangeProductionType$ = (request: PostChangeProductionTypeRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Improvement/ChangeProductionType`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postRequestChangeProductionTypeValues$ = (request: PostChangeProductionTypeRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Improvement/GetProductionChangeValues`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postRequestChangeCropTypeValues$ = (request: PostChangeCropTypeRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Improvement/GetCropChangeValues`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postRequestJob$ = (request: PostRequestJobRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Job/Request`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getGameRules$ = () => {
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/Rules`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postTrainingRequest$ = (request: PostTrainingRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Training/Request`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postCancelTrainingRequest$ = (request: PostCancelTrainingRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Training/Cancel`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getKingdomsLand$ = (kingdomId: number) => {
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/LandsSearch/Kingdom/${kingdomId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getSearchPlots$ = (kingdomId: number, address: string) => {
    
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/LandsSearch/Plot/${kingdomId}/${address}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getSearchUserKingdomDetail$ = (userKingdomId: string) => {
    
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/LandsSearch/Details/${userKingdomId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getNotifications$ = () => {

    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/Notifications`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getRedeemPackage$ = (kingdomId: number, notificationId: string) => {

    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/Notifications/Redeem/${kingdomId}/${notificationId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postJoinBattleRequest$ = (request: JoinBattleRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Combat/Request`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postAcknowledgeNotification$ = (notificationId: string) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Notifications/Acknowledge/${notificationId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postCloseAllNotifications$ = () => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Notifications/CloseAll`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postMarketSellRequest$ = (request: PostMarketSellRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Market/Sell`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postMarketCancelRequest$ = (saleId: string) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Market/Cancel/${saleId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postMarketBuyRequest$ = (saleId: string) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Market/Buy/${saleId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  getOwnMarketSales$ = (kingdomId: number) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/Market/OwnSales/${kingdomId}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postMarketSearchRequest$ = (request: PostMarketSearchRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Market/Search`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postPurchase$ = (request: PostPurchaseRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/Market/Purchase`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postToggleGrowth$ = (request: PostToggleGrowthRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/UserKingdom/Growth`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

  postUpdateUserSettings$ = (request: PostUpdateUserSettingsRequest) => {
    this.checkForToken();

    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .post({
        url: `${this.baseUrl}/User/Settings`,
        headers: this.headers,
        body: request
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }


  getDEBUGProcessTick$ = (count: number) => {
    this.checkForToken();
    
    const response$: Observable<AjaxResponse<string>> = this
      .ajaxClient
      .get({
        url: `${this.baseUrl}/UserKingdom/DEBUG/PROCESSTICK/${count}`,
        headers: this.headers
      })

    const output$ = response$.pipe(
      map(response => {
        return response.response;
      })
    );

    return output$;
  }

}