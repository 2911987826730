import LocalAuthToken from "../types/LocalAuthToken";

export function SetAuthToken(token: string) {
  // Save the Token for 1 Day
  window.localStorage.setItem("UplandKingdomsAuthToken", JSON.stringify({
      token: token,
      expires: (new Date()).getTime() +  86400000
    } as LocalAuthToken
  ));
}

export function GetAuthToken() {
  const authTokenString = window.localStorage.getItem("UplandKingdomsAuthToken");

  if (!authTokenString) {
    return null;
  }

  const tokenObject = JSON.parse(authTokenString);
  
  if ((new Date()).getTime() > tokenObject.expires) {
    window.localStorage.removeItem("UplandKingdomsAuthToken");
    return null;
  }

  return tokenObject.token;
}

export function RemoveAuthToken() {
  window.localStorage.removeItem("UplandKingdomsAuthToken");
}