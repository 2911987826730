import { Row, Col, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import "../../styles/style.scss"
import { useTranslation } from "react-i18next";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import SearchImprovement from '../../common/types/SearchImprovement';


interface StateProps {

};

interface DispatchProps {

}

interface OwnProps {
  improvement: SearchImprovement;
  address: string;
}

export type SearchImprovementDisplayProps = DispatchProps & StateProps & OwnProps;

export const SearchImprovementDisplay = (props: SearchImprovementDisplayProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  return (
    <div key={props.improvement.id}>
      <Row className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
          <Col><Image className="thumbnail-image" src={`/images/improvements/${props.improvement.typeString}.png`}/></Col>
          <Col className="sub-entry-margin-class">{t(`ImprovementTypes.${props.improvement.typeString}`)}</Col>
          <Col className="sub-entry-margin-class">{props.improvement.size} up2</Col>
          <Col className="sub-entry-margin-class">{props.address}</Col>
          <Col className="sub-entry-margin-class">{props.improvement.underConstruction ? t("UserKingdomPage.ImprovementsTab.UnderConstruction") : t("UserKingdomPage.ImprovementsTab.Completed")}</Col>
      </Row>
    </div>
  );
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const stateProps: StateProps = {

  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {

  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchImprovementDisplay);