import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Image, Form } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../components/footerComponent/footer';
import Header from '../../components/headerComponent/header';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.scss"
import PostLoginRequest from '../../common/types/messages/PostLoginRequest';
import PostRegisterRequest from '../../common/types/messages/PostRegisterRequest';
import PostResetPasswordRequest from '../../common/types/messages/PostResetPasswordRequest';
import { login, registerUser, resetPassword } from '../../store/actions/authenticationActions';
import { LoadingComponent } from '../../components/loadingComponent/loadingComponent';
import { useTranslation } from "react-i18next";

interface StateProps {
  accessCode?: string,
  isLoading: boolean,
  hasError: boolean,
  errorMessage: string,
  authTokenSet: boolean
};

interface DispatchProps {
  postLogin: (request: PostLoginRequest) => void;
  postRegisterUser: (request: PostRegisterRequest) => void;
  postResetPassword: (request: PostResetPasswordRequest) => void;
}

export type LoginViewProps = DispatchProps & StateProps;

export const LoginView = (props: LoginViewProps) => {

  const [viewState, setViewState] = useState("Login");
  const [priorViewState, setPriorViewState] = useState("Login");

  const[username, setUsername] = useState("");
  const[password, setPassword] = useState("");
  const[confirmPassword, setConfirmPassword] = useState("");
  const [formValidMessage, setFormValidMessage] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.authTokenSet) {
      navigate("/Play");
    }
  }, [navigate, props]);

  // create an event listener
  useEffect(() => {
    if (props.hasError && viewState === "OTP") {
      setViewState(priorViewState);
    }
  }, [priorViewState, props, viewState]);

  const updateUsername = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(changeEvent.currentTarget.value);
  }

  const updatePassword = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(changeEvent.currentTarget.value);
  } 

  const updateConfirmPassword = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(changeEvent.currentTarget.value);
  }
  
  const validateRegisterReset = () => {
    if (!username || username === '') {
      setFormValidMessage(t("Exceptions.MissingUsername").toString());
      return false;
    }

    if (!password || password === '') {
      setFormValidMessage(t("Exceptions.MissingPassword").toString());
      return false;
    }

    if (password.length < 8) {
      setFormValidMessage(t("Exceptions.PasswordInvalid").toString());
        return false;
    }

    if (password !== confirmPassword) {
      setFormValidMessage(t("Exceptions.PasswordsMustMatch").toString());
      return false;
    }

    setFormValidMessage('');
    return true;
  }

  const ValidateLogin = () => {
    if (!username || username === '') {
      setFormValidMessage(t("Exceptions.MissingUsername").toString());
      return false;
    }

    if (!password || password === '') {
        setFormValidMessage(t("Exceptions.MissingPassword").toString());
        return false;
    }

    if (password.length < 8) {
      setFormValidMessage(t("Exceptions.PasswordInvalid").toString());
        return false;
    }

    setFormValidMessage('');
    return true;
  }

  const sendLoginRequest = () =>
  {
    if (ValidateLogin()) {
      props.postLogin({
        username: username,
        password: password,
      } as PostLoginRequest);
    }
  }

  const sendRegisterRequest = () =>
  {
    if (validateRegisterReset()) {
      props.postRegisterUser({
        username: username,
        password: password,
      } as PostLoginRequest);

      setPriorViewState(viewState);
      setViewState("OTP");
    }
  }

  const sendResetRequest = () =>
  {
    if (validateRegisterReset()) {
      props.postResetPassword({
        username: username,
        password: password,
      } as PostLoginRequest);

      setPriorViewState(viewState);
      setViewState("OTP");
    }
  }

  const renderGreenTextBox = (message: string) => {
    return (
      <Row>
        <Col>
          <div className="green-text-box">
            <b>{message}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const renderRedTextBox = (message: string) => {
    return (
      <Row>
        <Col>
          <div className="red-text-box">
            <b>{message}</b>
          </div>
        </Col>
      </Row>
    );
  }

  const handleEnterKeyPress_Login = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      sendLoginRequest();
    }
  }

  const handleEnterKeyPress_Register = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      sendRegisterRequest();
    }
  }

  const handleEnterKeyPress_Reset = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      sendResetRequest();
    }
  }

  const renderOTPInstructions = () => {
    return (
      <Row>
        <div className="standard-text-box">
          <div>
            <div className="secondary-header"><center>{t("LoginPage.OTPInstructions.VerifyYourSelf")}</center></div>
            {renderGreenTextBox(props.accessCode!)}
            <ol className ="centered-list standard-text-md">
              <li>{t("LoginPage.OTPInstructions.Step1")}</li>
              <li>{t("LoginPage.OTPInstructions.Step2")}</li>
              <li>{t("LoginPage.OTPInstructions.Step3")}</li>
              <li>{t("LoginPage.OTPInstructions.Step4")}</li>
              <li>{t("LoginPage.OTPInstructions.Step5")}</li>
              <li>{t("LoginPage.OTPInstructions.Step6")}</li>
              <li>{t("LoginPage.OTPInstructions.Step7")}</li>
              <li>{t("LoginPage.OTPInstructions.Step8")}</li>
            </ol>
          </div>
        </div>
      </Row>
    );
  }

  const renderLogin = () => {
    return (
      <Row  className="standard-text-box">
        <Form className="login-form" onSubmit={e => {e.preventDefault()}}>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("CommonWords.Username")}</Form.Label>
            <Form.Control className="form-text-box" type="text" 
              value={username} onChange={updateUsername}/>
          </Form.Group>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("LoginPage.Labels.Password")}</Form.Label>
            <Form.Control type="password" className="form-text-box" placeholder={t("LoginPage.Labels.PasswordPlaceholder").toString()} 
              value={password} onChange={updatePassword} onKeyPress={handleEnterKeyPress_Login}/>
          </Form.Group>
          <br></br>
          <Form.Group>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col className="darkStone-button" onClick={sendLoginRequest}>
                <div className="quaternary-header">{t("Buttons.Login")}</div>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Row>
    );
  }

  const renderRegister = () => {
    return (
      <Row className="standard-text-box">
        <Form className="login-form" onSubmit={e => {e.preventDefault()}}>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("CommonWords.Username")}</Form.Label>
            <Form.Control className="form-text-box" type="text"
              value={username} onChange={updateUsername}/>
          </Form.Group>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("LoginPage.Labels.Password")}</Form.Label>
            <Form.Control type="password" className="form-text-box" placeholder={t("LoginPage.Labels.PasswordPlaceholder").toString()}
              value={password} onChange={updatePassword}/>
          </Form.Group>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("LoginPage.Labels.ConfirmPassword")}</Form.Label>
            <Form.Control type="password" className="form-text-box" placeholder={t("LoginPage.Labels.PasswordPlaceholder").toString()}
              value={confirmPassword} onChange={updateConfirmPassword} onKeyPress={handleEnterKeyPress_Register}/>
          </Form.Group>
          <br></br>
          <Form.Group>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col className="darkStone-button" onClick={sendRegisterRequest}>
                <div className="quaternary-header">{t("Buttons.Register")}</div>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Row>
    );
  }

  const renderReset = () => {
    return (
      <Row className="standard-text-box">
        <Form className="login-form" onSubmit={e => {e.preventDefault()}}>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("CommonWords.Username")}</Form.Label>
            <Form.Control className="form-text-box" type="text"
              value={username} onChange={updateUsername}/>
          </Form.Group>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("LoginPage.Labels.Password")}</Form.Label>
            <Form.Control type="password" className="form-text-box" placeholder={t("LoginPage.Labels.PasswordPlaceholder").toString()}
              value={password} onChange={updatePassword}/>
          </Form.Group>
          <Form.Group>
            <Form.Label className="quaternary-header">{t("LoginPage.Labels.ConfirmPassword")}</Form.Label>
            <Form.Control type="password" className="form-text-box" placeholder={t("LoginPage.Labels.PasswordPlaceholder").toString()}
              value={confirmPassword} onChange={updateConfirmPassword} onKeyPress={handleEnterKeyPress_Reset}/>
          </Form.Group>
          <br></br>
          <Form.Group>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col className="darkStone-button" onClick={sendResetRequest}>
                <div className="quaternary-header">{t("Buttons.ResetPassword")}</div>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Row>
    );
  }

  const renderPageView = () => {
    if (viewState === "Register") {
      return renderRegister();
    } else if (viewState === "Reset") {
      return renderReset();
    } else if (viewState === "OTP") {
      return renderOTPInstructions();
    } else {
      return renderLogin();
    }
  }

  const renderLoginView = () => {
    return (
      <Container fluid="md">
        <Row>
          <div className="primary-header">{t("AppName")}</div>
        </Row>
        <Row className="m-auto logo-image-large">
          <Image src="/images/UplandKingdoms_Logo.png"/>
        </Row>
        <br></br>
        <Row>
          <Col className="darkStone-button" onClick={() => setViewState("Login")}>
            <div className="quaternary-header">{t("Buttons.Login")}</div>
          </Col>
          <Col className="darkStone-button" onClick={() => setViewState("Register")}>
            <div className="quaternary-header">{t("Buttons.Register")}</div>
          </Col>
          <Col className="darkStone-button" onClick={() => setViewState("Reset")}>
            <div className="quaternary-header">{t("Buttons.ResetPassword")}</div>
          </Col>
        </Row>
        {props.isLoading && <LoadingComponent />}
        {!props.isLoading && formValidMessage !== '' && renderRedTextBox(formValidMessage)}
        {!props.isLoading && props.hasError && renderRedTextBox(t(`Exceptions.${props.errorMessage}`))}
        {!props.isLoading && renderPageView()}
      </Container>
    );
  }

  return (
    <>
      <div className="footer-padding">
        <Header />
        {renderLoginView()}
      </div>
      <Footer />
    </>
  )
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    accessCode: state.AuthenticationState.accessCode,
    isLoading: state.AuthenticationState.isLoading,
    hasError: state.AuthenticationState.hasError,
    errorMessage: state.AuthenticationState.errorMessage,
    authTokenSet: state.AuthenticationState.authTokenSet
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    postLogin: (request: PostLoginRequest) => dispatch(login(request)),
    postRegisterUser: (request: PostRegisterRequest) => dispatch(registerUser(request)),
    postResetPassword: (request: PostResetPasswordRequest) => dispatch(resetPassword(request)),
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
