import { Row, Col, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../styles/style.scss"
import Resource from '../../common/types/userKingdom/resource';
import { useTranslation } from "react-i18next";
import Upkeep from '../../common/types/userKingdom/upkeep';

export interface OwnProps {
  upkeep: Upkeep,
  resources: Resource[],
  saveResource: (resource: Resource, upkeepPriority: number, maxAmount?: number) => void;
}

export const UpkeepDisplayTableEntry = (props: OwnProps) => {
  const [expanded, setExpanded] = useState(props.resources.length > 0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const availableResources = props.resources
    .filter((x) => x.upkeepPriority !== 0)
    .reduce((a, b) => a = a + b.amount, 0);

  const resourceBounsColor = () => {
    let resourceCounts = props.resources
      .filter((x) => x.upkeepPriority === 2)
      .length;

    let neededAmt = props.upkeep.amount / (2 * resourceCounts);

    let varietyMeetAmounts =  props.resources
      .filter((x) => x.upkeepPriority === 2 && x.amount >= neededAmt)
      .length;

    if (props.upkeep.amount > props.resources
      .filter((x) => x.upkeepPriority === 2 || x.upkeepPriority === 1)
      .reduce((a, b) => a + b.amount, 0)) {
        return "red"
    }

    if (props.upkeep.resourcesForNeutral.includes(varietyMeetAmounts)) {
      return "green";
    } else if (varietyMeetAmounts > Math.max(...props.upkeep.resourcesForNeutral)) {
      return "blue";
    } else {
      return "yellow";
    }
  }

  const toggleExpanded = (event: any) => {
    setExpanded(!expanded);
  }

  const renderExpandToggle = () => {
    if (expanded) {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleDown}/></Col>
      )
    } else {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleRight}/></Col>
      );
    }
  }

  const updateUpkeepFlag = (resource: Resource) => {
    props.saveResource(resource, (resource.upkeepPriority + 1) % 3, resource.userSetMaximum);
  }

  const renderExpanded = () => {
    if (expanded) {
      return (
        <>
        <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col></Col>
            <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Resource")}</Col>
            <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Amount")}</Col>
            <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Upkeep")}</Col>
        </Row>
        {props.resources.map((x) => 
          <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
            <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
            <Col className="sub-entry-margin-class">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
            <Col className="sub-entry-margin-class">{x.amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1})}</Col>
            {x.upkeepPriority === 0 && 
              <Col className="sub-entry-margin-class morris-lg">
                <div className="errorRed-button" onClick={() => updateUpkeepFlag(x)}>
                  {t("UserKingdomPage.ResourceTab.UpkeepExclude")}
                </div>
              </Col>}
            {x.upkeepPriority === 1 && 
              <Col className="sub-entry-margin-class morris-lg">
                <div className="iceBlue-button" onClick={() => updateUpkeepFlag(x)}>
                  {t("UserKingdomPage.ResourceTab.UpkeepSecondary")}
                </div>
              </Col>}
            {x.upkeepPriority === 2 && 
              <Col className="sub-entry-margin-class morris-lg">
                <div className="appleGreen-button" onClick={() => updateUpkeepFlag(x)}>
                  {t("UserKingdomPage.ResourceTab.UpkeepPrimary")}
                </div>
              </Col>}
          </Row>)}
      </>
      )
    }
    return (<></>);
  }

  return (
    <>
      <Row onClick={toggleExpanded} className="resource-table standard-text-md">
        <Col>
          {t(`UpkeepTypes.${props.upkeep.typeString}`)}
        </Col>
        <Col>
          {t("GameDate.Day", { count: props.upkeep.nextDueDate, ordinal: true })}{t(`GameDate.${props.upkeep.nextDueSeason}`, { count: props.upkeep.nextDueYear })}
        </Col>
        <Col className="sub-entry-margin-class">
          <Row>
            <Col>
              <div className={`morris-sm ${availableResources >= props.upkeep.amount ? "bgGreen" : "bgRed"}`}>
                {availableResources.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1})} / {props.upkeep.amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1})}
              </div>
            </Col>
            <Col className="py-2" xs={1}>
              <div className={`resource-upkeep-color ${resourceBounsColor()}`}></div>
            </Col>
          </Row>
        </Col>
        {renderExpandToggle()}
      </Row>
      {renderExpanded()}
    </>
  );
}