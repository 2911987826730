import { KeyValuePair } from "../KeyValuePair";

export const combatWorkerTypeEnum: KeyValuePair[] = [
  { key: "Peasant"     , value: "0"   },
  { key: "LightArcher" , value: "2"   },
  { key: "Archer"      , value: "3"   },
  { key: "Spearmen"    , value: "4"   },
  { key: "Pikemen"     , value: "5"   },
  { key: "Skirmisher"  , value: "6"   },
  { key: "Swordsmen"   , value: "7"   },
  { key: "LightCavalry", value: "8"   },
  { key: "Knight"      , value: "9"   },
];