import { Row, Col, Image, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import "../../styles/style.scss"
import ResourceStorage from '../../common/types/userKingdom/resourceStorage';
import Resource from '../../common/types/userKingdom/resource';
import { useTranslation } from "react-i18next";
import ConfirmModal from '../confirmModal';

export interface OwnProps {
  resourceStorage: ResourceStorage,
  resources: Resource[],
  saveResource: (resource: Resource, upkeepPriority: number, maxAmount?: number) => void;
  subtractResources: (resource: Resource, amount: number) => void;
  updateResourceSetMax: (resource: Resource, amount?: number) => void;
}

export const ResourceDisplayTableEntry = (props: OwnProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [removeResource, setRemoveResource] = useState({
    type: 0,
    amount: 0
  } as Resource);
  const [removeAmount, setRemoveAmount] = useState(0);
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const toggleExpanded = (event: any) => {
    setExpanded(!expanded);
  }

  const renderExpandToggle = () => {
    if (expanded) {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleDown}/></Col>
      )
    } else {
      return (
        <Col className="align-to-right"><FontAwesomeIcon size="1x" icon={faAngleRight}/></Col>
      );
    }
  }

  const ZeroResourceCheck = (amount: number, subtractAmount: number) => {
    if (amount - subtractAmount < 0) {
      return amount;
    }

    return subtractAmount;
  }

  const removeResources = (resource: Resource, amount: number) => {
    if (resource.amount === 0) {
      return;
    }

    setRemoveResource(resource);
    setRemoveAmount(amount);
    setShowModal(true);
  }

  const modalConfirm = () => {
    setShowModal(false);
    props.subtractResources(removeResource, ZeroResourceCheck(removeResource.amount, removeAmount));
  }

  
  const renderExpanded = () => {
    if (expanded) {
      return (
        <>
          <Row className={`resource-table-sub-entry dark-wheat-background ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col></Col>
              <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Resource")}</Col>
              <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Amount")}</Col>
              <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Max")}</Col>
              <Col className="sub-entry-margin-class">{t("UserKingdomPage.ResourceTab.Remove")}</Col>
          </Row>
          {props.resources.map((x) => 
            <Row key={x.type} className={`resource-table-sub-entry ${isMobile ? "standard-text-sm": "standard-text"}`}>
              <Col><Image className="thumbnail-image" src={`/images/resources/${x.typeString}.png`}/></Col>
              <Col className="sub-entry-margin-class">{t(`Resources.${x.typeString}`, { count: x.amount })}</Col>
              <Col className="sub-entry-margin-class">{x.amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0})}</Col>
              <Col className="sub-entry-margin-class login-form">
                <Form.Control className="form-text-box" type="text" 
                  value={x.userSetMaximum || ""} onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                    if (changeEvent.currentTarget.value) {
                      props.updateResourceSetMax(x, parseInt(changeEvent.currentTarget.value))
                    } else {
                      props.updateResourceSetMax(x, undefined)
                    }
                }}
                onBlur={() => props.saveResource(x, x.upkeepPriority, x.userSetMaximum)}/>
              </Col>
              <Col className="sub-entry-margin-class">
                <Row>
                  <Col><div className="iceBlue-button standard-text-sm m-1" onClick={() => removeResources(x, 10)}>-10</div></Col>
                  <Col><div className="iceBlue-button standard-text-sm m-1" onClick={() => removeResources(x, 100)}>-100</div></Col>
                  <Col><div className="iceBlue-button standard-text-sm m-1" onClick={() => removeResources(x, 1000)}>-1000</div></Col>
                </Row>
              </Col>
            </Row>)}
        </>
      )
    }
    return (<></>);
  }

  return (
    <>
      <ConfirmModal showModal={showModal} confirmAction={modalConfirm} denyAction={() => setShowModal(false)} />
      <Row onClick={toggleExpanded} className="resource-table standard-text-md">
        <Col>
          {t(`StorageTypes.${props.resourceStorage.typeString}`)}
        </Col>
        <Col>
          {props.resources.reduce((a, b) => a = a + b.amount, 0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0})} / {props.resourceStorage.amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0})}
        </Col>
        {renderExpandToggle()}
      </Row>
      {renderExpanded()}
    </>
  );
}